import React, { useState, useEffect } from "react";
import SideMenu from "../../../Components/Commons/SideMenu";
import {
  Container,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
} from "@material-ui/core";
import { Link } from '@mui/material';
import { Site } from "../../../utils/icons";
import CommonButton from "../../../Components/FormComponents/CommonButton";
import text from "../../../utils/text";
import { useHistory } from "react-router-dom";
import { deleteSite, getAllSites } from "../../../Axios/Call/AuthApi";
import { withStyles } from "@material-ui/core/styles";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import ModalGeneral from "../../../Components/Commons/ModalGeneral/ModalGeneral";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import moment from "moment";
import FilterTextList from "../../../Components/Commons/FilterTextList/FilterTextList";
import HeaderSections from "../../../Components/Commons/HeaderSections/HeaderSections";

const SitesList = () => {
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [sites, setSites] = useState([]);
  const [filteredSites, setFilteredSites] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [section, setSection] = useState("on-going");
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const history = useHistory();

  const sections = [
    {
      action: "on-going",
      name: text.SitesList.section1,
    },
    {
      action: "not-started",
      name: text.SitesList.section2,
    },
    {
      action: "finished",
      name: text.SitesList.section3,
    },
    {
      action: "not-signed",
      name: text.SitesList.section4,
    },
  ];

  useEffect(() => {
    populateTable();

    return () => {
      setLoadingApi(false);
  }
  }, [section]);

  const populateTable = () => {
    setLoadingApi(true);
    getAllSites(section)
      .then((response) => {
        setSites(response.data);
        setFilteredSites(response.data);
        setLoadingApi(false);
     
      })
      .catch((error) => {
        setErrorApi(true);
        setLoadingApi(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const StyledTableCell = withStyles((theme) => ({
    root: {
      backgroundColor: "#345369",
      fontSize: 14,
      fontFamily: ["roboto", "-apple-system"].join(","),
      color: "white",
      wordWrap: "break-word",
      border: "none",
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const StyledTableSortLabel = withStyles((theme) => ({
    root: {
      color: "white",
      "&:hover": {
        color: "white",
      },
      "&$active": {
        color: "white",
      },
    },
    active: {},
    icon: {
      color: "inherit !important",
    },
  }))(TableSortLabel);

  const StyledTablePagination = withStyles((theme) => ({
    root: {
      color: "#345369",
    },
    menuItem: {
      fontFamily: ["roboto", "-apple-system"].join(","),
    },
    caption: {
      fontFamily: ["roboto", "-apple-system"].join(","),
    },
    selectIcon: {
      color: "white",
    },
  }))(TablePagination);

  const onDeleteClient = () => {
    deleteSite(openModalDelete)
      .then((response) => {
        setOpenModalDelete(false);
        populateTable();
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertError}
        />
      )}
      <ModalGeneral
        isOpenModal={openModalDelete}
        title={""}
        description={
          "Êtes-vous certain de vouloir supprimer le chantier définitivement?"
        }
        button1Text={"Oui"}
        button2Text={"Annuler"}
        onClick1={() => onDeleteClient()}
        onClick2={() => setOpenModalDelete(false)}
        isPassword
      />
      <SideMenu>
        <Container style={{ maxWidth: "none" }}>
          <Grid container>
            <Grid item xs={12} align="left" style={{paddingTop:20}}>
              <div style={{ display: "flex" }}>
                <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                  <Site fill={"#345369"} size={38} />
                </div>
                <Typography variant="h2" >
                  {text.SitesList.title}
                </Typography>
              </div>
            </Grid>
            <Grid item md={8} align="left" style={{ marginTop: 20 }}>
              <Grid container>
                <HeaderSections
                  setSection={setSection}
                  section={section}
                  sections={sections}
                  variant="h6"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} align="right" style={{ marginTop: 10 }}>
              <CommonButton
                text={text.SitesList.ctaAdd}
                onClick={() => history.push("/site-detail/information")}
                style={{ width: 200 }}
              />
            </Grid>
            <Grid item xs={12} md={4} align="left" style={{ marginTop: 20 }}>
              <FilterTextList allItems={sites} setFiltered={setFilteredSites} />
            </Grid>
            <Grid item xs={12}>
              {loadingApi ? (
                <LoadingButton />
              ) : (
                <Grid
                  container
                  justifyContent="center"
                  style={{ marginTop: 20 }}
                >
                  <Grid item xs={12} md={12}>
                    <TableContainer>
                      <Table style={{ width: "100%" }}>
                        <TableHead className="tableBackground">
                          <TableRow>
                            <StyledTableCell align="left">
                              <StyledTableSortLabel
                                active={orderBy === "name"}
                                direction={orderBy === "name" ? order : "asc"}
                                onClick={createSortHandler("name")}
                              >
                                {text.SitesList.site}
                              </StyledTableSortLabel>
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              {text.SitesList.client}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {text.SitesList.address}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <StyledTableSortLabel
                                active={orderBy === "starting_date"}
                                direction={
                                  orderBy === "starting_date" ? order : "asc"
                                }
                                onClick={createSortHandler("starting_date")}
                              >
                                {text.SitesList.starting_date}
                              </StyledTableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <StyledTableSortLabel
                                active={orderBy === "ending_date"}
                                direction={
                                  orderBy === "ending_date" ? order : "asc"
                                }
                                onClick={createSortHandler("ending_date")}
                              >
                                {text.SitesList.ending_date}
                              </StyledTableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {text.SitesList.commercial}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {text.SitesList.conducteur}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {text.SitesList.assistant}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {text.SitesList.price}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {text.SitesList.advancement}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {text.SitesList.action}
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {stableSort(
                            filteredSites,
                            getComparator(order, orderBy)
                          )
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((site) => (
                              <StyledTableRow key={site.id}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align={"left"}
                                >
                                  <Typography component="span">
                                   <Link 
                                   style={{textDecoration:'none', color:'#345369', cursor:'pointer'}}
                                   onClick={() => {
                                        history.push(
                                          `/site-detail/information/${site.id}`
                                        );
                                      }}
                                   > {site.name}</Link>
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {`${site?.client?.name} ${site?.client?.surname}`}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {site.address}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {moment(site?.starting_date).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {moment(site?.ending_date).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {
                                      site?.client?.client_assignation?.find(
                                        (assignation) =>
                                          assignation.type === "commercial"
                                      )?.business?.name
                                    }
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {
                                      site?.client?.client_assignation?.find(
                                        (assignation) =>
                                          assignation.type === "conducteur"
                                      )?.business?.name
                                    }
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {
                                      site?.client?.client_assignation?.find(
                                        (assignation) =>
                                          assignation.type === "assistant"
                                      )?.business?.name
                                    }
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  {site.price}€
                                </TableCell>
                                <TableCell align="left">
                                  {site.advancement_global?.toFixed(2)} %
                                </TableCell>
                                <TableCell align="right">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <AiFillEdit
                                      size={24}
                                      color="#345369"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        history.push(
                                          `/site-detail/information/${site.id}`
                                        );
                                      }}
                                    />
                                    <AiFillDelete
                                      size={24}
                                      color="#900C3F"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        setOpenModalDelete(site.id)
                                      }
                                    />
                                  </div>
                                </TableCell>
                              </StyledTableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <StyledTablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={sites.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage={text.General.rowXPage}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Container>
      </SideMenu>
    </>
  );
};

export default SitesList;
