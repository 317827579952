import { useState, useEffect, useCallback } from "react";
import {
  Grid,
  MenuItem,
  TextField,
  Typography,
  Divider,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import text from "../../../utils/text";
import { AiFillDelete } from "react-icons/ai";
import {
  createArtisan,
  deleteSiteCategory,
  deleteSiteItem,
  getAllArtisanItem,
  getArticleCategory,
  getArticles,
  getArtisan,
  getCrafts,
  getSupplier,
  getUnits,
  storeArticle,
  storeSupplier,
  storeUnit,
} from "../../../Axios/Call/AuthApi";
import AlertGeneral from "../../Commons/AlertGeneral/AlertGeneral";
import CommonButton from "../../FormComponents/CommonButton";
import LoadingButton from "../../Commons/LoadingButton/LoadingButton";
import ArtisanLine from "../../BusinessComponents/CreateEditQuote/ArtisanLine";
import { Articles, Artisan } from "../../../utils/icons";
import ArticleLine from "../../BusinessComponents/CreateEditQuote/ArticleLine";
import { useSelector } from "react-redux";
import ModalAddArticles from "../../../Containers/BusinessPages/ArticlesList/ModalAddArticle";
import ModalAddArtisan from "../../../Containers/BusinessPages/ArtisanList/ModalAddArtisan";
import ModalAddSupplier from "../../../Containers/BusinessPages/SupplierList/ModalAddSupplier";
import { randomString } from "../../../utils/utils";
import Fab from "@mui/material/Fab";
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from "@mui/icons-material/Add";

import ClearIcon from '@mui/icons-material/Clear';


const QuoteBody = ({
  quoteObject,
  isQuote,
  setQuoteObject,
  createQuoteHandler,
}) => {
  //Artisan state
  const [artisans, setArtisans] = useState([]);
  const [units, setUnits] = useState([]);
  //Article state
  const [articles, setArticles] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [artisanWork, setArtisanWork] = useState([]);
  //Add article state
  const [openModalAddArticle, setOpenModalAddArticle] = useState(false);
  const [openModalAddSupplier, setOpenModalAddSupplier] = useState(false);
  const [categories, setCategories] = useState([]);
  //Add artisan state
  const [crafts, setCrafts] = useState([]);
  const [openModalAddArtisan, setOpenModalAddArtisan] = useState(false);
  //Loading and error helper
  const [errorApi, setErrorApi] = useState(false);
  const [loadingApiGet, setLoadingApiGet] = useState(false);
  const [loadingApiAddArtisan, setLoadingApiAddArtisan] = useState(false);
  const clientMode = useSelector((state) => state.dashboard.clientMode);

  const setTVAGlobally = useCallback(
    (tva, category) =>
      setQuoteObject({
        ...quoteObject,
        site_categories: quoteObject.site_categories.map((cat) => ({
          ...cat,
          tva: cat.level.startsWith(category.level) ? tva : cat.tva,
          site_items: cat.site_items.map((item) => ({
            ...item,
            tva: cat.level.startsWith(category.level) ? tva : item.tva,
          })),
        })),
      }),
    [quoteObject, setQuoteObject]
  );

  const useStyles = makeStyles({
    label: {
      fontFamily: ["Roboto", "-apple-system"].join(","),
      fontWeight: 700,
      color: "#2A3254",
    },
    input: {
      fontFamily: ["Roboto", "-apple-system"].join(","),
      fontSize: 14,
    },
  });

  const classes = useStyles();

  useEffect(() => {
    let mounted = true;
    if (openModalAddArticle && !categories.length) {

      if(mounted){
      getArticleCategory()
        .then((response) => {
          setCategories(response.data);
        })
        
        
        .catch((error) => {
          setErrorApi(true);
        });
      }
    }

    return function cleanup() {
mounted=false;
    }
  }, [openModalAddArticle]);



  useEffect(() => {

    let mounted=true;

    if(mounted){
    setLoadingApiGet(true);
    getArticles() //Articles
      .then((response) => {
        setArticles(response.data);
        getArtisan() //Artisans
          .then((responseArtisan) => {
            setArtisans(responseArtisan.data);
            getUnits() //Units
              .then((responseUnit) => {
                setUnits(responseUnit.data);
                getCrafts() //Crafts
                  .then((response) => {
                    setCrafts(response.data);
                    getSupplier()
                      .then((response) => {
                        
                        setSuppliers(
                          response.data.map((supplier) => {
                            return { name: supplier.company || supplier.name, id: supplier.id };
                          })
                        );
                        getAllArtisanItem()
                          .then((response) => {
                            setLoadingApiGet(false);
                            setArtisanWork(response.data);
                          })
                          .catch((error) => {
                            setLoadingApiGet(false);
                            setErrorApi(true);
                          });
                      })
                      .catch((error) => {
                        setLoadingApiGet(false);
                        setErrorApi(true);
                      });
                  })
                  .catch((error) => {
                    setErrorApi(true);
                    setLoadingApiGet(false);
                  });
              })
              .catch((error) => {
                setErrorApi(true);
                setLoadingApiGet(false);
              });
          })
          .catch((error) => {
            setErrorApi(true);
            setLoadingApiGet(false);
          });
      })
      .catch((error) => {
        setErrorApi(true);
      });
    }



      return function cleanup() {  
        mounted=false;
            }

  }, []);




  

  const filterCategory = () => {
    let orderedCategories = [];
    let categories = quoteObject?.site_categories;
    categories?.forEach((categoryFirstLevel) => {
      if (!categoryFirstLevel?.parent) {
        //It has no parent
        orderedCategories.push(categoryFirstLevel);
        //Second level
        categories.forEach((categorySecondLevel) => {
          if (categorySecondLevel.parent === categoryFirstLevel.level) {
            orderedCategories.push(categorySecondLevel);
            //Third level
            categories.forEach((categoryThirdLevel) => {
              if (categoryThirdLevel.parent === categorySecondLevel.level) {
                orderedCategories.push(categoryThirdLevel);
              }
            });
          }
        });
      }
    });

    return orderedCategories;
  };

  const onAddHandler = ({ parentLevel, importance }) => {
    let level =
      quoteObject.site_categories.filter(
        (filteredCategory) => !filteredCategory.parent
      ).length + 1;
    if (parentLevel) {
      const currentLevel =
        quoteObject.site_categories.filter(
          (filteredCategory) => filteredCategory.parent === parentLevel
        ).length + 1;
      level = `${parentLevel}${currentLevel}`;
    }

    setQuoteObject({
      ...quoteObject,
      site_categories: quoteObject?.site_categories
        ?.map((cat) => ({ ...cat, lastAdded: false }))
        ?.concat({
          level: `${level}.`,
          is_selected: true,
          parent: parentLevel ?? undefined,
          name: "",
          site_items: [],
          importance: importance,
          lastAdded: true,
          feId: randomString(),
        }),
    });
  };

  const onChangeLevelNameHandler = ({ name, level }) => {
    setQuoteObject({
      ...quoteObject,
      site_categories: quoteObject?.site_categories?.map((category) =>
        category.level === level ? { ...category, name: name } : { ...category }
      ),
    });
  };

  const calculatePrice = (objectQuote) => {
    let total_amount = 0;
    let total_amount_tva = 0;
    let total_amount_no_tva = 0;
    objectQuote?.site_categories.forEach((category) => {
      category.site_items.forEach((item) => {
        total_amount_no_tva += item.total_price;
        total_amount_tva += (item.total_price * item.tva) / 100;
      });
    });
    total_amount = total_amount_no_tva + total_amount_tva;

    return { total_amount, total_amount_tva, total_amount_no_tva };
  };

  useEffect(() => {
    let mounted = true;
    if(mounted){

    setQuoteObject({
      ...quoteObject,
      ...calculatePrice(quoteObject),
    });    
  }

  return function cleanup(){
    mounted=false;
  }
  }, [quoteObject.site_categories]);

  const onDeleteCategoryHandler = ({ level }) => {
    const foundCategory = quoteObject?.site_categories?.find(
      (category) => category.level === level
    );

    let newCategories = [];
    let parentsSub = [];

    //Take all the sub parent
    quoteObject?.site_categories?.forEach((category) => {
      if (category.parent === level) {
        parentsSub.push(category.level);
      }
    });

    quoteObject?.site_categories?.forEach((category) => {
      if (
        category.parent !== level &&
        category.level !== level &&
        !parentsSub.find((parent) => parent === category.parent)
      ) {
        newCategories.push(category);
      }
    });

    let renameCategory = [];
    newCategories.forEach((category, index) => {
      if (!category.parent) {
        renameCategory.push({ ...category, level: `${index + 1}.` });
      }
      newCategories
        .filter((subCate) => subCate.parent === category.level)
        .forEach((subCatFound, subIndex) => {
          renameCategory.push({
            ...subCatFound,
            level: `${index + 1}.${subIndex + 1}.`,
            parent: `${index + 1}.`,
          });
          newCategories
            .filter((subCate2) => subCate2.parent === subCatFound.level)
            .forEach((subCatFound2, subIndex2) => {
              renameCategory.push({
                ...subCatFound2,
                level: `${index + 1}.${subIndex + 1}.${subIndex2 + 1}.`,
                parent: `${subIndex + 1}.`,
              });
            });
        });
    });

    if (foundCategory?.id) {
      deleteSiteCategory(foundCategory?.id)
        .then((response) => {
          setQuoteObject({
            ...quoteObject,
            site_categories: renameCategory,
          });
          //createQuoteHandler({
          //  ...quoteObject,
          //  site_categories: renameCategory,
          //});
        })
        .catch((error) => {
          setErrorApi(true);
        });
    } else {
      //createQuoteHandler({
      //  ...quoteObject,
      //  site_categories: renameCategory,
      //});
      setQuoteObject({
        ...quoteObject,
        site_categories: renameCategory,
      });
    }
  };

  //----------------- Artisan -----------------------
  // Add ad artisan item
  const addArtisanItem = ({ level }) => {
    const categoryFound = quoteObject?.site_categories?.find(
      (category) => category.level === level
    );

    const firstArtisanFoundId = categoryFound?.site_items?.find(
      (item) => item.artisan_id
    )?.artisan_id;

    setQuoteObject({
      ...quoteObject,
      site_categories: quoteObject?.site_categories?.map((category) =>
        category.level === level
          ? {
              ...category,
              site_items: category.site_items
                .map((item) => ({ ...item, lastAdded: false }))
                .concat({
                  artisan_work_name: "",
                  artisan_id: firstArtisanFoundId ?? 0,
                  unit_id: 2,
                  quantity: 1,
                  buy_price: 0,
                  selling_price: 0,
                  margin_percentage: 0,
                  tva: 20,
                  total_price: 0,
                  type: "artisan",
                  lastAdded: true,
                }),
            }
          : {
              ...category,
              site_items: category.site_items.map((item) => ({
                ...item,
                lastAdded: false,
              })),
            }
      ),
    });
  };

  //----------------- Change the value of artisan row ------------------
  const onChangeItemHandler = ({
    type,
    value,
    index,
    level,
    artisan_work_object = null,
  }) => {
    console.log("artisan_work_object", artisan_work_object);
    const findedCategory = quoteObject.site_categories.find(
      (category) => category.level === level
    );

    const findedItem = findedCategory.site_items.find(
      (item, indexItem) => indexItem === index
    );

    if (type === "article_id") {
      value = { ...value, article_id: value.id };
    }

    if (artisan_work_object) {
      value = { ...artisan_work_object };
    }

    let totalPriceItem = findedItem.total_price;
    let marginPercentage = findedItem.margin_percentage;
    let totalPriceTva = quoteObject.total_amount_tva;
    let totalPriceNoTva = quoteObject.total_amount_no_tva;
    let totalPrice = quoteObject.total_amount;
    let sellingPrice = findedItem.selling_price;

    if (type === "buy_price") {
      marginPercentage =
        ((findedItem.selling_price - value) * 100) / findedItem.selling_price;
    }
    if (type === "selling_price") {
      sellingPrice = value;
      marginPercentage = ((value - findedItem.buy_price) * 100) / value;
      totalPriceItem = findedItem.quantity * value;
    }

    if (type === "margin_percentage" && findedItem.buy_price) {
      const calculateSellingPrice =
        parseFloat(findedItem.buy_price) * (1 / (1 - parseFloat(value / 100)));

      console.log("value", value);
      console.log("calculateSellingPrice", calculateSellingPrice);

      totalPriceItem = findedItem.quantity * calculateSellingPrice.toFixed(2);
      sellingPrice = calculateSellingPrice.toFixed(2);
      marginPercentage = value.margin_percentage;
    }

    if (type === "quantity") {
      totalPriceItem = findedItem.selling_price * value;
    }

    if (type === "article_id" || artisan_work_object) {
      totalPriceItem = value.selling_price * 1;
      value = { ...value, total_price: totalPriceItem };
    }

    totalPriceNoTva += totalPriceItem - findedItem.total_price;
    totalPriceTva +=
      (totalPriceItem * (type === "tva" ? value : findedItem.tva)) / 100 -
      (findedItem.total_price * findedItem.tva) / 100;

    totalPrice = totalPriceNoTva + totalPriceTva;

    setQuoteObject({
      ...quoteObject,
      total_amount_no_tva: totalPriceNoTva,
      total_amount_tva: totalPriceTva,
      total_amount: totalPrice,
      site_categories: quoteObject?.site_categories?.map((category) =>
        category.level === level
          ? {
              ...category,
              site_items: category.site_items.map((item, indexMap) =>
                indexMap === index
                  ? type !== "article_id"
                    ? artisan_work_object
                      ? { ...artisan_work_object }
                      : {
                          ...item,
                          [type]: value,
                          margin_percentage: marginPercentage,
                          total_price: totalPriceItem,
                          selling_price: sellingPrice,
                        }
                    : { ...item, ...value, id: item.id }
                  : { ...item }
              ),
            }
          : { ...category }
      ),
    });
  };

  const onCreateNewArtisan = (data) => {
    setLoadingApiAddArtisan(true);
    createArtisan({
      ...data,
    })
      .then((response) => {
        setLoadingApiAddArtisan(false);
        setArtisans(artisans.concat(response.data));
        setOpenModalAddArtisan(false);
      })
      .catch(() => {
        setLoadingApiAddArtisan(false);
        setErrorApi(true);
      });
  };

  const onCreateNewArticle = (data) => {
    setLoadingApiAddArtisan(true);
    storeArticle(data)
      .then((response) => {
        setLoadingApiAddArtisan(false);
        setOpenModalAddArticle(false);
        setArticles(articles.concat(response.data));
      })
      .catch((error) => {
        setLoadingApiAddArtisan(false);
        setErrorApi(true);
      });
  };

  //******************* ARTICLES  *******************************/
  // Add an article to a certain list level
  const AddArticleItem = ({ level }) => {
    setQuoteObject({
      ...quoteObject,
      site_categories: quoteObject?.site_categories?.map((category) =>
        category.level === level
          ? {
              ...category,
              site_items: category.site_items
                .map((item) => ({ ...item, lastAdded: false }))
                .concat({
                  artisan_work_name: "",
                  artisan_id: 0,
                  unit_id: 1,
                  quantity: 1,
                  buy_price: 0,
                  selling_price: 0,
                  margin_percentage: 0,
                  tva: 20,
                  total_price: 0,
                  type: "article",
                  lastAdded: true,
                }),
            }
          : {
              ...category,
              site_items: category.site_items.map((item) => ({
                ...item,
                lastAdded: false,
              })),
            }
      ),
    });
  };

  //-------------------------- Unit -----------------
  const onAddUnitHandler = (value) => {
    storeUnit({ name: value })
      .then((response) => {
        setUnits(units.concat(response.data));
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  const onCreateSupplierHandler = (data) => {
    setLoadingApiAddArtisan(true);
    storeSupplier(data)
      .then((response) => {
        setLoadingApiAddArtisan(false);
        setOpenModalAddSupplier(false);
        setSuppliers(suppliers.concat(response.data));
      })
      .catch((error) => {
        setLoadingApiAddArtisan(false);
        setErrorApi(true);
      });
  };

  const renderPlaceHolder = (level) => {
    if (level === 1) {
      return "Décrivez le corps";
    }
    if (level === 2) {
      return "Décrivez le sous-corps";
    }
    if (level === 3) {
      return "Décrivez le sous-corps";
    }
  };

  const handlerDeleteSiteItem = (siteItem, index, level) => {
    console.log(siteItem);
    if (siteItem.id) {
      deleteSiteItem(siteItem.id)
        .then((response) => {
          setQuoteObject({
            ...quoteObject,
            site_categories: quoteObject.site_categories.map((category) =>
              category.id === siteItem.site_category_id
                ? {
                    ...category,
                    site_items: category.site_items.filter(
                      (item) => item.id !== siteItem.id
                    ),
                  }
                : category
            ),
          });
        })
        .catch((error) => {
          setErrorApi(true);
        });
    } else {
      setQuoteObject({
        ...quoteObject,
        site_categories: quoteObject.site_categories.map((category) =>
          category.level === level
            ? {
                ...category,
                site_items: category.site_items.filter(
                  (item, indexItem) => indexItem !== index
                ),
              }
            : category
        ),
      });
    }
  };

  const calculateCategoryMargin = (categoryLevel) => {
    const foundCategory = quoteObject.site_categories.find(
      (category) => category.level === categoryLevel
    );

    let total_selling_price = 0;
    let total_buying_price = 0;

    foundCategory.site_items?.forEach((item) => {
      total_buying_price += item.buy_price * item.quantity;
      total_selling_price += item.selling_price * item.quantity;
    });

    quoteObject?.site_categories
      ?.filter((subCate) => subCate.parent === categoryLevel)
      .forEach((subCatFound, subIndex) => {
        subCatFound.site_items?.forEach((item) => {
          total_buying_price += item.buy_price * item.quantity;
          total_selling_price += item.selling_price * item.quantity;
        });
        quoteObject?.site_categories
          ?.filter((subCate2) => subCate2.parent === subCatFound.level)
          .forEach((subCatFound2, subIndex2) => {
            subCatFound2.site_items?.forEach((item) => {
              total_buying_price += item.buy_price * item.quantity;
              total_selling_price += item.selling_price * item.quantity;
            });
          });
      });

    return total_selling_price
      ? (
          ((total_selling_price - total_buying_price) / total_selling_price) *
          100
        ).toFixed(0)
      : 0;
  };

  const calculateCategoryMarginPrice = (categoryLevel) => {
    const foundCategory = quoteObject.site_categories.find(
      (category) => category.level === categoryLevel
    );

    let total_selling_price = 0;
    let total_buying_price = 0;

    foundCategory.site_items?.forEach((item) => {
      total_buying_price += item.buy_price * item.quantity;
      total_selling_price += item.selling_price * item.quantity;
    });

    quoteObject?.site_categories
      ?.filter((subCate) => subCate.parent === categoryLevel)
      .forEach((subCatFound, subIndex) => {
        subCatFound.site_items?.forEach((item) => {
          total_buying_price += item.buy_price * item.quantity;
          total_selling_price += item.selling_price * item.quantity;
        });
        quoteObject?.site_categories
          ?.filter((subCate2) => subCate2.parent === subCatFound.level)
          .forEach((subCatFound2, subIndex2) => {
            subCatFound2.site_items?.forEach((item) => {
              total_buying_price += item.buy_price * item.quantity;
              total_selling_price += item.selling_price * item.quantity;
            });
          });
      });

    return (total_selling_price - total_buying_price).toFixed(0);
  };

  const calculateCategoryTotalPrice = (categoryLevel) => {
    const foundCategory = quoteObject.site_categories.find(
      (category) => category.level === categoryLevel
    );

    let total_selling_price = 0;
    foundCategory.site_items?.forEach((item) => {
      total_selling_price += item.selling_price * item.quantity;
    });

    quoteObject?.site_categories
      ?.filter((subCate) => subCate.parent === categoryLevel)
      .forEach((subCatFound, subIndex) => {
        subCatFound.site_items?.forEach((item) => {
          total_selling_price += item.selling_price * item.quantity;
        });
        quoteObject?.site_categories
          ?.filter((subCate2) => subCate2.parent === subCatFound.level)
          .forEach((subCatFound2, subIndex2) => {
            subCatFound2.site_items?.forEach((item) => {
              total_selling_price += item.selling_price * item.quantity;
            });
          });
      });

    return total_selling_price.toFixed(0);
  };

  const handleEnterKey = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      const activeElement = document.activeElement;
      if (!activeElement) {
        return;
      }

      const categoryId = activeElement
        .closest(".first-level-category")
        ?.getAttribute("data-category-id");
      const categoryItem = quoteObject.site_categories.find(
        (cat) => cat.id === Number(categoryId) || cat.feId === categoryId
      );
      if (!categoryItem) {
        return;
      }

      const contextEl = activeElement.closest("[data-context]");
      const context = contextEl?.getAttribute("data-context");
      if (context === "category") {
        onAddHandler({
          parentLevel: categoryItem.parent,
          importance: categoryItem.importance,
        });
      }

      if (context === "article") {
        const level = contextEl.getAttribute("data-parent-level");
        AddArticleItem({ level });
      }

      if (context === "artisan") {
        const level = contextEl.getAttribute("data-parent-level");
        addArtisanItem({ level });
      }
    }
  };

  return (
    <>
      <ModalAddArtisan
        setOpenModalAdd={setOpenModalAddArtisan}
        openModalAdd={openModalAddArtisan}
        onCreateNewArtisan={onCreateNewArtisan}
        loadingApiAdd={loadingApiAddArtisan}
        editArtisan={{}}
        setEditArtisan={() => console.log("")}
        crafts={crafts}
      />
      <ModalAddArticles
        setOpenModalAdd={setOpenModalAddArticle}
        openModalAdd={openModalAddArticle}
        onCreateArticle={onCreateNewArticle}
        loadingApiAdd={loadingApiAddArtisan}
        editArticle={{}}
        setEditArticle={() => console.log("")}
        units={units}
        setUnits={setUnits}
        categories={categories}
        setCategories={setCategories}
        suppliers={suppliers}
        setSuppliers={setSuppliers}
        setErrorApi={setErrorApi}
      />
      <ModalAddSupplier
        setOpenModalAdd={setOpenModalAddSupplier}
        openModalAdd={openModalAddSupplier}
        onCreateSupplier={onCreateSupplierHandler}
        loadingApiAdd={loadingApiAddArtisan}
        editSupplier={{}}
        setEditSupplier={() => console.log("")}
        setErrorApi={setErrorApi}
      />
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertError}
        />
      )}

      {loadingApiGet ? (
        <LoadingButton />
      ) : (
        <div onKeyDown={handleEnterKey}>
          {/*<Grid container>
            <Grid item xs={11} align="left" style={{ marginTop: 10 }}>
              <CommonButton
                text={"+ corps"}
                onClick={() =>
                  onAddHandler({ parentLevel: undefined, importance: 1 })
                }
                isSmallButton
              />
            </Grid>
          </Grid>*/}
          {/************ FIRST LEVEL CATEGORY ****/}
          {filterCategory().map((firstLevelCategory, index) => {
            return (
              <div
              key ={index}
                data-category-id={
                  firstLevelCategory.id ?? firstLevelCategory.feId
                }
                className="first-level-category"
                data-context="category"
              >
                <Grid
                item
                  align="left"
                  xs={12}
                  style={{
                    marginTop: 20,
                    paddingLeft: (firstLevelCategory.importance - 1) * 20,
                  }}
                >
                  <Grid container spacing={2} alignItems='center'>
                    {firstLevelCategory.level.length <= 2 && (
                      <Grid item xs={12}>
                        <Divider style={{marginBottom:10}}/>
                      </Grid>
                    )}
               

                    <Grid item>
                      <Typography variant="h5">
                        {firstLevelCategory.level}
                      </Typography>
                    </Grid>
                    <Grid item align="left" xs={6} style={{ paddingLeft: 20 }}>
                      <TextField
                        autoFocus={firstLevelCategory.lastAdded}
                        variant="outlined"
                        label={renderPlaceHolder(firstLevelCategory.importance)}
                        placeholder={renderPlaceHolder(
                          firstLevelCategory.importance
                        )}
                        name={`category-${firstLevelCategory.level}`}
                        type={"input"}
                        value={firstLevelCategory.name}
                        onChange={(event) =>
                          onChangeLevelNameHandler({
                            name: event.target.value,
                            level: firstLevelCategory.level,
                          })
                        }
                        fullWidth
                        InputProps={{
                          className: [classes.input ?? ""].join(" "),
                        }}
                        size="small"
                      />
                    </Grid>
                    
                  </Grid>
                  <Grid
                    container
                    justifyContent="flex-start"
                    style={{ paddingBottom: 20 }}
                  >
                    <Grid
                    item
                      align="left"
                   
                      style={{ paddingLeft: firstLevelCategory.importance===1 ? 50 : firstLevelCategory.importance===2 ? 70 : 80,marginTop:10, paddingRight:10 }}
                    >
                      {firstLevelCategory.importance !== 3 && (
                        <CommonButton
                          text={"+ sous-corps"}
                          isSmallButton
                          onClick={() =>
                            onAddHandler({
                              parentLevel: firstLevelCategory.level,
                              importance: firstLevelCategory.importance + 1,
                            })
                          }
                        />
                      )}
                    </Grid>
                    <Grid
                      item
                   style={{marginTop:10}}
                   
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            addArtisanItem({
                              level: firstLevelCategory.level,
                            })
                          }
                        >
                          <Artisan
                          fill={"#345369"}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              addArtisanItem({
                                level: firstLevelCategory.level,
                              })
                            }
                          />
                        </div>
                        <div
                          style={{ marginLeft: 10, cursor: "pointer" }}
                          onClick={() =>
                            AddArticleItem({
                              level: firstLevelCategory.level,
                            })
                          }
                        >
                          <Articles fill={"#345369"} />
                        </div>

                        <div style={{ marginLeft: 10 }}>
                        <Tooltip title='Supprimer'>
                          <ClearIcon
              
                            className='deleteIcon'
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              onDeleteCategoryHandler({
                                level: firstLevelCategory.level,
                              })
                            }
                          /></Tooltip>
                        </div>
                      </div>
                    </Grid>


                    {!clientMode && (
                   
                   <>
                     <Grid item style={{ paddingLeft: 200, paddingTop: 15 }}>
                     <Typography variant='h6' style={{fontSize:14}}> Marge:{" "}
                       {calculateCategoryMargin(firstLevelCategory.level)}%</Typography>
                     </Grid>
                     <Grid item style={{ paddingLeft: 5, paddingTop: 15 }}>
                       {"( "}
                       {calculateCategoryMarginPrice(
                         firstLevelCategory.level
                       )}
                       {"€ )"}
                     </Grid>
                     <Grid item style={{ paddingLeft: 5, paddingTop: 15 }}>
                     <Typography variant='h6' style={{fontSize:14}}> {"- Sous-total: "}
                       {calculateCategoryTotalPrice(
                         firstLevelCategory.level
                       )}
                       {"€"}</Typography>
                     </Grid>
                     </>
                 )}


                     <div style={{ flexGrow: 1 }} />
                     {!firstLevelCategory.parent && (
                       <Grid item xs={1}>
                         <TextField
                           size="small"
                           fullWidth
                           variant="outlined"
                           select
                           label="TVA corps"
                           value={firstLevelCategory.tva ?? 20}
                           onChange={(e) => {
                             setTVAGlobally(
                               e.target.value,
                               firstLevelCategory
                             );
                           }}
                         >
                           <MenuItem value={0}>0%</MenuItem>
                           <MenuItem value={5.5}>5,5%</MenuItem>
                           <MenuItem value={10}>10%</MenuItem>
                           <MenuItem value={20}>20%</MenuItem>
                         </TextField>
                       </Grid>
                     )}
                  








                  </Grid>
                  <Grid container >
                    {firstLevelCategory?.site_items?.map((item, index) => {
                      return (
                        <Grid item style={{ marginTop: 10 }} key={index}>
                          {item.type === "artisan" && (
                            <div
                              key={item.id}
                              data-context="artisan"
                              data-parent-level={firstLevelCategory.level}
                            >
                              <ArtisanLine
                                autoFocus={item.lastAdded}
                                setOpenModalAddArtisan={setOpenModalAddArtisan}
                                units={units}
                                artisans={artisans}
                                artisanItem={item}
                                level={firstLevelCategory.level}
                                index={index}
                                onChangeArtisanHandler={onChangeItemHandler}
                                onAddUnitHandler={onAddUnitHandler}
                                handlerDeleteArtisanItem={handlerDeleteSiteItem}
                                artisanWork={artisanWork}
                                isSigned={quoteObject.status === "accepted"}
                              />
                            </div>
                          )}
                          {item.type === "article" && (
                            <div
                              key={item.id}
                              data-context="article"
                              data-parent-level={firstLevelCategory.level}
                            >
                              <ArticleLine
                                autoFocus={item.lastAdded}
                                setOpenModalAddArticle={setOpenModalAddArticle}
                                setOpenModalAddSupplier={
                                  setOpenModalAddSupplier
                                }
                                units={units}
                                supplier={artisans}
                                article={item}
                                suppliers={suppliers}
                                level={firstLevelCategory.level}
                                index={index}
                                onChangeArticleHandler={onChangeItemHandler}
                                onAddUnitHandler={onAddUnitHandler}
                                articles={articles}
                                handlerDeleteArticleItem={handlerDeleteSiteItem}
                                isSigned={quoteObject.status === "accepted"}
                              />
                            </div>
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </div>
            );
          })}


<Tooltip title='Ajouter un corps'>
          <Fab
            aria-label="add"
            style={{ position: "fixed", right: 170, bottom: 20, backgroundColor:'#345369', color:"#FFF" }}
          >
            <AddIcon
              onClick={() =>
                onAddHandler({ parentLevel: undefined, importance: 1 })
              }
            />
          </Fab>
          </Tooltip>


    

      
 
        </div>
      )}
    </>
  );
};

export default QuoteBody;
