import { useCallback, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
  Container,
} from "@material-ui/core";
import text from "../../../utils/text";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import {
  changeInvoiceStatus,
  getAllInvoices,
  getAllQuoteAllSites,
  quoteChangeStatus,
} from "../../../Axios/Call/AuthApi";
import { withStyles } from "@material-ui/core/styles";
import { AiOutlineEye } from "react-icons/ai";
import FilterTextList from "../../../Components/Commons/FilterTextList/FilterTextList";
import moment from "moment";
import StatusCommon from "../../../Components/Commons/StatusCommon/StatusCommon";
import CommonSelect from "../../../Components/FormComponents/CommonSelect";
import CommonDateTimePicker from "../../../Components/FormComponents/CommonDateTimePicker";
import SideMenu from "../../../Components/Commons/SideMenu";
import HeaderSections from "../../../Components/Commons/HeaderSections/HeaderSections";
import ModalSendInvoice from "../../../Components/BusinessComponents/Invoices/ModalSendInvoice";
import ModalSendQuote from "../../../Components/BusinessComponents/Invoices/ModalSendQuote";
import CommonButton from "../../../Components/FormComponents/CommonButton";
import ModalViewInvoice from "../../../Components/BusinessComponents/Invoices/ModalViewInvoice";

const SellingList = () => {
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoice, setFilteredInvoices] = useState([]);
  const [startingDate, setStartingDate] = useState(null);
  const [endingDate, setEndingDate] = useState(null);
  const [totalAmount, setTotalAmount] = useState([]);
  //Modal state
  const [openModal, setOpenModal] = useState(false);
  const [openViewInvoiceModal, setOpenViewInvoiceModal] = useState(0);
  //Loading helper
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  //Table helper helper
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");
  //Status change
  const [changeStatus, setChangeStatus] = useState(false);
  const [statusOption, setStatusOption] = useState(false);
  //Sections
  const [section, setSection] = useState("invoice");
  const [subSection, setSubsection] = useState("all");
  const [subSections, setSubsections] = useState([]);
  const sections = [
    { action: "invoice", name: "Factures" },
    { action: "quote", name: "Devis" },
  ];

  const numberWithSpaces = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  useEffect(() => {
    if (section === "invoice") {
      setSubsections([
        { action: "all", name: "Toutes les factures" },
        { action: "paid", name: "Payées" },
        { action: "not-paid", name: "Approuvées" },
        { action: "in-retard", name: "En retard" },
        { action: "sended", name: "Envoyés" },
        { action: "draft", name: "Brouillons" },
      ]);
      setStatusOption([
        { id: "draft", name: "BROUILLON" },
        { id: "paid", name: "PAYÉE" },
        { id: "not-paid", name: "APPROUVÉE" },
        { id: "in-retard", name: "EN RETARD" },
        { id: "sended", name: "ENVOYÉ" },
      ]);
    }
    if (section === "quote") {
      setSubsections([
        { action: "all", name: "Tous les devis" },
        { action: "accepted", name: "Approuvés" },
        { action: "sended", name: "Envoyés" },
        { action: "draft", name: "Brouillons" },
      ]);
      setStatusOption([
        { id: "draft", name: "BROUILLON" },
        { id: "sended", name: "ENVOYÉ" },
        { id: "refused", name: "REFUSÉ" },
      ]);
    }
  }, [section]);

  const fetchData = useCallback(() => {
    setLoadingApi(true);
    if (section === "invoice") {
      getAllInvoices(
        subSection,
        startingDate ? moment(startingDate).format("yyyy-MM-DD") : "",
        endingDate ? moment(endingDate).format("yyyy-MM-DD") : ""
      )
        .then((response) => {
          setLoadingApi(false);
          setInvoices(response.data.invoices);
          setFilteredInvoices(response.data.invoices);
          setTotalAmount(response.data);
        })
        .catch((error) => {
          setLoadingApi(false);
          setErrorApi(true);
        });
    } else {
      getAllQuoteAllSites(
        subSection,
        startingDate ? moment(startingDate).format("yyyy-MM-DD") : "",
        endingDate ? moment(endingDate).format("yyyy-MM-DD") : ""
      )
        .then((response) => {
          setLoadingApi(false);
          setInvoices(response.data.quotes);
          setFilteredInvoices(response.data.quotes);
          setTotalAmount(response.data);
        })
        .catch((error) => {
          setLoadingApi(false);
          setErrorApi(true);
        });
    }
  }, [startingDate, endingDate, section, subSection]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onChangeStatusHandler = (status) => {
    const functionStatus =
      section === "invoice" ? changeInvoiceStatus : quoteChangeStatus;

    functionStatus(changeStatus, { status: status })
      .then((response) => {
        setInvoices(
          invoices.map((invoice) =>
            invoice.id === changeStatus
              ? { ...invoice, status: status, is_validated: true }
              : invoice
          )
        );
        setFilteredInvoices(
          filteredInvoice.map((invoice) =>
            invoice.id === changeStatus
              ? { ...invoice, status: status, is_validated: true }
              : invoice
          )
        );
        setChangeStatus(false);
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  const renderStatus = (invoice) => {
    if (section === "invoice") {
      if (invoice.is_validated) {
        return invoice.status;
      }
    } else {
      return invoice.status;
    }

    return "draft";
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const StyledTableCell = withStyles((theme) => ({
    root: {
      backgroundColor: "#345369",
      fontSize: 14,
      fontFamily: ["roboto", "-apple-system"].join(","),
      color: "white",
      wordWrap: "break-word",
      border: "none",
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const StyledTableSortLabel = withStyles((theme) => ({
    root: {
      color: "white",
      "&:hover": {
        color: "white",
      },
      "&$active": {
        color: "white",
      },
    },
    active: {},
    icon: {
      color: "inherit !important",
    },
  }))(TableSortLabel);

  const StyledTablePagination = withStyles((theme) => ({
    root: {
      color: "#345369",
    },
    menuItem: {
      fontFamily: ["roboto", "-apple-system"].join(","),
    },
    caption: {
      fontFamily: ["roboto", "-apple-system"].join(","),
    },
    selectIcon: {
      color: "white",
    },
  }))(TablePagination);

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertError}
        />
      )}
      {section === "invoice" && (
        <ModalSendInvoice
          openModal={openModal}
          setOpenModal={setOpenModal}
          onInvoiceSent={fetchData}
        />
      )}
      {section === "invoice" && (
        <ModalViewInvoice
          invoiceId={openViewInvoiceModal}
          open={Boolean(openViewInvoiceModal)}
          onClose={() => setOpenViewInvoiceModal(0)}
        />
      )}
      {section === "quote" && (
        <ModalSendQuote openModal={openModal} setOpenModal={setOpenModal} />
      )}
      <SideMenu>
        <Container style={{ maxWidth: "none" }}>
          <Grid container>
            <Grid item align="left">
              <Grid container style={{ paddingTop: 20 }}>
                <HeaderSections
                  setSection={setSection}
                  section={section}
                  sections={sections}
                  variant="h5"
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              align="left"
              style={{ marginTop: 10, fontWeight: 400 }}
            >
              <Grid container>
                <HeaderSections
                  setSection={setSubsection}
                  section={subSection}
                  sections={subSections}
                  variant="span"
                  fontSize={16}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} align="left" style={{ marginTop: 20 }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={2}
                  xl={1}
                  style={{ marginTop: 15, padding: 10 }}
                >
                  <CommonDateTimePicker
                    name={"starting_date"}
                    labelCustom={"Date de début"}
                    labelColor={"#345369"}
                    field={{
                      value: startingDate,
                      onChange: (date) => setStartingDate(date),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  xl={1}
                  style={{ marginTop: 15, padding: 10 }}
                >
                  <CommonDateTimePicker
                    name={"ending_date"}
                    labelCustom={"Date de fin"}
                    labelColor={"#345369"}
                    field={{
                      value: endingDate,
                      onChange: (date) => setEndingDate(date),
                    }}
                  />
                </Grid>

                <Grid
                  style={{
                    textAlign: "center",
                    padding: 30,
                    marginTop: "auto",
                    marginBottom: "auto",
                    fontWeight: 800,
                    marginBottom: 10,
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    borderRadius: "5px",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div style={{ display: "flex" }}>
                      <Typography>Total </Typography>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          paddingLeft: 10,

                          fontSize: "16px",
                        }}
                      >
                        {numberWithSpaces(totalAmount.total?.toFixed(0))} €
                      </Typography>
                    </div>
                    <div style={{ paddingLeft: 30, display: "flex" }}>
                      <Typography style={{ color: "#437F9D" }}>
                        En Cours
                      </Typography>
                      <Typography
                        style={{
                          color: "#437F9D",
                          fontWeight: "bold",
                          paddingLeft: 10,

                          fontSize: "16px",
                        }}
                      >
                        {numberWithSpaces(totalAmount.total_sended?.toFixed(0))}{" "}
                        €
                      </Typography>
                    </div>
                    <div style={{ paddingLeft: 30, display: "flex" }}>
                      <Typography style={{ color: "#55A53A" }}>Payé</Typography>
                      <Typography
                        style={{
                          color: "#55A53A",
                          fontWeight: "bold",
                          paddingLeft: 10,

                          fontSize: "16px",
                        }}
                      >
                        {numberWithSpaces(totalAmount.total_paid?.toFixed(0))} €
                      </Typography>
                    </div>
                    <div style={{ paddingLeft: 30, display: "flex" }}>
                      <Typography style={{ color: "#DB3838" }}>
                        En Retard
                      </Typography>
                      <Typography
                        style={{
                          color: "#DB3838",
                          fontWeight: "bold",
                          paddingLeft: 10,

                          fontSize: "16px",
                        }}
                      >
                        {numberWithSpaces(
                          totalAmount.total_en_retard?.toFixed(0)
                        )}{" "}
                        €
                      </Typography>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} md={12} align="left">
                  <FilterTextList
                    allItems={invoices}
                    setFiltered={setFilteredInvoices}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {loadingApi ? (
                <LoadingButton />
              ) : (
                <Grid
                  container
                  justifyContent="center"
                  style={{ marginTop: 20 }}
                >
                  <Grid item xs={12} md={12}>
                    <TableContainer>
                      <Table style={{ width: "100%" }}>
                        <TableHead className="tableBackground">
                          <TableRow>
                            <StyledTableCell align="left">
                              <StyledTableSortLabel
                                active={orderBy === "created_at"}
                                direction={
                                  orderBy === "created_at" ? order : "asc"
                                }
                                onClick={createSortHandler("created_at")}
                              >
                                {text.invoiceList.date}
                              </StyledTableSortLabel>
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              {text.invoiceList.number}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {text.invoiceList.client}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {text.invoiceList.site}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {text.quoteList.price}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {text.quoteList.status}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {text.quoteList.action}
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {stableSort(
                            filteredInvoice,
                            getComparator(order, orderBy)
                          )
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((invoice) => (
                              <StyledTableRow key={invoice.id}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align={"left"}
                                >
                                  <Typography component="span">
                                    {moment(invoice?.created_at).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align={"left"}
                                >
                                  <Typography component="span">
                                    {invoice?.invoice_number || invoice?.name}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {`${invoice?.site?.client?.name} ${invoice?.site?.client?.surname}`}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {invoice?.site?.name}
                                  </Typography>
                                </TableCell>
                                <TableCell component="th" align="left">
                                  <Typography component="span">
                                    {invoice?.total_amount_no_tva?.toFixed(2)} €
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  {invoice.id === changeStatus ? (
                                    <CommonSelect
                                      field={{
                                        value: invoice.status,
                                        onChange: (status) =>
                                          onChangeStatusHandler(status),
                                      }}
                                      options={statusOption}
                                    />
                                  ) : (
                                    <Typography component="span">
                                      <StatusCommon
                                        setChangeStatus={
                                          invoice.status === "accepted"
                                            ? () => true
                                            : setChangeStatus
                                        }
                                        itemId={invoice.id}
                                        status={renderStatus(invoice)}
                                      />
                                    </Typography>
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  {section === "invoice" &&
                                    (["draft", "approved"].includes(
                                      invoice.status
                                    ) ? (
                                      <CommonButton
                                        onClick={() => setOpenModal(invoice.id)}
                                        text={"Générer"}
                                      />
                                    ) : (
                                      <CommonButton
                                        onClick={() =>
                                          setOpenViewInvoiceModal(invoice.id)
                                        }
                                        text={text.General.view}
                                      />
                                    ))}
                                  {section !== "invoice" && (
                                    <AiOutlineEye
                                      onClick={() =>
                                        setOpenModal({
                                          quoteId: invoice.id,
                                          isNoPrice: false,
                                        })
                                      }
                                      style={{ cursor: "pointer" }}
                                      size={20}
                                    />
                                  )}
                                </TableCell>
                              </StyledTableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <StyledTablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={invoices.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage={text.General.rowXPage}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Container>
      </SideMenu>
    </>
  );
};

export default SellingList;
