import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from '@material-ui/core';
import { useState } from 'react';
import { contracts } from '../../../Axios/Call/AuthApi';
import { formatTemplateName } from './SiteContractList';

function CreateContractDialog({ open, onClose, templateList, onCreated, onError, siteId }) {
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await contracts.store(siteId, { contract_template_id: template });
      if (typeof onCreated === 'function') {
        onCreated(response.data);
      }
    } catch {
      if (typeof onError === 'function') {
        onError();
      }
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
      <DialogTitle>Créer un nouveau document</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit} id="edit-contract-form">
          <TextField
            label="Motif"
            name="contract_template_id"
            variant="outlined"
            fullWidth
            margin="normal"
            select
            value={template}
            onChange={(e) => setTemplate(e.target.value)}
          >
            <MenuItem value="" disabled>
              Choisir un modèle
            </MenuItem>
            {templateList.map((template) => (
              <MenuItem key={template.id} value={template.id}>
                {formatTemplateName(template.type)}
              </MenuItem>
            ))}
          </TextField>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
        <Button
          disabled={loading || !template}
          type={'submit'}
          form={'edit-contract-form'}
          variant="contained"
          color="primary"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateContractDialog;
