import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle,Typography } from '@material-ui/core';
import { getPdfQuote } from '../../../Axios/Call/AuthApi';
import LoadingButton from '../../Commons/LoadingButton/LoadingButton';
import text from '../../../utils/text';
import AlertGeneral from '../../Commons/AlertGeneral/AlertGeneral';

const ModalSendQuote = ({ openModal, setOpenModal }) => {
  const [errorApi, setErrorApi] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [pdf, setPdf] = useState('');

  useEffect(() => {
    if (Object.keys(openModal).length) {
      setLoadingApi(true);
      getPdfQuote(openModal.quoteId, openModal.isNoPrice, openModal.estimate, openModal.avp)
        .then((response) => {
          setPdf(response.data);
          // setLoadingApi(false);
        })
        .catch((error) => {
          setErrorApi(true);
          setLoadingApi(false);
        });
    }
  }, [openModal]);

  const handleClose = () => {
    setOpenModal(false);
    setPdf('');
  };

  return (
    <>
      {errorApi && <AlertGeneral color="danger" setTrigger={setErrorApi} description={text.General.alertError} />}
      <Dialog open={Object.keys(openModal).length} maxWidth="md" fullWidth onClose={handleClose}>
        <DialogTitle></DialogTitle>
        <DialogContent style={{ minHeight: '80vh' }}>
          {loadingApi && 
          <>
          <LoadingButton />
          <Typography variant="h6" style={{textAlign:'center'}}>
          Chargement des données...
        </Typography>
        </>
          }
          {pdf && (
            <iframe
              onLoad={() => setLoadingApi(false)}
              src={pdf}
              style={{
                width: '100%',
                height: '80vh',
                display: loadingApi ? 'none' : 'block',
              }}
              type="application/pdf"
              name="myiframe"
              id="myiframe"
              title="myiframe"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalSendQuote;
