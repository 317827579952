import { Grid, Typography } from '@material-ui/core';
import SitePlanningDocuments from '../../../../Components/BusinessComponents/Planning/SitePlanningDocuments';
import text from '../../../../utils/text';

const UploadPlan = ({ site }) => {
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} style={{ marginTop: 20, textAlign: 'left' }}>
          <Typography variant="h5">{text.planSection.title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <SitePlanningDocuments siteId={site.id} />
        </Grid>
      </Grid>
    </>
  );
};

export default UploadPlan;
