import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  changeColorCategory,
  changeDatePlanning,
  getAllQuotesWithCategory,
  getArtisanAvailability,
  updateSiteItemNotes,
} from "../../../../Axios/Call/AuthApi";
import {
  Grid,
  Typography,
  Select,
  Card,
  FormControl,
  makeStyles,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  Checkbox,
  Tooltip,
  Divider,
} from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import AlertGeneral from "../../../../Components/Commons/AlertGeneral/AlertGeneral";
import text from "../../../../utils/text";
import { useParams } from "react-router-dom";
import CommonDateTimePicker from "../../../../Components/FormComponents/CommonDateTimePicker";
import moment from "moment";
import { scrollbarStyle } from "../../../../utils/scrollbar";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import * as R from "ramda";
import PostproneDialog from "../../../../Components/BusinessComponents/Planning/PostproneDialog";

import { apiBaseUrl } from "../../../../Axios/AxiosInstance";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    overflowX: "hidden",
    paddingTop: 30,
    paddingRight: 30,
    borderRadius: 15,
    paddingBottom: theme.spacing(2),
    marginBottom: 10,
    position: "relative",
    ...scrollbarStyle,
    "&:hover": {
      overflowX: "scroll",
    },
  },
  buttonsContainer: {
    position: "sticky",
    left: 0,
    right: 0,
    top: 0,
    marginBottom: theme.spacing(3),
  },
  buttonCalendar: {
    border: "1px solid #41546830",
    color: "#415468",
    backgroundColor: "#FFF",
    fontSize: "13px",

    margin: 1,
    "&:hover": {
      color: "#FFF",
      backgroundColor: "#415468",
      border: "1px solid #FFF",
      borderRadius: 5,
    },
  },
  table: {
    boxShadow: "rgba(100, 100, 111, 0.3) 0px 7px 29px 0px",
    tableLayout: "fixed",
    minWidth: "100%",
    borderCollapse: "collapse",
    borderRadius: 25,
    marginLeft: 5,

    
  },
  tableCell: {
    boxShadow: "rgba(30, 30, 30, 0.1) 0px 2px 3px 0px",
    width: "150px",
    color: "white",
    textAlign: "left",
    fontSize:"10px",
    fontWeight:300,
    maxWidth: "180px",
    borderBottom: "1px solid",
    borderBottomColor: "#DDD",
    position: "relative",
    "&:first-child": {
      position: "sticky",
      left: 0,
      backgroundColor: "white",
      zIndex: 10,
      width: "1%",
      borderRadius:3,

      "& p": {
        borderRightColor: grey[500],
        whiteSpace: "nowrap",
        padding: theme.spacing(1, 0.5),
        
      },
    },
  },

  cellInput: {
    border: 0,
    background: "inherit",
    width: "100%",
    overflow: "hidden",
    textAlign: "left",
    color: "inherit",
    maxWidth: 500,
    textOverflow: "ellipsis",
        fontSize:"10px",
  },

  tableRow: {
    "& th, & td": {
      
      minWidth: 43,
      "&:first-child": {
        padding: 10,
      },
    },
    "& th": {
      
      textTransform: "capitalize",
    },
  },
  todayLine: {
    position: "absolute",
    height: "100%",
    width: 1,
    backgroundColor: "white",
    left: "50%",
    top: 0,
  },
  sidePannel: {
    ...scrollbarStyle,
    height: "80vh",
    overflowY: "auto",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    borderRadius: 5,
       
  },

  tableHeader: { color: theme.palette.primary.main },
}));

const Planning = ({ site }) => {
  const [errorApi, setErrorApi] = useState(false);
  const [choosenQuote, setChoosenQuote] = useState([]);
  const [calendarView, setCalendarView] = useState("days");
  const [artisanBusyConfirmationDialog, setArtisanBusyConfirmationDialg] =
    useState(false);

  const [checkedItems, setCheckedItems] = useState([]);
  const [postproneOpen, setPostproneOpen] = useState(false);
  const [quotes, setQuotes] = useState([]);
  const [fullscreen, setFullscreen] = useState(false);
  const [buttonText, setButtonText] = useState("+");
  const [viewButton, setviewButton] = useState("false");

  const planningRef = useRef();

  const handleClick = (unit) => {
    setCalendarView(unit);
    setviewButton("true");
    makeStyles((theme) => ({
      buttonCalendar: {
        backgroundColor: viewButton ? "#415468" : "#FFF",
      },
    }));
  };

  const itemDuration = (item) => {
    let duration = moment(item.ending_date).diff(
      moment(item.starting_date),
      "days"
    );

    if (duration > 1) {
      duration = duration + " jours";
    } else {
      duration = duration + " jour";
    }
    return duration;
  };

  const possibleViews = {
    days: "Aujourd'hui",
    weeks: "Cette semaine",
    months: "Ce mois-ci",
    allSite: "CHANTIER (jours)",
  };

  const { siteId } = useParams();
  const colorItems = [
    "#345369",
    "#e6194B",
    "#3cb44b",
    "#ffe119",
    "#4363d8",
    "#f58231",
    "#911eb4",
    "#42d4f4",
    "#f032e6",
    "#bfef45",
    "#fabed4",
    "#469990",
    "#dcbeff",
    "#9A6324",
    "#fffac8",
    "#800000",
    "#aaffc3",
    "#808000",
    "#ffd8b1",
    "#000075",
    "#a9a9a9",
    "#ffffff",
    "#000000",
  ];

  const fetchQuotes = useCallback(() => {
    getAllQuotesWithCategory(siteId)
      .then((response) => {
        setChoosenQuote(response.data.siteCategories);
        setQuotes(response.data.quotes);
      })
      .catch((error) => {
        setErrorApi(true);
      });
  }, [siteId]);

  useEffect(() => {
    fetchQuotes();
  }, [fetchQuotes]);

  const changeDateHandler = async ({ date, type, level, itemId }) => {
    const initialChoosenQuote = R.clone(choosenQuote);
    const updatedChoosenQuote = choosenQuote.map((category) =>
      category.level === level
        ? {
            ...category,
            site_items: category.site_items.map((item, index) =>
              item.id === itemId
                ? {
                    ...item,
                    starting_date:
                      type === "starting" ? date : item.starting_date,
                    ending_date: type === "ending" ? date : item.ending_date,
                  }
                : { ...item }
            ),
          }
        : { ...category }
    );
    setChoosenQuote(updatedChoosenQuote);

    const item = updatedChoosenQuote
      .map((q) => q.site_items)
      .flat()
      .find((i) => i.id === itemId);

    const isArtisnBusy =
      item.starting_date && item.ending_date && item.artisan_id
        ? (
            await getArtisanAvailability(
              item.artisan_id,
              item.starting_date,
              item.ending_date,
              item.id
            )
          ).data.is_busy
        : false;

    if (isArtisnBusy) {
      setArtisanBusyConfirmationDialg({
        onConfirm: () => {
          changeDatePlanning(itemId, {
            starting_date: item.starting_date
              ? moment(item.starting_date).format("yyyy-MM-DD")
              : undefined,
            ending_date: item.ending_date
              ? moment(item.ending_date).format("yyyy-MM-DD")
              : undefined,
          });
          setArtisanBusyConfirmationDialg(false);
          fetchQuotes();
        },

        onCancel: () => {
          setChoosenQuote(initialChoosenQuote);
          setArtisanBusyConfirmationDialg(false);
        },
      });
    } else {
      changeDatePlanning(itemId, {
        starting_date: item.starting_date
          ? moment(item.starting_date).format("yyyy-MM-DD")
          : undefined,
        ending_date: item.ending_date
          ? moment(item.ending_date).format("yyyy-MM-DD")
          : undefined,
      })
        .then(() => fetchQuotes())
        .catch(() => {
          setErrorApi(true);
        });
    }
  };

  const changeColorHandler = (color, id) => {
    setChoosenQuote(
      choosenQuote.map((category) =>
        category.id === id
          ? {
              ...category,
              color: color,
            }
          : { ...category }
      )
    );

    changeColorCategory(id, { color: color })
      .then((response) => {})
      .catch((error) => {
        setErrorApi(true);
      });
  };

  const updateNotes = (notes, catId, id) => {
    setChoosenQuote(
      choosenQuote.map((category) =>
        category.id === catId
          ? {
              ...category,
              site_items: category.site_items.map((item) => ({
                ...item,
                notes: item.id === id ? notes : item.notes,
              })),
            }
          : category
      )
    );
  };

  const updateNotseBlur = (notes, id) => {
    updateSiteItemNotes(id, notes)
      .then((response) => {})
      .catch((error) => {
        setErrorApi(true);
      });
  };

  const calendarDays = useMemo(() => {
    if (calendarView === "days") {
      return [moment()];
    }

    if (calendarView === "weeks") {
      return R.map(
        (index) => moment().startOf("isoWeek").add(index, "days"),
        R.range(0, 7)
      );
    }

    if (calendarView === "months") {
      return R.map(
        (index) => moment().startOf("month").add(index, "days"),
        R.range(0, moment().endOf("month").date())
      );
    }

    if (calendarView === "allSite") {
      return R.map(
        (index) => moment(site.starting_date).add(index, "days"),
        R.range(
          0,
          moment(site.ending_date).diff(moment(site.starting_date), "days")
        )
      );
    }
  }, [calendarView, site.starting_date, site.ending_date]);

  const classes = useStyles();

  const calendarItems = useMemo(
    () =>
      choosenQuote
        .map((quote) => ({ ...quote, ...quote.site_items[0], catId: quote.id }))
        .filter(Boolean)
        .filter(
          (item) => Boolean(item.starting_date) && Boolean(item.ending_date)
        ),
    [choosenQuote]
  );

  const fullScreen = () => {
    setFullscreen(!fullscreen);
    setButtonText(!fullscreen ? "-" : "+");
  };

  const reduceSize = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  return (
    <>
      <PostproneDialog
        open={postproneOpen}
        onClose={() => {
          setPostproneOpen(false);
          setCheckedItems([]);
        }}
        onError={() => setErrorApi(true)}
        onSuccess={(days) =>
          setChoosenQuote(
            choosenQuote.map((category) => ({
              ...category,
              site_items: category.site_items.map((item) =>
                checkedItems.includes(item.id)
                  ? {
                      ...item,
                      starting_date: moment(item.starting_date)
                        .add(days, "days")
                        .toDate(),
                      ending_date: moment(item.ending_date)
                        .add(days, "days")
                        .toDate(),
                    }
                  : { ...item }
              ),
            }))
          )
        }
        items={useMemo(
          () =>
            choosenQuote
              .flatMap((q) => q.site_items)
              .filter((i) => checkedItems.includes(i.id)),
          [choosenQuote, checkedItems]
        )}
      />
      <Dialog
        open={Boolean(artisanBusyConfirmationDialog)}
        onClose={() => setArtisanBusyConfirmationDialg(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{text.artisanBusyDialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {text.artisanBusyDialog.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={artisanBusyConfirmationDialog?.onCancel}>
            {text.artisanBusyDialog.cancel}
          </Button>
          <Button onClick={artisanBusyConfirmationDialog?.onConfirm}>
            {text.artisanBusyDialog.confirm}
          </Button>
        </DialogActions>
      </Dialog>

      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertError}
        />
      )}

      <Grid container spacing={4} style={{ marginTop: 20 }}>
        <Grid
          item
          xs={12}
          md={4}
          xl={3}
          style={{ display: !fullscreen ? "flex" : "none" }}
        >
          <Grid container>
            {/* <Grid item xs={12}>
              <Controller
                render={(field) => (
                  <CommonSelect
                    field={field}
                    name={"quote_id"}
                    labelCustom={"Choosen a quote"}
                    options={quotes.map((quote) => {
                      return { name: quote.name, id: quote.id };
                    })}
                    error={errors?.template}
                  />
                )}
                name="quote_id"
                defaultValue={""}
                rules={{}}
                control={control}
              />
              </Grid> */}
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <div className={classes.sidePannel}>
                {quotes
                  ?.filter(
                    (quote) =>
                      quote.site_categories.filter(
                        (cat) => cat.site_items.length
                      ).length
                  )
                  ?.map((quote) => {
                    return (
                      <>
                        <Box marginY={2}>
                          <Typography variant="h3" align="left">
                            {quote.name}
                          </Typography>
                          <Divider />
                        </Box>
                        {quote.site_categories?.map((quoteCategory) => {
                          if (!quoteCategory.site_items?.length) {
                            return (
                              <Typography
                                align="left"
                                gutterBottom
                                variant="h6"
                              >
                                {quoteCategory.level} {quoteCategory.name}
                              </Typography>
                            );
                          }
                          return (
                            <Card
                              key={quoteCategory.id}
                              style={{
                                padding: 20,
                                marginTop: 20,
                                boxShadow:
                                  "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                              }}
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={1}>
                                  <Checkbox
                                    color="primary"
                                    size="small"
                                    edge="start"
                                    checked={checkedItems.includes(
                                      quoteCategory.site_items[0].id
                                    )}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setCheckedItems((list) => [
                                          ...list,
                                          quoteCategory.site_items[0].id,
                                        ]);
                                      } else {
                                        setCheckedItems((list) =>
                                          list.filter(
                                            (i) =>
                                              i !==
                                              quoteCategory.site_items[0].id
                                          )
                                        );
                                      }
                                    }}
                                    disabled={
                                      !quoteCategory.site_items[0]
                                        .starting_date ||
                                      !quoteCategory.site_items[0].ending_date
                                    }
                                  />
                                </Grid>
                                <Grid item xs={9} align="left">
                                  <Typography
                                    variant="h2"
                                    style={{
                                      color: quoteCategory.color ?? "#355877",
                                      fontSize: "14px",
                                      paddingTop: 10,
                                    }}
                                    gutterBottom
                                  >
                                    {quoteCategory.level} {quoteCategory.name}
                                  </Typography>
                                </Grid>

                                <Grid item xs={2} align="right">
                                  <FormControl fullWidth size="small">
                                    <Select
                                      value={quoteCategory.color}
                                      onChange={(event) =>
                                        changeColorHandler(
                                          event.target.value,
                                          quoteCategory.id
                                        )
                                      }
                                      variant="outlined"
                                      style={{
                                        width: "100%",

                                        backgroundColor: quoteCategory.color,
                                      }}
                                    >
                                      {colorItems.map((color) => {
                                        return (
                                          <MenuItem
                                            key={color}
                                            value={color}
                                            style={{
                                              backgroundColor: color,
                                              width: "100%",
                                              height: 20,
                                              borderBottom: "1px solid black",

                                              // marginBottom: 10,
                                            }}
                                          >
                                            &nbsp;
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </Grid>

                                {/* HERE IS THE ARTISAN PART */}
                                {/* <Grid item xs={12} align="left" style={{ marginBottom: 20 }}>
                                  <Typography>
                                    {quoteCategory.site_items[0].artisan?.artisan_profile?.company}
                                  </Typography>
                                </Grid>*/}

                                <Grid item xs={6}>
                                  <CommonDateTimePicker
                                    name={"starting_date"}
                                    labelCustom={"Début"}
                                    minDate={moment(site.starting_date)}
                                    maxDate={
                                      quoteCategory.site_items[0].ending_date
                                    }
                                    labelColor={
                                      quoteCategory.color ?? "#345369"
                                    }
                                    fontSize={"10px"}
                                    field={{
                                      value:
                                        quoteCategory.site_items[0]
                                          .starting_date,
                                      onChange: (date) =>
                                        changeDateHandler({
                                          date: date,
                                          type: "starting",
                                          level: quoteCategory.level,
                                          itemId:
                                            quoteCategory.site_items[0].id,
                                        }),
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={6}>
                                  <CommonDateTimePicker
                                    name={"ending_date"}
                                    minDate={
                                      quoteCategory.site_items[0].starting_date
                                    }
                                    maxDate={moment(site.ending_date)}
                                    labelCustom={"Fin"}
                                    labelColor={
                                      quoteCategory.color ?? "#345369"
                                    }
                                    field={{
                                      value:
                                        quoteCategory.site_items[0].ending_date,
                                      onChange: (date) =>
                                        changeDateHandler({
                                          date: date,
                                          type: "ending",
                                          level: quoteCategory.level,
                                          itemId:
                                            quoteCategory.site_items[0].id,
                                        }),
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Card>
                          );
                        })}
                      </>
                    );
                  })}

                <Tooltip
                  title={
                    checkedItems.length
                      ? "Postpone"
                      : "Veuillez cocher les cases pour décaler les dates souhaitées"
                  }
                >
                  <Box marginTop={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setPostproneOpen(true)}
                      disabled={checkedItems.length === 0}
                      fullWidth
                    >
                      DÉCALER LES DATES
                    </Button>
                  </Box>
                </Tooltip>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={!fullscreen ? 8 : 12} xl={!fullscreen ? 9 : 12}>
          <div className={classes.tableContainer}>
            <div className={classes.buttonsContainer}>
              <ButtonGroup>
                {Object.entries(possibleViews).map(([unit, label]) => (
                  <Button
                    key={unit}
                    onClick={() => handleClick(unit)}
                    className={classes.buttonCalendar}
                  >
                    {label}
                  </Button>
                ))}
              </ButtonGroup>
            </div>
            <table className={classes.table} ref={planningRef}>
              {["months", "allSite"].includes(calendarView) && (
                <tr
                  className={classes.tableRow}
                  style={{ textTransform: "capitalize" }}
                >
                  {R.uniqBy((d) => d.format("MMMM YYYY"), calendarDays).map(
                    (day, index) => (
                      <th
                        key={day.unix()}
                        style={{
                          borderRight: "1px solid",
                          borderRightColor: grey[500],
                          paddingBottom: 16,
                        }}
                        colSpan={
                          moment(day)
                            .endOf("month")
                            .diff(
                              moment(day).isSame(
                                moment(site.starting_date),
                                "month"
                              )
                                ? moment(site.starting_date)
                                : moment(day).startOf("month"),
                              "days"
                            ) + (index === 0 ? 2 : 1)
                        }
                      >
                        {day.format("MMMM YYYY")}
                      </th>
                    )
                  )}
                </tr>
              )}
              <tr className={classes.tableRow}>
                <th
                  className={[classes.tableCell, classes.tableHeader].join(" ")}
                >
                  <Typography style={{ fontSize: "18px" }}>
                    Description
                  </Typography>
                </th>

                {calendarDays.map((day) => (
                  <th
                    className={[classes.tableCell, classes.tableHeader].join(
                      " "
                    )}
                    key={day.unix()}
                    style={{
                      borderRight: "1px solid",
                      borderRightColor: moment(day).isSame(
                        moment(day).endOf("month"),
                        "days"
                      )
                        ? grey[500]
                        : "transparent",
                      fontSize: "12px",
                    }}
                  >
                    {calendarView === "days" && day.format("dddd DD MMMM YYYY")}
                    {calendarView === "weeks" && day.format("ddd DD/MM")}
                    {["months", "allSite"].includes(calendarView) &&
                      day.format("DD/MM")}
                  </th>
                ))}
              </tr>

              {calendarItems.map((item) => {
                
                const firstItemDay = calendarDays.find((day) =>
                  day.isBetween(
                    moment(item.starting_date),
                    moment(item.ending_date),
                    "days",
                    "[]"
                  )
                );

                const itemDaysInPlan = calendarDays.reduce((total, _day) => {
                  const isInDate = _day.isBetween(
                    moment(item.starting_date),
                    moment(item.ending_date),
                    "days",
                    "[]"
                  );

                  if (isInDate) {
                    return total + 1;
                  }

                  return total;
                }, 0);

                return (
                  <tr key={item.id} className={classes.tableRow}>
                    <td className={classes.tableCell}>
                      <Typography
                        style={{
                          fontSize: "13px",
                          width: "180px",
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          marginTop: 0,
                          marginBottom: 0,
                          padding: 0,
                        }}
                      >
                        <Tooltip
                          title={item.name + " ( " + itemDuration(item) + " ) "}
                        >
                          <strong>{reduceSize(item.name, 25)}</strong>
                        </Tooltip>{" "}
                        <br />{" "}
                        <p
                          style={{
                            marginTop: -10,
                            marginBottom: 0,
                            padding: 0,
                            fontSize: 10,
                            color: `${item.color}`,
                          }}
                        >
                          ({item.artisan?.artisan_profile?.company})
                        </p>
                      </Typography>
                    </td>

                    {calendarDays.map((day) => {
                      const isItemDate = day.isBetween(
                        moment(item.starting_date),
                        moment(item.ending_date),
                        "days",
                        "[]"
                      );

                      const isItemDateStart =
                        firstItemDay && day.isSame(firstItemDay, "days");

                      if (isItemDateStart) {
                        return (
                          <td
                            className={classes.tableCell}
                            key={day.unix()}
                            style={{
                              backgroundColor: item.color,
                              cursor: "text",
                            }}
                            colSpan={itemDaysInPlan}
                            onClick={(e) => {
                              const textarea =
                                e.currentTarget.querySelector("textarea");
                              if (textarea) {
                                textarea.focus();
                                textarea.scrollIntoView();
                              }
                            }}
                          >
                            <textarea
                              rows={2}
                              value={item.notes ?? ""}
                              onChange={(e) => {
                                e.currentTarget.scrollIntoView();
                                updateNotes(
                                  e.target.value,
                                  item.site_category_id,
                                  item.id
                                );
                              }}
                              onBlur={() =>
                                updateNotseBlur(item.notes, item.id)
                              }
                              type="text"
                              className={classes.cellInput}
                            />
                          </td>
                        );
                      }

                      if (isItemDate) {
                        return null;
                      }

                      return (
                        <td className={classes.tableCell} key={day.unix()}></td>
                      );
                    })}
                  </tr>
                );
              })}
            </table>
          </div>

          <Typography
            variant="h6"
            style={{ textAlign: "left", fontSize: 16, marginBottom: 10 }}
          >
            Actions{" "}
          </Typography>

          <Grid
            container
            style={{
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              padding: 10,
              borderRadius: 10,
              marginRight: 25,
              width: "370px",
            }}
          >
            <Box display="flex" justifyContent="flex-end">
              <Tooltip title={"Agrandir le planning"}>
                <Button
                  color="primary"
                  style={{ marginLeft: "-10px", fontSize: "19px", height: 30 }}
                  onClick={() => fullScreen()}
                >
                  {buttonText}
                </Button>
              </Tooltip>

              <Tooltip
                title={"Exporter une version du planning organisé par semaines"}
              >
                <Button
                  color="primary"
                  style={{ marginLeft: 10, fontSize: "11px", height: 30 }}
                  component="a"
                  href={`${apiBaseUrl}planning/${site.id}/planning.pdf`}
                  //target="_blank"
                >
                  Exporter (Semaines)
                </Button>
              </Tooltip>

              {/* EXPORT MONTH */}
              <Tooltip
                title={"Exporter une version du planning organisé par mois"}
              >
                <Button
                  color="primary"
                  style={{ marginLeft: 10, fontSize: "11px", height: 30 }}
                  component="a"
                  href={`${apiBaseUrl}planning/${site.id}/planning_month.pdf`}
                  //target="_blank"
                >
                  Exporter (Mois)
                </Button>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Planning;
