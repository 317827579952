import { useState, useEffect, useRef } from 'react';
import { showSiteDashboard, storeMessage } from '../../../../Axios/Call/AuthApi';
import { useParams } from 'react-router-dom';
import AlertGeneral from '../../../../Components/Commons/AlertGeneral/AlertGeneral';
import text from '../../../../utils/text';
import { Grid, Typography, Card } from '@material-ui/core';
import { useSelector } from 'react-redux';
import moment from 'moment';
import classes from './Sections.module.css';
import { AiOutlineSend } from 'react-icons/ai';
import { useForm } from 'react-hook-form';
import TextFieldCustom from '../../../../Components/FormComponents/TextFieldCustom';
import LoadingButton from '../../../../Components/Commons/LoadingButton/LoadingButton';
import { Chat } from '../../../../utils/icons';
import ModalAddIncident from '../../../../Components/BusinessComponents/IncidentPage/ModalAddIncident';
import numeral from 'numeral';
import { useWindowWidth } from '@react-hook/window-size';

const Dashboard = ({ site }) => {
  const userData = useSelector((state) => state.user.userData);
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [messages, setMessages] = useState([]);
  const [usergentItems, setUrgentItems] = useState([]);
  const [dashboardData, setDashboardData] = useState({});
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const messagesEndRef = useRef(null);
  const { siteId } = useParams();

  const { register, watch, handleSubmit, reset, control, setValue } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    setLoadingApi(true);
    showSiteDashboard(siteId)
      .then((response) => {
        setLoadingApi(false);
        setMessages(response?.data?.messages);
        setUrgentItems(response?.data?.urgent_items);
        setDashboardData({
          sended: response?.data?.sended,
          total_invoice: response?.data?.total_invoice,
          total_price: response?.data?.total_price,
          advancement_global: response?.data?.advancement_global,
          incidents: response?.data?.incidents,
          paid: response?.data?.paid,
          unpaid: response?.data?.unpaid,
          avg_margin: response?.data?.avg_margin,
        });
console.log(response?.data)
      
      })
      .catch((error) => {
        setErrorApi(true);
        setLoadingApi(false);
      });
  }, [siteId]);

  const scrollToBottom = () => {
    if (messagesEndRef && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView();
      // { behavior: 'smooth' }
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages.length]);

  const sendMessageHanler = (data) => {
    const message = data.message;
    setValue('message', '');
    storeMessage({
      message: message,
      site_id: siteId,
    })
      .then((response) => {
        setMessages(messages.concat(response.data));
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  const width = useWindowWidth();

  const numberWithSpaces = (x) => {
    return x && numeral(x).format('0,0[.]00').replace(',', ' ');
  };

  return (
    <>
      {errorApi && <AlertGeneral color="danger" setTrigger={setErrorApi} description={text.General.alertError} />}
      <ModalAddIncident openModalAdd={openModalAdd} setOpenModalAdd={setOpenModalAdd} noAdd />

      <Grid container>
        <Grid item xs={12} md={7} style={{ marginTop: 30 }}>
   
            <Typography variant="h5" style={{  marginBottom: 10, textAlign:'left', color:'#34536980' }}>
              Chantier
            </Typography>
   

          <Grid container>
            <Grid
              xs={12}
              md={3}
              style={{
                backgroundColor: '#345369',
                padding: 20,
                borderRadius: 5,
                //width: width > 1200 ? "180" : "100%",
                margin: 5,
              }}
              align="center"
            >
            
              <Typography style={{ color: 'white', marginTop: 5, textAlign:'left'  }} variant="h5">
                {numberWithSpaces(dashboardData?.total_price)} €
              </Typography>
              <Typography style={{ color: 'white', fontWeight: 'bolder', textAlign:'left' }}>Estimatif du marché (HT)</Typography>
            </Grid>

            <Grid
              xs={12}
              md={2}
              style={{
                backgroundColor: '#4A8C95',
                padding: 20,
                borderRadius: 5,
                margin: 5,
              }}
            >
            
              <Typography style={{ color: 'white', marginTop: 5, textAlign:'left'  }} variant="h5">
                {dashboardData?.advancement_global} %
              </Typography>
              <Typography style={{ color: 'white', fontWeight: 'bolder', textAlign:'left'  }}>Avancement</Typography>
            </Grid>
            <Grid
              xs={12}
              md={2}
              style={{
                backgroundColor: '#B9554F',
                padding: 20,
                borderRadius: 5,
                margin: 5,
              }}
            >
             
              <Typography style={{ color: 'white', marginTop: 5, textAlign:'left'  }} variant="h5">
                {dashboardData?.incidents}
              </Typography>
              <Typography style={{ color: 'white', fontWeight: 'bolder', textAlign:'left'  }}>Incidents</Typography>
            </Grid>

            <Grid
              xs={12}
              md={2}
              style={{
                backgroundColor: '#222',
                padding: 20,
                borderRadius: 5,
                margin: 5,
              }}
            >
             
              <Typography style={{ color: 'white', marginTop: 5, textAlign:'left'  }} variant="h5">
                {moment(site.ending_date).diff(moment(), 'days')}
              </Typography>
              <Typography style={{ color: 'white', fontWeight: 'bolder', textAlign:'left'  }}>Jours restants</Typography>
            </Grid>

            {/* MARGIN HERE */}

            <Grid
              xs={12}
              md={2}
              style={{
                backgroundColor: '#4A8C95',
                padding: 20,
                borderRadius: 5,
                margin: 5,
              }}
            >
          
              <Typography style={{ color: 'white', marginTop: 5, textAlign:'left'  }} variant="h5">
                {dashboardData?.avg_margin?.toFixed(0)} %
              </Typography>
              <Typography style={{ color: 'white', fontWeight: 'bolder', textAlign:'left'  }}>Marge</Typography>
            </Grid>

            {/* MARGIN HERE */}

           
          </Grid>
          </Grid>

          <Grid item xs={12} md={5} style={{ marginTop: 30 }}>
   
            <Typography variant="h5" style={{  marginBottom: 10,textAlign:'left', color:'#34536980' }}>
              Facturation
            </Typography>
   

          <Grid container >
            <Grid
              xs={12}
              md={3}
              style={{
                backgroundColor: '#4A8C95',
                padding: 20,
                borderRadius: 5,
                margin: 5,
              }}
            >
              <Typography style={{ color: 'white', marginTop: 5, textAlign:'left'  }} variant="h5">
                {numberWithSpaces(Math.round(dashboardData?.total_invoice))} €
              </Typography>
              <Typography style={{ color: 'white', fontWeight: 'bolder', textAlign:'left'  }}>Facturé (HT)</Typography>

            </Grid>
            <Grid
              xs={12}
              md={3}
              style={{
                backgroundColor: '#B9554F',
                padding: 20,
                borderRadius: 5,
                margin: 5,
              }}
            >
            
              <Typography style={{ color: 'white', marginTop: 5, textAlign:'left'  }} variant="h5">
                {numberWithSpaces(Math.round(dashboardData?.unpaid))} €
              </Typography>
              <Typography style={{ color: 'white', fontWeight: 'bolder', textAlign:'left'  }}>En retard</Typography>
            </Grid>

            <Grid
              xs={12}
              md={3}
              style={{
                backgroundColor: '#345369',
                padding: 20,
                borderRadius: 5,

                margin: 5,
              }}
            >
             
              <Typography style={{ color: 'white', marginTop: 5, textAlign:'left'  }} variant="h5">
                {numberWithSpaces(Math.round(dashboardData?.sended))} €
              </Typography>
              <Typography style={{ color: 'white', fontWeight: 'bolder', textAlign:'left'  }}>En attente</Typography>
            </Grid>
          </Grid>
          </Grid>
          


<Grid container spacing={5} style={{marginTop:30}}>
          <Grid item md={7} style={{ marginTop: 20, paddingBottom: 20 }}>
           {usergentItems.length > 0 && <Grid item  style={{ paddingRight: 10 }}>

           <Typography variant="h5" style={{ marginBottom: 15, textAlign:'left', color:'#34536980' }}>
                    Incidents
                  </Typography>
        
              <Card
                style={{
                  padding: 20,
                  border: '1px solid #EEE',
                  borderRadius: 11,
                  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                }}
                elevation={0}
              >
                {loadingApi ? (
                  <LoadingButton />
                ) : (
                  <Grid container>
                    {usergentItems.map((item) => {
                      return (
                        <Grid item xs={12}>
                          <Grid container justifyContent="space-between">
                            <Grid item xs={10} align="left" style={{ marginTop: 20 }}>
                              <Typography style={{ fontWeight: 'bold' }}>{item.artisan_work_name}</Typography>
                      
                              <Typography >
                                {item.starting_date &&
                                  item.ending_date &&
                                  ` ( ${moment(item.starting_date).format('DD/MM/YYYY')} - ${moment(
                                    item.ending_date
                                  ).format('DD/MM/YYYY')} )`}
                              </Typography>
                            </Grid>
                            <Grid  item xs={1} align="left" style={{ marginTop: 20 }}>
                              <div
                                style={{ paddingLeft: 0, cursor: 'pointer' }}
                                className="my-auto"
                                onClick={() =>
                                  setOpenModalAdd({
                                    item: item,
                                    category: item.site_category,
                                  })
                                }
                              >
                                <Chat fill={item?.incidents?.length ? '#345369' : '#AEB4BE'} height={26} width={26} />
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </Card>
            </Grid>}
          </Grid>
   

  

          <Grid item xs={12} md={4} style={{ marginTop: 20, paddingBottom: 20 }}>
          <Typography variant="h5" style={{textAlign:'left',marginBottom: 15, color:'#34536980'}}>Notes</Typography>
            <Card
              xs={12}
              style={{
                padding: 20,
                border: '1px solid #EEE',
                borderRadius: 11,
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
              }}
              elevation={0}
            >
              {loadingApi ? (
                <LoadingButton />
              ) : (
                <>
                  <div className={classes.scrollBar}>
                    {messages.map((message, index) => {
                      return (
                        <>
                          {(index - 1 > 0 &&
                            moment(messages[index - 1].created_at).format('DD/MM/YYYY') !==
                              moment(message.created_at).format('DD/MM/YYYY')) ||
                          index - 1 < 0 ? (
                            <Grid item xs={12} align="center">
                              <Typography style={{ fontSize: 12 }}>
                                {moment(message.created_at).format('DD/MM/YYYY')}
                              </Typography>
                            </Grid>
                          ) : null}
                          <Grid
                            item
                            align={message.business_id === userData.id ? 'right' : 'left'}
                            key={index}
                            style={{ padding: 2 }}
                          >
                            <div
                              className={
                                message.business_id === userData.id ? classes.ownerMessage : classes.otherPartyMessage
                              }
                              ref={index === messages.length - 1 ? messagesEndRef : null}
                            >
                              <Typography
                                className={
                                  message.business_id === userData.id ? classes.ownerText : classes.otherPartyText
                                }
                              >
                                {message.message}
                              </Typography>
                              <Typography style={{ marginTop: 10, fontSize: 12 }}>
                                {moment(message.created_at).format('HH:mm')} - {message?.business?.name}{' '}
                                {message?.business?.surname}
                              </Typography>
                            </div>
                          </Grid>
                          
                        </>
                      );
                    })}
                  </div>
                  <div>
                    <form
                      onSubmit={handleSubmit(sendMessageHanler)}
                      style={{
                        paddingTop: 20,
                        display: 'flex',
                        backgroundColor: 'white',
                      }}
                      noValidate
                    >
                      <div style={{ width: '90%' }}>
                        <TextFieldCustom
                          type="input"
                          ref={register({ required: true })}
                          defaultValue=""
                          name={'message'}
                        />
                      </div>
                      <div style={{ width: '10%' }}>
                        {watch('message') && (
                          <button
                            type="submit"
                            style={{
                              border: 'none',
                              backgroundColor: '#5e35b1',
                              borderRadius: 100,
                              marginLeft: 10,
                              cursor: 'pointer',
                              height: '80%',
                            }}
                          >
                            <AiOutlineSend size={20} color="white" />
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </>
              )}
            </Card>
          </Grid>

        </Grid>
        </Grid>
    

    </>
  );
};

export default Dashboard;
