import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import {
  changeStatusArtisanOrder,
  getArtisansOfThisSite,
} from "../../../../Axios/Call/AuthApi";
import AlertGeneral from "../../../../Components/Commons/AlertGeneral/AlertGeneral";
import text from "../../../../utils/text";
import FilterTextList from "../../../../Components/Commons/FilterTextList/FilterTextList";
import LoadingButton from "../../../../Components/Commons/LoadingButton/LoadingButton";
import { withStyles } from "@material-ui/core/styles";
import ModalSendContract from "../../../../Components/BusinessComponents/SuppliersOrder/ModalSendContract";
import { AiOutlineEye } from "react-icons/ai";
import { artisanOrderStatus } from "../../../../utils/constant";
import CommonSelect from "../../../../Components/FormComponents/CommonSelect";
import StatusCommon from "../../../../Components/Commons/StatusCommon/StatusCommon";
import { CloudUploadOutlined, FindInPage } from "@material-ui/icons";
import UploadArtisanQuoteDialog from "../../../../Components/BusinessComponents/ArtisansOrder/UploadArtisanQuoteDialog";
import ViewArtisanOrderQuoteDialog from "../../../../Components/BusinessComponents/ArtisansOrder/ViewArtisanOrderQuoteDialog";
import NoteAddIcon from "@mui/icons-material/NoteAdd";

const getArtisanStatus = (artisan) =>
  artisan.status === "not-sent" && !artisan.artisan_quote
    ? "waiting_quote"
    : artisan.status;

const ArtisansOrder = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [changeStatus, setChangeStatus] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const { siteId } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [uploadQuote, setUploadQuote] = useState(null);
  const [viewQuote, setViewQuote] = useState(null);

  const fetchOrders = useCallback( () => {

    let mounted = true;
      getArtisansOfThisSite(siteId)
        .then((response) => {
          if (mounted) {
            setLoadingApi(false);
            const artisans = Array.isArray(response.data)
              ? response.data.map((artisan) => ({
                  ...artisan,
                  status: getArtisanStatus(artisan),
                }))
              : [];
            setSuppliers(artisans);
            setFilteredSuppliers(artisans);
          }
        })
        .catch((error) => {
          setErrorApi(true);
          setLoadingApi(false);
        });

        return function cleanup() {
          mounted = false;
        };
    
      },
    [siteId]
  );

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const StyledTableCell = withStyles((theme) => ({
    root: {
      backgroundColor: "#345369",
      fontSize: 14,
      fontFamily: ["roboto", "-apple-system"].join(","),
      color: "white",
      wordWrap: "break-word",
      border: "none",
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const StyledTableSortLabel = withStyles((theme) => ({
    root: {
      color: "white",
      "&:hover": {
        color: "white",
      },
      "&$active": {
        color: "white",
      },
    },
    active: {},
    icon: {
      color: "inherit !important",
    },
  }))(TableSortLabel);

  const StyledTablePagination = withStyles((theme) => ({
    root: {
      color: "#345369",
    },
    menuItem: {
      fontFamily: ["roboto", "-apple-system"].join(","),
    },
    caption: {
      fontFamily: ["roboto", "-apple-system"].join(","),
    },
    selectIcon: {
      color: "white",
    },
  }))(TablePagination);

  const onChangeStatusHandler = (status) => {
    changeStatusArtisanOrder(changeStatus, { status })
      .then((response) => {
        setSuppliers(
          suppliers.map((supplier) =>
            supplier.id === changeStatus
              ? { ...supplier, status: status }
              : supplier
          )
        );
        setFilteredSuppliers(
          suppliers.map((supplier) =>
            supplier.id === changeStatus
              ? { ...supplier, status: status }
              : supplier
          )
        );
        setChangeStatus(false);
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  return (
    <>
      <ViewArtisanOrderQuoteDialog
        order={viewQuote}
        onClose={() => setViewQuote(null)}
      />
      <UploadArtisanQuoteDialog
        order={uploadQuote}
        onClose={() => setUploadQuote(null)}
        onUploaded={fetchOrders}
      />
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertError}
        />
      )}
      <ModalSendContract
        openModal={openModal}
        setOpenModal={setOpenModal}
        onSent={({ id }) => {
          const update = (s) => (s.id === id ? { ...s, status: "sent" } : s);
          setSuppliers((list) => list.map(update));
          setFilteredSuppliers((list) => list.map(update));
        }}
      />
      <Container style={{ maxWidth: "none" }}>
        <Grid container>
          <Grid item xs={12} align="left">
            <Grid item xs={12} md={6} align="left" style={{ marginTop: 20 }}>
              <FilterTextList
                allItems={suppliers}
                setFiltered={setFilteredSuppliers}
              />
            </Grid>
            <Grid item xs={12}>
              {loadingApi ? (
                <LoadingButton />
              ) : (
                <Grid
                  container
                  justifyContent="center"
                  style={{ marginTop: 20 }}
                >
                  <Grid item xs={12} md={12}>
                    <TableContainer>
                      <Table style={{ width: "100%" }}>
                        <TableHead className="tableBackground">
                          <TableRow>
                            <StyledTableCell align="left">
                              <StyledTableSortLabel
                                active={orderBy === "company"}
                                direction={
                                  orderBy === "company" ? order : "asc"
                                }
                                onClick={createSortHandler("company")}
                              >
                                {"Artisan"}
                              </StyledTableSortLabel>
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              {text.SupplierList.contact}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {text.SupplierList.email}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {text.SupplierList.address}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {"Statut"}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {text.SupplierList.phone}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {"Numéro de devis"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {text.ArticleList.action}
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {stableSort(
                            filteredSuppliers,
                            getComparator(order, orderBy)
                          )
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((supplier) => (
                              <StyledTableRow key={supplier.id}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align={"left"}
                                >
                                  <Typography component="span">
                                    {
                                      supplier?.artisan?.artisan_profile
                                        ?.company
                                    }
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {`${supplier?.artisan?.name} ${supplier?.artisan?.surname}`}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">{`${supplier?.artisan?.email}`}</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {`${supplier?.artisan?.artisan_profile?.address}, `}
                                    {`${supplier?.artisan?.artisan_profile?.postal_code}, `}
                                    {`${supplier?.artisan?.artisan_profile?.city}`}
                                  </Typography>
                                </TableCell>

                                <TableCell align="left">
                                  {supplier.id === changeStatus ? (
                                    <CommonSelect
                                      field={{
                                        value: supplier.status,
                                        onChange: (status) =>
                                          onChangeStatusHandler(status),
                                      }}
                                      options={artisanOrderStatus}
                                    />
                                  ) : (
                                    <Typography component="span">
                                      <StatusCommon
                                        setChangeStatus={setChangeStatus}
                                        itemId={supplier.id}
                                        status={supplier.status}
                                        disabled={
                                          supplier.status === "waiting_quote"
                                        }
                                      />
                                    </Typography>
                                  )}
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">{`${supplier?.artisan?.mobile}`}</Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography component="span">
                                    {supplier?.quote?.name}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: 4,
                                    }}
                                  >
                                    {supplier.artisan_quote ? (
                                      <Tooltip title="Voir le devis artisan">
                                        <IconButton
                                          size="small"
                                          color="primary"
                                          onClick={() => setViewQuote(supplier)}
                                        >
                                          <FindInPage />
                                        </IconButton>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="Importer le devis de l'artisan">
                                        <IconButton
                                          size="small"
                                          color="primary"
                                          onClick={() =>
                                            setUploadQuote(supplier)
                                          }
                                        >
                                          <CloudUploadOutlined />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                    <Tooltip title="Générer le contrat de sous-traitance">
                                      <IconButton
                                        color="primary"
                                        size="small"
                                        onClick={() =>
                                          setOpenModal({
                                            artisanId: supplier.artisan_id,
                                            quoteId: supplier.quote_id,
                                            status: supplier.status,
                                            id: supplier.id,
                                          })
                                        }
                                      >
                                        <NoteAddIcon size={24} />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </TableCell>
                              </StyledTableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <StyledTablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={suppliers.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage={text.General.rowXPage}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ArtisansOrder;
