import axios from "axios";
require("dotenv").config();

export const apiBaseUrl =
  process.env.REACT_APP_API_URL || "http://inforenov-api.vigee.fr/";
const api = `${apiBaseUrl}api/`;


export const clientCredentialAxios = axios.create({
  baseURL: (axios.defaults.baseURL = api),
});

export const authApiAxios = axios.create({
  baseURL: (axios.defaults.baseURL = api),
});

authApiAxios.defaults.headers.common["Authorization"] =
  localStorage.getItem("token");
//http://api.app.inforenov.com/api/
//http://127.0.0.1:8000/api/
//http://inforenov-api.vigee.fr/api/

authApiAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }
    return error;
  }
);
