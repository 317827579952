import './App.css';
import { Route, Router as BrowserRouter, Switch, useHistory, Redirect } from 'react-router-dom';
//----------- Error ------------
import Page404 from './ErrorPage/Page404';
import Page403 from './ErrorPage/Page403';
//---------- Auth pages ----------
import Login from './AuthPages/Login/Login';
import LayoutNotLogin from '../Hoc/LayoutNotLogin';
import LayoutLogin from '../Hoc/LayoutLogin';
import Dashboard from './CommonPages/Dashboard/Dashboard';
import ForgotPassword from './AuthPages/ForgotPassword/ForgotPassword';
import ChangePassword from './AuthPages/ChangePassword/ChangePassword';
import ClientList from './BusinessPages/Clients/ClientList';
import ArtisanList from './BusinessPages/ArtisanList/ArtisanList';
import BusinessesList from './BusinessPages/BusinessesList/BusinessesList';
import Profile from './BusinessPages/Profile/Profile';
import Configuration from './BusinessPages/Configuration/Configuration';
import ArticlesList from './BusinessPages/ArticlesList/Articles';
import SellingList from './BusinessPages/SellingsList/SellingList';
import SitesList from './BusinessPages/SitesList/SitesList';
import SiteDetail from './BusinessPages/SiteDetail/SiteDetail';
import InvoiceAndQuote from './BusinessPages/SiteDetail/Sections/InvoiceAndQuote';
import Informations from './BusinessPages/SiteDetail/Sections/Informations';
import Planning from './BusinessPages/SiteDetail/Sections/Planning';
import DashboardSiteDetail from './BusinessPages/SiteDetail/Sections/Dashboard';
import CreateEditQuote from './BusinessPages/SiteDetail/Sections/CreateEditQuote';
import UploadPlan from './BusinessPages/SiteDetail/Sections/UploadPlan';
import IncidentPage from './BusinessPages/SiteDetail/Sections/IncidentPage';
import Artisans from './BusinessPages/SiteDetail/Sections/ArtisansOrder';
import SupplierOrder from './BusinessPages/SiteDetail/Sections/SupplierOrder';
import SupplierList from './BusinessPages/SupplierList/SupplierList';
import TextEditor from './BusinessPages/SiteDetail/Sections/TextEditor';
import ArtisanPlanning from './BusinessPages/ArtisanPlanning/ArtisanPlanning';
import TemplateList from './BusinessPages/TemplateList/TemplateList';
import TemplateDetail from './BusinessPages/TemplateDetail/TemplateDetail';
import Contracts from './BusinessPages/SiteDetail/Sections/Contracts';
/* Route */
// eslint-disable-next-line no-unused-vars

const isAuthenticated = () => localStorage.getItem('token');
const PublicRoute = (props) => <Route {...props} />;
const PrivateRoute = (props) => <Route {...props} />;
// const PrivateRoute = (props) =>
//   isAuthenticated() ? <Route {...props} /> : <Redirect to={"/"} />;

const App = () => {
  const history = useHistory();

  return (
    <div className="App">
      <BrowserRouter history={history} basename={'/'}>
        <Switch>
          <PublicRoute path="/" exact>
            <LayoutNotLogin>
              <Login />
            </LayoutNotLogin>
          </PublicRoute>

          <PublicRoute path="/forgot-password" exact>
            <LayoutNotLogin>
              <ForgotPassword />
            </LayoutNotLogin>
          </PublicRoute>

          <PrivateRoute path="/dashboard" exact>
            <LayoutLogin>
              <Dashboard />
            </LayoutLogin>
          </PrivateRoute>

          <PublicRoute path="/reset-password/:tokenId" exact>
            <LayoutNotLogin>
              <ChangePassword />
            </LayoutNotLogin>
          </PublicRoute>

          <PrivateRoute path="/clients" exact>
            <LayoutLogin>
              <ClientList />
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/artisans" exact>
            <LayoutLogin>
              <ArtisanList />
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/artisans" exact>
            <LayoutLogin>
              <ArtisanList />
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/artisans-planning" exact>
            <LayoutLogin>
              <ArtisanPlanning />
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/businesses" exact>
            <LayoutLogin>
              <BusinessesList />
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/articles" exact>
            <LayoutLogin>
              <ArticlesList />
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/suppliers" exact>
            <LayoutLogin>
              <SupplierList />
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/profile" exact>
            <LayoutLogin>
              <Profile />
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/selling" exact>
            <LayoutLogin>
              <SellingList />
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/sites" exact>
            <LayoutLogin>
              <SitesList />
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/site-detail/:siteId/dashboard">
            <LayoutLogin>
              <SiteDetail>
                <DashboardSiteDetail />
              </SiteDetail>
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/site-detail/:siteId/planning">
            <LayoutLogin>
              <SiteDetail>
                <Planning />
              </SiteDetail>
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/site-detail/:siteId/contracts">
            <LayoutLogin>
              <SiteDetail>
                <Contracts />
              </SiteDetail>
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/site-detail/:siteId/invoice-quote/:redirectSection?">
            <LayoutLogin>
              <SiteDetail>
                <InvoiceAndQuote />
              </SiteDetail>
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/site-detail/:siteId/create-quote/:quoteId?">
            <LayoutLogin>
              <SiteDetail>
                <CreateEditQuote />
              </SiteDetail>
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/site-detail/:siteId/project-plan">
            <LayoutLogin>
              <SiteDetail>
                <UploadPlan />
              </SiteDetail>
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/site-detail/:siteId/text-editor">
            <LayoutLogin>
              <SiteDetail>
                <TextEditor />
              </SiteDetail>
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/site-detail/:siteId/artisans">
            <LayoutLogin>
              <SiteDetail>
                <Artisans />
              </SiteDetail>
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/site-detail/:siteId/suppliers">
            <LayoutLogin>
              <SiteDetail>
                <SupplierOrder />
              </SiteDetail>
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/site-detail/:siteId/incidents">
            <LayoutLogin>
              <SiteDetail>
                <IncidentPage />
              </SiteDetail>
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/site-detail/information/:siteId?">
            <LayoutLogin>
              <SiteDetail>
                <Informations />
              </SiteDetail>
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/configuration" exact>
            <LayoutLogin>
              <Configuration />
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/templates" exact>
            <LayoutLogin>
              <TemplateList />
            </LayoutLogin>
          </PrivateRoute>

          <PrivateRoute path="/template/:templateId?" exact>
            <LayoutLogin>
              <TemplateDetail />
            </LayoutLogin>
          </PrivateRoute>

          <PublicRoute path="/403" exact component={Page403} />
          <Route component={Page404} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
