import { Box } from '@material-ui/core';
import SiteContractList from '../../../../Components/BusinessComponents/SiteContractList/SiteContractList';

function Contracts({ site }) {
  return (
    <Box marginTop={5}>
      <SiteContractList siteId={site.id} price={site.price} />
    </Box>
  );
}

export default Contracts;
