import { Typography } from '@material-ui/core';
import React from 'react';

const StatusCommon = ({ status, setChangeStatus, itemId, disabled = false }) => {
  const renderStatusButton = () => {
    let backgroundColor = '#C22E2E';
    let text = 'EN RETARD';

    if (status === 'sended' || status === 'sent') {
      backgroundColor = '#345369';
      text = 'ENVOYÉ';
    }
    if (status === 'not-paid') {
      backgroundColor = '#54C6B2';
      text = 'APPROUVÉE';
    }
    if (status === 'draft' || status === 'not-sent') {
      backgroundColor = '#AEB4BE';
      text = 'Brouillon'.toUpperCase();
    }
    if (status === 'waiting_quote') {
      backgroundColor = '#AEB4BE';
      text = 'DEVIS EN ATTENTE';
    }
    if (status === 'paid') {
      backgroundColor = '#55A53A';
      text = 'PAYÉE';
    }
    if (status === 'received') {
      backgroundColor = '#55A53A';
      text = 'REÇUE';
    }
    if (status === 'accepted') {
      backgroundColor = '#55A53A';
      text = 'SIGNÉ';
    }
    if (status === 'refused') {
      backgroundColor = 'red';
      text = 'REFUSÉ';
    }

    return (
      <div
        style={{
          backgroundColor: backgroundColor,
          borderRadius: 5,
          padding: 10,
          textAlign: 'center',
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
        onClick={() => (disabled ? null : setChangeStatus(itemId))}
      >
        <Typography style={{ color: 'white' }}>{text}</Typography>
      </div>
    );
  };

  return <>{renderStatusButton()}</>;
};

export default StatusCommon;
