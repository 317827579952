import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import CommonSelectAddOption from "../../FormComponents/CommonSelectAddOption";
import { useWindowWidth } from "@react-hook/window-size";
import { useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddIcon from "@mui/icons-material/Add";
import { Artisan } from "../../../utils/icons";

const ArtisanLine = ({
  autoFocus,
  units,
  artisans,
  artisanItem,
  onChangeArtisanHandler,
  level,
  index,
  setOpenModalAddArtisan,
  onAddUnitHandler,
  handlerDeleteArtisanItem,
  artisanWork,
  isSigned,
}) => {
  const clientMode = useSelector((state) => state.dashboard.clientMode);

  const [artisanWorkList, setArtisanWorkList] = useState([]);

  useEffect(() => {
    if (isAddManualy) {
      setArtisanWorkList(artisanItem.artisan_work_name);
    } else {
      const key = "artisan_work_name";
      const arrayUniqueByKey = [
        ...new Map(artisanWork.map((item) => [item[key], item])).values(),
      ];
      setArtisanWorkList(arrayUniqueByKey);
    }
  }, [artisanItem, artisanWork]);

  const useStyles = makeStyles({
    label: {
      fontFamily: ["Roboto", "-apple-system"].join(","),
    },
    input: {
      fontFamily: ["Roboto", "-apple-system"].join(","),
    },
  });
  const classes = useStyles();
  const screenWidth = useWindowWidth();
  const [isAddManualy, setIsAddManualy] = useState(false);

  const tvaItem = [
    { id: 0, name: "0%" },
    { id: 5.5, name: "5,5%" },
    { id: 10, name: "10%" },
    { id: 20, name: "20%" },
  ];

  return (
    <Grid container justify-items="flex-end">
      <Grid item  style={{ paddingTop: 5 }}>
      <Artisan />
      </Grid>
      {isAddManualy ? (
        <Grid style={{ paddingLeft: 10 }}>
          <TextField
            variant="outlined"
            label={"Main d’oeuvre"}
            name={"artisan_work_name"}
            type={"input"}
            value={artisanWorkList}
            onChange={(event) => {
              onChangeArtisanHandler({
                type: "artisan_work_name",
                value: event.target.value,
                index: index,
                level: level,
              });
            }}
            fullWidth
            InputProps={{
              className: `${classes.input} ${classes.label}`,
            }}
            size="small"
            style={{ width: screenWidth > 1600 ? 500 : 150 }}
          />
        </Grid>
      ) : (
        <Grid style={{ paddingLeft: 10 }}>
     
          <Autocomplete
            name={"artisan_work_name"}
            options={artisanWorkList}
            getOptionLabel={(option) => option.artisan_work_name ? option.artisan_work_name : ""}
            noOptionsText={"pas de main d'oeuvre"}
            onChange={(event, newValue) =>
              newValue &&
              onChangeArtisanHandler({
                type: "artisan_work_name",
                value: newValue?.artisan_work_name,
                index: index,
                level: level,
                artisan_work_object: { ...newValue, id: artisanItem.id },
              })
            }
            value={
              artisanWork.find(
                (findArticle) =>
                  findArticle.artisan_work_name ===
                  artisanItem.artisan_work_name
              ) ?? ""
            }
            defaultValue={artisanWork.find(
              (findArticle) =>
                findArticle.artisan_work_name === artisanItem.artisan_work_name
            )}
            className={classes.input}
            fullWidth
            onInputChange={(event) => {
              artisanWork.filter(
                (articleFilter) =>
                  articleFilter.artisan_work_name === event?.target?.value
              );
            }}
            style={{ width: screenWidth > 1600 ? 500 : 150, minWidth: "300px" }}
            size="small"
            renderInput={(params) => (
              <TextField
                label="Choisissez une main d'oeuvre"
                autoFocus={autoFocus}
                {...params}
                variant="outlined"
                fullWidth
                name={"article_id"}
              />
            )}
          />
        </Grid>
      )}

      <Grid style={{ paddingLeft: 10, paddingTop: 5 }}>
        <ModeEditIcon
          className="addIcon"
          size={22}
          style={{ cursor: "pointer" }}
          onClick={() => setIsAddManualy(!isAddManualy)}
        />
      </Grid>

      <Grid style={{ paddingLeft: 5 }}>
        <TextField
          InputProps={{
            className: `${classes.input} ${classes.label}`,
          }}
          style={{ width: "190px" }}
          variant="outlined"
          select
          SelectProps={{ native: true }}
          label={"Artisan"}
          value={artisanItem.artisan_id}
          size="small"
          onChange={(event) => {
            onChangeArtisanHandler({
              type: "artisan_id",
              value: event.target.value,
              index: index,
              level: level,
            });
          }}
          name={"Artisan_id"}
        >
          <option value=""></option>
          {artisans
            .sort((a, b) => {
              const first =
                a.artisan_profile?.company?.toUpperCase() ||
                a.name.toUpperCase();
              const second =
                b.artisan_profile?.company?.toUpperCase() ||
                b.name.toUpperCase();

              if (first < second) {
                return -1;
              }

              if (first > second) {
                return 1;
              }
              return 0;
            })
            .map((option) => {
              return (
                <option
                  key={option.id}
                  value={option.id}
                  style={{
                    color: option?.artisan_profile?.is_blacklisted
                      ? "red "
                      : "black",
                  }}
                >
                  {option?.artisan_profile?.company || option.name}
                </option>
              );
            })}
        </TextField>
      </Grid>
      <Grid style={{ paddingLeft: 5 }}>
        <AddIcon
          size={20}
          className="addIcon"
          style={{ marginTop: 6, cursor: "pointer" }}
          onClick={() => setOpenModalAddArtisan(true)}
        />
      </Grid>
      <Grid style={{ paddingLeft: 5, width: 120 }}>
        <FormControl fullWidth size="small" variant="outlined">
          {!artisanItem.unit_id && <InputLabel>Unité</InputLabel>}
          <CommonSelectAddOption
            field={{
              value: artisanItem.unit_id,
              onChange: (value) => {
                onChangeArtisanHandler({
                  type: "unit_id",
                  value: value,
                  index: index,
                  level: level,
                });
              },
            }}
            options={units}
            inputStyle={{ width: 80 }}
            name={"unit_id"}
            onAddHandler={onAddUnitHandler}
          />
        </FormControl>
      </Grid>
      {!clientMode && (
        <Grid style={{ paddingLeft: 5 }}>
          <TextField
            variant="outlined"
            label={"Prix achat"}
            name={"buy_price"}
            type={"number"}
            value={artisanItem.buy_price}
            style={{ width: 90 }}
            onChange={(event) => {
              onChangeArtisanHandler({
                type: "buy_price",
                value: event.target.value,
                index: index,
                level: level,
              });
            }}
            fullWidth
            InputProps={{
              className: [classes.input ?? ""].join(" "),
            }}
            size="small"
          />
        </Grid>
      )}
      <Grid style={{ paddingLeft: 5 }}>
        <TextField
          variant="outlined"
          label={"Prix unitaire"}
          name={"selling_price"}
          type={"number"}
          value={artisanItem.selling_price}
          style={{ width: 90 }}
          onChange={(event) => {
            onChangeArtisanHandler({
              type: "selling_price",
              value: event.target.value,
              index: index,
              level: level,
            });
          }}
          fullWidth
          InputProps={{
            readOnly: isSigned,
            className: [classes.input ?? ""].join(" "),
          }}
          size="small"
        />
      </Grid>
      <Grid style={{ paddingLeft: 5 }}>
        <TextField
          variant="outlined"
          label={"Qté"}
          name={"quantity"}
          type={"number"}
          value={artisanItem.quantity}
          onChange={(event) => {
            onChangeArtisanHandler({
              type: "quantity",
              value: event.target.value,
              index: index,
              level: level,
            });
          }}
          style={{ width: 50 }}
          InputProps={{
            readOnly: isSigned,
            className: [classes.input ?? ""].join(" "),
          }}
          size="small"
        />
      </Grid>
      {!clientMode && (
        <Grid style={{ paddingLeft: 5 }}>
          <TextField
            variant="outlined"
            label={"Marge"}
            name={"margin_percentage"}
            type={"number"}
            value={artisanItem?.margin_percentage?.toFixed(0)}
            style={{ width: 70 }}
            onChange={(event) => {
              onChangeArtisanHandler({
                type: "margin_percentage",
                value: event.target.value,
                index: index,
                level: level,
              });
            }}
            fullWidth
            InputProps={{
              className: [classes.input ?? ""].join(" "),
              readOnly: isSigned,
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>%</Typography>
                </InputAdornment>
              ),
            }}
            size="small"
          />
        </Grid>
      )}
      <Grid style={{ paddingLeft: 5 }}>
        <FormControl size="small" variant="outlined" fullWidth>
          <InputLabel>TVA</InputLabel>
          <Select
            native
            label={"Tva"}
            value={artisanItem.tva}
            onChange={(event) => {
              onChangeArtisanHandler({
                type: "tva",
                value: event.target.value,
                index: index,
                level: level,
              });
            }}
            name={"tva"}
          >
            {tvaItem.map((option) => {
              return (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid style={{ paddingLeft: 10, paddingTop: 6 }}>
        <ClearIcon
          className="deleteIcon"
          size={22}
          style={{ cursor: "pointer", color: "#999" }}
          onClick={() => handlerDeleteArtisanItem(artisanItem, index, level)}
        />
      </Grid>

      <Grid style={{ paddingLeft: 10, paddingTop: 6 }}>
        <Typography style={{ fontSize: 18, fontWeight: "bolder" }}>
          {artisanItem?.total_price?.toFixed(2)} €
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ArtisanLine;
