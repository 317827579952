import {
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import { useState } from 'react';
import { toBase64 } from '../../../utils/utils';

function UploadDocumentDialog({ open, onClose, uploadDocument }) {
  const [title, setTitle] = useState('');
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const handleClose = () => {
    onClose();
    setFile(null);
    setTitle('');
    setUploading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file || !title) {
      return;
    }

    setUploading(true);
    const base64File = await toBase64(file);
    await uploadDocument({ title, file: base64File });
    setUploading(false);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Ajout d'un plan</DialogTitle>
      <DialogContent>
        <form id="upload-document-form" onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            fullWidth
            label="Description du document"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <Box marginTop={2} display="flex" justifyContent="space-between" alignItems="center">
            <Typography>{file ? file.name : 'Aucun fichier sélectionné'}</Typography>
            <input
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
              accept="application/pdf"
              hidden
              id="file-input"
            />
            <Button variant="contained" color="primary" component="label" htmlFor="file-input">
              Choisir le fichier
            </Button>
          </Box>
        </form>
        <Box marginTop={4} minHeight={1}>
          {uploading && <LinearProgress />}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Fermer</Button>
        <Button
          type="submit"
          form="upload-document-form"
          variant="contained"
          color="primary"
          startIcon={<CloudUpload />}
          disabled={!file || !title || uploading}
        >
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UploadDocumentDialog;
