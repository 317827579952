import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useState } from 'react';
import { contracts } from '../../../Axios/Call/AuthApi';
import WarningIcon from '@material-ui/icons/Warning';

function DeleteContractDialog({ open, onClose, onDeleted, onError, contractId }) {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await contracts.destroy(contractId);
      onDeleted(contractId);
      onClose();
    } catch {
      if (typeof onError === 'function') {
        onError();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Êtes-vous certain de vouloir supprimer définitivement ce document ?</DialogTitle>
      <DialogContent>
        <DialogContentText>Cette action est irreversible.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button
          color="secondary"
          variant="contained"
          startIcon={<WarningIcon />}
          onClick={handleDelete}
          disabled={loading}
        >
          Supprimer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteContractDialog;
