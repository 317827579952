import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useState } from 'react';
import { contracts } from '../../../Axios/Call/AuthApi';
import EmailIcon from '@material-ui/icons/Email';

function SendContractDialog({ open, onClose, onSent, onError, contractId }) {
  const [loading, setLoading] = useState(false);

  const handleSend = async () => {
    setLoading(true);
    try {
      const response = await contracts.send(contractId);
      onSent(response.data);
      onClose();
    } catch {
      if (typeof onError === 'function') {
        onError();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Voulez-vous envoyer ce document ?</DialogTitle>
      <DialogContent>
        <DialogContentText>Le client recevra le document par Email</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button color="primary" variant="contained" startIcon={<EmailIcon />} onClick={handleSend} disabled={loading}>
          Envoyer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SendContractDialog;
