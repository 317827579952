import React, { useEffect, useState } from "react";
import { Grid, Modal, Box, Typography } from "@material-ui/core";
import { Cross } from "../../../utils/icons";
import {
  editInvoiceBeforeSend,
  getOneInvoice,
  getOneInvoicePdf,
  sendInvoice as sendInvoiceApi,
} from "../../../Axios/Call/AuthApi";
import CommonButton from "../../FormComponents/CommonButton";
import LoadingButton from "../../Commons/LoadingButton/LoadingButton";
import { useForm, Controller } from "react-hook-form";
import TextFieldCustom from "../../FormComponents/TextFieldCustom";
import text from "../../../utils/text";
import CommonDateTimePicker from "../../FormComponents/CommonDateTimePicker";
import moment from "moment";
import AlertGeneral from "../../Commons/AlertGeneral/AlertGeneral";
import TextBoxCustom from "../../FormComponents/TextBoxCustom";
import CommonSelect from "../../FormComponents/CommonSelect";

const ModalSendInvoice = ({ openModal, setOpenModal, onInvoiceSent }) => {
  const [invoice, setInvoice] = useState({});
  const [errorApi, setErrorApi] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [sending, setSending] = useState(false);
  const [pdf, setPdf] = useState("");
  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
    watch,
    setValue,
    setError,
  } = useForm({
    mode: "onBlur",
  }); // initialise the hook
  const conditionPaymentList = [
    { id: "7 Jours", name: "7 Jours" },
    { id: "15 Jours", name: "15 Jours" },
    { id: "30 Jours", name: "30 Jours" },
    { id: "À réception", name: "À réception" },
  ];

  useEffect(() => {
    if (openModal) {
      setLoadingApi(true);
      getOneInvoice(openModal)
        .then((response) => {
          setInvoice(response.data);
          setValue("name", response.data.name);
          reset({
            ...response.data,
            condition_payment: response.data.condition_payment || "15 Jours",
          });
          setLoadingApi(false);
        })
        .catch((error) => {
          setErrorApi(true);
          setLoadingApi(false);
        });
    }
  }, [openModal, reset, setValue]);

  const onShowInvoiceHandler = (data) => {
    setLoadingPdf(true);
    console.log(data);
    if (!data.condition_payment) {
      setError("condition_payment", {
        message: text.General.required,
      });
      setLoadingPdf(false);
      return;
    }
    editInvoiceBeforeSend(openModal, {
      ...data,
      payment_estimated_date: moment(data.payment_estimated_date).format(
        "yyyy-MM-DD"
      ),
    })
      .then((response) => {
        getOneInvoicePdf(openModal)
          .then((response) => {
            setPdf(response.data);
          })
          .catch((error) => {
            setErrorApi(true);
            setLoadingPdf(false);
          });
      })
      .catch((error) => {
        setErrorApi(true);
        setLoadingPdf(false);
      });
  };

  const handleClose = () => {
    setOpenModal(false);
    setInvoice({});
    setPdf("");
  };

  const sendInvoice = async () => {
    setSending(true);
    await sendInvoiceApi(invoice.id);
    handleClose();
    if (typeof onInvoiceSent === "function") {
      onInvoiceSent(invoice);
    }
    setSending(false);
  };

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertError}
        />
      )}
      <Modal open={openModal} size={"xl"}>
        <Grid container className={"modalWrapperWeb"}>
          <div
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <Cross />
          </div>
          {loadingApi ? (
            <>
              <LoadingButton />
              <Typography variant="h6" style={{ textAlign: "center" }}>
                Chargement des données...
              </Typography>
            </>
          ) : (
            <>
              {(!pdf || loadingPdf) && (
                <Grid item xs={12}>
                  <form
                    onSubmit={handleSubmit(onShowInvoiceHandler)}
                    noValidate
                  >
                    <Grid container>
                      <Grid item xs={12} md={6} style={{ marginTop: 15 }}>
                        <TextFieldCustom
                          variant="outlined"
                          label={"Numéro de facture"}
                          type="input"
                          ref={register({ required: text.General.required })}
                          defaultValue=""
                          name={"name"}
                          error={errors.name}
                          readOnly
                        />
                      </Grid>
                      <Grid item xs={12} md={6} style={{ paddingLeft: 20 }}>
                        <Controller
                          render={(field) => (
                            <CommonDateTimePicker
                              field={field}
                              labelCustom={"Date de facturation"}
                              name={"payment_estimated_date"}
                            />
                          )}
                          control={control}
                          name="payment_estimated_date"
                          defaultValue={moment()}
                          rules={{}}
                        />
                      </Grid>

                      <Grid item xs={12} md={12} style={{ marginTop: 15 }}>
                        <Controller
                          render={(field) => (
                            <CommonSelect
                              labelCustom={"Conditions de paiement"}
                              error={errors.condition_payment}
                              field={field}
                              options={conditionPaymentList}
                            />
                          )}
                          control={control}
                          defaultValue={""}
                          name="condition_payment"
                          rules={{}}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} style={{ marginTop: 15 }}>
                        <TextBoxCustom
                          variant="outlined"
                          label={"Message"}
                          placeHolder="Message à ajouter sur la facture (facultatif)"
                          type="input"
                          ref={register({})}
                          defaultValue=""
                          name={"message"}
                          error={errors.message}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        align="right"
                        style={{ marginTop: 20 }}
                      >
                        <CommonButton
                          text="Créer la facture"
                          loading={loadingPdf}
                          style={{ width: 200 }}
                        />
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              )}
              <Grid item xs={12}>
                <iframe
                  src={pdf}
                  style={{
                    width: "100%",
                    height: "60vh",
                    display: !pdf || loadingPdf ? "none" : "block",
                  }}
                  type="application/pdf"
                  name="myiframe"
                  id="myiframe"
                  title="myiframe"
                  onLoad={() => setLoadingPdf(false)}
                />

                {pdf && !loadingPdf && (
                  <Box marginTop={4} display="flex" justifyContent="flex-end">
                    <CommonButton
                      onClick={sendInvoice}
                      text={text.General.send}
                      loading={sending}
                    />
                  </Box>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Modal>
    </>
  );
};

export default ModalSendInvoice;
