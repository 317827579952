import { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
  IconButton,
  SvgIcon,
  Tooltip,
} from '@material-ui/core';

import text from '../../../utils/text';
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
import AlertGeneral from '../../../Components/Commons/AlertGeneral/AlertGeneral';
import {
  deleteQuote,
  getAllQuote,
  quoteChangeStatus,
  signContractAndCreateInvoice,
  signInvoiceManually,
} from '../../../Axios/Call/AuthApi';
import { withStyles } from '@material-ui/core/styles';
import {
  AiFillEdit,
  AiFillDelete,
  AiOutlineEye,
  AiOutlineFileDone,
  AiOutlineUpload,
  AiTwotoneEye,
} from 'react-icons/ai';
import { FaFileSignature } from 'react-icons/fa';
import { CgFileDocument } from 'react-icons/cg';
import { GiStoneCrafting } from 'react-icons/gi';
import { TbReportMoney } from 'react-icons/tb';
import { HiOutlineDocumentText, HiOutlineDocumentReport } from 'react-icons/hi';

import ModalGeneral from '../../../Components/Commons/ModalGeneral/ModalGeneral';
import FilterTextList from '../../../Components/Commons/FilterTextList/FilterTextList';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import CommonSelect from '../../FormComponents/CommonSelect';
import StatusCommon from '../../Commons/StatusCommon/StatusCommon';
import ModalSendQuote from '../Invoices/ModalSendQuote';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { toBase64 } from '../../../utils/utils';
import DuplicateQuoteDialog from './DuplicateQuoteDialog';
import DoneAllIcon from '@mui/icons-material/DoneAll';

const QuoteList = () => {
  const [quotes, setQuotes] = useState([]);
  const [filteredQuote, setFilteredQuote] = useState([]);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModal, setOpenModal] = useState({});
  const [loadingApi, setLoadingApi] = useState(false);
  const [loadingSignContract, setLoadingSignContract] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [changeStatusErrorApi, setChangeStatusErrorApi] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [duplicate, setDuplicate] = useState(0);
  const { siteId } = useParams();
  const history = useHistory();
  //Status change
  const [changeStatus, setChangeStatus] = useState(false);
  const statusOption = [
    { id: 'draft', name: 'Brouillon'.toUpperCase() },
    { id: 'sended', name: 'ENVOYÉ' },
    { id: 'refused', name: 'REFUSÉ' },
  ];

  useEffect(() => {
    setLoadingApi(true);
    getAllQuote(siteId)
      .then((response) => {
        setLoadingApi(false);
        setQuotes(response.data);
        setFilteredQuote(response.data);
      })
      .catch((error) => {
        setLoadingApi(false);
        setErrorApi(false);
      });
  }, [siteId]);

  const onChangeStatusHandler = (status) => {
    quoteChangeStatus(changeStatus, { status: status })
      .then((response) => {
        setQuotes(
          quotes.map((invoice) =>
            invoice.id === changeStatus ? { ...invoice, status: status, is_validated: true } : invoice
          )
        );
        setFilteredQuote(
          filteredQuote.map((invoice) =>
            invoice.id === changeStatus ? { ...invoice, status: status, is_validated: true } : invoice
          )
        );
        setChangeStatus(false);
      })
      .catch((error) => {
        setChangeStatusErrorApi(true);
      });
  };

  const onDeleteQuoteHandler = () => {
    deleteQuote(siteId, openModalDelete)
      .then((response) => {
        setQuotes(quotes.filter((aQuote) => aQuote.id !== openModalDelete));
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const StyledTableCell = withStyles((theme) => ({
    root: {
      backgroundColor: '#345369',
      fontSize: 14,
      fontFamily: ['roboto', '-apple-system'].join(','),
      color: 'white',
      wordWrap: 'break-word',
      border: 'none',
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const StyledTableSortLabel = withStyles((theme) => ({
    root: {
      color: 'white',
      '&:hover': {
        color: 'white',
      },
      '&$active': {
        color: 'white',
      },
    },
    active: {},
    icon: {
      color: 'inherit !important',
    },
  }))(TableSortLabel);

  const StyledTablePagination = withStyles((theme) => ({
    root: {
      color: '#345369',
    },
    menuItem: {
      fontFamily: ['roboto', '-apple-system'].join(','),
    },
    caption: {
      fontFamily: ['roboto', '-apple-system'].join(','),
    },
    selectIcon: {
      color: 'white',
    },
  }))(TablePagination);

  const signContractHandler = (quoteId) => {
    setLoadingSignContract(quoteId);
    signContractAndCreateInvoice(siteId, quoteId, {})
      .then((response) => {
        setQuotes(quotes.map((quote) => (quote.id === quoteId ? { ...quote, status: 'sended' } : quote)));
        setFilteredQuote(quotes.map((quote) => (quote.id === quoteId ? { ...quote, status: 'sended' } : quote)));
        setLoadingSignContract(false);
      })
      .catch((error) => {
        setLoadingSignContract(false);
        setErrorApi(true);
      });
  };

  return (
    <>
      {errorApi && <AlertGeneral color="danger" setTrigger={setErrorApi} description={text.General.alertError} />}
      {changeStatusErrorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setChangeStatusErrorApi}
          description={'Impossible de revenir à un état antérieur'}
        />
      )}
      <DuplicateQuoteDialog
        open={Boolean(duplicate)}
        onClose={() => setDuplicate(0)}
        onError={() => setErrorApi(true)}
        onSuccess={(quote) => {
          setQuotes((list) => [...list, quote]);
          setFilteredQuote((list) => [...list, quote]);
        }}
        quoteId={duplicate}
        siteId={siteId}
      />

     
      <ModalSendQuote openModal={openModal} setOpenModal={setOpenModal} />
      <ModalGeneral
        isOpenModal={openModalDelete}
        title={text.ArtisanList.deleteTitle}
        description={text.ArtisanList.deleteDescription}
        button1Text={'Oui'}
        button2Text={'Annuler'}
        onClick1={() => onDeleteQuoteHandler()}
        onClick2={() => setOpenModalDelete(false)}
        isPassword
      />

      <Grid container>
        <Grid item xs={12} align="left">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} align="left">
              <FilterTextList allItems={quotes} setFiltered={setFilteredQuote} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {loadingApi ? (
            <>
            <LoadingButton />
            <Typography variant='h6'>Chargement des données...</Typography>
            </>
          ) : (
            <Grid container justifyContent="center" style={{ marginTop: 20 }}>
              <Grid item xs={12} md={12}>
                <TableContainer>
                  <Table style={{ width: '100%' }}>
                    <TableHead className="tableBackground">
                      <TableRow>
                        <StyledTableCell align="left">
                          <StyledTableSortLabel
                            active={orderBy === 'created_at'}
                            direction={orderBy === 'created_at' ? order : 'asc'}
                            onClick={createSortHandler('created_at')}
                          >
                            {text.quoteList.date}
                          </StyledTableSortLabel>
                        </StyledTableCell>

                        <StyledTableCell align="left">{text.quoteList.client}</StyledTableCell>
                        <StyledTableCell align="left">
                          <TableSortLabel
                            active={orderBy === 'status'}
                            direction={orderBy === 'status' ? order : 'asc'}
                            onClick={createSortHandler('status')}
                          >
                            {text.quoteList.status}
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <TableSortLabel
                            active={orderBy === 'name'}
                            direction={orderBy === 'name' ? order : 'asc'}
                            onClick={createSortHandler('name')}
                          >
                            {text.quoteList.number}
                          </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell align="left">{text.quoteList.price}</StyledTableCell>
                        <StyledTableCell align="center">Documents</StyledTableCell>

                        <StyledTableCell align="center">{text.quoteList.action}</StyledTableCell>
                        <StyledTableCell align="center">Signature</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stableSort(filteredQuote, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((quote) => (
                          <StyledTableRow key={quote.id}>
                            <TableCell component="th" scope="row" align={'left'}>
                              <Typography component="span">{moment(quote?.created_at).format('DD/MM/YYYY')}</Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography component="span">
                                {`${quote?.site?.client?.name} ${quote?.site?.client?.surname}`}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography component="span">
                                {quote.id === changeStatus ? (
                                  <CommonSelect
                                    field={{
                                      value: quote.status,
                                      onChange: (status) => onChangeStatusHandler(status),
                                    }}
                                    options={statusOption}
                                  />
                                ) : (
                                  <Typography component="span">
                                    <StatusCommon
                                      setChangeStatus={quote.status === 'accepted' ? () => true : setChangeStatus}
                                      itemId={quote.id}
                                      status={quote.status}
                                    />
                                  </Typography>
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography component="span">{quote?.name}</Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography component="span">{quote?.total_amount_no_tva?.toFixed(2)} €</Typography>
                            </TableCell>

                            <TableCell align="right">
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  color: '#345369',
                                }}
                              >
                                <Tooltip title="Appel d'offre artisan">
                                  <IconButton
                                    size="small"
                                    color="inherit"
                                    onClick={() =>
                                      setOpenModal({
                                        quoteId: quote.id,
                                        isNoPrice: true,
                                        estimate: false,
                                        avp: false,
                                      })
                                    }
                                  >
                                    <GiStoneCrafting />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="AVP">
                                  <IconButton
                                    size="small"
                                    color="inherit"
                                    onClick={() =>
                                      setOpenModal({
                                        quoteId: quote.id,
                                        isNoPrice: false,
                                        estimate: false,
                                        avp: true,
                                      })
                                    }
                                  >
                                    <HiOutlineDocumentReport size={20} />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="Estimatif">
                                  <IconButton
                                    size="small"
                                    color="inherit"
                                    onClick={() =>
                                      setOpenModal({
                                        quoteId: quote.id,
                                        isNoPrice: false,
                                        estimate: true,
                                        avp: false,
                                      })
                                    }
                                  >
                                    <TbReportMoney size={20} />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="Devis">
                                  <IconButton
                                    size="small"
                                    color="inherit"
                                    onClick={() =>
                                      setOpenModal({
                                        quoteId: quote.id,
                                        isNoPrice: false,
                                        estimate: false,
                                        avp: false,
                                      })
                                    }
                                  >
                                    <HiOutlineDocumentText style={{ cursor: 'pointer' }} size={20} />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </TableCell>

                            <TableCell align="right">
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  color: '#345369',
                                }}
                              >
                                <Tooltip title="Dupliquer">
                                  <IconButton color="primary" size="small" onClick={() => setDuplicate(quote.id)}>
                                    <FileCopyOutlinedIcon />
                                  </IconButton>
                                </Tooltip>

                                {quote.status && (
                                  <Tooltip title="Éditer">
                                    <IconButton
                                      size="small"
                                      color="inherit"
                                      onClick={() => {
                                        history.push(`/site-detail/${siteId}/create-quote/${quote.id}`);
                                      }}
                                    >
                                      <AiFillEdit size={24} />
                                    </IconButton>
                                  </Tooltip>
                                )}

                                {quote.status === 'draft' && (
                                  <Tooltip title="Supprimer">
                                    <IconButton
                                      size="small"
                                      color="inherit"
                                      onClick={() => setOpenModalDelete(quote.id)}
                                      style={{ color: '#900C3F' }}
                                    >
                                      <AiFillDelete size={24} />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </div>
                            </TableCell>

                            <TableCell align="right">
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  color: '#345369',
                                }}
                              >
                                {loadingSignContract === quote.id ? (
                                  
                                  <LoadingButton />
                                ) : (
                                  <>
                                    <Tooltip title="Signature manuelle">
                                      <IconButton
                                        size="small"
                                        color="inherit"
                                        component="label"
                                        htmlFor="upload-manual-sighature"
                                      >
                                        <AiOutlineUpload size={24} />
                                      </IconButton>
                                    </Tooltip>
                                    <input
                                      type="file"
                                      onChange={async (e) => {
                                        const file = e.target.files[0];
                                        if (file) {
                                          const doc = (await toBase64(file)).replace(
                                            'data:application/pdf;base64,',
                                            ''
                                          );
                                          await signInvoiceManually(siteId, quote.id, doc);
                                          setQuotes(
                                            quotes.map((q) => (q.id === quote.id ? { ...q, status: 'accepted' } : q))
                                          );
                                          setFilteredQuote(
                                            quotes.map((q) => (q.id === quote.id ? { ...q, status: 'accepted' } : q))
                                          );
                                        }
                                      }}
                                      value={''}
                                      hidden
                                      id="upload-manual-sighature"
                                      accept="application/pdf"
                                    />
                                  </>
                                )}
                                {quote.status === 'draft' &&
                                  (loadingSignContract === quote.id ? (
                                    <LoadingButton />
                                  ) : (
                                    <Tooltip title="Envoyer par DocuSign">
                                      <IconButton
                                        size="small"
                                        color="inherit"
                                        onClick={() => signContractHandler(quote.id)}
                                      >
                                        <AiOutlineFileDone size={24} />
                                      </IconButton>
                                    </Tooltip>
                                  ))}

                                {['sended', 'accepted'].includes(quote.status) && quote.signature_type === 'docusign' && (
                                  <Tooltip title="Envoyé par DocuSign">
                                    <DoneAllIcon size={24} />
                                  </Tooltip>
                                )}

                                {['sended', 'accepted'].includes(quote.status) && quote.signature_type === 'manual' && (
                                  <Tooltip title="Signature manuelle">
                                    <DoneAllIcon size={24} />
                                  </Tooltip>
                                )}
                              </div>
                            </TableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <StyledTablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={quotes.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={text.General.rowXPage}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default QuoteList;
