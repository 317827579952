import React, { useEffect, useRef } from 'react';
import { Grid, Typography, Modal } from '@material-ui/core';
import text from '../../../utils/text';
import { Clients, Cross } from '../../../utils/icons';
import classes from './ClientList.module.css';
import TextFieldCustom from '../../../Components/FormComponents/TextFieldCustom';
import RadioGroupCustom from '../../../Components/FormComponents/RadioGroupCustom';
import { useForm, Controller } from 'react-hook-form';
import CommonButton from '../../../Components/FormComponents/CommonButton';
import CommonSelect from '../../../Components/FormComponents/CommonSelect';
import {
  formatErrorEmail,
  formatErrorLetters,
  formatErrorPhoneNumber,
  formatErrorSiret,
} from '../../../utils/formValidation';
import CommonDateTimePicker from '../../../Components/FormComponents/CommonDateTimePicker';
import moment from 'moment';
import TextBoxCustom from '../../../Components/FormComponents/TextBoxCustom';

const ModalAddClient = ({
  setOpenModalAdd,
  openModalAdd,
  onCreateNewClient,
  loadingApiAdd,
  editClient,
  setEditClient,
  commercials,
  assistants,
  conducteurs,
  errorEmail,
}) => {
  const typeRef = useRef(null);
  const { register, handleSubmit, errors, reset, control, watch, setError } = useForm({
    mode: 'onBlur',
  }); // initialise the hook
  const roles = [
    { id: 'particuler', name: 'Particulier' },
    { id: 'company', name: 'Enterprise' },
  ];

  const createNewClient = (data) => {
    onCreateNewClient(data);
  };

  useEffect(() => {
    if (Object.keys(editClient).length) {
      reset({
        ...editClient,
        ...editClient?.client_profile,
        commercial_id: editClient?.client_assignation?.find((assignation) => assignation.type === 'commercial')
          ?.business_id,
        assistant_id: editClient?.client_assignation?.find((assignation) => assignation.type === 'assistant')
          ?.business_id,
        conducteur_id: editClient?.client_assignation?.find((assignation) => assignation.type === 'conducteur')
          ?.business_id,
      });
    } else {
      reset({});
    }
  }, [editClient]);

  useEffect(() => {
    if (errorEmail) {
      setError('email', {
        message: text.General.emailTooken,
      });
      if (typeRef.current) {
        typeRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    }
  }, [errorEmail]);

  return (
    <Modal open={openModalAdd} size={'lg'}>
      <Grid container className={'modalWrapperWeb'}>
        <div
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            cursor: 'pointer',
          }}
          onClick={() => {
            setOpenModalAdd(false);
            setEditClient({});
          }}
        >
          <Cross />
        </div>
        <Grid item align="left" style={{ paddingTop: 3 }}>
          <Clients size={38} fill="#345369" />
        </Grid>
        <Grid item align="left" style={{ paddingLeft: 10 }}>
          <Typography variant="h2">{text.ClientList.modalTitle}</Typography>
        </Grid>
        <Grid item xs={12} align="left" style={{ marginTop: 10 }}>
          <form onSubmit={handleSubmit(createNewClient)} style={{ marginTop: 20 }} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12} align="left" style={{ marginTop: 5, paddingLeft: 20 }} ref={typeRef}>
                <Controller
                  onFocus={() => {
                    if (typeRef.current) {
                      console.log('i scroll');
                      typeRef.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'nearest',
                      });
                    }
                  }}
                  render={(field) => (
                    <RadioGroupCustom
                      field={field}
                      options={roles}
                      name="status"
                      labelName={text.ClientList.status}
                      error={errors.type}
                    />
                  )}
                  name="status"
                  defaultValue={'particuler'}
                  control={control}
                  rules={{ required: text.General.required }}
                />
              </Grid>
              {watch('status') === 'company' && (
                <>
                  <Grid item xs={12} align="left" style={{ marginTop: 10, paddingTop: 15, paddingRight: 0 }}>
                    <Typography variant="h3">{text.ClientList.sectionOne}</Typography>
                  </Grid>
                  <Grid item xs={12} md={4} align="left" style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}>
                    <TextFieldCustom
                      variant="outlined"
                      label={text.ClientList.company}
                      type="input"
                      ref={register({ required: text.General.required })}
                      defaultValue=""
                      name={'company'}
                      error={errors.company}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} align="left" style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}>
                    <TextFieldCustom
                      variant="outlined"
                      label={text.ClientList.siret}
                      type="input"
                      ref={register({
                        required: text.General.required,
                        validate: { formatErrorSiret: formatErrorSiret },
                      })}
                      defaultValue=""
                      name={'siret'}
                      error={errors.siret}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} align="left" style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}>
                    <TextFieldCustom
                      variant="outlined"
                      label={text.ClientList.tva}
                      type="input"
                      ref={register({ required: text.General.required })}
                      defaultValue=""
                      name={'tva'}
                      error={errors.tva}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} align="left" style={{ marginTop: 10, paddingTop: 15, paddingRight: 0 }}>
                <Typography variant="h3">{text.ClientList.sectionTwo}</Typography>
              </Grid>
              <Grid item xs={12} md={4} align="left" style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={text.ClientList.name}
                  type="input"
                  ref={register({
                    required: text.General.required,
                    validate: { formatErrorLetters: formatErrorLetters },
                  })}
                  defaultValue=""
                  name={'name'}
                  error={errors.name}
                />
              </Grid>
              <Grid item xs={12} md={4} align="left" style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={text.ClientList.surname}
                  type="input"
                  ref={register({
                    required: text.General.required,
                    validate: { formatErrorLetters: formatErrorLetters },
                  })}
                  defaultValue=""
                  name={'surname'}
                  error={errors.surname}
                />
              </Grid>
              <Grid item xs={12} md={4} align="left" style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={text.ClientList.email}
                  type="input"
                  ref={register({
                    required: text.General.required,
                    validate: { formatErrorEmail: formatErrorEmail },
                  })}
                  defaultValue=""
                  name={'email'}
                  error={errors.email}
                />
              </Grid>

              <Grid item xs={12} md={4} align="left" style={{ marginTop: 1, paddingTop: 0, paddingRight: 0 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={text.ClientList.phone}
                  type="input"
                  ref={register({})}
                  defaultValue=""
                  name={'phone'}
                  error={errors.phone}
                />
              </Grid>
              <Grid item xs={12} md={4} align="left" style={{ marginTop: 1, paddingTop: 0, paddingRight: 0 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={text.ClientList.mobile}
                  type="input"
                  ref={register({
                    required: text.General.required,
                    validate: {
                      formatErrorPhoneNumber: formatErrorPhoneNumber,
                    },
                  })}
                  defaultValue=""
                  name={'mobile'}
                  error={errors.mobile}
                />
              </Grid>
              <Grid item xs={12} align="left" style={{ marginTop: 10 }}>
                <Typography variant="h3">Adresse</Typography>
              </Grid>
              <Grid item xs={12} md={6} align="left" style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={text.ClientList.address}
                  type="input"
                  ref={register({ required: text.General.required })}
                  defaultValue=""
                  name={'address'}
                  error={errors.address}
                />
              </Grid>
              <Grid item xs={6} md={3} align="left" style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={text.ClientList.codePostal}
                  type="input"
                  ref={register({ required: text.General.required })}
                  defaultValue=""
                  name={'postal_code'}
                  error={errors.postal_code}
                />
              </Grid>
              <Grid item xs={6} md={3} align="left" style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={text.ClientList.city}
                  type="input"
                  ref={register({ required: text.General.required })}
                  defaultValue=""
                  name={'city'}
                  error={errors.city}
                />
              </Grid>
              <Grid item xs={12} align="left" style={{ marginTop: 10 }}>
                <Typography variant="h3">Équipe</Typography>
              </Grid>
              <Grid item xs={12} md={4} align="left" style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}>
                <Controller
                  render={(field) => (
                    <CommonSelect
                      field={field}
                      labelCustom={'Commercial'}
                      options={commercials}
                      error={errors.commercial_id}
                      name={'commercial_id'}
                    />
                  )}
                  name={'commercial_id'}
                  control={control}
                  defaultValue={''}
                  rules={{ required: text.General.required }}
                />
              </Grid>
              <Grid item xs={12} md={4} align="left" style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}>
                <Controller
                  render={(field) => (
                    <CommonSelect
                      field={field}
                      labelCustom={'Conducteur'}
                      options={conducteurs}
                      error={errors.conducteur_id}
                      name={'conducteur_id'}
                    />
                  )}
                  name={'conducteur_id'}
                  control={control}
                  defaultValue={''}
                  //rules={{ required: text.General.required }}
                />
              </Grid>
              <Grid item xs={12} md={4} align="left" style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}>
                <Controller
                  render={(field) => (
                    <CommonSelect
                      field={field}
                      labelCustom={'Assistant'}
                      options={assistants}
                      error={errors.assistant_id}
                      name={'assistant_id'}
                    />
                  )}
                  name={'assistant_id'}
                  control={control}
                  defaultValue={''}
                  //rules={{ required: text.General.required }}
                />
              </Grid>
              <Grid item xs={12} align="left" style={{ marginTop: 10 }}>
                <Typography variant="h3">{text.ClientList.sectionThree}</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  render={(field) => (
                    <CommonDateTimePicker
                      field={field}
                      labelCustom={text.ClientList.labelDate}
                      minDate={moment()}
                      minDateMessage={text.ClientList.minDateMessage}
                      name={'recontact_at'}
                    />
                  )}
                  control={control}
                  name="recontact_at"
                  defaultValue={moment()}
                  rules={{ valid: (val) => !moment(val).isValid() && 'Invalid Date' }}
                />
              </Grid>
              <Grid item xs={12} md={9} style={{ marginTop: 30 }}>
                <TextBoxCustom
                  variant="outlined"
                  label={text.ClientList.note}
                  ref={register({})}
                  defaultValue=""
                  name={'note'}
                  error={errors.note}
                />
              </Grid>
              <Grid item xs={12} md={12} style={{ marginTop: 10, marginBottom: 10 }}>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={12} md={4} align="center" style={{ marginTop: 20 }}>
                    <CommonButton
                      loading={loadingApiAdd}
                      text={Object.keys(editClient).length ? text.ClientList.ctaEdit : text.ClientList.ctaCreate}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ModalAddClient;
