import { toBase64 } from '../../utils/utils';
import { authApiAxios } from '../AxiosInstance';

export const me = () => {
  return authApiAxios.get('me');
};

export const getNotifications = () => {
  return authApiAxios.get('/notifications');
};

export const changeNotification = (id) => {
  return authApiAxios.put(`/notifications/${id}`);
};

export const getNotificationSettings = () => {
  return authApiAxios.get('/notification-settings');
};

export const editNotificationSettings = (data) => {
  return authApiAxios.put('/notification-settings', data);
};

export const getPicture = () => {
  return authApiAxios.get('me-picture');
};

export const checkPassword = (data) => {
  return authApiAxios.post('check-password', data);
};

export const changeProfileApi = (data) => {
  return authApiAxios.post('change-profile', data);
};

export const changePasswordApi = (data) => {
  return authApiAxios.post('change-password', data);
};

//-----------Client api ------------
export const getClients = (section) => {
  return authApiAxios.get(`/business/clients?type=${section}`);
};

export const getOneClient = (id) => {
  return authApiAxios.get(`/business/client/${id}`);
};

export const createClient = (data) => {
  return authApiAxios.post(`/business/client`, data);
};

export const editClientApi = (id, data) => {
  return authApiAxios.patch(`/business/client/${id}`, data);
};

export const deleteClient = (id) => {
  return authApiAxios.delete(`/business/client/${id}`);
};

export const getBusinessForClient = () => {
  return authApiAxios.get(`/business/clients-assignate`);
};

//---------- Artisan api ------------
export const getArtisan = (withSiteItems = false) => {
  return authApiAxios.get('/business/artisans', {
    params: { with_site_items: withSiteItems ? 'true' : 'undefined' },
  });
};

export const getOneArtisan = (id) => {
  return authApiAxios.get(`/business/artisan/${id}`);
};

export const createArtisan = (data) => {
  return authApiAxios.post(`/business/artisan`, data);
};

export const editArtisanApi = (id, data) => {
  return authApiAxios.patch(`/business/artisan/${id}`, data);
};

export const deleteArtisan = (id) => {
  return authApiAxios.delete(`/business/artisan/${id}`);
};

export const getArtisanFile = (id) => {
  return authApiAxios.get(`/business/artisan/file/${id}`);
};

export const getArtisanJob = (id) => {
  return authApiAxios.get(`/business/artisan/${id}/site-items`);
};

export const getArtisanAvailability = (id, starting_date, ending_date, site_item_id) =>
  authApiAxios.get(`/business/artisan/${id}/availability`, {
    params: { starting_date, ending_date, site_item_id },
  });


  //---------- Dashboard global API -------------
  export const showDashboard = () => {
    return authApiAxios.get(`/business/site/dashboard`);
  };

//---------- Business api -------------
export const getBusinesses = () => {
  return authApiAxios.get('/business/businesses');
};

export const getOneBusiness = (id) => {
  return authApiAxios.get(`/business/business/${id}`);
};

export const createBusiness = (data) => {
  return authApiAxios.post(`/business/business`, data);
};

export const editBusinessApi = (id, data) => {
  return authApiAxios.patch(`/business/business/${id}`, data);
};

export const deleteBusiness = (id) => {
  return authApiAxios.delete(`/business/business/${id}`);
};

//---------- Role -------------
export const getRoles = () => {
  return authApiAxios.get('/business/roles');
};

//------- Craft ------------
export const getCrafts = () => {
  return authApiAxios.get('/business/crafts');
};

//------------ Configuration ----------------
export const getConfiguration = () => {
  return authApiAxios.get('/business/configuration');
};

export const storeConfiguration = (data) => {
  return authApiAxios.post('/business/configuration', data);
};
//----------- Article Category ------------
export const getArticleCategory = () => {
  return authApiAxios.get('/business/article-categories');
};

export const storeArticleCategory = (data) => {
  return authApiAxios.post('/business/article-category', data);
};

//----------- Article sub category ----------
export const getArticleSubCategory = (articleCategory) => {
  return authApiAxios.get(`/business/article-sub-categories/${articleCategory}`);
};

export const storeArticleSubCategory = (data) => {
  return authApiAxios.post('/business/article-sub-category', data);
};

//----------- Supplier ----------
export const getSupplier = () => {
  return authApiAxios.get('/business/suppliers');
};

export const storeSupplier = (data) => {
  return authApiAxios.post('/business/supplier', data);
};

export const editSupplierApi = (supplierId, data) => {
  return authApiAxios.patch(`/business/supplier/${supplierId}`, data);
};

export const getOneSupplier = (supplierId) => {
  return authApiAxios.get(`/business/supplier/${supplierId}`);
};

export const deleteSupplier = (supplierId) => {
  return authApiAxios.delete(`/business/supplier/${supplierId}`);
};

//----------- Unit -------------
export const getUnits = () => {
  return authApiAxios.get('/business/units');
};

export const storeUnit = (data) => {
  return authApiAxios.post('/business/unit', data);
};

//---------- Articles -----------
export const getArticles = () => {
  return authApiAxios.get('/business/articles');
};

export const getOneArticle = (id) => {
  return authApiAxios.get(`/business/article/${id}`);
};

export const storeArticle = (data) => {
  return authApiAxios.post('/business/article', data);
};

export const editArticleApi = (id, data) => {
  return authApiAxios.patch(`/business/article/${id}`, data);
};

export const deleteArticle = (id) => {
  return authApiAxios.delete(`/business/article/${id}`);
};

//----------------- Sites ---------------

export const getAllSites = (type) => {
  return authApiAxios.get(`/business/sites?type=${type}`);
};

export const getAllSitesNoType = () => {
  return authApiAxios.get(`/business/sites`);
};

export const showSite = (siteId) => {
  return authApiAxios.get(`/business/site/${siteId}`);
};

export const showSiteDashboard = (siteId) => {
  return authApiAxios.get(`/business/site/${siteId}/dashboard`);
};

export const storeSite = (data) => {
  return authApiAxios.post('/business/site', data);
};

export const editSite = (siteId, data) => {
  return authApiAxios.patch(`/business/site/${siteId}`, data);
};

export const deleteSite = (siteId) => {
  return authApiAxios.delete(`/business/site/${siteId}`);
};

export const getSitePlanPdf = (siteId) => {
  return authApiAxios.get(`/business/site/${siteId}/plan`);
};

export const uploadSitePlanPdf = (siteId, data) => {
  return authApiAxios.post(`/business/site/${siteId}/upload-plan`, data);
};

export const signContractAndCreateInvoice = (siteId, quoteId) => {
  return authApiAxios.get(`/business/site/${siteId}/quote/${quoteId}/create-first-invoice`);
};

export const signInvoiceManually = (siteId, quoteId, doc) =>
  authApiAxios.post(`/business/site/${siteId}/quote/${quoteId}/manual-sign`, {
    doc,
  });

export const getAllQuotesWithCategory = (siteId) => {
  return authApiAxios.get(`/business/site/${siteId}/quotes-detail`);
};

export const editTextEditor = (siteId, data) => {
  return authApiAxios.post(`/business/site/${siteId}/text-editor`, data);
};

//---------------- Quote ----------------------

export const getAllQuoteAllSites = (subSection, startingDate, endingDate) => {
  return authApiAxios.get(`/business/quotes?status=${subSection}&${
    startingDate ? `starting_date=${startingDate}` : ''
  }${startingDate && endingDate ? '&' : ''}
    ${endingDate ? `ending_date=${endingDate}` : ''}`);
};

export const quoteChangeStatus = (quoteId, data) => {
  return authApiAxios.post(`/business/quote/${quoteId}/change-status`, data);
};

export const getAllQuote = (siteId) => {
  return authApiAxios.get(`/business/site/${siteId}/quotes`);
};

export const getOneQuote = (siteId, quoteId) => {
  return authApiAxios.get(`/business/site/${siteId}/quote/${quoteId}`);
};

/**
 *
 * @param {number} quoteId - id of the quote
 * @param {boolean} noPrice - if it is no price
 * @param {boolean} estimate - if it is estimative
 * @returns
 */
export const getPdfQuote = (quoteId, noPrice = false, estimate = false, avp = false) => {
  return authApiAxios.get(`/business/quote/${quoteId}/pdf`, {
    params: {
      artisan_view: noPrice.toString(),
      estimate: estimate.toString(),
      avp: avp.toString(),
    },
  });
};

export const storeQuote = (siteId, data) => {
  return authApiAxios.post(`/business/site/${siteId}/quote`, data);
};

export const editQuote = (siteId, quoteId, data) => {
  return authApiAxios.patch(`/business/site/${siteId}/quote/${quoteId}`, data);
};

export const deleteQuote = (siteId, quoteId) => {
  return authApiAxios.delete(`/business/site/${siteId}/quote/${quoteId}`);
};

export const getAllArtisanItem = () => {
  return authApiAxios.get(`/business/quote/all-items`);
};

//---------- Site Category -----------------
export const deleteSiteCategory = (siteCategoryId) => {
  return authApiAxios.delete(`/business/site-category/${siteCategoryId}`);
};

export const changePercentageDoneCategory = (siteCategoryId, data) => {
  return authApiAxios.post(`/business/site-category/${siteCategoryId}/percentage-done`, data);
};

//---------- Message ----------

export const storeMessage = (data) => {
  return authApiAxios.post('/business/message', data);
};

//-------------------- Calendar ----------------

export const getOneQuoteInPlanning = (siteId, quoteId) => {
  return authApiAxios.get(`/business/calendar/site/${siteId}/quote/${quoteId}`);
};

export const changeDatePlanning = (siteId, data) => {
  return authApiAxios.post(`/business/calendar/site-item/${siteId}/add-dates`, data);
};

export const changeColorCategory = (categoryId, data) => {
  return authApiAxios.post(`/business/calendar/site-category/${categoryId}/change-color`, data);
};

//------------------ Site Item -----------------

export const changeIsUrgentStatus = (itemId) => {
  return authApiAxios.post(`/business/site-item/${itemId}/urgent`);
};

export const updateSiteItemNotes = (itemId, notes) =>
  authApiAxios.put(`/business/site-item/${itemId}/notes`, { notes });

export const changePercentageDone = (itemId, data) => {
  return authApiAxios.post(`/business/site-item/${itemId}/percentage-done`, data);
};

export const deleteSiteItem = (itemId) => {
  return authApiAxios.delete(`/business/site-item/${itemId}`);
};

//----------- Incident -------------

export const addIncidentToItem = (itemId, data) => {
  return authApiAxios.post(`/business/incident/site-item/${itemId}`, data);
};

export const showIncidentsOfItems = (itemId) => {
  return authApiAxios.get(`/business/incidents/site-item/${itemId}`);
};

export const deleteIncident = (incidentId) => {
  return authApiAxios.delete(`/business/incident/${incidentId}`);
};

export const showPdfIncident = (siteId) => {
  return authApiAxios.get(`/business/incident/site/${siteId}/pdf`);
};

//---------- Invoices --------------
export const getAllInvoicesOfSite = (siteId, startingDate, endingDate) => {
  return authApiAxios.get(
    `/business/invoices/site/${siteId}?${startingDate ? `starting_date=${startingDate}` : ''}${
      startingDate && endingDate ? '&' : ''
    }
    ${endingDate ? `ending_date=${endingDate}` : ''}`
  );
};

export const getAllInvoices = (subSection, startingDate, endingDate) => {
  return authApiAxios.get(`/business/invoices?status=${subSection}&${
    startingDate ? `starting_date=${startingDate}` : ''
  }${startingDate && endingDate ? '&' : ''}
    ${endingDate ? `ending_date=${endingDate}` : ''}`);
};

export const changeInvoiceStatus = (invoiceId, data) => {
  return authApiAxios.post(`/business/invoice/${invoiceId}/change-status`, data);
};

export const getOneInvoice = (invoiceId) => {
  return authApiAxios.get(`/business/invoice/${invoiceId}`);
};

export const getOneInvoicePdf = (invoiceId) => {
  return authApiAxios.get(`/business/invoice/${invoiceId}/pdf`);
};

export const editInvoiceBeforeSend = (invoiceId, data) => {
  return authApiAxios.patch(`/business/invoice/${invoiceId}`, data);
};

export const sendInvoice = (invoiceId) => authApiAxios.post(`/business/invoice/${invoiceId}/send`);

//--------------- Artisan order ---------------------------

export const artisanOrderPdf = (quoteId, artisanId) => {
  return authApiAxios.get(`/business/quote/${quoteId}/artisan/${artisanId}/pdf`);
};

export const getArtisansOfThisSite = (siteId) => {
  return authApiAxios.get(`/business/artisans/site/${siteId}`);
};

export const changeStatusArtisanOrder = (artisanOrderId, data) => {
  return authApiAxios.post(`/business/artisan-order/${artisanOrderId}/change-status`, data);
};

export const sendArtisanContractToClient = (artisanOrderId) =>
  authApiAxios.post(`/business/artisan-order/${artisanOrderId}/send`);

//--------------- Supplier order -----------------------

export const supplierOrderPdf = (quoteId, supplierId) => {
  return authApiAxios.get(`/business/quote/${quoteId}/supplier/${supplierId}/pdf`);
};

export const getSupplierOfThisSite = (siteId) => {
  return authApiAxios.get(`/business/suppliers/site/${siteId}`);
};

export const changeStatusSupplierOrder = (supplierOrderId, data) => {
  return authApiAxios.post(`/business/supplier-order/${supplierOrderId}/change-status`, data);
};

export const sendSupplierOrderToClient = (quoteId, supplierId) =>
  authApiAxios.post(`/business/quote/${quoteId}/supplier/${supplierId}/send`);

//---------- Contracts Api -----------

export const contracts = {
  index: (siteId) => authApiAxios.get(`/business/sites/${siteId}/contracts`),
  store: (siteId, payload) => authApiAxios.post(`/business/sites/${siteId}/contracts`, payload),
  view: (id) => authApiAxios.get(`/business/contracts/${id}`),
  update: (id, payload) => authApiAxios.put(`/business/contracts/${id}`, payload),
  send: (id) => authApiAxios.put(`/business/contracts/${id}`, {}, { params: { action: 'send' } }),
  sign: async (id, file) =>
    authApiAxios.put(`/business/contracts/${id}`, { file: await toBase64(file) }, { params: { action: 'sign' } }),
  destroy: (id) => authApiAxios.delete(`/business/contracts/${id}`),
};

//---------- Template api ------------
export const getTemplates = () => {
  return authApiAxios.get('/business/templates');
};

export const getOneTemplate = (id) => {
  return authApiAxios.get(`/business/template/${id}`);
};

export const getTemplateCategories = (id) => {
  return authApiAxios.get(`/business/template/categories/${id}`);
};

export const getTemplateCategoriesItems = (id, category) => {
  return authApiAxios.get(`/business/template/categories-items/${id}?category=${category}`);
};

export const createTemplate = (data) => {
  return authApiAxios.post(`/business/template`, data);
};

export const editTemplate = (id, data) => {
  return authApiAxios.patch(`/business/template/${id}`, data);
};

export const deleteTemplate = (id) => {
  return authApiAxios.delete(`/business/template/${id}`);
};

export const getSitePlanningDocuments = (siteId) => authApiAxios.get(`/business/site/${siteId}/planning-documents`);

export const postSitePlanningDocument = (siteId, payload) =>
  authApiAxios.post(`/business/site/${siteId}/planning-documents`, payload);

export const deleteSitePlanningDocument = (siteId, docId) =>
  authApiAxios.delete(`/business/site/${siteId}/planning-documents/${docId}`);

export const sendSitePlanningDocument = (siteId, docId, payload) =>
  authApiAxios.post(`/business/site/${siteId}/planning-documents/${docId}/send`, payload);

export const postArtisanOrderQuote = (orderId, payload) =>
  authApiAxios.post(`/business/artisan-order/${orderId}/artisan-quote`, payload);
