import React, { useEffect, useRef, useState } from 'react';
import { Grid, Typography, Modal, InputAdornment, Switch, Box, FormControlLabel, Divider } from '@material-ui/core';
import text from '../../../utils/text';
import { AddPicture, Articles, Cross } from '../../../utils/icons';
import classes from './ArticleList.module.css';
import TextFieldCustom from '../../../Components/FormComponents/TextFieldCustom';
import { useForm, Controller } from 'react-hook-form';
import CommonButton from '../../../Components/FormComponents/CommonButton';
import TextBoxCustom from '../../../Components/FormComponents/TextBoxCustom';
import CommonSelectAddOption from '../../../Components/FormComponents/CommonSelectAddOption';
import {
  getArticleSubCategory,
  storeArticleCategory,
  storeArticleSubCategory,
  storeSupplier,
  storeUnit,
} from '../../../Axios/Call/AuthApi';
import CommonSelect from '../../../Components/FormComponents/CommonSelect';

const ModalAddArticles = ({
                            setOpenModalAdd,
                            openModalAdd,
                            onCreateArticle,
                            loadingApiAdd,
                            editArticle,
                            setEditArticle,
                            suppliers,
                            categories,
                            units,
                            setUnits,
                            setSuppliers,
                            setCategories,
                            setErrorApi,
                            setIsNewArticle,
                            isNewArticle
                          }) => {
  const inputFileRef = useRef(null);
  const [subCategories, setSubCategories] = useState(false);
  const { register, handleSubmit, errors, reset, control, watch, setValue } = useForm({
    mode: 'onBlur',
  }); // initialise the hook

  const createNewArtisan = (data) => {
      console.log(data)
    onCreateArticle({
      ...data,
      margin_price: watch('margin_price'),
      margin_percentage: watch('margin_percentage'),
      coeficent: watch('coeficent'),
    });
  };



  useEffect(() => {
    if (Object.keys(editArticle).length && !isNewArticle) {
      reset({ ...editArticle, category_id: editArticle.article_category_id });
    }
  }, [editArticle]);

  useEffect(() => {
    if (watch('category_id')) {
      getArticleSubCategory(watch('category_id'))
          .then((response) => {
            setSubCategories(response.data);
          })
          .catch((error) => {
            setErrorApi(true);
          });
    }


  }, [watch('category_id')]);

  useEffect(() => {
    if (Object.keys(editArticle).length && !isNewArticle) {
      reset({ ...editArticle, category_id: editArticle.article_category_id });
    } else {
      reset({})
    }
  }, [isNewArticle]);

  function fileToBase64(file) {
    return new Promise((res, rej) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onloadend = () => {
        res(fileReader.result);
      };
      fileReader.onerror = () => rej();
    });
  }


  const addSupplierHandler = (value) => {



    storeSupplier({ name: value })
        .then((response) => {

          setSuppliers(suppliers.concat(response.data));
          setValue('supplier_id', response.data.id);

        })

        .catch((error) => {
          setErrorApi(true);
        });


  };

  const addCategoryHandler = (value) => {

    storeArticleCategory({ name: value })
        .then((response) => {
          setCategories(categories.concat(response.data));
          setValue('category_id', response.data.id);
        })
        .catch((error) => {
          setErrorApi(true);
        });
  };

  const addSubCategoryHandler = (value) => {
    storeArticleSubCategory({
      article_category_id: watch('category_id'),
      name: value,
    })
        .then((response) => {
          setSubCategories(subCategories.concat(response.data));
          setValue('article_sub_category_id', response.data.id);
        })
        .catch((error) => {
          setErrorApi(true);
          console.log(error)
        });
  };

  const addUnitHandler = (value) => {
    storeUnit({ name: value })
        .then((response) => {
          setUnits(units.concat(response.data));
          setValue('unit_id', response.data.id);
        })
        .catch((error) => {
          setErrorApi(true);
        });
  };

  const calculateMargeAndCoeff = () => {
    if (watch('buy_price') && watch('selling_price')) {
      setValue('margin_price', (watch('selling_price') - watch('buy_price')).toFixed(2));
      setValue(
          'margin_percentage',
          (((watch('selling_price') - watch('buy_price')) / watch('selling_price')) * 100).toFixed(2)
      );
      setValue('coeficent', (watch('selling_price') / watch('buy_price')).toFixed(2));
    }
  };

  const changeSellingPriceBasedOnMerge = () => {
    if (watch('buy_price') && watch('margin_percentage')) {
      setValue('selling_price', 0);
      const calculateSellingPrice =
          parseFloat(watch('buy_price')) * (1 / (1 - parseFloat(watch('margin_percentage') / 100)));

      setValue('selling_price', calculateSellingPrice.toFixed(2));
      setValue('margin_price', (calculateSellingPrice - parseFloat(watch('buy_price'))).toFixed(2));
    }
  };

  return (
      <Modal open={openModalAdd} size={'xl'}>
        <Grid container className={classes.modalWrapperWeb}>
          <div
              style={{
                position: 'absolute',
                top: 10,
                right: 10,
                cursor: 'pointer',
              }}
              onClick={() => {
                setOpenModalAdd(false);
                setEditArticle({});
              }}
          >
            <Cross />
          </div>
          <Grid item align="left" style={{ paddingTop: 0 }}>
            <Articles size={38} fill="#345369" />
          </Grid>
          <Grid item align="left" style={{ paddingLeft: 10 }}>
            <Typography variant="h2">{text.ArticleList.modalTitle}</Typography>
          </Grid>
          <Grid item xs={12} align="left" style={{ paddingTop: 25 }}>
            <form onSubmit={handleSubmit(createNewArtisan)} noValidate>
              <Grid container spacing={3}>

                <Grid item xs={12} md={8}>
                  <Typography variant="h3">{text.ArticleList.sectionOne}</Typography>
                  <Divider style={{marginTop:5,marginBottom:25 }}/>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4} style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}>
                      <Controller
                          render={(field) => (
                              <CommonSelect
                                  name="supplier_id"
                                  options={suppliers}
                                  field={field}
                                  labelCustom={'Fournisseurss'}
                                  addName={'add_supplier'}
                                  onAddHandler={addSupplierHandler}
                              />
                          )}
                          control={control}
                          defaultValue={''}
                          rules={{ required: text.General.required }}
                          name="supplier_id"
                      />
                    </Grid>
                    <Grid item xs={12} md={3} style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}>
                      <TextFieldCustom
                          variant="outlined"
                          labelCustom={'Code article'}
                          type="input"
                          ref={register({})}
                          defaultValue=""
                          name={'code'}
                          error={errors.code}
                      />
                    </Grid>

                    <Grid item xs={3}  style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}>
                      <Typography style={{ fontWeight: 'bolder' }}>Non facturable</Typography>

                      <Controller
                          render={(field) => <Switch checked={field.value} onClick={() => field.onChange(!field.value)} />}
                          name="is_not_invoiced"
                          defaultValue={false}
                          control={control}
                      />
                    </Grid>

                    <Grid item xs={12} md={2} style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}>
                      <Controller
                          render={(field) => (
                              <CommonSelectAddOption
                                  name="unit_id"
                                  options={units}
                                  field={field}
                                  labelCustom={'Unité'}
                                  addName={'add_unit'}
                                  onAddHandler={addUnitHandler}
                              />
                          )}
                          control={control}
                          defaultValue={''}
                          rules={{ required: text.General.required }}
                          name="unit_id"
                      />
                    </Grid>





                    <Grid item xs={12}>
                      <TextBoxCustom
                          variant="outlined"
                          label={text.ArticleList.name}
                          type="input"
                          ref={register({ required: text.General.required })}
                          defaultValue=""
                          name={'description'}
                          error={errors.description}
                      />
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 10 }}>
                      <Typography variant="h3">{text.ArticleList.sectionTwo}</Typography>
                      <Divider style={{marginTop:5,marginBottom:5 }}/>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}>
                      <Controller
                          render={(field) => (
                              <CommonSelectAddOption
                                  name="category_id"
                                  options={categories}
                                  field={field}
                                  labelCustom={'Categories'}
                                  addName={'add_category'}
                                  onAddHandler={addCategoryHandler}
                              />
                          )}
                          control={control}
                          defaultValue={''}
                          rules={{ required: text.General.required }}
                          name="category_id"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}>
                      {watch('category_id') && subCategories && (
                          <Controller
                              render={(field) => (
                                  <CommonSelectAddOption
                                      name="article_sub_category_id"
                                      options={subCategories}
                                      field={field}
                                      labelCustom={'Sous-categorie'}
                                      addName={'add_sub_category'}
                                      onAddHandler={addSubCategoryHandler}
                                  />
                              )}
                              control={control}
                              defaultValue={''}
                              rules={{ required: text.General.required }}
                              name="article_sub_category_id"
                          />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4} align="center">
                  <div
                      style={{
                        width: 200,
                        height: 200,
                        border: '1px solid #345369',
                        borderRadius: 10,
                      }}
                  >
                    <div
                        style={{
                          marginTop: watch('picture') ? '0px' : '70px',
                          cursor: 'pointer',
                          width: '100%',
                        }}
                        onClick={() => inputFileRef?.current?.click()}
                    >
                      {watch('picture') ? (
                          <img
                              src={watch('picture')}
                              alt="logo"
                              style={{
                                height: '198px',
                                width: '198px',
                                borderRadius: 10,
                              }}
                          />
                      ) : (
                          <>
                            <AddPicture fill="#345369" />
                            <Typography>Ajouter une image</Typography>
                          </>
                      )}
                      <Controller
                          render={(field) => (
                              <input
                                  type="file"
                                  style={{ display: 'none' }}
                                  ref={inputFileRef}
                                  accept="image/png, image/jpeg, application/pdf"
                                  onChange={async (e) => {
                                    const b64 = await fileToBase64(e.target.files[0]);
                                    field.onChange(b64);
                                  }}
                              />
                          )}
                          defaultValue={null}
                          name="picture"
                          control={control}
                      />
                    </div>
                  </div>

                  <Box>
                    <FormControlLabel
                        control={
                          <Controller
                              render={(field) => (
                                  <Switch checked={field.value} onClick={() => field.onChange(!field.value)} />
                              )}
                              name="render_image"
                              defaultValue={true}
                              control={control}
                          />
                        }
                        label="Afficher la photo dans le devis"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} style={{ marginTop: 1, marginBottom: 10 }}>
                  <Grid container>
                    <Grid item xs={12} md={12} style={{ marginTop: 1, marginBottom: 10 }}>
                      <Typography variant="h3">Prix</Typography>
                      <Divider style={{marginTop:5,marginBottom:5 }}/>
                    </Grid>
                    <Grid style={{ marginTop: 8, paddingTop: 1, paddingRight: 10 }}>
                      <TextFieldCustom
                          variant="outlined"
                          label={'Prix d’achat (HT)'}
                          type="number"
                          ref={register({ required: text.General.required })}
                          defaultValue=""
                          name={'buy_price'}
                          error={errors.buy_price}
                          onBlur={() => calculateMargeAndCoeff()}
                          InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                  <Typography>€</Typography>
                                </InputAdornment>
                            ),
                          }}
                      />
                    </Grid>

                    <Grid style={{ marginTop: 8, paddingTop: 1, paddingRight: 10 }}>
                      <TextFieldCustom
                          variant="outlined"
                          label={'Prix de vente (HT)'}
                          type="number"
                          ref={register({ required: text.General.required })}
                          defaultValue=""
                          name={'selling_price'}
                          error={errors.selling_price}
                          onBlur={() => calculateMargeAndCoeff()}
                          InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                  <Typography>€</Typography>
                                </InputAdornment>
                            ),
                          }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginTop: 20, marginLeft: 0 }}>
                    <Grid item xs={12} md={12} style={{ marginTop: 1, marginBottom: 10 }}>
                      <Typography variant="h3">Coeff & Marge</Typography>
                      <Divider style={{marginTop:5,marginBottom:5 }}/>
                    </Grid>

                    <Grid style={{ marginTop: 1, paddingTop: 1, paddingRight: 10 }}>
                      <TextFieldCustom
                          variant="outlined"
                          type="number"
                          label={'Taux de marge'}
                          ref={register({ required: text.General.required })}
                          defaultValue=""
                          readOnly={!watch('selling_price')}
                          name={'margin_percentage'}
                          error={errors.margin_percentage}
                          onBlur={() => changeSellingPriceBasedOnMerge()}
                          InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                  <Typography>%</Typography>
                                </InputAdornment>
                            ),
                          }}
                      />
                    </Grid>

                    <Grid style={{ marginTop: 1, paddingTop: 1, paddingRight: 10 }}>
                      <TextFieldCustom
                          variant="outlined"
                          type="number"
                          ref={register({ required: text.General.required })}
                          label={'Coeff'}
                          defaultValue=""
                          readOnly={true}
                          name={'coeficent'}
                          error={errors.coeficent}
                          InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                  <Typography></Typography>
                                </InputAdornment>
                            ),
                          }}
                      />
                    </Grid>

                    <Grid style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}>
                      <TextFieldCustom
                          variant="outlined"
                          type="number"
                          label={'Marge'}
                          ref={register({ required: text.General.required })}
                          defaultValue=""
                          readOnly={true}
                          name={'margin_price'}
                          error={errors.margin_price}
                          InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                  <Typography>€</Typography>
                                </InputAdornment>
                            ),
                          }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h3">Note supplémentaire</Typography>
                  <Divider style={{marginTop:5,marginBottom:15 }}/>
                  <TextBoxCustom
                      variant="outlined"
                      label={'Note'}
                      type="input"
                      ref={register({})}
                      defaultValue=""
                      name={'note'}
                      error={errors.note}
                  />
                </Grid>
                <Grid item xs={12} md={12} style={{ marginTop: 10, marginBottom: 10 }}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={4} align="center">
                      <CommonButton
                          loading={loadingApiAdd}
                          text={editArticle ? text.ArticleList.ctaEditModal : text.ArticleList.ctaCreateModal}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Modal>
  );
};

export default ModalAddArticles;
