import { ResponsiveContainer, Bar, BarChart, Tooltip, Legend, YAxis, XAxis } from 'recharts';
import { Grid, Box, makeStyles, Typography } from '@material-ui/core';

const CustomBarChart = ({title, data}) => {

  return (
    <Grid container style={{ margin: 20 }} justifyContent='center'>
    <Typography variant='h3' style={{ textAlign: 'center', margin: 30 }}>{title}</Typography>
    <ResponsiveContainer height={350}>

      <BarChart margin={{ top: 10, right: 30, left: 0, bottom: 0 }} data={data}>
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend wrapperStyle={{ bottom: -10 }} />
        <Bar dataKey="CA" type="number" fill="#82ca9d" unit="€"/>
        <Bar dataKey="CAprev" type="number" stackId="a" fill="#345369" unit="€"/>
      </BarChart>
    </ResponsiveContainer>
    </Grid>
  )
}

export default CustomBarChart