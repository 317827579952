import { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import AlertGeneral from '../../Commons/AlertGeneral/AlertGeneral';
import text from '../../../utils/text';
import LoadingButton from '../../Commons/LoadingButton/LoadingButton';
import { showPdfIncident } from '../../../Axios/Call/AuthApi';
import { useParams } from 'react-router-dom';

const ModalSendIncident = ({ openModalAdd, setOpenModalAdd }) => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [pdf, setPdf] = useState(null);
  const { siteId } = useParams();

  useEffect(() => {
    if (openModalAdd) {
      setLoadingApi(true);
      showPdfIncident(siteId)
        .then((response) => {
          setLoadingApi(false);
          setPdf(response.data);
        })
        .catch((error) => {
          setErrorApi(true);
          setLoadingApi(false);
        });
    }
  }, [openModalAdd, siteId]);

  const handleClose = () => {
    setOpenModalAdd(false);
  };

  return (
    <>
      {errorApi && <AlertGeneral color="danger" setTrigger={setErrorApi} description={text.General.alertError} />}
      <Dialog onClose={handleClose} open={openModalAdd} maxWidth="md" fullWidth>
        <DialogTitle></DialogTitle>
        <DialogContent style={{ minHeight: '80vh' }}>
          {loadingApi ? (
            <LoadingButton />
          ) : (
            <iframe
              src={pdf}
              style={{
                width: '100%',
                height: '80vh',
              }}
              type="application/pdf"
              name="myiframe"
              id="myiframe"
              title="myiframe"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalSendIncident;
