import React, { useState, useEffect, useRef } from "react";
import SideMenu from "../../../Components/Commons/SideMenu";
import {
  Container,
  Grid,
  Typography,
  Switch,
  Avatar,
  withStyles,
} from "@material-ui/core";
import { Email, Notification2, ProfileCircle } from "../../../utils/icons";
import text from "../../../utils/text";
import { useForm } from "react-hook-form";
import TextFieldCustom from "../../../Components/FormComponents/TextFieldCustom";
import {
  errorPasswordNotSame,
  formatErrorLetters,
  formatErrorPhoneNumber,
} from "../../../utils/formValidation";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";
import {
  changePasswordApi,
  changeProfileApi,
  editNotificationSettings,
  getNotificationSettings,
  getPicture,
} from "../../../Axios/Call/AuthApi";
import * as actionCreator from "../../../store/action/index";
import CommonButton from "../../../Components/FormComponents/CommonButton";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";

const Profile = () => {
  const [section, setSection] = useState("profile");
  const [file, setFile] = useState({});
  const [currentPicture, setCurrentPicture] = useState("");
  const [errorApi, setErrorApi] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [loadingApiPassword, setLoadingApiPassword] = useState(false);
  const [successApiProfile, setSuccessApiProfile] = useState(false);
  const [successApiPassword, setSuccessApiPassword] = useState(false);
  const userData = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();
  const inputFileRef = useRef(null);
  const { register, handleSubmit, errors, setValue, reset } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    errors: errors2,
    watch: watch2,
  } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  const StyledSwitch = withStyles((theme) => ({
    switchTrack: {
      backgroundColor: "#345369",
    },
    switchBase: {
      color: "#EEE",
      "&.Mui-disabled": {
        color: "#345369",
      },
      "&.Mui-checked": {
        color: "#345369",
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#345369 !important",
        

      },
    },
    switchPrimary: {
      "&.Mui-checked": {

        color: "#345369",
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#4CAF50",
      },
    },
  }))(Switch);

  const [settings, setSettings] = useState([
    {
      name: "COMMERCIAL",
      items: [
        {
          name: "Nouveau prospect",
          email: false,
          notification: true,
          id: "prospect_created",
        },
        {
          name: "Prospect à relancer",
          email: false,
          notification: true,
          id: "command_created",
        },
      ],
    },
    {
      name: "OPERATIONNEL",
      items: [
        {
          name: "Commande livrée",
          email: false,
          notification: true,
          id: "site_created",
        },
        {
          name: "Règlement d’une facture client",
          email: false,
          notification: true,
          id: "invoice_created",
        },
        {
          name: "Document arrivé à expiration",
          email: false,
          notification: true,
          id: "artisan_file_expired",
        },
      ],
    },
  ]);

  const changeProfile = (data) => {
    setLoadingApi(true);
    changeProfileApi({ ...data, file: file })
      .then((response) => {
        setLoadingApi(false);
        dispatch(actionCreator.populateUserData());
        setSuccessApiProfile(true);
      })
      .catch((error) => {
        setLoadingApi(false);
        setErrorApi(true);
      });
  };

  useEffect(() => {
    getPicture()
      .then((response) => {
        setCurrentPicture(response.data);
      })
      .catch((error) => {
        setErrorApi(true);
      });
  }, []);

  const changePasswordHandler = (data) => {
    setLoadingApiPassword(true);
    changePasswordApi(data)
      .then((response) => {
        setLoadingApiPassword(false);
        setSuccessApiPassword(true);
      })
      .catch((error) => {
        setErrorApi(true);
        setLoadingApiPassword(false);
      });
  };

  useEffect(() => {
    if (Object.keys(userData).length) {
      reset({ ...userData });
      getNotificationSettings()
        .then((response) => {
          const items = [];
          Object.keys(response.data).forEach((key) => {
            settings.forEach((setting) => {
              setting.items.forEach((item) => {
                if (item.id === key) {
                  items.push({
                    id: key,
                    email: response.data[key].includes("email"),
                    notification: response.data[key].includes("database"),
                  });
                }
              });
            });
          });

          console.log(items);

          const newSettings = settings.map((setting) => {
            return {
              ...setting,
              items: setting.items.map((item) => {
                return {
                  ...item,
                  ...items.find((aItem) => aItem.id === item.id),
                };
              }),
            };
          });

          setSettings(newSettings);
        })
        .catch((error) => {});
    }
  }, [userData]);

  const changeSwitchNotification = (type, indexItem, nameSetting) => {
    const newSettings = settings.map((setting) =>
      setting.name === nameSetting
        ? {
            ...setting,
            items: setting.items.map((item, index) =>
              index === indexItem
                ? {
                    ...item,
                    [type]: !item[type],
                  }
                : { ...item }
            ),
          }
        : { ...setting }
    );
    setSettings(newSettings);

    let settingsFormatted = {};
    newSettings.forEach((setting) => {
      setting.items.forEach((item) => {
        settingsFormatted = {
          ...settingsFormatted,
          [item.id]:
            item.email && item.notification
              ? ["email", "database"]
              : item.email
              ? ["email"]
              : item.notification
              ? ["database"]
              : [],
        };
      });
    });

    editNotificationSettings({ settings: settingsFormatted })
      .then((response) => {})
      .catch((error) => {
        setErrorApi(true);
      });
  };

  function fileToBase64(file) {
    return new Promise((res, rej) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onloadend = () => {
        res(fileReader.result);
      };
      fileReader.onerror = () => rej();
    });
  }

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertError}
        />
      )}
      {successApiPassword && (
        <AlertGeneral
          color="success"
          setTrigger={setSuccessApiPassword}
          description={"Password changed succesfully"}
        />
      )}
      {successApiProfile && (
        <AlertGeneral
          color="success"
          setTrigger={setSuccessApiProfile}
          description={"Profile changed sucessfully"}
        />
      )}

      <SideMenu>
        <Container style={{ maxWidth: "none", paddingTop:20 }}>
          <Grid container>
            <Grid item align="left" style={{ paddingTop: 5 }}>
              <ProfileCircle fill={"#345369"} size={30} />
            </Grid>
            <Grid
              item
              align="left"
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              <Typography
                variant="h5"
                style={{
                  color: section === "profile" ? "#345369" : "#AEB4BE",
                  borderBottom:
                    section === "profile" ? "2px solid #345369" : "none",
                  cursor: "pointer",
                  marginLeft: 20,
                }}
                onClick={() => setSection("profile")}
              >
                {text.Profile.myProfile}
              </Typography>
            </Grid>
            <Grid
              item
              align="left"
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              <Typography
                variant="h5"
                style={{
                  color: section === "information" ? "#345369" : "#AEB4BE",
                  borderBottom:
                    section === "information" ? "2px solid #345369" : "none",
                  cursor: "pointer",
                  marginLeft: 20,
                }}
                onClick={() => setSection("information")}
              >
                {text.Profile.myInformation}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={10}>
              {!Object.keys(userData).length ? (
                <LoadingButton />
              ) : (
                <>
                  {section === "profile" && (
                    <>
                      <form key={1} onSubmit={handleSubmit(changeProfile)}>
                        <Grid container>
                          <Grid
                            align="left"
                            item
                            xs={12}
                            style={{ marginTop: 50 }}
                          >
                            <Typography variant="h3"  style={{ marginBottom: 20 }}>
                              {text.Profile.personalInfo}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{ marginTop: 20 }}>
                            <div style={{ display: "flex" }}>
                              <Avatar
                                src={file.base64 ?? currentPicture ?? ""}
                                style={{
                                  width: 150,
                                  height: 150,
                                  cursor: "pointer",
                                  border: "1px solid #345369",
                                }}
                                onClick={() => inputFileRef?.current?.click()}
                              />
                              <div
                                style={{
                                  paddingLeft: 20,
                                }}
                                className="my-auto"
                              >
                                <Typography
                                  onClick={() => inputFileRef?.current?.click()}
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 16,
                                    cursor: "pointer",
                                  }}
                                  align="left"
                                >
                                  Modifier la photo de profil
                                </Typography>
                                <Typography
                                  style={{ color: "#AEB4BE", marginTop: 5 }}
                                >
                                  Maximum 1Mo. Formats acceptes: JPG, PNG
                                </Typography>
                              </div>
                            </div>
                            <input
                              type="file"
                              accept="image/png"
                              style={{ display: "none" }}
                              ref={inputFileRef}
                              onChange={async (e) => {
                                const name = e?.target?.files[0]?.name;
                                const base64 = await fileToBase64(
                                  e.target.files[0]
                                );
                                setFile({ base64: base64, name: name });
                              }}
                            />
                          </Grid>
                          <Grid
                            xs={12}
                            md={6}
                            style={{ marginTop: 30, paddingRight: 10 }}
                          >
                            <TextFieldCustom
                              label={"Prénom du contact"}
                              type="input"
                              ref={register({
                                required: text.General.required,
                                validate: {
                                  formatErrorLetters,
                                },
                              })}
                              defaultValue=""
                              name="name"
                              error={errors.name}
                            />
                          </Grid>
                          <Grid
                            xs={12}
                            md={6}
                            style={{ marginTop: 30, paddingLeft: 10 }}
                          >
                            <TextFieldCustom
                              label={"Nom du contact"}
                              type="input"
                              ref={register({
                                required: text.General.required,
                                validate: {
                                  formatErrorLetters,
                                },
                              })}
                              defaultValue=""
                              name="surname"
                              error={errors.surname}
                            />
                          </Grid>
                          <Grid
                            xs={12}
                            md={6}
                            style={{ marginTop: 30, paddingRight: 10 }}
                          >
                            <TextFieldCustom
                              label={"Téléphone portable"}
                              type="input"
                              ref={register({
                                required: text.General.required,
                                validate: {
                                  formatErrorPhoneNumber,
                                },
                              })}
                              defaultValue=""
                              name="mobile"
                              error={errors.mobile}
                            />
                          </Grid>
                          <Grid
                            xs={12}
                            md={6}
                            style={{ marginTop: 30, paddingLeft: 10 }}
                          >
                            <TextFieldCustom
                              label={"E-mail"}
                              type="email"
                              readOnly={true}
                              ref={register({
                                required: text.General.required,
                              })}
                              defaultValue=""
                              name="email"
                              error={errors.email}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ marginTop: 20 }}>
                            <CommonButton
                              text={"SAUVEGARDER MON PROFIL"}
                              loading={loadingApi}
                              style={{ width: 300 }}
                            />
                          </Grid>
                        </Grid>
                      </form>
                      <form
                        key={2}
                        onSubmit={handleSubmit2(changePasswordHandler)}
                        style={{ marginTop: 40 }}
                      >
                        <Grid container>
                          <Grid
                            align="left"
                            item
                            xs={12}
                            style={{ marginTop: 20, marginBottom: 20 }}
                          >
                            <Typography variant="h3">
                              Changer mon mot de passe
                            </Typography>
                          </Grid>


                          <Grid container>
                          <Grid item xs={12} md={4} style={{ paddingRight: 10,paddingTop:3 }}>
                            <TextFieldCustom
                              label={"Entrer votre nouveau mot de passe"}
                              type="password"
                              ref={register2({
                                required: text.General.required,
                                validate: {
                                  errorPasswordNotSame: (value) =>
                                    errorPasswordNotSame(
                                      value,
                                      watch2("confirm_password")
                                    ),
                                },
                              })}
                              defaultValue=""
                              name="password"
                              error={errors2.password}
                            />
                          </Grid>
                          <Grid item xs={12} md={4} style={{paddingTop:3}}>
                            <TextFieldCustom
                              label={"Confirmer votre nouveau mot de passe"}
                              type="password"
                              ref={register2({
                                required: text.General.required,
                                validate: {
                                  errorPasswordNotSame: (value) =>
                                    errorPasswordNotSame(
                                      value,
                                      watch2("password")
                                    ),
                                },
                              })}
                              defaultValue=""
                              name="confirm_password"
                              error={errors2.confirm_password}
                            />
                          </Grid>
                          <Grid
                            xs={4}
                            style={{ marginTop: 0, paddingBottom: 0 }}
                          >
                            <CommonButton
                              text={"CHANGER MON MOT DE PASSE"}
                              loading={loadingApiPassword}
                              style={{ width: 350 }}
                            />
                          </Grid>
                          </Grid>
                        </Grid>
                      </form>
                    </>
                  )}
                  {section === "information" && (
                    <Grid
                      item
                      xs={12}
                      style={{ marginTop: 30, paddingBottom: 20 }}
                    >
                      <Grid container justifyContent="center">
                        {settings.map((setting) => {
                          return (
                            <>
                              <Grid
                                item
                                xs={8}
                                align="left"
                                style={{ marginTop: 50 }}
                              >
                                <Typography variant='h6' style={{ fontWeight: 400 }}>
                                  {setting.name}
                                </Typography>
                              </Grid>
                              <Grid item xs={2} style={{ marginTop: 30 }}>
                                <Notification2 fill={"#AEB4BE"} />
                                <Typography style={{ color: "#AEB4BE" }}>
                                  Push
                                </Typography>
                              </Grid>
                              <Grid item xs={2} style={{ marginTop: 30 }}>
                                <Email fill={"#AEB4BE"} />
                                <Typography style={{ color: "#AEB4BE" }}>
                                  Email
                                </Typography>
                              </Grid>
                              <Grid
                                container
                                justifyContent="center"
                                style={{
                                  border: "1px solid #34536940",
                               
                                  padding: 10,
                                  borderRadius: 5,
                                  marginTop:10
                                }}
                              >
                                {setting.items.map((item, indexItem) => {
                                  return (
                                    <>
                                      <Grid
                                        item
                                        xs={8}
                                        align="left"
                                        style={{
                                          marginTop: "auto",
                                          marginBottom: "auto",
                                        }}
                                      >
                                        <Typography style={{fontWeight:400}}>{item.name}</Typography>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <StyledSwitch
                                          checked={item.notification}
                                          color={"blue"}
                                          onChange={() =>
                                            changeSwitchNotification(
                                              "notification",
                                              indexItem,
                                              setting.name
                                            )
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={2}>
                                        <StyledSwitch
                                          checked={item.email}
                                          color={"blue"}
                                          onChange={() =>
                                            changeSwitchNotification(
                                              "email",
                                              indexItem,
                                              setting.name
                                            )
                                          }
                                        />
                                      </Grid>
                                    </>
                                  );
                                })}
                              </Grid>
                            </>
                          );
                        })}
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </SideMenu>
    </>
  );
};

export default Profile;
