import { useEffect, useState, useMemo, useRef } from "react";
import {
  Container,
  Grid,
  Typography,
  TextField,
  makeStyles,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import text from "../../../utils/text";
import SideMenu from "../../../Components/Commons/SideMenu";
import { Artisan } from "../../../utils/icons";
import { useHistory } from "react-router-dom";
import { getArtisan, getArtisanJob } from "../../../Axios/Call/AuthApi";
import { scrollbarStyle } from "../../../utils/scrollbar";
import grey from "@material-ui/core/colors/grey";
import moment from "moment";
import * as R from "ramda";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    overflowX: "hidden",
    paddingTop: 30,
    paddingRight: 30,
    borderRadius: 15,
    paddingBottom: theme.spacing(2),
    marginBottom: 10,
    position: "relative",
    ...scrollbarStyle,
    "&:hover": {
      overflowX: "scroll",
    },
  },
  buttonsContainer: {
    position: "sticky",
    left: 0,
    right: 0,
    top: 0,
    marginBottom: theme.spacing(3),
  },
  buttonCalendar: {
    border: "1px solid #41546830",
    color: "#415468",
    backgroundColor: "#FFF",
    fontSize: "13px",

    margin: 1,
    "&:hover": {
      color: "#FFF",
      backgroundColor: "#415468",
      border: "1px solid #FFF",
      borderRadius: 5,
    },
  },
  table: {
    boxShadow: "rgba(100, 100, 111, 0.3) 0px 7px 29px 0px",
    tableLayout: "fixed",
    minWidth: "100%",
    borderCollapse: "collapse",
    borderRadius: 25,
    marginLeft: 5,
  },
  tableCell: {
    boxShadow: "rgba(30, 30, 30, 0.1) 0px 2px 3px 0px",
    width: "150px",
    color: "#999",
    textAlign: "left",
    fontSize:'12px',
    fontWeight:300,
    maxWidth: "180px",
    borderBottom: "1px solid",
    borderBottomColor: "#DDD",
    position: "relative",
    "&:first-child": {
      position: "sticky",
      left: 0,
      backgroundColor: "#EEE",
      zIndex: 10,
      width: "1%",

      "& p": {
        borderRightColor: grey[500],
        whiteSpace: "nowrap",
        padding: theme.spacing(1, 0.5),
      },
    },
  },
}));

const ArtisanPlanning = () => {
  const [artisans, setArtisans] = useState([]);
  const [artisan, setArtisan] = useState({});
  const [artisanJobs, setArtisanJobs] = useState([]);
  const [artisanBusyConfirmationDialog, setArtisanBusyConfirmationDialg] =
    useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const history = useHistory();
  const [calendarView, setCalendarView] = useState("days");

  const planningRef = useRef();

  const possibleViews = {
    days: "Aujourd'hui",
    weeks: "Cette semaine",
    months: "Ce mois-ci",
    allSite: "CHANTIER (jours)",
  };

  const classes = useStyles();

  useEffect(() => {
    getArtisan(true)
      .then((response) => {
        const artisans = response.data.filter((a) => a.site_items_count);
        setArtisans(artisans);
        if (artisans.length) {
          setArtisan(artisans[0]);
        }
      })
      .catch(() => {
        setErrorApi(true);
      });
  }, []);

  useEffect(() => {
    if (Object.keys(artisan).length) {
      getArtisanJob(artisan.id)
        .then((response) => {
          setArtisanJobs(response.data);
        })
        .catch((error) => {
          setErrorApi(true);
        });
    }
  }, [artisan]);

  const onChangeArtisanHandler = (newValue) => {
    setArtisan(newValue);
  };

  console.log("artisanJobs", artisanJobs);

  const earliestStartingDate = R.head(
    artisanJobs
      .filter((job) => Boolean(job.starting_date))
      .sort(
        (a, b) =>
          moment(a.starting_date).unix() - moment(b.starting_date).unix()
      )
  )?.starting_date;

  const latestEndingDate = R.head(
    artisanJobs
      .filter((job) => Boolean(job.ending_date))
      .sort(
        (a, b) => moment(b.ending_date).unix() - moment(a.ending_date).unix()
      )
  )?.ending_date;

  const calendarDays = useMemo(() => {
    if (calendarView === "days") {
      return [moment()];
    }

    if (calendarView === "weeks") {
      return R.map(
        (index) => moment().startOf("isoWeek").add(index, "days"),
        R.range(0, 7)
      );
    }

    if (calendarView === "months") {
      return R.map(
        (index) => moment().startOf("month").add(index, "days"),
        R.range(0, moment().endOf("month").date())
      );
    }

    if (calendarView === "allSite") {
      return R.map(
        (index) => moment(earliestStartingDate).add(index, "days"),
        R.range(
          0,
          moment(latestEndingDate).diff(moment(earliestStartingDate), "days")
        )
      );
    }
  }, [calendarView, earliestStartingDate, latestEndingDate]);

  return (
    <>
      <Dialog
        open={Boolean(artisanBusyConfirmationDialog)}
        onClose={() => setArtisanBusyConfirmationDialg(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{text.artisanBusyDialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {text.artisanBusyDialog.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={artisanBusyConfirmationDialog?.onCancel}>
            {text.artisanBusyDialog.cancel}
          </Button>
          <Button onClick={artisanBusyConfirmationDialog?.onConfirm}>
            {text.artisanBusyDialog.confirm}
          </Button>
        </DialogActions>
      </Dialog>

      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertError}
        />
      )}
      <SideMenu>
        <Container style={{ maxWidth: "none", paddingTop: 20 }}>
          <Grid container>
            <Grid item align="left" style={{ paddingTop: 5 }}>
              <Artisan size={38} fill="#345369" />
            </Grid>
            <Grid item align="left" style={{ paddingLeft: 10 }}>
              <Typography
                variant="h2"
                style={{ cursor: "pointer", color: "#AEB4BE" }}
                onClick={() => history.push("/artisans")}
              >
                {text.ArtisanList.title}
              </Typography>
            </Grid>
            <Grid item align="left" style={{ paddingLeft: 20 }}>
              <Typography
                variant="h2"
                style={{
                  cursor: "pointer",
                  borderBottom: "2px solid #345369",
                }}
                onClick={() => history.push("artisans-planning")}
              >
                {"Plannings"}
              </Typography>
            </Grid>

            <Grid item xs={12} md={12} align="center" style={{ marginTop: 20 }}>
              <Typography variant="h3">Choisir un artisan</Typography>
              <Grid item xs={6} align="left" style={{ marginTop: 20 }}>
                <Autocomplete
                  name={"article_id"}
                  options={artisans}
                  getOptionLabel={(option) =>
                    option.name &&
                    `${option?.artisan_profile?.company} - ${option.name} ${option.surname}`
                  }
                  noOptionsText={"pas d'artisans"}
                  onChange={(event, newValue) =>
                    newValue && onChangeArtisanHandler(newValue)
                  }
                  defaultValue={artisans.find(
                    (findArticle) => findArticle.id === artisan?.id
                  )}
                  value={
                    artisans.find(
                      (findArticle) => findArticle.id === artisan?.id
                    ) ?? ""
                  }
                  className={classes.input}
                  fullWidth
                  onInputChange={(event) =>
                    artisans.filter(
                      (articleFilter) =>
                        articleFilter?.artisan_profile?.company ===
                        event?.target?.value
                    )
                  }
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      name={"article_id"}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          {Boolean(artisanJobs?.length) && (
            <Box marginTop={4}>
              <Grid container>
                <Grid item xs={12}>
                  <div className={classes.tableContainer}>
                    <div className={classes.buttonsContainer}>
                      <ButtonGroup >
                        {Object.entries(possibleViews).map(([unit, label]) => (
                          <Button
                            key={unit}
                            onClick={() => setCalendarView(unit)}
                          >
                            {label}
                          </Button>
                        ))}
                      </ButtonGroup>
                    </div>
                    <table ref={planningRef} className={classes.table}>
                      {["months", "allSite"].includes(calendarView) && (
                        <tr
                          className={classes.tableRow}
                          style={{ textTransform: "capitalize" }}
                        >
                          {R.uniqBy(
                            (d) => d.format("MMMM YYYY"),
                            calendarDays
                          ).map((day, index) => (
                            <th
                              key={day.unix()}
                              style={{
                                borderRight: "1px solid",
                                borderRightColor: grey[500],
                                paddingBottom: 16,
                              }}
                              colSpan={
                                moment(day)
                                  .endOf("month")
                                  .diff(
                                    moment(day).isSame(
                                      moment(earliestStartingDate),
                                      "month"
                                    ) && calendarView === "allSite"
                                      ? moment(earliestStartingDate)
                                      : moment(day).startOf("month"),
                                    "days"
                                  ) + (index === 0 ? 2 : 1)
                              }
                            >
                              {day.format("MMMM YYYY")}
                            </th>
                          ))}
                        </tr>
                      )}
                      <tr className={classes.tableRow}>
                        <th className={classes.tableCell}>
                          <Typography>Chantier</Typography>
                        </th>
                        {calendarDays.map((day) => (
                          <th
                            className={classes.tableCell}
                            key={day.unix()}
                            // ref={day.isSame(moment(), 'day') ? todayRef : undefined}
                            style={{
                              borderRight: "1px solid",
                              borderRightColor: moment(day).isSame(
                                moment(day).endOf("month"),
                                "days"
                              )
                                ? grey[500]
                                : "transparent",
                            }}
                          >
                            {calendarView === "days" &&
                              day.format("dddd DD MMMM YYYY")}
                            {calendarView === "weeks" && day.format("dddd DD")}
                            {["months", "allSite"].includes(calendarView) &&
                              day.format("DD")}
                          </th>
                        ))}
                      </tr>
                      {artisanJobs
                        .filter(
                          (job) =>
                            Boolean(job.site_category) && Boolean(job.site)
                        )
                        .map((item) => {
                          return (
                            <tr key={item.id} className={classes.tableRow}>
                              <td className={classes.tableCell}>
                              <Typography
                        style={{
                          fontSize: "13px",
                          width: "180px",
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          marginTop: 0,
                          marginBottom: 0,
                          padding: 0,
                        }}
                      >{item.site.name}</Typography>
                              </td>
                              {calendarDays.map((day) => {
                                const isItemDate = day.isBetween(
                                  moment(item.starting_date),
                                  moment(item.ending_date),
                                  "days",
                                  "[]"
                                );
                                const color =
                                  item.site.status === "not-signed"
                                    ? "red"
                                    : "green";
                                return (
                                  <td
                                    className={classes.tableCell}
                                    key={day.unix()}
                                    style={{
                                      backgroundColor: isItemDate
                                        ? color
                                        : "white",
                                    }}
                                  >
                                    {isItemDate && (
                                      <div
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                      />
                                    )}
                                    {moment().isSame(day, "day") && (
                                      <div className={classes.todayLine} />
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                    </table>
                  </div>

                  <Box display="flex" justifyContent="flex-end">
                    {/*<Button
                      onClick={() =>
                        exportComponentAsJPEG(planningRef, {
                          fileName: 'Planning',
                        })
                      }
                    >
                      Export Image
                    </Button>*/}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Container>
      </SideMenu>
    </>
  );
};

export default ArtisanPlanning;
