import React, { useState } from "react";
import {
  Select,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GrAddCircle } from "react-icons/gr";
import { IoMdDoneAll } from "react-icons/io";
import { IoTrashBin } from "react-icons/io5";
import text from "../../utils/text";
import AddIcon from '@mui/icons-material/Add';

const useStyles = makeStyles({
  label: {
    fontFamily: ["Roboto", "-apple-system"].join(","),
    fontWeight: 700,
    color: "#2A3254",
  },
  input: {
    fontFamily: ["Roboto", "-apple-system"].join(","),
    fontSize: 14,
  },
});

const CommonSelectAddOption = ({
  field,
  label,
  options,
  name,
  error,
  labelCustom,
  onAddHandler,
  addName,
  inputStyle,
}) => {
  const classes = useStyles();
  const [isAdd, setIsAdd] = useState(false);
  const [addError, setAddError] = useState(false);
  const [value, setValue] = useState("");

  const onAddItemHandler = () => {
    if (!value) {
      setAddError(text.General.required);
      return;
    }
    setAddError(false);
    onAddHandler(value);
    setIsAdd(false);
  };

  return (
    <>
      <Grid container>
        {labelCustom && (
          <Grid item xs={12} align="left">
            <Typography component="span" className={classes.label}>
              {labelCustom}
            </Typography>
          </Grid>
        )}
        <Grid item xs={10}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-outlined-label">
              {label}
            </InputLabel>
            {isAdd ? (
              <>
                <TextField
                  error={!!addError ?? false}
                  variant="outlined"
                  label={label}
                  name={addName}
                  type={"input"}
                  fullWidth
                  value={value}
                  onChange={(event) => setValue(event.target.value)}
                  helperText={addError}
                  InputProps={{
                    className: [classes.input].join(" "),
                  }}
                  size="small"
                  style={{ ...inputStyle }}
                />
              </>
            ) : (
              <Select
                native
                value={field.value}
                onChange={(event) => field.onChange(event.target.value)}
                variant="outlined"
                label={label}
                name={name}
                error={!!error}
                className={classes.input}
              >
                <option value=""></option>
                {options.map((option) => {
                  return (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  );
                })}
              </Select>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={1} style={{ paddingLeft: 5, marginTop: 8 }}>
          {isAdd ? (
            <IoMdDoneAll
              size={18}
              style={{ cursor: "pointer" }}
              onClick={() => onAddItemHandler()}
              color={"green"}
            />
          ) : (
            <AddIcon
              size={10}
              className='addIcon'
              style={{ cursor: "pointer", paddingRight:5 }}
              onClick={() => setIsAdd(true)}
            />
          )}
        </Grid>
        <Grid item xs={1} style={{ paddingLeft: 10, marginTop: 8 }}>
          {isAdd && (
            <IoTrashBin
              size={18}
              style={{ cursor: "pointer" }}
              onClick={() => setIsAdd(false)}
              color={"red"}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography component="span" style={{ color: "red", fontSize: 12 }}>
            {error?.message}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default CommonSelectAddOption;
