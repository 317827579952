import React, { useState, useEffect, useRef } from "react";
import SideMenu from "../../../Components/Commons/SideMenu";
import { Container, Grid, Typography } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { AddPicture, Settings } from "../../../utils/icons";
import TextFieldCustom from "../../../Components/FormComponents/TextFieldCustom";
import ChooseAddress from "../../../Components/Commons/ChoosingAddress/ChoosingAddress";
import text from "../../../utils/text";
import MapShow from "../../../Components/Commons/Map/Map";
import CheckBoxCustom from "../../../Components/FormComponents/CheckBoxCustom";
import CommonButton from "../../../Components/FormComponents/CommonButton";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import {
  getConfiguration,
  storeConfiguration,
} from "../../../Axios/Call/AuthApi";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";
import { useHistory } from "react-router-dom";

const Configuration = () => {
  const [errorApi, setErrorApi] = useState(false);
  const [successApi, setSuccessApi] = useState(false);
  const [configurations, setConfiguations] = useState({});
  const [loadingApi, setLoadingApi] = useState(false);
  const [loadingApiFetch, setLoadingApiFetch] = useState(false);
  const { register, handleSubmit, errors, setValue, watch, control } = useForm({
    mode: "onBlur",
  }); // initialise the hook
  const inputFileRef = useRef(null);
  const history = useHistory();

  const changeConfiguration = (data) => {
    setLoadingApi(true);
    storeConfiguration({
      ...data,
      address: data?.address?.address,
      latitude: data?.address?.latitude,
      longitude: data?.address?.longitude,
    })
      .then((response) => {
        setLoadingApi(false);
        setSuccessApi(true);
      })
      .catch((error) => {
        setErrorApi(true);
        setLoadingApi(false);
      });
  };

  useEffect(() => {
    setLoadingApiFetch(true);
    getConfiguration()
      .then((response) => {
        setConfiguations(response.data);
        setLoadingApiFetch(false);
        if (
          response.data.address &&
          response?.data?.latitude &&
          response.data.longitude
        ) {
          setValue("address", {
            address: response?.data?.address,
            latitude: response?.data?.latitude,
            longitude: response.data.longitude,
          });
        }
        setValue("company", response.data.company);
        setValue("siret", response.data.siret);
        setValue("tva", response.data.tva);
        setValue("phone", response.data.phone);
        setValue("name", response.data.name);
        setValue("surname", response.data.surname);
        setValue("email", response.data.email);
        setValue("mobile", response.data.mobile);
        setValue("address_invoice", response.data.address_invoice);
        setValue("city_invoice", response.data.city_invoice);
        setValue("postal_code_invoice", response.data.postal_code_invoice);
        setValue("civic_number_invoice", response.data.civic_number_invoice);
        setValue("bank", response.data.bank);
        setValue("iban", response.data.iban);
        setValue("logo", response.data.logo);
        if (!response.data.postal_code_invoice) {
          setValue("is_address_different", false);
        }
      })
      .catch((error) => {
        setErrorApi(true);
        setLoadingApiFetch(false);
      });
  }, []);

  useEffect(() => {
    if (watch("is_address_different")) {
      setValue("address_invoice", configurations.address_invoice);
      setValue("city_invoice", configurations.city_invoice);
      setValue("postal_code_invoice", configurations.postal_code_invoice);
      setValue("civic_number_invoice", configurations.civic_number_invoice);
    }
  }, [watch("is_address_different")]);

  function fileToBase64(file) {
    return new Promise((res, rej) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onloadend = () => {
        res(fileReader.result);
      };
      fileReader.onerror = () => rej();
    });
  }

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertError}
        />
      )}
      {successApi && (
        <AlertGeneral
          color="success"
          setTrigger={setSuccessApi}
          description={"Les données ont été enregistrées"}
        />
      )}
      <SideMenu>
        <Container>
          {loadingApiFetch ? (
            <LoadingButton />
          ) : (
            <>
              <Grid container style={{paddingTop:20}}>
                <Grid item align="left" style={{ paddingTop: 5 }}>
                  <Settings size={38} fill="#345369" />
                </Grid>
                <Grid item align="left" style={{ paddingLeft: 10 }}>
                  <Typography
                    variant="h2"
                    style={{ borderBottom: "2px solid #345369" }}
                  >
                    {text.Configuration.title}
                  </Typography>
                </Grid>
                <Grid item align="left" style={{ paddingLeft: 10 }}>
                  <Typography
                    variant="h2"
                    style={{ cursor: "pointer", color: "#AEB4BE" }}
                    onClick={() => history.push("/templates")}
                  >
                    Modèles devis
                  </Typography>
                </Grid>
              </Grid>
              <form
                key={1}
                onSubmit={handleSubmit(changeConfiguration)}
                style={{ marginTop: 20 }}
              >
                <Grid container>
                  <Grid item xs={12} md={3}>
                    <div
                      style={{
                        width: 200,
                        height: "100%",
                        border: "1px solid #345369",
                        borderRadius: 10,
                      }}
                    >
                      <div
                        style={{
                          marginTop: watch("logo") ? "0px" : "20%",
                          cursor: "pointer",
                          height: "100%",
                        }}
                        onClick={() => inputFileRef?.current?.click()}
                      >
                        {watch("logo") ? (
                          <img
                            src={watch("logo")}
                            alt="logo"
                            style={{
                              width: "198px",
                              height: "100%",
                              borderRadius: 10,
                            }}
                          />
                        ) : (
                          <>
                            <AddPicture fill="#345369" />
                            <Typography>Photo</Typography>
                          </>
                        )}
                        <Controller
                          render={(field) => (
                            <input
                              type="file"
                              style={{ display: "none" }}
                              ref={inputFileRef}
                              accept="image/png, image/jpeg, application/pdf"
                              onChange={async (e) => {
                                const b64 = await fileToBase64(
                                  e.target.files[0]
                                );
                                field.onChange(b64);
                              }}
                            />
                          )}
                          defaultValue={null}
                          name="logo"
                          control={control}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} align="left">
                        <Typography variant="h3">Entreprise</Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextFieldCustom
                          label={"Nom de l’entreprise"}
                          type="input"
                          ref={register({})}
                          defaultValue=""
                          name="company"
                          error={errors.company}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextFieldCustom
                          label={"Téléphone fixe"}
                          type="input"
                          ref={register({})}
                          defaultValue=""
                          name="phone"
                          error={errors.phone}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextFieldCustom
                          label={"SIRET"}
                          type="input"
                          ref={register({})}
                          defaultValue=""
                          name="siret"
                          error={errors.siret}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextFieldCustom
                          label={"Numéro de TVA"}
                          type="input"
                          ref={register({})}
                          defaultValue=""
                          name="tva"
                          error={errors.tva}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} style={{ marginTop: 50 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} align="left">
                        <Typography variant="h3">
                          Adresse de l’entreprise
                        </Typography>
                      </Grid>
                      <Grid item xs={9} md={12}>
                        <Controller
                          render={(field) => (
                            <ChooseAddress
                              field={field}
                              error={errors.address}
                            />
                          )}
                          name="address"
                          defaultValue={null}
                          rules={{
                            required: text.General.required,
                          }}
                          control={control}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        align="left"
                        style={{ marginTop: 30 }}
                      >
                        <Typography variant="h3">
                          Coordonnées de facturation
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextFieldCustom
                          label={"Prénom du contact"}
                          type="input"
                          ref={register({})}
                          defaultValue=""
                          name="name"
                          error={errors.name}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextFieldCustom
                          label={"Nom du contact"}
                          type="input"
                          ref={register({})}
                          defaultValue=""
                          name="surname"
                          error={errors.surname}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextFieldCustom
                          label={"Téléphone portable"}
                          type="input"
                          ref={register({})}
                          defaultValue=""
                          name="mobile"
                          error={errors.mobile}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextFieldCustom
                          label={"E-mail"}
                          type="email"
                          ref={register({})}
                          defaultValue=""
                          name="email"
                          error={errors.email}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextFieldCustom
                          label={"IBAN"}
                          type="input"
                          ref={register({})}
                          defaultValue=""
                          name="iban"
                          error={errors.iban}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextFieldCustom
                          label={"Banque"}
                          type="input"
                          ref={register({})}
                          defaultValue=""
                          name="bank"
                          error={errors.bank}
                        />
                      </Grid>
                      <Grid item xs={12} align="left">
                        <Grid container>
                          <Controller
                            render={(field) => (
                              <CheckBoxCustom
                                field={field}
                                error={errors.is_address_different}
                                options={[
                                  {
                                    id: "1",
                                    name: "L’adresse de facturation est différente de l’adresse de l’entreprise",
                                  },
                                ]}
                              />
                            )}
                            name="is_address_different"
                            defaultValue={true}
                            rules={{}}
                            control={control}
                          />
                        </Grid>
                      </Grid>
                      {watch("is_address_different") && (
                        <>
                          <Grid item xs={3} md={2}>
                            <TextFieldCustom
                              label={"Numéro"}
                              type="input"
                              ref={register({})}
                              defaultValue=""
                              name="civic_number_invoice"
                              error={errors.civic_number_invoice}
                            />
                          </Grid>
                          <Grid item xs={9} md={10}>
                            <TextFieldCustom
                              label={"Rue"}
                              type="input"
                              ref={register({})}
                              defaultValue=""
                              name="address_invoice"
                              error={errors.address_invoice}
                            />
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <TextFieldCustom
                              label={"Code postal"}
                              type="input"
                              ref={register({})}
                              defaultValue=""
                              name="postal_code_invoice"
                              error={errors.postal_code_invoice}
                            />
                          </Grid>
                          <Grid item xs={6} md={8}>
                            <TextFieldCustom
                              label={"Ville"}
                              type="input"
                              ref={register({})}
                              defaultValue=""
                              name="city_invoice"
                              error={errors.city_invoice}
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} md={12} style={{ paddingBottom: 30 }}>
                        <CommonButton
                          text={"SAUVEGARDER CONFIGURATION"}
                          loading={loadingApi}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    align="center"
                    style={{ marginTop: 30 }}
                  >
                    {watch("address")?.latitude &&
                      watch("address")?.longitude && (
                        <MapShow
                          latitude={watch("address")?.latitude}
                          longitude={watch("address")?.longitude}
                        />
                      )}
                  </Grid>
                </Grid>
              </form>
            </>
          )}
        </Container>
      </SideMenu>
    </>
  );
};

export default Configuration;
