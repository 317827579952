import React, { useEffect } from "react";
//--------- Others ------------------
import * as actionCreator from "../store/action/index";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const LayoutNotLogin = ({ children, page }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(actionCreator.populateUserData());
    }
  }, []);

  useEffect(() => {
    if (Object.keys(userData).length) {
      if (userData.email_verified_at) {
        if (userData.type === "owner" || userData.type === "responsable") {
          history.push("/dashboard");
        }
      } else {
        dispatch(actionCreator.cleanUserData());
      }
    }
  }, [userData]);

  return <>{children}</>;
};

export default LayoutNotLogin;
