import React, { useEffect, useState } from 'react';
import { Grid, Modal } from '@material-ui/core';
import { Cross } from '../../../utils/icons';
import {
  artisanOrderPdf,
  sendArtisanContractToClient,
  sendSupplierOrderToClient,
  supplierOrderPdf,
} from '../../../Axios/Call/AuthApi';
import LoadingButton from '../../Commons/LoadingButton/LoadingButton';
import text from '../../../utils/text';
import AlertGeneral from '../../Commons/AlertGeneral/AlertGeneral';
import CommonButton from '../../FormComponents/CommonButton';

const ModalSendContract = ({ openModal, setOpenModal, onSent }) => {
  const [errorApi, setErrorApi] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [sending, setSending] = useState(false);
  const [pdf, setPdf] = useState('');

  useEffect(() => {
    if (openModal) {
      setLoadingApi(true);
      if (openModal?.supplierId) {
        supplierOrderPdf(openModal.quoteId, openModal?.supplierId)
          .then((response) => {
            setPdf(response.data);
            // reset({ ...response.data });
            setLoadingApi(false);
          })
          .catch((error) => {
            setErrorApi(true);
            setLoadingApi(false);
          });
      } else {
        artisanOrderPdf(openModal.quoteId, openModal?.artisanId)
          .then((response) => {
            setPdf(response.data);
            // reset({ ...response.data });
            setLoadingApi(false);
          })
          .catch((error) => {
            setErrorApi(true);
            setLoadingApi(false);
          });
      }
    }
  }, [openModal]);

  const handleSend = async () => {
    setSending(true);

    try {
      if (openModal?.supplierId) {
        await sendSupplierOrderToClient(openModal.quoteId, openModal?.supplierId);
      } else {
        await sendArtisanContractToClient(openModal.id);
      }
      if (typeof onSent === 'function') {
        onSent(openModal);
      }
    } catch {
      setErrorApi(true);
    } finally {
      setSending(false);
      setOpenModal(false);
      setPdf('');
    }
  };

  return (
    <>
      {errorApi && <AlertGeneral color="danger" setTrigger={setErrorApi} description={text.General.alertError} />}
      <Modal open={openModal} size={'xl'}>
        <Grid container className={'modalWrapperWeb'}>
          <div
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
              cursor: 'pointer',
            }}
            onClick={() => {
              setOpenModal(false);
              setPdf('');
            }}
          >
            <Cross />
          </div>
          {loadingApi ? (
            <LoadingButton />
          ) : (
            <>
              {pdf && (
                <>
                  <Grid item xs={12}>
                    <iframe
                      src={pdf}
                      style={{
                        width: '100%',
                        height: '60vh',
                      }}
                      type="application/pdf"
                      name="myiframe"
                      id="myiframe"
                      title="myiframe"
                    />
                  </Grid>
                  {openModal?.status === 'not-sent' && (
                    <Grid item xs={12}>
                      <CommonButton text={text.General.send} onClick={handleSend} loading={sending} />
                    </Grid>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      </Modal>
    </>
  );
};

export default ModalSendContract;
