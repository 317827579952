import React, { useEffect, useState } from 'react';
import { Grid, InputAdornment, Typography, Divider, Container } from '@material-ui/core';
import text from '../../../../utils/text';
import { Controller, useForm } from 'react-hook-form';
import TextFieldCustom from '../../../../Components/FormComponents/TextFieldCustom';
import TextBoxCustom from '../../../../Components/FormComponents/TextBoxCustom';
import ChooseAddress from '../../../../Components/Commons/ChoosingAddress/ChoosingAddress';
import MapShow from '../../../../Components/Commons/Map/Map';
import classes from './Sections.module.css';
import { editSite, getClients, storeSite, signContractAndCreateInvoice } from '../../../../Axios/Call/AuthApi';
import AlertGeneral from '../../../../Components/Commons/AlertGeneral/AlertGeneral';
import CommonSelect from '../../../../Components/FormComponents/CommonSelect';
import CommonDateTimePicker from '../../../../Components/FormComponents/CommonDateTimePicker';
import moment from 'moment';
import CommonButton from '../../../../Components/FormComponents/CommonButton';
import { useHistory, useParams } from 'react-router-dom';

const Informations = ({ site }) => {
  const [clientsProspectus, setClientsProspectus] = useState([]);
  const [loadingApi, setLoadingApi] = useState(false);
  const [loadingSignContract, setLoadingSignContract] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successEdit, setSuccessEdit] = useState(false);
  const [loadingApiAdd, setLoadingApiAdd] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const { siteId } = useParams();
  const optionsStatus = [
    { id: 'on-going', name: 'En cours' },
    { id: 'not-started', name: 'Signé' },
    { id: 'not-signed', name: "À l'étude" },
    { id: 'finished', name: 'Terminé' },
  ];
  const history = useHistory();
  const { register, handleSubmit, errors, setValue, reset, control, watch } = useForm({
    mode: 'onBlur',
  }); // initialise the hook

  useEffect(() => {
    let mounted = true;
    if (!clientsProspectus.length) {
      getClients('all')
        .then((response) => {
          if(mounted) {
          setClientsProspectus(
            response.data.map((client) => {
              return { ...client, name: `${client.name} ${client.surname}` };
            })
          );
          setLoadingApi(false);
          if (Object.keys(site).length) {
            setValue('client_id', site?.client_id);
          }}
        })
        .catch(() => {
          setErrorApi(true);
          setLoadingApi(false);
        });
    }
    return function cleanup() {
      mounted = false
  }

  }, []);

  useEffect(() => {
    let mounted = true;
    if (Object.keys(site).length) {
      if(mounted) {

      reset({
        ...site,
        address: {
          address: site.address,
          latitude: site.latitude,
          longitude: site.longitude,
        },
      });
    }}
    return function cleanup() {
      mounted = false
  }

  }, [site]);

  const createCostructionSite = (data) => {
    setLoadingApiAdd(true);
    if (siteId) {
      editSite(siteId, {
        ...data,
        ...data?.address,
        starting_date: moment(data.starting_date).format('yyyy-MM-DD'),
        ending_date: moment(data.ending_date).format('yyyy-MM-DD'),
      })
        .then((response) => {
          setLoadingApiAdd(false);
          setSuccessEdit(true);
        })
        .catch((errorApi) => {
          setLoadingApiAdd(false);
          setErrorApi(true);
        });
    } else {
      storeSite({
        ...data,
        ...data?.address,
        starting_date: moment(data.starting_date).format('yyyy-MM-DD'),
        ending_date: moment(data.ending_date).format('yyyy-MM-DD'),
      })
        .then((response) => {
          setLoadingApiAdd(false);
          setSuccess(true);
          const time = setTimeout(() => history.push(`/site-detail/information/${response.data.id}`), 1000);
          return () => {
            clearTimeout(time);
          };
        })
        .catch((error) => {
          setLoadingApiAdd(false);
          setErrorApi(true);
        });
    }
  };

  const signContractHandler = (quoteId) => {
    setLoadingSignContract(true);
    signContractAndCreateInvoice(siteId, quoteId, {})
      .then((response) => {
        setLoadingSignContract(false);
      })
      .catch((error) => {
        setLoadingSignContract(false);
        setErrorApi(true);
      });
  };

  return (
    <>
      {errorApi && <AlertGeneral color="danger" setTrigger={setErrorApi} description={text.General.alertError} />}
      {success && (
        <AlertGeneral
          color="success"
          setTrigger={setSuccess}
          description={text.SiteDetail.sectionInformation.alertSuccess}
        />
      )}
      {successEdit && (
        <AlertGeneral
          color="success"
          setTrigger={setSuccessEdit}
          description={text.SiteDetail.sectionInformation.alertSuccessEdit}
        />
      )}
      <Container
        maxWidth='xl'
        style={{
          marginTop: 10,
          paddingBottom: 20,
          backgroundColor: '#FFF',
          padding: '30px 10px ',
        
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", 
             borderRadius:"5px", 
        }}
      >
        
          <Grid container 
  
          >
            <Grid  item xs={12} md={6} 
             style={{padding:'20px 50px'}}
            >
              <form key={1} onSubmit={handleSubmit(createCostructionSite)} id="edit-site-details-form">
                <Grid container >
                  <Grid item xs={12} md={6} className={classes.boxStyle} >
                    <TextFieldCustom
                      labelCustom={text?.SiteDetail?.sectionInformation?.site}
                      placeHolder={text?.SiteDetail?.sectionInformation?.sitePlaceholder}
                      type="input"
                      ref={register({
                        required: text.General.required,
                      })}
                      defaultValue=""
                      name="name"
                      error={errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}  className={classes.boxStyle}>
                    <Controller
                      render={(field) => (
                        <CommonSelect
                          labelCustom={text?.SiteDetail?.sectionInformation?.clientLabel}
                          error={errors.client_id}
                          field={field}
                          options={clientsProspectus}
                        />
                      )}
                      control={control}
                      defaultValue=""
                      name="client_id"
                      rules={{ required: text.General.required }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} className={classes.boxStyle}>
                    <Controller
                      render={(field) => (
                        <CommonSelect
                          labelCustom={text?.SiteDetail?.sectionInformation?.status}
                          error={errors.status}
                          field={field}
                          options={optionsStatus}
                        />
                      )}
                      control={control}
                      defaultValue=""
                      name="status"
                      rules={{ required: text.General.required }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}  className={classes.boxStyle}>
                    <TextFieldCustom
                      labelCustom={text?.SiteDetail?.sectionInformation?.price}
                      type="number"
                      ref={register({
                        required: text.General.required,
                      })}
                      defaultValue=""
                      name="price"
                      error={errors.price}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Typography>€</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} p={1} className={classes.boxStyle}>
                    <Controller
                      render={(field) => (
                        <CommonDateTimePicker
                          field={field}
                          name={'starting_date'}
                          labelCustom={text?.SiteDetail?.sectionInformation?.starting}
                        />
                      )}
                      control={control}
                      name={'starting_date'}
                      defaultValue={moment()}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}  p={1} className={classes.boxStyle}>
                    <Controller
                      render={(field) => (
                        <CommonDateTimePicker
                          field={field}
                          name={'ending_date'}
                          labelCustom={text?.SiteDetail?.sectionInformation?.ending}
                          error={errors.ending_date}
                        />
                      )}
                      control={control}
                      name={'ending_date'}
                      defaultValue={moment().add('30', 'days')}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}  className={classes.boxStyle} style={{boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius:'5px', textAlign:'center', padding:10, maxWidth:180, float:'center', marginLeft:'auto', marginRight:'auto'}}>
                    {watch('starting_date') && watch('ending_date') && (
                      <>
                        <Typography component="span" style={{ color: '#345369', fontWeight: 'bolder' }}>
                          Durée
                        </Typography>
                        <Typography
                          style={{
                            marginTop: 10,
                            fontWeight: 'bold',
                            color: '#EE3333',
                          }}
                        >
                          {moment(watch('ending_date')).diff(moment(watch('starting_date')), 'days')} Jours
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} md={12} className={classes.boxStyle}>
                    <TextFieldCustom
                      labelCustom={text?.SiteDetail?.sectionInformation?.descriptionProject}
                      placeHolder={text?.SiteDetail?.sectionInformation?.descriptionProjectPlaceholder}
                      type="input"
                      ref={register({
                        required: text.General.required,
                      })}
                      defaultValue=""
                      name="description_project"
                      error={errors.description_project}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} className={classes.boxStyle}>
                    <TextBoxCustom
                      labelCustom={text?.SiteDetail?.sectionInformation?.description}
                      placeholder={text?.SiteDetail?.sectionInformation?.descriptionPlaceholder}
                      type="input"
                      ref={register({
                        required: text.General.required,
                      })}
                      defaultValue=""
                      name="description"
                      error={errors.description}
                      rows={4}
                    />
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid  item  xs={12} md={6} 
               style={{padding:'20px 50px'}}>
              <Grid container>
                <Grid item xs={12} style={{ marginTop: 10 }} >
                  <Controller
                    render={(field) => (
                      <ChooseAddress
                        field={field}
                        error={errors.address}
                        label={text?.SiteDetail?.sectionInformation?.address}
                      />
                    )}
                    name="address"
                    defaultValue={null}
                    rules={{
                      required: text.General.required,
                    }}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} style={{ height: 350, marginTop: 10 }}>
                  {watch('address')?.latitude && watch('address')?.longitude && (
                    <MapShow latitude={watch('address')?.latitude} longitude={watch('address')?.longitude} />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={12} align="center">
              <CommonButton
                text={`${
                  siteId
                    ? text?.SiteDetail?.sectionInformation?.editCta
                    : text?.SiteDetail?.sectionInformation?.createCta
                }`}
                loading={loadingApiAdd}
                style={{ width: 200 }}
                form={'edit-site-details-form'}
              />
            </Grid>
          </Grid>

      </Container>
    </>
  );
};

export default Informations;
