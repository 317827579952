import React, { useState, useEffect } from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import text from "../../../utils/text";
import TextFieldCustom from "../../../Components/FormComponents/TextFieldCustom";
import { useForm } from "react-hook-form";
import { formatErrorEmail } from "../../../utils/formValidation";
import CommonButton from "../../../Components/FormComponents/CommonButton";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import Img from "../../../Assets/Images/login-illu.png";
import LogoWhite from "../../../Assets/Images/logo-white.png";
import { ResetPasswordEmail } from "../../../Axios/Call/ClientCredential";
import { useHistory } from "react-router-dom";
import classes from "../Login/Login.module.css";

const ForgotPassword = () => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  const sendFormLogin = (data) => {
    setLoadingApi(true);
    ResetPasswordEmail(data)
      .then((response) => {
        setEmailSuccess(true);
        setLoadingApi(false);
      })
      .catch((error) => {
        setErrorApi(true);
        setLoadingApi(false);
      });
  };

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertGeneralError}
        />
      )}
      {emailSuccess && (
        <AlertGeneral
          color="success"
          setTrigger={setEmailSuccess}
          description={text.forgotPassword.alertSuccess}
        />
      )}
      <div style={{ backgroundColor: "white" }}>
      <Container style={{ maxWidth: "none", paddingRight: "0px"}}>
      <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={8} style={{ backgroundColor: "white" }}>
              <form
                onSubmit={handleSubmit(sendFormLogin)}
                style={{ marginTop: 20 }}
                noValidate
              >
                <Grid container justifyContent="center" className={classes.leftColumn} >
                  <Grid item xs={12} md={5}>
                    <Grid container>
                      <Grid item xs={12} align="left">
                        <Typography variant="h1">
                          {text.forgotPassword.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} align="left">
                        <Typography style={{ fontSize: 18 }}>
                          {text.forgotPassword.description}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 30 }}>
                        <TextFieldCustom
                          label={text.forgotPassword.emailLabel}
                          type="email"
                          ref={register({
                            required: text.General.required,
                            validate: {
                              formatErrorEmail,
                            },
                          })}
                          defaultValue=""
                          name="email"
                          error={errors.email}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        align="center"
                        style={{ marginTop: 20 }}
                      >
                        <Typography
                          style={{ cursor: "pointer" }}
                          onClick={() => history.push("/")}
                        >
                          {text.forgotPassword.alreadyAccount}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{ marginTop: 10, marginBottom: 10 }}
                      >
                        <CommonButton
                          loading={loadingApi}
                          text={text.forgotPassword.cta}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
              <div style={{ position: "absolute", bottom: 10, left: 10 }}>
                <Typography style={{ color: "#AEB4BE" }}>
                  © 2021 Vigee
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4} className={classes.rightColumn}>
             
             <img className={classes.logo} src={LogoWhite} alt="logo-white" />
         
           <img
            className={classes.illustration}
             src={Img}
             alt="person-writing"
             style={{width:"100%"}}
           />

<div className={classes.bottomRightText} >
               <Typography  style={{ color: "#AEB4BE", paddingRight: 10 ,textDecoration:null}}>
                 <a className={classes.link} href="https://www.vigee.fr">{`www.vigee.fr `}</a><a className={classes.link} href="mailto:contact@vigee.fr">{` | contact@vigee.fr`}</a>
               </Typography>
   
             </div>
           
          
          
         </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default ForgotPassword;
