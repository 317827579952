import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useEffect, useState } from 'react';

function ViewSiteDocumentDialog({ document, onClose }) {
  const [loading, setLoading] = useState(true);

  const handleClose = () => {
    onClose();
    setLoading(true);
  };

  useEffect(() => {
    setLoading(true);
  }, [document]);

  return (
    <Dialog open={Boolean(document)} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>{document?.title}</DialogTitle>
      <DialogContent>
        {loading && (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight={600}>
            <CircularProgress size={40} />
          </Box>
        )}
        {document && (
          <iframe
            src={document.url}
            title={document.title}
            onLoad={() => setLoading(false)}
            style={{
              display: loading ? 'none' : 'block',
              width: '100%',
              minHeight: '80vh',
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ViewSiteDocumentDialog;
