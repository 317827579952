import React from 'react';
import { Grid, TextField, Typography, FormControl, InputLabel, InputAdornment, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GrAddCircle } from 'react-icons/gr';
import CommonSelectAddOption from '../../FormComponents/CommonSelectAddOption';
import { Autocomplete } from '@material-ui/lab';
import { useWindowWidth } from '@react-hook/window-size';
import { useSelector } from 'react-redux';
import { AiFillDelete } from 'react-icons/ai';
import ClearIcon from '@mui/icons-material/Clear';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddIcon from '@mui/icons-material/Add';
import { Articles } from "../../../utils/icons";

const ArticleLine = ({
  autoFocus,
  level,
  index,
  article,
  onChangeArticleHandler,
  units,
  setOpenModalAddArticle,
  setOpenModalAddSupplier,
  onAddUnitHandler,
  suppliers,
  articles,
  handlerDeleteArticleItem,
  isSigned,
}) => {
  const clientMode = useSelector((state) => state.dashboard.clientMode);
  const useStyles = makeStyles({
    label: {
      fontFamily: ['Roboto', '-apple-system'].join(','),
    },
    input: {
      fontFamily: ['Roboto', '-apple-system'].join(','),
    },
  });
  const classes = useStyles();
  const screenWidth = useWindowWidth();

  const tvaItem = [
    { id: 0, name: '0%' },
    { id: 5.5, name: '5,5%' },
    { id: 10, name: '10%' },
    { id: 20, name: '20%' },
  ];

  return (
    <Grid container style={{width:"100%"}}>

<Grid item style={{ paddingTop: 5 }}>
        <Articles />
        </Grid>

      <Grid style={{ paddingLeft: 10}}>
     
        <Autocomplete
          name={'article_id'}
          options={articles}
          getOptionLabel={(option) => (option && `${ option.description} ( ${suppliers.filter(s => s.id === option.supplier_id && s.name).map(s => s.name)})`)}
          noOptionsText={"pas d'articles"}
          onChange={(event, newValue) =>
            newValue &&
            onChangeArticleHandler({
              type: 'article_id',
              value: newValue,
              index: index,
              level: level,
            })
          }
          defaultValue={articles!=='undefined' && articles.find((findArticle) => findArticle.id === article.article_id)}
          value={articles.find((findArticle) => findArticle.id === article.article_id) ?? ''}
          className={classes.input}
          fullWidth
          onInputChange={(event) =>
            articles.filter((articleFilter) => articleFilter.description === event?.target?.value)
          }
          style={{ width: screenWidth > 1600 ? 500 : 150, minWidth:'300px'  }}
          size="small"
          renderInput={(params) => {
            return (
              <TextField
                autoFocus={autoFocus}
                label="Référence article"
                {...params}
                variant="outlined"
                fullWidth
                name={'article_id'}
              />
            );
          }}
        />
      </Grid>
      <Grid style={{ paddingLeft: 5 }}>
        <AddIcon
          size={20}
          className='addIcon'
          style={{ marginTop: 6, cursor: 'pointer' }}
          onClick={() => setOpenModalAddArticle(true)}
        />
      </Grid>
      <Grid style={{ paddingLeft: 5, width: 120, minWidth:'200px'  }}>
        <TextField
          variant="outlined"
          size="small"
          id={`supplier-select-${index}`}
          fullWidth
          select
          label={'Fournisseur'}
          value={article.supplier_id ?? ''}
          SelectProps={{ native: true }}
          onChange={(event) => {
            onChangeArticleHandler({
              type: 'supplier_id',
              value: event.target.value,
              index: index,
              level: level,
            });
          }}
          name={'Artisan_id'}
        >
          <option value=""></option>
          {suppliers.map((option) => {
            return (
              <option key={option.id} value={option.id}>
                {option.company || option.name}
              </option>
            );
          })}
        </TextField>
      </Grid>
      <Grid style={{ paddingLeft: 5 }}>
        <AddIcon
        className='addIcon'
          size={20}
          style={{ marginTop: 6, cursor: 'pointer' }}
          onClick={() => setOpenModalAddSupplier(true)}
        />
      </Grid>
      <Grid style={{ paddingLeft: 5, width: 120 }}>
        <FormControl fullWidth size="small" variant="outlined">
          {!article.unit_id && <InputLabel>Unité</InputLabel>}
          <CommonSelectAddOption
            field={{
              value: article.unit_id,
              onChange: (value) => {
                onChangeArticleHandler({
                  type: 'unit_id',
                  value: value,
                  index: index,
                  level: level,
                });
              },
            }}
            options={units}
            inputStyle={{ width: 80 }}
            name={'unit_id'}
            onAddHandler={onAddUnitHandler}
          />
        </FormControl>
      </Grid>
      {!clientMode && (
        <Grid style={{ paddingLeft: 5 }}>
          <TextField
            variant="outlined"
            label={'Prix achat'}
            name={'buy_price'}
            type={'number'}
            value={article.buy_price}
            style={{ width: 90 }}
            onChange={(event) => {
              onChangeArticleHandler({
                type: 'buy_price',
                value: event.target.value,
                index: index,
                level: level,
              });
            }}
            fullWidth
            InputProps={{
              className: [classes.input ?? ''].join(' '),
            }}
            size="small"
          />
        </Grid>
      )}
      <Grid style={{ paddingLeft: 5 }}>
        <TextField
          variant="outlined"
          label={'Prix unitaire'}
          name={'selling_price'}
          type={'number'}
          value={article.selling_price}
          style={{ width: 90 }}
          onChange={(event) => {
            onChangeArticleHandler({
              type: 'selling_price',
              value: event.target.value,
              index: index,
              level: level,
            });
          }}
          fullWidth
          InputProps={{
            readOnly: isSigned,
            className: [classes.input ?? ''].join(' '),
          }}
          size="small"
        />
      </Grid>
      <Grid style={{ paddingLeft: 5 }}>
        <TextField
          variant="outlined"
          label={'Qté'}
          name={'quantity'}
          type={'number'}
          value={article.quantity}
          onChange={(event) => {
            onChangeArticleHandler({
              type: 'quantity',
              value: event.target.value,
              index: index,
              level: level,
            });
          }}
          style={{ width: 50 }}
          InputProps={{
            readOnly: isSigned,
            className: [classes.input ?? ''].join(' '),
          }}
          size="small"
        />
      </Grid>
      {!clientMode && (
        <Grid style={{ paddingLeft: 5 }}>
          <TextField
            variant="outlined"
            label={'Marge'}
            name={'margin_percentage'}
            type={'number'}
            value={article?.margin_percentage?.toFixed(0)}
            style={{ width: 70 }}
            onChange={(event) => {
              onChangeArticleHandler({
                type: 'margin_percentage',
                value: event.target.value,
                index: index,
                level: level,
              });
            }}
            fullWidth
            InputProps={{
              className: [classes.input ?? ''].join(' '),
              readOnly: isSigned,
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>%</Typography>
                </InputAdornment>
              ),
            }}
            size="small"
          />
        </Grid>
      )}
      <Grid style={{ paddingLeft: 5 }}>
        <FormControl size="small" variant="outlined" fullWidth>
          <InputLabel>TVA</InputLabel>
          <Select
            native
            label={'Tva'}
            value={article.tva}
            onChange={(event) => {
              onChangeArticleHandler({
                type: 'tva',
                value: event.target.value,
                index: index,
                level: level,
              });
            }}
            name={'tva'}
          >
            {tvaItem.map((option) => {
              return (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              );
            })}
          </Select>
        </FormControl>

  
      </Grid>

      <Grid style={{ paddingLeft: 10, paddingTop:6 }}>
        <ClearIcon
        className='deleteIcon'
          size={22}
          style={{ cursor: 'pointer', color:'#999' }}
          onClick={() => handlerDeleteArticleItem(article, index, level)}
        />
      </Grid>
      <Grid style={{ paddingLeft: 10, paddingTop:6  }}>
        <Typography style={{ fontSize: 18, fontWeight: 'bolder' }}>{article?.total_price?.toFixed(2)} €</Typography>
      </Grid>
    </Grid>
  );
};

export default ArticleLine;
