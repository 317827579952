import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';

/**
 *
 * @param {Object} props
 * @param {Boolean} props.open
 * @param {function} props.onClose
 * @param {string} props.link
 * @returns {import("react").ReactElement}
 */
function SiteContractViewDialog({ open, onClose, link }) {
  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <DialogContent>
        <embed src={link} type="application/pdf" style={{ width: '100%', height: '90vh' }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Fermer</Button>
      </DialogActions>
    </Dialog>
  );
}

export default SiteContractViewDialog;
