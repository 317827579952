import { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, Button, DialogTitle } from '@material-ui/core';
import { getOneInvoicePdf } from '../../../Axios/Call/AuthApi';
import LoadingButton from '../../Commons/LoadingButton/LoadingButton';

const ModalViewInvoice = ({ open, onClose, invoiceId }) => {
  const [loading, setLoading] = useState(false);
  const [pdf, setPdf] = useState('');

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (open && invoiceId) {
      setLoading(true);
      getOneInvoicePdf(invoiceId).then((res) => {
        setPdf(res.data);
      });
    } else {
      setPdf('');
    }
  }, [invoiceId, open]);

  return (
    <Dialog open={open} maxWidth="md" fullWidth onClose={handleClose}>
      <DialogTitle></DialogTitle>
      <DialogContent>
        {loading && <LoadingButton />}
        <iframe
          src={pdf}
          style={{
            width: '100%',
            height: '80vh',
            display: !pdf || loading ? 'none' : 'block',
          }}
          type="application/pdf"
          name="myiframe"
          id="myiframe"
          title="myiframe"
          onLoad={() => setLoading(false)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalViewInvoice;
