import React, { useReducer } from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { createTheme, darken, lighten } from '@material-ui/core/styles';

import App from './Containers/App';

import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
//-----------------Redux Import ------------------------------
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import userReducer from './store/reducer/user';
import dashboardReducer from './store/reducer/dashboard';
import thunk from 'redux-thunk';

import './utils/momentFr';

const rootReducer = combineReducers({
  user: userReducer,
  dashboard: dashboardReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const theme = createTheme();

theme.typography.h1 = {
  fontSize: 42,
  fontFamily: ['roboto', '-apple-system'].join(','),
  color: '#345369',
  fontWeight: '500',
};

theme.typography.h2 = {
  fontSize: 36,
  fontFamily: ['roboto', '-apple-system'].join(','),
  color: '#345369',
  fontWeight: '500',
};

theme.typography.h3 = {
  fontSize: 22,
  fontFamily: ['roboto', '-apple-system'].join(','),
  color: '#345369',
  fontWeight: '400',
};

theme.typography.h5 = {
    fontSize: 24,
    fontFamily: ['roboto', '-apple-system'].join(','),
    color: '#345369',
    fontWeight: '500',
  };

theme.typography.h6 = {
  fontSize: 18,
  fontFamily: ['roboto', '-apple-system'].join(','),
  color: '#345369',
};

theme.typography.body1 = {
  fontSize: 14,
  fontFamily: ['roboto', '-apple-system'].join(','),
  color: '#345369',
  wordWrap: 'break-word',
};

theme.typography.body2 = {
  fontSize: 14,
  fontFamily: ['roboto', '-apple-system'].join(','),
  color: '#345369',
  wordWrap: 'break-word',
};

theme.palette.primary.main = '#345369';
theme.palette.primary.dark = darken('#345369', 0.2);
theme.palette.primary.light = lighten('#345369', 0.2);

ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline>
            <App />
          </CssBaseline>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
