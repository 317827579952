import React, { useEffect } from "react";
import { Grid, Typography, Modal } from "@material-ui/core";
import text from "../../../utils/text";
import { Artisan, Cross } from "../../../utils/icons";
import TextFieldCustom from "../../../Components/FormComponents/TextFieldCustom";
import { useForm } from "react-hook-form";
import CommonButton from "../../../Components/FormComponents/CommonButton";
import {
  formatErrorEmail,
  formatErrorLetters,
  formatErrorPhoneNumber,
  formatErrorSiret,
} from "../../../utils/formValidation";

const ModalAddSupplier = ({
  setOpenModalAdd,
  openModalAdd,
  onCreateSupplier,
  loadingApiAdd,
  editSupplier,
  setEditSupplier,
}) => {
  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  const createNewArtisan = (data) => {
    onCreateSupplier(data);
  };

  useEffect(() => {
    if (Object.keys(editSupplier).length) {
      reset({ ...editSupplier });
    } else {
      reset({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSupplier]);

  return (
    <Modal open={openModalAdd} size={"xl"}>
      <Grid container className={"modalWrapperWeb"}>
        <div
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            cursor: "pointer",
          }}
          onClick={() => {
            setOpenModalAdd(false);
            setEditSupplier({});
          }}
        >
          <Cross />
        </div>
        <Grid item align="left" style={{ paddingTop: 0 }}>
          <Artisan size={38} fill="#345369" />
        </Grid>
        <Grid item align="left" style={{ paddingLeft: 10 }}>
          <Typography variant="h2">{text.SupplierList.modalTitle}</Typography>
        </Grid>
        <Grid item xs={12} align="left" style={{ marginTop: 10 }}>
          <form
            onSubmit={handleSubmit(createNewArtisan)}
            style={{ marginTop: 20 }}
            noValidate
          >
            <Grid container spacing={3}>
              <Grid item xs={12} align="left" style={{ marginTop: 10 }}>
                <Typography variant="h3">
                  {text.ArtisanList.sectionOne}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                align="left"
                style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}
              >
                <TextFieldCustom
                  variant="outlined"
                  label={text.ArtisanList.entreprise}
                  type="input"
                  ref={register({ required: text.General.required })}
                  defaultValue=""
                  name={"company"}
                  error={errors.company}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                align="left"
                style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}
              >
                <TextFieldCustom
                  variant="outlined"
                  label={text.ArtisanList.siret}
                  type="input"
                  ref={register({
                    //required: text.General.required,
                    //validate: { formatErrorSiret: formatErrorSiret },
                  })}
                  defaultValue=""
                  name={"siret"}
                  error={errors.siret}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                align="left"
                style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}
              >
                <TextFieldCustom
                  variant="outlined"
                  label={text.ArtisanList.tva}
                  type="input"
                  ref={register({
                    //required: text.General.required
                  })}
                  defaultValue=""
                  name={"tva"}
                  error={errors.tva}
                />
              </Grid>
              <Grid item xs={12} align="left" style={{ marginTop: 10 }}>
                <Typography variant="h3">
                  {text.ArtisanList.sectionTwo}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                align="left"
                style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}
              >
                <TextFieldCustom
                  variant="outlined"
                  label={text.ArtisanList.name}
                  type="input"
                  ref={register({
                    required: text.General.required,
                    validate: { formatErrorLetters: formatErrorLetters },
                  })}
                  defaultValue=""
                  name={"name"}
                  error={errors.name}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                align="left"
                style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}
              >
                <TextFieldCustom
                  variant="outlined"
                  label={text.ArtisanList.surname}
                  type="input"
                  ref={register({
                    required: text.General.required,
                    validate: { formatErrorLetters: formatErrorLetters },
                  })}
                  defaultValue=""
                  name={"surname"}
                  error={errors.surname}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                align="left"
                style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}
              >
                <TextFieldCustom
                  variant="outlined"
                  label={text.ArtisanList.email}
                  type="input"
                  ref={register({
                    required: text.General.required,
                    validate: { formatErrorEmail: formatErrorEmail },
                  })}
                  defaultValue=""
                  name={"email"}
                  error={errors.email}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                align="left"
                style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}
              >
                <TextFieldCustom
                  variant="outlined"
                  label={text.ArtisanList.phone}
                  type="input"
                  ref={register({
                    // required: text.General.required,
                    //validate: {
                    //  formatErrorPhoneNumber: formatErrorPhoneNumber,
                    //},
                  })}
                  defaultValue=""
                  name={"phone"}
                  error={errors.phone}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                align="left"
                style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}
              >
                <TextFieldCustom
                  variant="outlined"
                  label={text.ArtisanList.mobile}
                  type="input"
                  ref={register({
                    // required: text.General.required,
                    // validate: {
                    //  formatErrorPhoneNumber: formatErrorPhoneNumber,
                    //},
                  })}
                  defaultValue=""
                  name={"mobile"}
                  error={errors.mobile}
                />
              </Grid>

              <Grid item xs={12} align="left" style={{ marginTop: 10 }}>
                <Typography variant="h3">{"Adresse"}</Typography>
              </Grid>

              {/*<Grid item xs={4} md={2} align="left" style={{ marginTop: 1, paddingTop:1 , paddingRight:0}}>
                <TextFieldCustom
                  variant="outlined"
                  label={"Numéro"}
                  type="input"
                  ref={register({ required: text.General.required })}
                  defaultValue=""
                  name={"house_number"}
                  error={errors.house_number}
                />
                </Grid>*/}
              <Grid
                item
                xs={12}
                md={5}
                align="left"
                style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}
              >
                <TextFieldCustom
                  variant="outlined"
                  label={"Numéro et rue"}
                  type="input"
                  ref={register({ required: text.General.required })}
                  defaultValue=""
                  name={"address"}
                  error={errors.address}
                />
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                align="left"
                style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}
              >
                <TextFieldCustom
                  variant="outlined"
                  label={"Code postal"}
                  type="input"
                  ref={register({ required: text.General.required })}
                  defaultValue=""
                  name={"postal_code"}
                  error={errors.postal_code}
                />
              </Grid>
              <Grid
                item
                xs={6}
                md={4}
                align="left"
                style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}
              >
                <TextFieldCustom
                  variant="outlined"
                  label={"Ville"}
                  type="input"
                  ref={register({ required: text.General.required })}
                  defaultValue=""
                  name={"city"}
                  error={errors.city}
                />
              </Grid>

              <Grid item xs={12} align="left" style={{ marginTop: 10 }}>
                <Typography variant="h3">{"Note"}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                align="left"
                style={{ marginTop: 1, paddingTop: 1, paddingRight: 0 }}
              >
                <TextFieldCustom
                  variant="outlined"
                  label={text.ArtisanList.note}
                  type="input"
                  ref={register({
                    //required: text.General.required
                  })}
                  defaultValue=""
                  name={"note"}
                  error={errors.note}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{ marginTop: 20 }}
                >
                  <Grid item xs={12} md={4} align="center">
                    <CommonButton
                      loading={loadingApiAdd}
                      text={
                        editSupplier
                          ? text.SupplierList.ctaEdit
                          : text.SupplierList.ctaCreate
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ModalAddSupplier;
