import React, { useState, useEffect } from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import text from "../../../utils/text";
import TextFieldCustom from "../../../Components/FormComponents/TextFieldCustom";
import { useForm } from "react-hook-form";
import { formatErrorEmail } from "../../../utils/formValidation";
import CommonButton from "../../../Components/FormComponents/CommonButton";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import Img from "../../../Assets/Images/login-illu.png";
import LogoWhite from "../../../Assets/Images/logo-white.png";
import { login } from "../../../Axios/Call/ClientCredential";
import * as actionCreator from "../../../store/action/index";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import classes from "./Login.module.css";
import { FormatUnderlinedOutlined } from "@material-ui/icons";

const Login = () => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [emailConfirmationError, setEmailConfirmationError] = useState(false);
  const userData = useSelector((state) => state.user.userData);
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  const sendFormLogin = (data) => {
    setLoadingApi(true);
    login(data)
      .then((response) => {
        localStorage.setItem("token", "Bearer " + response.data.token);
        dispatch(actionCreator.populateUserData());
        setLoadingApi(false);
      })
      .catch((error) => {
        setErrorApi(true);
        setLoadingApi(false);
      });
  };

  useEffect(() => {
    if (Object.keys(userData).length) {
      if (userData.email_verified_at) {
        history.push("/dashboard");
      } else {
        setEmailConfirmationError(true);
        dispatch(actionCreator.cleanUserData());
      }
    }
  }, [userData]);

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.Login.alertError}
        />
      )}
      {emailConfirmationError && (
        <AlertGeneral
          color="danger"
          setTrigger={setEmailConfirmationError}
          description={text.Login.alertError}
        />
      )}
      
      <div style={{ backgroundColor: "white" }}>
        <Container style={{ maxWidth: "none", paddingRight: "0px"}}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} md={8} style={{ backgroundColor: "white" }}>
              <form
                onSubmit={handleSubmit(sendFormLogin)}
                style={{ marginTop: 20 }}
                noValidate
              >
               <img className={classes.logoBlue}  src={LogoWhite} alt="logo-white" />
                               <Grid container className={classes.leftColumn} justifyContent="center">
                  <Grid item xs={12} md={5}>
                    <Grid container>
                      <Grid item xs={12} align="left">
                        <Typography variant="h1">{text.Login.title}</Typography>
                      </Grid>
                      <Grid item xs={12} align="left">
                        <Typography style={{ fontSize: 18 }}>
                          {text.Login.description}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 30 }}>
                        <TextFieldCustom
                          label={text.Login.emailLabel}
                          type="email"
                          ref={register({
                            required: text.General.required,
                            validate: {
                              formatErrorEmail,
                            },
                          })}
                          defaultValue=""
                          name="email"
                          error={errors.email}
                        />
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: 20 }}>
                        <TextFieldCustom
                          variant="outlined"
                          label={text.Login.passwordLabel}
                          type="password"
                          ref={register({ required: text.General.required })}
                          defaultValue=""
                          name={"password"}
                          error={errors.password}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        align="center"
                        style={{ marginTop: 20 }}
                      >
                        <Typography
                          style={{ cursor: "pointer" }}
                          onClick={() => history.push("forgot-password")}
                        >
                          {text.Login.passwordForgotten}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{ marginTop: 10, marginBottom: 10 }}
                      >
                        <CommonButton
                          loading={loadingApi}
                          text={text.Login.cta}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
              <div className="bottomLeftText">
                <Typography style={{ color: "#AEB4BE" }}>
                  © Info Renov {new Date().getFullYear()}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4} className={classes.rightColumn}>
             
                <img className={classes.logo} src={LogoWhite} alt="logo-white" />
            
              <img
               className={classes.illustration}
                src={Img}
                alt="person-writing"
                style={{width:"100%"}}
              />

<div className={classes.bottomRightText} >
                  <Typography  style={{ color: "#AEB4BE", paddingRight: 10 ,textDecoration:null}}>
                    <a className={classes.link} href="https://www.vigee.fr">{`www.vigee.fr `}</a><a className={classes.link} href="mailto:contact@vigee.fr">{` | contact@vigee.fr`}</a>
                  </Typography>
      
                </div>
              
             
             
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Login;
