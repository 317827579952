import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
} from '@material-ui/core';
import { changeStatusSupplierOrder, getSupplierOfThisSite } from '../../../../Axios/Call/AuthApi';
import AlertGeneral from '../../../../Components/Commons/AlertGeneral/AlertGeneral';
import text from '../../../../utils/text';
import FilterTextList from '../../../../Components/Commons/FilterTextList/FilterTextList';
import LoadingButton from '../../../../Components/Commons/LoadingButton/LoadingButton';
import { withStyles } from '@material-ui/core/styles';
import ModalSendContract from '../../../../Components/BusinessComponents/SuppliersOrder/ModalSendContract';
import { AiOutlineEye } from 'react-icons/ai';
import { supplierOrderStatus } from '../../../../utils/constant';
import CommonSelect from '../../../../Components/FormComponents/CommonSelect';
import StatusCommon from '../../../../Components/Commons/StatusCommon/StatusCommon';

const SupplierOrder = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [changeStatus, setChangeStatus] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const { siteId } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');

  useEffect(() => {
    getSupplierOfThisSite(siteId)
      .then((response) => {
        setLoadingApi(false);
        setSuppliers(response.data);
        setFilteredSuppliers(response.data);
      })
      .catch((error) => {
        setErrorApi(true);
        setLoadingApi(false);
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const StyledTableCell = withStyles((theme) => ({
    root: {
      backgroundColor: '#345369',
      fontSize: 14,
      fontFamily: ['roboto', '-apple-system'].join(','),
      color: 'white',
      wordWrap: 'break-word',
      border: 'none',
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const StyledTableSortLabel = withStyles((theme) => ({
    root: {
      color: 'white',
      '&:hover': {
        color: 'white',
      },
      '&$active': {
        color: 'white',
      },
    },
    active: {},
    icon: {
      color: 'inherit !important',
    },
  }))(TableSortLabel);

  const StyledTablePagination = withStyles((theme) => ({
    root: {
      color: '#345369',
    },
    menuItem: {
      fontFamily: ['roboto', '-apple-system'].join(','),
    },
    caption: {
      fontFamily: ['roboto', '-apple-system'].join(','),
    },
    selectIcon: {
      color: 'white',
    },
  }))(TablePagination);

  const onChangeStatusHandler = (status) => {
    changeStatusSupplierOrder(changeStatus, { status })
      .then((response) => {
        setSuppliers(
          suppliers.map((supplier) => (supplier.id === changeStatus ? { ...supplier, status: status } : supplier))
        );
        setFilteredSuppliers(
          suppliers.map((supplier) => (supplier.id === changeStatus ? { ...supplier, status: status } : supplier))
        );
        setChangeStatus(false);
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  return (
    <>
      {errorApi && <AlertGeneral color="danger" setTrigger={setErrorApi} description={text.General.alertError} />}
      <ModalSendContract
        openModal={openModal}
        setOpenModal={setOpenModal}
        onSent={(supplier) => {
          const updateSuppliers = (sup) =>
            sup.map((s) =>
              s.supplier_id === supplier.supplierId && s.quote_id === supplier.quoteId ? { ...s, status: 'sent' } : s
            );
          setSuppliers(updateSuppliers);
          setFilteredSuppliers(updateSuppliers);
        }}
      />
      <Container style={{ maxWidth: 'none' }}>
        <Grid container>
          <Grid item xs={12} align="left">
            <Grid item xs={12} md={6} align="left" style={{ marginTop: 20 }}>
              <FilterTextList allItems={suppliers} setFiltered={setFilteredSuppliers} />
            </Grid>
            <Grid item xs={12}>
              {loadingApi ? (
                <LoadingButton />
              ) : (
                <Grid container justifyContent="center" style={{ marginTop: 20 }}>
                  <Grid item xs={12} md={12}>
                    <TableContainer>
                      <Table style={{ width: '100%' }}>
                        <TableHead className="tableBackground">
                          <TableRow>
                            <StyledTableCell align="left">
                              <StyledTableSortLabel
                                active={orderBy === 'company'}
                                direction={orderBy === 'company' ? order : 'asc'}
                                onClick={createSortHandler('company')}
                              >
                                {'Fournisseur'}
                              </StyledTableSortLabel>
                            </StyledTableCell>

                            <StyledTableCell align="left">{text.SupplierList.contact}</StyledTableCell>
                            <StyledTableCell align="left">{text.SupplierList.email}</StyledTableCell>
                            <StyledTableCell align="left">{text.SupplierList.address}</StyledTableCell>
                            <StyledTableCell align="left">{'Statut'}</StyledTableCell>
                            <StyledTableCell align="left">{text.SupplierList.phone}</StyledTableCell>
                            <StyledTableCell align="left">Numéro de devis</StyledTableCell>
                            <StyledTableCell align="left">Numéro de commande</StyledTableCell>
                            <StyledTableCell align="center">{text.ArticleList.action}</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {stableSort(filteredSuppliers, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((supplier) => (
                              <StyledTableRow key={supplier.id}>
                                <TableCell component="th" scope="row" align={'left'}>
                                  <Typography component="span">{supplier?.supplier?.company}</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {`${supplier?.supplier?.name} ${supplier?.supplier?.surname}`}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">{`${supplier?.supplier?.email}`}</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {`${supplier?.supplier?.address}, `}
                                    {`${supplier?.supplier?.postal_code}, `}
                                    {`${supplier?.supplier?.city}`}
                                  </Typography>
                                </TableCell>

                                <TableCell align="left">
                                  {supplier.id === changeStatus ? (
                                    <CommonSelect
                                      field={{
                                        value: supplier.status,
                                        onChange: (status) => onChangeStatusHandler(status),
                                      }}
                                      options={supplierOrderStatus}
                                    />
                                  ) : (
                                    <Typography component="span">
                                      <StatusCommon
                                        setChangeStatus={setChangeStatus}
                                        itemId={supplier.id}
                                        status={supplier.status}
                                      />
                                    </Typography>
                                  )}
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">{`${supplier?.supplier?.mobile}`}</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography component="span">{supplier?.quote?.name}</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography component={'span'}>n°{supplier.order_number}</Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <div
                                    style={{
                                      display: 'flex',
                                    }}
                                  >
                                    <AiOutlineEye
                                      onClick={() =>
                                        setOpenModal({
                                          supplierId: supplier.supplier_id,
                                          quoteId: supplier.quote_id,
                                          status: supplier.status,
                                        })
                                      }
                                      style={{ cursor: 'pointer' }}
                                      size={20}
                                    />
                                  </div>
                                </TableCell>
                              </StyledTableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <StyledTablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={suppliers.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage={text.General.rowXPage}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SupplierOrder;
