import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { useState } from 'react';
import EmailIcon from '@material-ui/icons/Email';

function SendEmailDialog({ open, onClose, sendEmail }) {
  const [email, setEmail] = useState('');
  const [sending, setSending] = useState(false);

  const handleClose = () => {
    onClose();
    setEmail('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email) {
      return;
    }

    setSending(true);
    sendEmail(email);
    setSending(false);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Envoyer par email</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginTop: '-10px' }}>
          Entrez l'email auquel vous souhaitez envoyer le plan
        </DialogContentText>
        <form id="send-document-form" onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            fullWidth
            label="Email (plusieurs séparés par une , )"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Fermer</Button>
        <Button
          startIcon={<EmailIcon />}
          type="submit"
          form="send-document-form"
          disabled={!email || sending}
          variant="contained"
          color="primary"
        >
          Envoyer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SendEmailDialog;
