import React, { useEffect, useState } from 'react';
import SideMenu from '../../../Components/Commons/SideMenu';
import {
  Container,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
} from '@material-ui/core';
import AlertGeneral from '../../../Components/Commons/AlertGeneral/AlertGeneral';
import text from '../../../utils/text';
import {
  deleteArticle,
  editArticleApi,
  getArticleCategory,
  getArticles,
  getOneArticle,
  getSupplier,
  getUnits,
  storeArticle,
} from '../../../Axios/Call/AuthApi';
import { withStyles } from '@material-ui/core/styles';
import { Articles } from '../../../utils/icons';
import CommonButton from '../../../Components/FormComponents/CommonButton';
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
import { AiFillEdit } from 'react-icons/ai';
import ModalAddArticles from './ModalAddArticle';
import ModalGeneral from '../../../Components/Commons/ModalGeneral/ModalGeneral';
import FilterTextList from '../../../Components/Commons/FilterTextList/FilterTextList';
import { useHistory } from 'react-router-dom';

const ArticlesList = () => {
  const [articles, setArticles] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [units, setUnits] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [errorApi, setErrorApi] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [editArticle, setEditArticle] = useState({});
  const [loadingApiAdd, setLoadingApiAdd] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [isNewArticle, setIsNewArticle] = useState(true);
  const history = useHistory();

  useEffect(() => {
    setLoadingApi(true);
    populateArticle();

    getUnits().then((response) => {
      setUnits(response.data);
    });

    getSupplier().then((response) => {
      setSuppliers(
        response.data.map((supplier) => {
          return { name: supplier.company || supplier.name, id: supplier.id };
        })
      );
    });

    getArticleCategory().then((response) => {
      setCategories(response.data);
    });

   
  }, []);

  const populateArticle = () => {
    getArticles()
      .then((response) => {
        setLoadingApi(false);
        setArticles(response.data);
        setFilteredArticles(response.data);
      })
      .catch((error) => {
        setLoadingApi(false);
        setErrorApi(true);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const StyledTableCell = withStyles((theme) => ({
    root: {
      backgroundColor: '#345369',
      fontSize: 14,
      fontFamily: ['roboto', '-apple-system'].join(','),
      color: 'white',
      wordWrap: 'break-word',
      border: 'none',
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const StyledTableSortLabel = withStyles((theme) => ({
    root: {
      color: 'white',
      '&:hover': {
        color: 'white',
      },
      '&$active': {
        color: 'white',
      },
    },
    active: {},
    icon: {
      color: 'inherit !important',
    },
  }))(TableSortLabel);

  const StyledTablePagination = withStyles((theme) => ({
    root: {
      color: '#345369',
    },
    menuItem: {
      fontFamily: ['roboto', '-apple-system'].join(','),
    },
    caption: {
      fontFamily: ['roboto', '-apple-system'].join(','),
    },
    selectIcon: {
      color: 'white',
    },
  }))(TablePagination);

  const onDeleteArticle = () => {
    deleteArticle(openModalDelete)
      .then((response) => {
        setArticles(articles.filter((article) => article.id !== openModalDelete));
        setOpenModalDelete(false);
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  const onCreateArticle = (data) => {
    setLoadingApiAdd(true);
    if (Object.keys(editArticle).length) {
      editArticleApi(editArticle.id, data)
        .then((response) => {
          setLoadingApiAdd(false);
          setOpenModalAdd(false);
          setEditArticle({});
          populateArticle();
        })
        .catch((error) => {
          setLoadingApiAdd(false);
          setErrorApi(true);
        });
    } else {
      storeArticle(data)
        .then((response) => {
          setLoadingApiAdd(false);
          setOpenModalAdd(false);
          populateArticle();
        })
        .catch((error) => {
          setLoadingApiAdd(false);
          setErrorApi(true);
        });
    }
  };

  const openModalNewArticle = (nouveau) => {
    setIsNewArticle(nouveau);
    setOpenModalAdd(true); // Ouvrir le modal
  }



  return (
    <>
      {errorApi && <AlertGeneral color="danger" setTrigger={setErrorApi} description={text.General.alertError} />}
      <ModalGeneral
        isOpenModal={openModalDelete}
        title={text.ArtisanList.deleteTitle}
        description={text.ArtisanList.deleteDescription}
        button1Text={'Oui'}
        button2Text={'Annuler'}
        onClick1={() => onDeleteArticle()}
        onClick2={() => setOpenModalDelete(false)}
        isPassword
      />
      <ModalAddArticles
          isNewArticle={isNewArticle}
          setIsNewArticle={setIsNewArticle}
        setOpenModalAdd={setOpenModalAdd}
        openModalAdd={openModalAdd}
        onCreateArticle={onCreateArticle}
        loadingApiAdd={loadingApiAdd}
        editArticle={editArticle}
        setEditArticle={setEditArticle}
        units={units}
        setUnits={setUnits}
        categories={categories}
        setCategories={setCategories}
        suppliers={suppliers}
        setSuppliers={setSuppliers}
        setErrorApi={setErrorApi}
      />
      <SideMenu>
        <Container style={{ maxWidth: 'none' }}>
          <Grid container style={{paddingTop:20}}>
            <Grid item align="left" style={{ paddingTop: 5 }}>
              <Articles size={38} fill="#345369" />
            </Grid>
            <Grid item align="left" style={{ paddingLeft: 10 }}>
              <Typography variant="h2" style={{ borderBottom: '2px solid #345369' }}>
                {text.ArticleList.title}
              </Typography>
            </Grid>
            <Grid item align="left" style={{ paddingLeft: 20 }}>
              <Typography
                variant="h2"
                style={{ cursor: 'pointer', color: '#AEB4BE' }}
                onClick={() => history.push('suppliers')}
              >
                {'Fournisseurs'}
              </Typography>
            </Grid>
            <Grid item xs={12} align="left">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} align="left" style={{ marginTop: 20 }}>
                  <FilterTextList allItems={articles} setFiltered={setFilteredArticles} />
                </Grid>
                <Grid item xs={12} md={4} align="left" style={{ marginTop: 20 }}>
                  <CommonButton
                    type="button"
                    text={text.ArticleList.ctaCreate}
                    onClick={() => openModalNewArticle(true)}
                    style={{ width: 200 }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {loadingApi ? (
                <LoadingButton />
              ) : (
                <Grid container justifyContent="center" style={{ marginTop: 20 }}>
                  <Grid item xs={12} md={12}>
                    <TableContainer>
                      <Table style={{ width: '100%' }}>
                        <TableHead className="tableBackground">
                          <TableRow>
                            <StyledTableCell align="left">
                              <StyledTableSortLabel
                                active={orderBy === 'company'}
                                direction={orderBy === 'company' ? order : 'asc'}
                                onClick={createSortHandler('company')}
                              >
                                {'Code'}
                              </StyledTableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align="left">{text.ArticleList.name}</StyledTableCell>
                            <StyledTableCell align="left">{text.ArticleList.furnisces}</StyledTableCell>
                            <StyledTableCell align="left">{text.ArticleList.categories}</StyledTableCell>
                            <StyledTableCell align="left">{text.ArticleList.subCategories}</StyledTableCell>
                            <StyledTableCell align="left">{text.ArticleList.Coeff}</StyledTableCell>
                            <StyledTableCell align="left">{text.ArticleList.sellPrice}</StyledTableCell>
                            <StyledTableCell align="left">{text.ArticleList.buyingPrice}</StyledTableCell>
                            <StyledTableCell align="left">{text.ArticleList.margin}</StyledTableCell>
                            <StyledTableCell align="center">{text.ArticleList.action}</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {stableSort(filteredArticles, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((article) => (
                              <StyledTableRow key={article.id}>
                                <TableCell component="th" scope="row" align={'left'}>
                                  <Typography component="span">{article?.code}</Typography>
                                </TableCell>
                                <TableCell component="th" scope="row" align={'left'} style={{maxWidth:"500px"}}>
                                  <Typography component="span">{article?.description}</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">{article?.supplier?.company}</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {`${article?.article_sub_category?.article_category?.name}`}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">{`${article?.article_sub_category?.name}`}</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">{`${article?.margin_price} €`}</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">{`${article?.selling_price} €`}</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">{`${article?.buy_price} €`}</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">{`${article?.margin_percentage}%`}</Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <AiFillEdit
                                      size={24}
                                      color="#345369"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        getOneArticle(article.id)
                                          .then((response) => {
                                            setEditArticle(response.data);
                                            openModalNewArticle(false)
                                          })
                                          .catch((error) => {
                                            setErrorApi(true);
                                          });
                                      }}
                                    />
                                    {/*
                                    <AiFillDelete
                                      size={24}
                                      color="#900C3F"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        setOpenModalDelete(article.id)
                                      }
                                    />*/}
                                  </div>
                                </TableCell>
                              </StyledTableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <StyledTablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={articles.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage={text.General.rowXPage}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Container>
      </SideMenu>
    </>
  );
};

export default ArticlesList;
