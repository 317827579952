/**
 * https://github.com/Minishlink/physbook/blob/02a0d5d7ca0d5d2cc6d308a3a9b81244c63b3f14/app/Resources/public/js/app.js#L177
 *
 * @param  {String} base64String
 * @return {Uint8Array}
 */
export function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

/**
 * @returns {boolean}
 */
export function getBroserPushSupport() {
  if (!('serviceWorker' in navigator)) {
    console.log("Service workers aren't supported in this browser.");
    return false;
  }

  if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
    console.log("Notifications aren't supported.");
    return false;
  }
  if (Notification.permission === 'denied') {
    console.log('The user has blocked notifications.');
    return false;
  }
  if (!('PushManager' in window)) {
    console.log("Push messaging isn't supported.");
    return false;
  }

  return true;
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const randomString = (length = 8) => Math.random().toString(36).slice(-length);
