import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

function DeleteSiteDocumentDialog({ document, onClose, onDelete, deleting }) {
  return (
    <Dialog open={Boolean(document)} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Êtes-vous certain de vouloiir supprimer ce document ?</DialogTitle>
      <DialogContent>
        <DialogContentText>{document?.title}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button disabled={deleting} variant="contained" color="secondary" onClick={onDelete} startIcon={<Delete />}>
          Supprimer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteSiteDocumentDialog;
