import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
} from "@material-ui/core";
import text from "../../../utils/text";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import {
  changeInvoiceStatus,
  getAllInvoicesOfSite,
} from "../../../Axios/Call/AuthApi";
import { withStyles } from "@material-ui/core/styles";
import FilterTextList from "../../../Components/Commons/FilterTextList/FilterTextList";
import { useParams } from "react-router-dom";
import moment from "moment";
import StatusCommon from "../../Commons/StatusCommon/StatusCommon";
import CommonSelect from "../../FormComponents/CommonSelect";
import CommonDateTimePicker from "../../FormComponents/CommonDateTimePicker";
import ModalSendInvoice from "../Invoices/ModalSendInvoice";
import CommonButton from "../../FormComponents/CommonButton";
import ModalViewInvoice from "../Invoices/ModalViewInvoice";

const InvoiceList = () => {
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoice, setFilteredInvoices] = useState([]);
  const [totalAmount, setTotalAmount] = useState({});
  const [startingDate, setStartingDate] = useState(null);
  const [endingDate, setEndingDate] = useState(null);
  const [changeStatus, setChangeStatus] = useState(false);
  const [viewInvoice, setViewInvoice] = useState(0);
  //Modal state
  const [openModal, setOpenModal] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [changeStatusErrorApi, setChangeStatusErrorApi] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");
  const { siteId } = useParams();
  const statusOption = [
    { id: "draft", name: "BROUILLON" },
    { id: "paid", name: "PAYÉE" },
    { id: "not-paid", name: "APPROUVÉE" },
    { id: "in-retard", name: "EN RETARD" },
    { id: "sended", name: "ENVOYÉ" },
  ];

  useEffect(() => {
    setLoadingApi(true);
    getAllInvoicesOfSite(
      siteId,
      startingDate ? moment(startingDate).format("yyyy-MM-DD") : "",
      endingDate ? moment(endingDate).format("yyyy-MM-DD") : ""
    )
      .then((response) => {
        setLoadingApi(false);
        setInvoices(response.data.invoices);
        setFilteredInvoices(response.data.invoices);
        setTotalAmount(response.data);
      })
      .catch((error) => {
        setLoadingApi(false);
        setErrorApi(true);
      });
  }, [startingDate, endingDate, siteId]);

  const onChangeStatusHandler = (status) => {
    changeInvoiceStatus(changeStatus, { status: status })
      .then((response) => {
        setInvoices(
          invoices.map((invoice) =>
            invoice.id === changeStatus
              ? { ...invoice, status: status, is_validated: true }
              : invoice
          )
        );
        setFilteredInvoices(
          filteredInvoice.map((invoice) =>
            invoice.id === changeStatus
              ? { ...invoice, status: status, is_validated: true }
              : invoice
          )
        );
        setChangeStatus(false);
      })
      .catch((error) => {
        setChangeStatusErrorApi(true);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const StyledTableCell = withStyles((theme) => ({
    root: {
      backgroundColor: "#345369",
      fontSize: 14,
      fontFamily: ["roboto", "-apple-system"].join(","),
      color: "white",
      wordWrap: "break-word",
      border: "none",
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const StyledTableSortLabel = withStyles((theme) => ({
    root: {
      color: "white",
      "&:hover": {
        color: "white",
      },
      "&$active": {
        color: "white",
      },
    },
    active: {},
    icon: {
      color: "inherit !important",
    },
  }))(TableSortLabel);

  const StyledTablePagination = withStyles((theme) => ({
    root: {
      color: "#345369",
    },
    menuItem: {
      fontFamily: ["roboto", "-apple-system"].join(","),
    },
    caption: {
      fontFamily: ["roboto", "-apple-system"].join(","),
    },
    selectIcon: {
      color: "white",
    },
  }))(TablePagination);

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertError}
        />
      )}
      {changeStatusErrorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setChangeStatusErrorApi}
          description={"Impossible de revenir à un état antérieur"}
        />
      )}
      <ModalViewInvoice
        open={Boolean(viewInvoice)}
        onClose={() => setViewInvoice(0)}
        invoiceId={viewInvoice}
      />
      <ModalSendInvoice
        openModal={openModal}
        setOpenModal={setOpenModal}
        onInvoiceSent={(invoice) => {
          const _invoices = invoices.map((i) => ({
            ...i,
            status: i.id === invoice.id ? "sended" : i.status,
            is_validated: i.id === invoice.id ? true : i.is_validated,
          }));
          setInvoices(_invoices);
          setFilteredInvoices(_invoices);
        }}
      />
  
  <Grid container align="left">
       
      

            
            <Grid item xs={4} md={2} style={{ marginTop: 14, paddingLeft:20, maxWidth:'150px'}}>
              <CommonDateTimePicker
                name={"starting_date"}
                labelCustom={"Date de début"}
                labelColor={"#345369"}
                field={{
                  value: startingDate,
                  onChange: (date) => setStartingDate(date),
                }}
              />
            </Grid>
            
            <Grid item xs={4} md={2} style={{ marginTop: 14, paddingLeft:20, maxWidth:'150px' }}>
              <CommonDateTimePicker
                name={"ending_date"}
                labelCustom={"Date de fin"}
                labelColor={"#345369"}
                field={{
                  value: endingDate,
                  onChange: (date) => setEndingDate(date),
                }}
              />
            </Grid>

        
           
            
          <Grid
          item
              xs={12}
              md={8}
              align="right"
              style={{ marginTop: 10, fontWeight: 600,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", padding:20, paddingTop:22, borderRadius:10, marginLeft:'auto', marginRight:'auto', display:'block'  }}
            >
              <div style={{ display: "flex", justifyContent: "center"}}>
                <div style={{ display: "flex" }}>
                  <Typography>Total : </Typography>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      paddingLeft: 10,
                      paddingRight: 20,
                      fontSize: "14px",
                    }}
                  >
                    {totalAmount.total?.toFixed(2)} €
                  </Typography>
                </div>
                <div style={{ paddingLeft: 10, display: "flex" }}>
                  <Typography style={{ color: "#437F9D" }}>En Cours :</Typography>
                  <Typography
                    style={{
                      color: "#437F9D",
                      fontWeight: "bold",
                      paddingLeft: 10,
                      paddingRight: 20,
                      fontSize: "14px",
                    }}
                  >
                    {totalAmount.total_sended?.toFixed(2)} €
                  </Typography>
                </div>
                <div style={{ paddingLeft: 10, display: "flex" }}>
                  <Typography style={{ color: "#55A53A" }}>Payées :</Typography>
                  <Typography
                    style={{
                      color: "#55A53A",
                      fontWeight: "bold",
                      paddingLeft: 10,
                      paddingRight: 20,
                      fontSize: "14px",
                    }}
                  >
                    {totalAmount.total_paid?.toFixed(2)} €
                  </Typography>
                </div>
                <div style={{ paddingLeft: 10, display: "flex" }}>
                  <Typography style={{ color: "#DB3838" }}>
                    En Retard :
                  </Typography>
                  <Typography
                    style={{
                      color: "#DB3838",
                      fontWeight: "bold",
                      paddingLeft: 10,
                      paddingRight: 20,
                      fontSize: "14px",
                    }}
                  >
                    {totalAmount.total_en_retard?.toFixed(2)} €
                  </Typography>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} md={12} align="left" style={{ marginTop: 10 }}>
              <FilterTextList
                allItems={invoices}
                setFiltered={setFilteredInvoices}
              />
            </Grid>


     
        <Grid item xs={12}>
          {loadingApi ? (
            <LoadingButton />
          ) : (
            <Grid container justifyContent="center" style={{ marginTop: 20 }}>
              <Grid item xs={12} md={12}>
                <TableContainer>
                  <Table style={{ width: "100%" }}>
                    <TableHead className="tableBackground">
                      <TableRow>
                        <StyledTableCell align="left">
                          <StyledTableSortLabel
                            active={orderBy === "created_at"}
                            direction={orderBy === "created_at" ? order : "asc"}
                            onClick={createSortHandler("created_at")}
                          >
                            {text.invoiceList.date}
                          </StyledTableSortLabel>
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {text.invoiceList.number}
                        </StyledTableCell>
                        <StyledTableCell align="left">Devis</StyledTableCell>
                        <StyledTableCell align="left">
                          {text.invoiceList.client}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {text.invoiceList.site}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {text.quoteList.price}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {text.quoteList.status}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {text.quoteList.action}
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stableSort(
                        filteredInvoice,
                        getComparator(order, orderBy)
                      )
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((invoice) => (
                          <StyledTableRow key={invoice.id}>
                            <TableCell
                              component="th"
                              scope="row"
                              align={"left"}
                            >
                              <Typography component="span">
                                {moment(invoice?.created_at).format(
                                  "DD/MM/YYYY"
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align={"left"}
                            >
                              <Typography component="span">
                                {invoice?.invoice_number || invoice?.name}
                              </Typography>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              align={"left"}
                            >
                              <Typography component="span">
                                {invoice?.quote?.name}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography component="span">
                                {`${invoice?.site?.client?.name} ${invoice?.site?.client?.surname}`}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography component="span">
                                {invoice?.site?.name}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography component="span">
                                {invoice?.total_amount_no_tva?.toFixed(2)} €
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {invoice.id === changeStatus ? (
                                <CommonSelect
                                  field={{
                                    value: invoice.status,
                                    onChange: (status) =>
                                      onChangeStatusHandler(status),
                                  }}
                                  options={statusOption}
                                />
                              ) : (
                                <Typography component="span">
                                  <StatusCommon
                                    setChangeStatus={setChangeStatus}
                                    itemId={invoice.id}
                                    status={invoice.status}
                                  />
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {["draft", "approved"].includes(
                                invoice.status
                              ) ? (
                                <CommonButton
                                  onClick={() => setOpenModal(invoice.id)}
                                  text={"Générer"}
                                />
                              ) : (
                                <CommonButton
                                  text={text.General.view}
                                  onClick={() => setViewInvoice(invoice.id)}
                                />
                              )}
                            </TableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <StyledTablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={invoices.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={text.General.rowXPage}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default InvoiceList;
