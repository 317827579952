import React, { useState, useEffect } from 'react';
import SideMenu from '../../../Components/Commons/SideMenu';
import { Container, Grid, Typography } from '@material-ui/core';
import text from '../../../utils/text';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Informations from './Sections/Informations';
import Dashboard from './Sections/Dashboard';
import { getAllSites, showSite } from '../../../Axios/Call/AuthApi';
import AlertGeneral from '../../../Components/Commons/AlertGeneral/AlertGeneral';
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
import InvoiceAndQuote from './Sections/InvoiceAndQuote';
import HeaderSections from '../../../Components/Commons/HeaderSections/HeaderSections';
import { ArrowBack } from '@material-ui/icons';

const SiteDetail = ({ children }) => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [site, setSite] = useState({});
  const [section, setSection] = useState('information');
  const history = useHistory();
  const location = useLocation();
  const { siteId } = useParams();

  const sections = [
    {
      action: 'dashboard',
      name: text.SiteDetail.dashboard,
      redirect: `/site-detail/${siteId}/dashboard`,
    },
    {
      action: 'planning',
      name: text.SiteDetail.planning,
      redirect: `/site-detail/${siteId}/planning`,
    },
    {
      action: 'information',
      name: text.SiteDetail.information,
      redirect: `/site-detail/information/${siteId}`,
    },
    {
      action: 'text-editor',
      name: text.SiteDetail.textEditor,
      redirect: `/site-detail/${siteId}/text-editor`,
    },
    {
      action: 'contracts',
      name: text.SiteDetail.contracts,
      redirect: `/site-detail/${siteId}/contracts`,
    },
    {
      action: 'invoice',
      name: text.SiteDetail.invoiceAndQuote,
      redirect: `/site-detail/${siteId}/invoice-quote`,
    },
    {
      action: 'plan',
      name: text.SiteDetail.plan,
      redirect: `/site-detail/${siteId}/project-plan`,
    },
    {
      action: 'artisans',
      name: text.SiteDetail.artisans,
      redirect: `/site-detail/${siteId}/artisans`,
    },
    {
      action: 'suppliers',
      name: text.SiteDetail.suppliers,
      redirect: `/site-detail/${siteId}/suppliers`,
    },
    {
      action: 'incidents',
      name: text.SiteDetail.incidents,
      redirect: `/site-detail/${siteId}/incidents`,
    },
  ];

  useEffect(() => {
    if (siteId) {
      setLoadingApi(true);
      showSite(siteId)
        .then((response) => {
          setLoadingApi(false);
          setSite(response.data);
        })
        .catch((error) => {
          setLoadingApi(false);
          setErrorApi(true);
        });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname) {
      if (location.pathname.includes('information')) {
        setSection('information');
      }
      if (location.pathname.includes('invoice-quote')) {
        setSection('invoice');
      }
      if (location.pathname.includes('planning')) {
        setSection('planning');
      }
      if (location.pathname.includes('dashboard')) {
        setSection('dashboard');
      }
      if (location.pathname.includes('project-plan')) {
        setSection('plan');
      }
      if (location.pathname.includes('text-editor')) {
        setSection('text-editor');
      }
      if (location.pathname.includes('artisans')) {
        setSection('artisans');
      }
      if (location.pathname.includes('suppliers')) {
        setSection('suppliers');
      }
      if (location.pathname.includes('incidents')) {
        setSection('incidents');
      }
      if (location.pathname.includes('contracts')) {
        setSection('contracts');
      }
    }
  }, [location.pathname]);

  return (
    <>
      {errorApi && <AlertGeneral color="danger" setTrigger={setErrorApi} description={text.General.alertError} />}
      <SideMenu>
        <Container style={{ maxWidth: 'none', paddingTop:20 }}>
          <Grid container>
            <Grid item xs={12} align="left" style={{ marginBottom: 20 }}>
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    cursor: 'pointer',
                  }}
                  onClick={() => history.push('/sites')}
                >
                  <ArrowBack />
                </div>
                <Typography variant="h2" style={{ paddingLeft: 10 }}>
                  {Object.keys(site).length ? site.name : text.SiteDetail.titleNew}
                </Typography>
              </div>
            </Grid>
            <HeaderSections
              setSection={setSection}
              section={section}
              sections={sections}
              variant="span"
              isBlocked={!siteId}
            />
          </Grid>
          {loadingApi ? (
            <LoadingButton />
          ) : (
            <>
              {React.cloneElement(children, {
                site: site,
              })}
            </>
          )}
        </Container>
      </SideMenu>
    </>
  );
};

export default SiteDetail;
