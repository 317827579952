import React, { useCallback, useEffect, useState } from 'react';
import SideMenu from '../../../Components/Commons/SideMenu';
import {
  Container,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import text from '../../../utils/text';
import { Clients } from '../../../utils/icons';
import CommonButton from '../../../Components/FormComponents/CommonButton';
import ModalAddClient from './ModalAddClient';
import {
  createClient,
  deleteClient,
  editClientApi,
  getBusinessForClient,
  getClients,
} from '../../../Axios/Call/AuthApi';
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
import AlertGeneral from '../../../Components/Commons/AlertGeneral/AlertGeneral';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { AiFillEdit, AiFillDelete } from 'react-icons/ai';
import ModalGeneral from '../../../Components/Commons/ModalGeneral/ModalGeneral';
import FilterTextList from '../../../Components/Commons/FilterTextList/FilterTextList';
import DeleteClientDialog from './DeleteClientDialog';

const ClientList = () => {
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [editClient, setEditClient] = useState({});
  const [section, setSection] = useState('prospect'); //prospect //old-client
  const [commercials, setCommercials] = useState([]);
  const [conducteurs, setConducteurs] = useState([]);
  const [assistants, setAssistants] = useState([]);
  const [clients, setClients] = useState([]);
  const [filteredClient, setFilteredClient] = useState([]);
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [loadingApiAdd, setLoadingApiAdd] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [errorEmail, setErrorEmail] = useState('');
  const [clientPendingDelete, setClientPendingDelete] = useState(0);

  useEffect(() => {
    getBusinessForClient()
      .then((response) => {
        setCommercials(
          response?.data?.commercials?.map((commercial) => {
            return {
              id: commercial.id,
              name: `${commercial.name} ${commercial.surname}`,
            };
          })
        );
        setAssistants(
          response?.data?.assistants?.map((commercial) => {
            return {
              id: commercial.id,
              name: `${commercial.name} ${commercial.surname}`,
            };
          })
        );
        setConducteurs(
          response?.data?.conducteurs?.map((commercial) => {
            return {
              id: commercial.id,
              name: `${commercial.name} ${commercial.surname}`,
            };
          })
        );
      })
      .catch((error) => {
        setErrorApi(true);
      });
  }, []);

  const populateTable = useCallback(() => {
    setLoadingApi(true);
    getClients(section)
      .then((response) => {
        setClients(response.data);
        setFilteredClient(response.data);
        setLoadingApi(false);
      })
      .catch(() => {
        setErrorApi(true);
        setLoadingApi(false);
      });
  }, [section]);

  useEffect(() => {
    populateTable();
  }, [section, populateTable]);

  const onCreateNewClient = (data) => {
    if (Object.keys(editClient).length) {
      editClientApi(editClient.id, {
        ...data,
        recontact_at: moment(data.recontact_at).format('yyyy-MM-DD'),
      })
        .then((response) => {
          setLoadingApiAdd(false);
          populateTable();
          setOpenModalAdd(false);
          setEditClient({});
        })
        .catch((error) => {
          setLoadingApiAdd(false);
          if (error.response && error.response.status === 400) {
            setErrorEmail(text.General.emailTooken);
          } else {
            setErrorApi(true);
          }
        });
    } else {
      setLoadingApiAdd(true);
      createClient({
        ...data,
        recontact_at: moment(data.recontact_at).format('yyyy-MM-DD'),
      })
        .then((response) => {
          setLoadingApiAdd(false);
          populateTable();
          setOpenModalAdd(false);
        })
        .catch((error) => {
          setLoadingApiAdd(false);
          if (error.response && error.response.status === 400) {
            setErrorEmail(text.General.emailTooken);
          } else {
            setErrorApi(true);
          }
        });
    }
  };

  const onDeleteClient = () => {
    deleteClient(openModalDelete)
      .then((response) => {
        setOpenModalDelete(false);
        populateTable();
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const StyledTableCell = withStyles((theme) => ({
    root: {
      backgroundColor: '#345369',
      fontSize: 14,
      fontFamily: ['roboto', '-apple-system'].join(','),
      color: 'white',
      wordWrap: 'break-word',
      border: 'none',

    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const StyledTableSortLabel = withStyles((theme) => ({
    root: {
      color: 'white',
      '&:hover': {
        color: 'white',
      },
      '&$active': {
        color: 'white',
      },
    },
    active: {},
    icon: {
      color: 'inherit !important',
    },
  }))(TableSortLabel);

  const StyledTablePagination = withStyles((theme) => ({
    root: {
      color: '#345369',
    },
    menuItem: {
      fontFamily: ['roboto', '-apple-system'].join(','),
    },
    caption: {
      fontFamily: ['roboto', '-apple-system'].join(','),
    },
    selectIcon: {
      color: 'white',
    },
  }))(TablePagination);

  return (
    <>
      {errorApi && <AlertGeneral color="danger" setTrigger={setErrorApi} description={text.General.alertError} />}
      <DeleteClientDialog
        open={Boolean(clientPendingDelete)}
        onClose={() => setClientPendingDelete(0)}
        clientId={clientPendingDelete}
        onError={() => setErrorApi(true)}
        onSuccess={(clientId) => {
          const updateClients = (_clients) => _clients.filter((c) => c.id !== clientId);
          setClients(updateClients);
          setFilteredClient(updateClients);
        }}
      />
      <ModalGeneral
        isOpenModal={openModalDelete}
        title={text.ClientList.deleteTitle}
        description={text.ClientList.deleteDescription}
        button1Text={'Oui'}
        button2Text={'Annuler'}
        onClick1={() => onDeleteClient()}
        onClick2={() => setOpenModalDelete(false)}
        isPassword
      />
      <ModalAddClient
        setOpenModalAdd={setOpenModalAdd}
        openModalAdd={openModalAdd}
        onCreateNewClient={onCreateNewClient}
        loadingApiAdd={loadingApiAdd}
        editClient={editClient}
        setEditClient={setEditClient}
        commercials={commercials}
        assistants={assistants}
        conducteurs={conducteurs}
        errorEmail={errorEmail}
      />
      <SideMenu>
        <Container style={{ maxWidth: 'none' }}>
          <Grid container style={{paddingTop:20}}>
            <Grid item align="left" style={{ paddingTop: 0 }}>
              <Clients size={38} fill="#345369" />
            </Grid>
            <Grid item align="left" style={{ paddingRight: 40, paddingLeft:20}}>
              <Typography
                variant="h2"
                style={{
                  color: section === 'prospect' ? '#345369' : '#AEB4BE',
                  cursor: 'pointer',
                  borderBottom: section === 'prospect' ? '2px solid #345369' : 'none',
                  fontSize: '25px',
                }}
                onClick={() => setSection('prospect')}
              >
                {text.ClientList.titleTwo}
              </Typography>
            </Grid>
            <Grid item align="left" style={{ paddingLeft: 10 }}>
              <div style={{ display: 'flex' }}>
                <Typography
                  className="my-auto"
                  variant="h2"
                  style={{
                    color: section === 'client' ? '#345369' : '#AEB4BE',
                    cursor: 'pointer',
                    borderBottom: section === 'client' ? '2px solid #345369' : 'none',
                    fontSize: '25px',
                  }}
                  onClick={() => setSection('client')}
                >
                  {text.ClientList.title}
                </Typography>
                <div className="my-auto" style={{ paddingLeft: 10 }}>
                  <Typography
                    style={{
                      color: section === 'client' ? '#345369' : '#AEB4BE',
                      cursor: 'pointer',
                      fontSize: '12px',
                      marginLeft: -5,
                    }}
                    onClick={() => setSection('client')}
                  >
                    ( En cours )
                  </Typography>
                </div>
              </div>
            </Grid>
            
            <Grid item align="left" style={{ paddingLeft: 40 }}>
              <div style={{ display: 'flex' }}>
                <Typography
                  className="my-auto"
                  variant="h2"
                  style={{
                    color: section === 'old-client' ? '#345369' : '#AEB4BE',
                    cursor: 'pointer',
                    borderBottom: section === 'old-client' ? '2px solid #345369' : 'none',
                    fontSize: '25px',
                  }}
                  onClick={() => setSection('old-client')}
                >
                  {text.ClientList.title}
                </Typography>
                <div className="my-auto" style={{ paddingLeft: 10 }}>
                  <Typography
                    style={{
                      color: section === 'old-client' ? '#345369' : '#AEB4BE',
                      cursor: 'pointer',
                      fontSize: '12px',
                      marginLeft: -5,
                    }}
                    onClick={() => setSection('old-client')}
                  >
                    ( Terminés )
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} align="left">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} align="left" style={{ marginTop: 20 }}>
                  <FilterTextList allItems={clients} setFiltered={setFilteredClient} />
                </Grid>
                {section !== 'old-client' && (
                  <Grid item xs={12} md={4} align="left" style={{ marginTop: 20 }}>
                    <CommonButton
                      type="button"
                      text={section === 'client' ? text.ClientList.ctaCreate : text.ClientList.ctaCreateTwo}
                      onClick={() => setOpenModalAdd(true)}
                      style={{ width: 200 }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {loadingApi ? (
                <LoadingButton />
              ) : (
                <Grid container justifyContent="center" style={{ marginTop: 20 }}>
                  <Grid item xs={12} md={12}>
                    <TableContainer>
                      <Table style={{ width: '100%' }}>
                        <TableHead className="tableBackground">
                          <TableRow>
                            <StyledTableCell align="left">
                              <StyledTableSortLabel
                                active={orderBy === 'company'}
                                direction={orderBy === 'company' ? order : 'asc'}
                                onClick={createSortHandler('company')}
                              >
                                {text.ClientList.listName}
                              </StyledTableSortLabel>
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              <StyledTableSortLabel
                                active={orderBy === 'surname'}
                                direction={orderBy === 'surname' ? order : 'asc'}
                                onClick={createSortHandler('surname')}
                              >
                                {text.ClientList.listContact}
                              </StyledTableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell   align="left" >Adresse</StyledTableCell>
                            <StyledTableCell align="left">
                              <StyledTableSortLabel
                                active={orderBy === 'email'}
                                direction={orderBy === 'email' ? order : 'asc'}
                                onClick={createSortHandler('email')}
                              >
                                {text.ClientList.listEmail}
                              </StyledTableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <StyledTableSortLabel
                                active={orderBy === 'phone'}
                                direction={orderBy === 'phone' ? order : 'asc'}
                                onClick={createSortHandler('phone')}
                              >
                                {text.ClientList.listPhone}
                              </StyledTableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align="left">{text.ClientList.commercial}</StyledTableCell>
                            <StyledTableCell align="left">{text.ClientList.conducteur}</StyledTableCell>
                            <StyledTableCell align="left">{text.ClientList.assistant}</StyledTableCell>
                            <StyledTableCell align="left">{text.ClientList.listPay}</StyledTableCell>
                            <StyledTableCell align="left">{text.ClientList.listWaiting}</StyledTableCell>
                            <StyledTableCell align="center">{text.ClientList.listActions}</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {stableSort(filteredClient, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((client) => (
                              <StyledTableRow key={client.id}>
                                <TableCell component="th" scope="row" align={'left'}>
                                  <Typography component="span">
                                    {client?.client_profile?.company ?? `${client.name} ${client.surname}`}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">{`${client.name} ${client.surname}`}</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {client?.client_profile?.house_number} {client?.client_profile?.address} ,
                                    {client?.client_profile?.city} ,{client?.client_profile?.postal_code}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">{client.email}</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">{client?.phone}</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {
                                      client?.client_assignation?.find(
                                        (assignation) => assignation.type === 'commercial'
                                      )?.business?.name
                                    }
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {
                                      client?.client_assignation?.find(
                                        (assignation) => assignation.type === 'conducteur'
                                      )?.business?.name
                                    }
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {
                                      client?.client_assignation?.find(
                                        (assignation) => assignation.type === 'assistant'
                                      )?.business?.name
                                    }
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">{client?.paid?.toFixed(2)} €</Typography>
                                </TableCell>
                                <TableCell align="left">{client?.waiting?.toFixed(2)} €</TableCell>
                                <TableCell align="right">
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                    }}
                                  >
                                    <Tooltip title="Éditer">
                                      <IconButton
                                        size="small"
                                        edge="start"
                                        onClick={() => {
                                          setEditClient(client);
                                          setOpenModalAdd(true);
                                        }}
                                        color="primary"
                                      >
                                        <AiFillEdit size={24} />
                                      </IconButton>
                                    </Tooltip>

                                    <Tooltip
                                      title={
                                        client.sites_count === 0
                                          ? 'Supprimer'
                                          : "Le client est lié à des chantiers en cours, veuillez supprimer d'abord les chantiers associés"
                                      }
                                    >
                                      <span>
                                        <IconButton
                                          disabled={client.sites_count > 0}
                                          size="small"
                                          edge="start"
                                          onClick={() => setClientPendingDelete(client.id)}
                                          color="secondary"
                                        >
                                          <AiFillDelete size={24} />
                                        </IconButton>
                                      </span>
                                    </Tooltip>

                                    {/*
                                    <AiFillDelete
                                      size={24}
                                      color="#900C3F"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        setOpenModalDelete(client.id)
                                      }
                                    />*/}
                                  </div>
                                </TableCell>
                              </StyledTableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <StyledTablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={clients.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage={text.General.rowXPage}
                      
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Container>
      </SideMenu>
    </>
  );
};

export default ClientList;
