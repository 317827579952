import { Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Box } from '@material-ui/core';
import { useState } from 'react';

function ViewArtisanOrderQuoteDialog({ order, onClose }) {
  const [loading, setLoading] = useState(true);

  const handleClose = () => {
    onClose();
    setLoading(true);
  };

  return (
    <Dialog open={Boolean(order) && Boolean(order.artisan_quote_url)} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>{order?.artisan_quote?.title}</DialogTitle>
      <DialogContent>
        {loading && (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight={600}>
            <CircularProgress size={40} />
          </Box>
        )}
        {order && order.artisan_quote && order.artisan_quote_url && (
          <iframe
            src={order.artisan_quote_url}
            title={order.artisan_quote.title}
            onLoad={() => setLoading(false)}
            style={{
              display: loading ? 'none' : 'block',
              width: '100%',
              minHeight: '80vh',
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ViewArtisanOrderQuoteDialog;
