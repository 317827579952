import React from "react";
import { Checkbox, Grid, Typography } from "@material-ui/core";

const CheckBoxCustom = ({ field, options, name, labelName, error }) => {
  return (
    <>
      <Grid item xs={12} style={{ paddingTop: 5 }}>
        <Typography>{labelName}</Typography>
      </Grid>
      {options.map((option) => {
        return (
          <>
            <Grid key={option.id} style={{ paddingLeft: 10 }}>
              <Checkbox
                checked={field.value}
                onChange={(event) => field.onChange(event.target.checked)}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </Grid>
            <Grid style={{ paddingLeft: 5, marginTop: 10 }}>
              <Typography>{option.name}</Typography>
            </Grid>
          </>
        );
      })}
      {error?.message && (
        <Grid item xs={12}>
          <Typography style={{ fontSize: 12, color: "red" }}>
            {error?.message}
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default CheckBoxCustom;
