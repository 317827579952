import { useEffect, useRef } from "react";
import { Grid, Typography, Modal } from "@material-ui/core";
import text from "../../../utils/text";
import { Business, Cross } from "../../../utils/icons";
import classes from "./BusinessesList.module.css";
import TextFieldCustom from "../../../Components/FormComponents/TextFieldCustom";
import { useForm, Controller } from "react-hook-form";
import CommonButton from "../../../Components/FormComponents/CommonButton";
import {
  formatErrorEmail,
  formatErrorLetters,
  formatErrorPhoneNumber,
} from "../../../utils/formValidation";
import RadioGroupCustom from "../../../Components/FormComponents/RadioGroupCustom";
import CommonDateTimePicker from "../../../Components/FormComponents/CommonDateTimePicker";
import moment from "moment";

const ModalAddBusinesses = ({
  setOpenModalAdd,
  openModalAdd,
  onCreateNewBusiness,
  loadingApiAdd,
  editBusiness,
  setEditBusiness,
  roles,
  errorEmail,
}) => {
  const typeRef = useRef(null);
  const { register, handleSubmit, errors, reset, control, watch, setError } =
    useForm({
      mode: "onBlur",
    }); // initialise the hook

  const createNewBusiness = (data) => {
    onCreateNewBusiness(data);
  };

  useEffect(() => {
    if (Object.keys(editBusiness).length) {
      reset({ ...editBusiness });
    } else {
      reset({});
    }
  }, [editBusiness]);

  useEffect(() => {
    if (errorEmail) {
      setError("email", {
        message: text.General.emailTooken,
      });
      if (typeRef.current) {
        typeRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }
  }, [errorEmail]);

  return (
    <Modal open={openModalAdd} size={"lg"}>
      <Grid container className={"modalWrapperWeb"}>
        <div
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            cursor: "pointer",
          }}
          onClick={() => {
            setOpenModalAdd(false);
            setEditBusiness({});
          }}
        >
          <Cross />
        </div>
        <Grid item align="left" style={{ paddingTop: 10 }}>
          <Business fill="#345369" size={38} />
        </Grid>
        <Grid item align="left" style={{ paddingLeft: 10, marginTop: 10 }}>
          <Typography variant="h2">
            {Object.keys(editBusiness).length
              ? text.BusinessesList.modalTitleEdit
              : text.BusinessesList.modalTitle}
          </Typography>
        </Grid>
        <Grid item xs={12} align="left" style={{ marginTop: 10 }}>
          <form
            onSubmit={handleSubmit(createNewBusiness)}
            style={{ marginTop: 20 }}
            noValidate
          >
            <Grid container spacing={3}>
              {roles.length && (
                <Grid
                  item
                  xs={12}
                  align="left"
                  style={{ marginTop: 10 }}
                  ref={typeRef}
                >
                  <Controller
                    onFocus={() => {
                      if (typeRef.current) {
                        typeRef.current.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                          inline: "nearest",
                        });
                      }
                    }}
                    render={(field) => (
                      <RadioGroupCustom
                        field={field}
                        options={roles}
                        name="role_id"
                        labelName={text.BusinessesList.role}
                        error={errors.role_id}
                        isInt
                      />
                    )}
                    name="role_id"
                    defaultValue={null}
                    control={control}
                    rules={{ required: text.General.required }}
                  />
                </Grid>
              )}
              <Grid item xs={12} align="left" style={{ marginTop: 10 }}>
                <Typography variant="h3">
                  {text.BusinessesList.sectionOne}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} align="left" style={{ marginTop: 5 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={text.BusinessesList.email}
                  type="input"
                  ref={register({
                    required: text.General.required,
                    validate: { formatErrorEmail: formatErrorEmail },
                  })}
                  defaultValue=""
                  name={"email"}
                  error={errors.email}
                />
              </Grid>
              <Grid item xs={12} md={6} align="left" style={{ marginTop: 5 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={text.BusinessesList.mobile}
                  type="input"
                  ref={register({
                    required: text.General.required,
                    validate: {
                      formatErrorPhoneNumber: formatErrorPhoneNumber,
                    },
                  })}
                  defaultValue=""
                  name={"mobile"}
                  error={errors.mobile}
                />
              </Grid>
              <Grid item xs={12} md={4} align="left" style={{ marginTop: 5 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={text.BusinessesList.name}
                  type="input"
                  ref={register({
                    required: text.General.required,
                    validate: { formatErrorLetters: formatErrorLetters },
                  })}
                  defaultValue=""
                  name={"name"}
                  error={errors.name}
                />
              </Grid>
              <Grid item xs={12} md={4} align="left" style={{ marginTop: 5 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={text.BusinessesList.surname}
                  type="input"
                  ref={register({
                    required: text.General.required,
                    validate: { formatErrorLetters: formatErrorLetters },
                  })}
                  defaultValue=""
                  name={"surname"}
                  error={errors.surname}
                />
              </Grid>
              <Grid item xs={12} md={4} align="left" style={{ marginTop: -10 }}>
                <Controller
                  render={(field) => (
                    <CommonDateTimePicker
                      field={field}
                      labelCustom={"Date de naissance"}
                      name={"dob"}
                    />
                  )}
                  control={control}
                  name="dob"
                  defaultValue={moment()}
                  rules={{}}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={12} md={4} align="center">
                    <CommonButton
                      loading={loadingApiAdd}
                      text={
                        Object.keys(editBusiness).length
                          ? text.BusinessesList.ctaEdit
                          : text.BusinessesList.ctaCreate
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ModalAddBusinesses;
