import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import CommonButton from '../../../../Components/FormComponents/CommonButton';
import { editTextEditor } from '../../../../Axios/Call/AuthApi';
import { useParams } from 'react-router-dom';
import AlertGeneral from '../../../../Components/Commons/AlertGeneral/AlertGeneral';
import text from '../../../../utils/text';
import { Editor } from '@tinymce/tinymce-react';

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    boxShadow: '0 1px 4px 0 #353F6A14',
    padding: '4px 0',
  },
  toolbar: {
    border: 'none',
    borderBottom: '2px solid #EFF0F3',
    borderRadius: 0,
    paddingBottom: '4px',
  },
  editor: {
    borderRadius: 0,
    padding: '0 8px',
  },
  buttonbox: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '22px',
  },
  valider: {
    borderRadius: '4px',
    marginLeft: '8px',
    backgroundColor: '#FD4953',
    color: '#FFFFFF',
    fontSize: '14px',
    padding: '8px 16.5px 10px',
    border: '1px solid #FD4953',
    mineight: '40px',
    cursor: 'pointer',
  },
  normalinred: {
    fontSize: '14px',
    lineHeight: '22px',
    verticalAlign: 'top',
    textAlign: 'right',
    color: '#FD4953',
    margin: 0,
    cursor: 'pointer',
  },
  boldinblue: {
    lineHeight: '22px',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#2A3254',
    textAlign: 'left',
    verticalAlign: 'top',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
  biginblue: {
    color: '#2A3254',
    fontSize: '26px',
    fontWeight: 'bold',
    textAlign: 'left',
    verticalAlign: 'top',
    margin: '0',
    lineHeight: '32px',
  },
  normalinblue: {
    lineHeight: '22px',
    fontSize: '14px',
    color: '#353F6A',
    textAlign: 'left',
    verticalAlign: 'bottom',
    margin: 0,
  },
}));



const TextEditor = ({ site }) => {
  console.log(site)
  const [editorState, seteditorState] = useState();
  const [errorApi, setErrorApi] = useState(false);
  const [successApi, setSuccessApi] = useState(false);

  const { siteId } = useParams();
  const styles = useStyles();


  const editorRef = useRef();


  // converting into b64

  function getBase64(file, callback) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
  
    reader.onerror = (error) => {};
  
  }

  // callback to upload the image

  function uploadFile(file) {
    return new Promise((resolve, reject) => {
      getBase64(file, (data) => resolve({ data: { link: data } }));
    });
  }

  const sendLetter = (data) => {

    editTextEditor(siteId, { text_editor_description: data })
      .then((response) => {
        setSuccessApi(true);
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };


  useEffect(() => {


  }, [site]);


  
  return (
    <>
      {errorApi && <AlertGeneral color="danger" setTrigger={setErrorApi} description={text.General.alertError} />}
      {successApi && <AlertGeneral color="success" setTrigger={setSuccessApi} description={'Données enregistrées'} />}
      <Grid container style={{ marginTop: 10 }}>
        <>
          <Editor
              apiKey='fld5u9fcbwquf3jf2n2yea8lslfeer9k99tml0d7qg8ychpc'
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue={site.text_editor_description}
              init={{
                language: 'fr_FR',
                height: 500,
                width: '100%',
                menubar: true,
                plugins: [
                  'image',
                    'numlist',
                    'bullist'
                ],
                /* enable title field in the Image dialog*/
                image_title: true,
                /* enable automatic uploads of images represented by blob or data URIs*/
                automatic_uploads: true,
                /*
                  URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
                  images_upload_url: 'postAcceptor.php',
                  here we add custom filepicker only to Image dialog
                */
                file_picker_types: 'image',
                /* and here's our custom image picker*/
                file_picker_callback: function (cb, value, meta) {
                  var input = document.createElement('input');
                  input.setAttribute('type', 'file');
                  input.setAttribute('accept', 'image/*');

                  /*
                    Note: In modern browsers input[type="file"] is functional without
                    even adding it to the DOM, but that might not be the case in some older
                    or quirky browsers like IE, so you might want to add it to the DOM
                    just in case, and visually hide it. And do not forget do remove it
                    once you do not need it anymore.
                  */

                  input.onchange = function () {
                    var file = this.files[0];

                    var reader = new FileReader();
                    reader.onload = function () {
                      /*
                        Note: Now we need to register the blob in TinyMCEs image blob
                        registry. In the next release this part hopefully won't be
                        necessary, as we are looking to handle it internally.
                      */
                      var id = 'blobid' + (new Date()).getTime();
                      var blobCache =  window.tinymce.activeEditor.editorUpload.blobCache;
                      var base64 = reader.result.split(',')[1];
                      var blobInfo = blobCache.create(id, file, base64);
                      blobCache.add(blobInfo);

                      /* call the callback and populate the Title field with the file name */
                      cb(blobInfo.blobUri(), { title: file.name });
                    };
                    reader.readAsDataURL(file);
                  };

                  input.click();
                },
                toolbar: 'formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | image | bullist numlist outdent indent | ',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }}
          />
        </>
      <Grid item xs={12} style={{marginBottom:5}}>
        <CommonButton
          onClick={() => sendLetter(editorRef.current.getContent())}
          text={'Enregistrer'}
        />
        </Grid>

        <Grid item xs={12}>

        </Grid>
      </Grid>
    </>
  );
};

export default TextEditor;
