import { useState, useEffect, useMemo, Fragment } from 'react';
import { Grid, Typography, Avatar, Tooltip } from '@material-ui/core';
import { useWindowWidth } from '@react-hook/window-size';
import logo from '../../Assets/Images/logo-white.png';
import { ChevronRight} from '@material-ui/icons';
import { AiOutlineMenu } from "react-icons/ai";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import classes from './SideMenu.module.css';
import { BsArrowBarLeft, BsArrowBarRight } from "react-icons/bs";
import * as actionCreator from '../../store/action/index';
import {
  Notification,
  Business,
  Artisan,
  Clients,
  Dashboard,
  Site,
  Settings,
  Articles,
  Selling,
  Disconnect,
} from '../../utils/icons';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { changeNotification, getNotifications, getConfiguration } from '../../Axios/Call/AuthApi';

const SideMenu = ({ children }) => {
  const [checked, setchecked] = useState(true);
  const [hide, setHide] = useState(false);
  const [openedTabs, setOpenedTabs] = useState([]);
  const [findLogo, setFindLogo] = useState(null);
  const userData = useSelector((state) => state.user.userData);
  const clientMode = useSelector((state) => state.dashboard.clientMode);
  const [openNotification, setOpenNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [news, setNews] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();



  const [menuItems, setMenuItems] = useState([
    {
      id: 0,
      name: 'Dashboard',
      redirect: '/dashboard',
      is_active: 'dashboard',
    },
    {
      id: 1,
      name: 'Clients',
      redirect: '/clients',
      is_active: 'clients',
    },
    {
      id: 2,
      name: 'Chantiers',
      redirect: '/sites',
      is_active: 'site',
    },
    {
      id: 3,
      name: 'Ventes',
      redirect: '/selling',
      is_active: 'selling',
    },
    {
      id: 4,
      name: 'Articles',
      redirect: '/articles',
      is_active: 'articles',
    },
    {
      id: 5,
      name: 'Artisans',
      redirect: '/artisans',
      is_active: 'artisans',
    },
    {
      id: 6,
      name: 'Utilisateurs',
      redirect: '/businesses',
      is_active: 'businesses',
    },
    {
      id: 7,
      name: 'Configuration',
      redirect: '/configuration',
      is_active: 'configuration',
    },
  ]);
  const width = useWindowWidth();
  const history = useHistory();

  const renderIcon = (redirect) => {
    if (redirect === '/dashboard') {
      return (
        <Dashboard
          fill={
            location.pathname.includes('dashboard') && !location.pathname.includes('site-detail') ? 'white' : '#AEB4BE'
          }


        />
      );

    }
    if (redirect === '/sites') {
      return <Site fill={location.pathname.includes('site') ? 'white' : '#AEB4BE'} />;
    }
    if (redirect === '/articles') {
      return <Articles fill={location.pathname.includes('article') ? 'white' : '#AEB4BE'} />;
    }
    if (redirect === '/businesses') {
      return <Business fill={location.pathname.includes('businesses') ? 'white' : '#AEB4BE'} />;
    }
    if (redirect === '/clients') {
      return <Clients fill={location.pathname.includes('clients') ? 'white' : '#AEB4BE'} />;
    }
    if (redirect === '/artisans') {
      return (
        <Artisan
          fill={
            location.pathname.includes('artisans') && !location.pathname.includes('site-detail') ? 'white' : '#AEB4BE'
          }
        />
      );
    }
    if (redirect === '/configuration') {
      return <Settings fill={location.pathname.includes('configuration') ? 'white' : '#AEB4BE'} />;
    }
    if (redirect === '/selling') {
      return <Selling fill={location.pathname.includes('selling') ? 'white' : '#AEB4BE'} />;
    }

  };

  const onClickItemHandler = (item) => {
    if (item?.subSection?.length) {
      if (!openedTabs.find((openTab) => openTab === item.id)) {
        setOpenedTabs(openedTabs.concat(item.id));
      } else {
        setOpenedTabs(openedTabs.filter((openTab) => openTab !== item.id));
      }
    } else {
      history.push(item.redirect);
    }
  };

  const onLogoutHandler = () => {
    dispatch(actionCreator.cleanUserData());
    history.push('/');
  };

  useEffect(() => {
    getConfiguration().then((response) => {
      setFindLogo(response.data.logo);
    });
  }, []);

  useEffect(() => {
    getNotifications().then((response) => {
      setNotifications(response.data);
    });

    const interval = setInterval(
      () => getNotifications().then((response) => setNotifications(response.data)),
      60 * 1000
    );
    return () => clearInterval(interval);
  }, []);

  const onClickNotificationHandler = (item) => {
    changeNotification(item.id).then();

    setNotifications(
      notifications.map((notification, index) =>
        notification.id === item.id ? { ...notification, isNew: false, key:index } : notification

      )
    );

    if (item.link) {
      history.push(item.link);
    }
  };

  useEffect(() => {
    if (width < 600)
    setHide(true);
    else if (width > 600)
    setHide(false);

  }, [width]);

  const hasNewNotifications = useMemo(() => notifications.filter((n) => n.isNew).length > 0, [notifications]);

  return (
    <>
      <Grid container style={{backgroundColor: "#F8F9FA"}}>
        <Grid
          className={checked && width < 600 ? classes.sideMenuWrapper : classes.sideMenuWrapper}
          style={{ width: width < 600 ? '45px' : '240px' && hide===false ? "240px" : "45px" }}

        >
          <Grid container style={{ margin: '6px 0 30px 0' }}>
            <Grid item xs={10} sm={12} align="center">
              <img
                src={findLogo || logo}
                alt="logo"
                style={{ width: !hide ? "150px" : "0px", maxHeight: 120, cursor: 'pointer' }}
                onClick={() => dispatch(actionCreator.changeClientMode())}
              />
            </Grid>
            {clientMode && (
              <Grid item xs={10} sm={12} align="center">
                <Typography style={{ color: 'white' }}>Mode Client</Typography>
              </Grid>
            )}
            <Grid item xs={2} align="left" >
              <div
                className={`${!checked && width < 600 ? classes.display : classes.none
                  }`}
                onClick={() => setchecked(!checked)}
              >
                <AiOutlineMenu size="30" color="white" />
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            style={{
              padding: '0px 10px 0px 10px',
            }}
          >


            <Grid container style={{ marginBottom: 20, backgroundColor: '#F8F8F9', padding:hide ? 0 : 10, borderRadius:5,paddingLeft:!hide && 10,paddingRight:!hide && 10, width:'auto', minWidth:hide ? 0 : '220px'}} >
              
              <Avatar
                src={''}
                style={{
                  color: '#FFF',
                  backgroundColor: '#345369',
                  cursor: 'pointer',
                  height:'25px',
                  width:'25px', 
                  marginTop: hide ? 7 : 2,
            
                }}
                onClick={() => history.push('/profile')}
              />
              <div className="my-auto" style={{ paddingLeft: 5, paddingRight: 10, minWidth:"110px" }}>
              {hide===false &&  <Typography
       
                  style={{ color: '#345369', cursor: 'pointer', fontWeight:600 }}
                  onClick={() => history.push('/profile')}
                >
                  {userData.name} {userData.surname?.substring(0, 1)}
                </Typography>}
              </div>

              <div
                style={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  cursor: 'pointer',
                  position: 'relative',
                  paddingLeft: hide ? 0 : 30,
                  paddingTop: 5,
            
                }}
                onClick={(event) =>
                  setOpenNotification(
                    openNotification ? false : notifications.length ? event.currentTarget : false
                  )
                }
              >
                <Notification fill={'#345369'} />
                {hasNewNotifications ? (
                  <div
                    style={{
                      position: 'absolute',
                      width: 10,
                      height: 10,
                      backgroundColor: '#FD4953',
                      top: 2,
                      right: 0,
                      borderRadius: 100,
                    }}
                  />
                ) : null}
              </div>
             
              <Menu
                id="simple-menu"
                anchorEl={openNotification}
                keepMounted
                open={Boolean(openNotification)}
                onClose={() => setOpenNotification(false)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                style={{ marginTop: 30 }}
              >
                {notifications?.map((item, index) => {
                  return (
                    <MenuItem
                      onClick={() => onClickNotificationHandler(item)}
                      key={index}
                      style={{
                        whiteSpace: 'normal',
                        width: 350,
                        borderBottom: '1px solid #D7D9E1',
                        padding: 15,
                        borderLeft: item.isNew ? '2px solid #5E35B1' : '',
                      }}
                    >
                      <Typography
                        component="span"
                        style={{
                          cursor: 'pointer',
                          color: item.isNew ? '#2A3254' : '#868CA6',
                        }}
                      >
                        {item.text}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Menu>

            </Grid>













            {menuItems.map((item, index) => {
              return (
                <Fragment key={index}>
                  <Grid item xs={12} >
                    <Grid
                      container
                      className={
                        location.pathname.includes(item.is_active)
                          ? classes.itemMenuContainerSelected
                          : classes.itemMenuContainer
                      }
                      onClick={() => onClickItemHandler(item)}
                    >
                      <Grid item xs={2} className="my-auto">
                        {renderIcon(item.redirect)}
                      </Grid>
                      <Grid item className="my-auto" align="left" style={{ position: 'relative' }}>
                        <div
                          style={{ cursor: 'pointer' }}
                          className={
                            (location.pathname.includes(item.is_active) &&
                              !location.pathname.includes('site-detail')) ||
                              (item.is_active === 'site' && location.pathname.includes('site-detail'))
                              ? classes.isSelectedText
                              : classes.IsNotSelectedText
                          }

                        >
                          <div className={`${!hide ? classes.display : classes.label_none}`}>
                            {item.name}
                          </div>
                        </div>
                        {news[item.news_name] > 0 && (
                          <div
                            style={{
                              position: 'absolute',
                              backgroundColor: 'red',
                              width: 13,
                              height: 13,
                              borderRadius: 100,
                              top: -5,
                              right: -15,
                              justifyContent: 'center',
                              textAlign: 'center',
                            }}
                          >
                            <Typography
                              style={{
                                color: 'white',
                                fontSize: 11,
                                fontWeight: 'bolder',
                              }}
                            >
                              {news[item.news_name]}
                            </Typography>
                          </div>
                        )}
                      </Grid>
                      {item?.subSection?.length && (
                        <Grid item xs={2} className="my-auto">
                          {openedTabs.find((openTab) => openTab === item.id) ? (
                            <KeyboardArrowDownIcon style={{ marginTop: 2 }} />
                          ) : (
                            <ChevronRight style={{ marginTop: 2 }} />
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item>
                    {openedTabs.find((openTab) => openTab === item.id)
                      ? item.subSection.map((section, index) => {
                        return (
                          <Grid container key={index} className={classes.subMenuContainer}>

                            <Grid item align="left">
                              <Typography
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  history.push(section.redirect);
                                }}
                              >
                                {section.name}
                              </Typography>
                            </Grid>
                          </Grid>
                        );
                      })
                      : null}
                  </Grid>
                </Fragment>
              );
            })}

            <Tooltip title='Réduire le menu'>
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "fixed",
                  marginTop: "5rem",
                  left: "200px",
                  bottom: "1rem",

                }}
              >

                <BsArrowBarLeft
                  size={30}
                  style={{ fill: "#FFFFFF60", cursor: 'pointer' }}
                  onClick={() => setHide(true)}
                  className={`${hide===false && width > 600 ? classes.display : classes.label_none
                    }`}
                />

              </Grid>
            </Tooltip>



          </Grid>

          <Tooltip title='Agrandir le menu'>
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                position: "fixed",
                bottom: "4rem",
                left: ".6rem",
              }}
            >
              <BsArrowBarRight
                size={30}
                style={{ fill: "#FFFFFF60", cursor: 'pointer' }}
                onClick={() => setHide(false)}
                className={`${hide===false || width < 600 ? classes.none : classes.display}`}
              />
            </Grid>
          </Tooltip>





          <Grid
            item
            xs={12}
            style={{
              position: width < 720 ? 'none' : 'fixed',
              bottom: 10,
              
              paddingLeft: width < 720 ? 11 : 20,
             
              marginBottom: width < 720 ? 20 : "0.7rem",
              marginTop: width < 720 ? 30 : 0,
            }}
          >
       
              <Disconnect />


              <Typography
                onClick={() => onLogoutHandler()}
                component="span"
                style={{
                  color: '#DDD',
                  cursor: 'pointer',
                  paddingLeft:10,
                  fontSize: '16px',
                  fontWeight: 500,
                  
                }}
                className={`${hide===false ? classes.display : classes.label_none}`}
              >
                Déconnexion
              </Typography>
     
          </Grid>
        </Grid>
        {checked && (
        
        
              <div
                style={{
                  backgroundColor: "#F8F9FA",
                  marginRight: width > 600 & !hide ? "0" : "-190px",
                  width: width < 600 ? width-45 : !hide ?  width - 240 : width - 45,
                  
                  marginLeft:!hide ? '240px' : '45px',
                }}
              >
                {children}
              </div>
        
       
        )}
      </Grid>
    </>
  );
};

export default SideMenu;
