import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useState } from 'react';
import { contracts } from '../../../Axios/Call/AuthApi';
import { AiOutlineUpload } from 'react-icons/ai';

function SignContractDialog({ open, onClose, onSigned, onError, contractId, file }) {
  const [loading, setLoading] = useState(false);

  const handleUpload = async () => {
    setLoading(true);
    try {
      const response = await contracts.sign(contractId, file);
      onSigned(response.data);
      onClose();
    } catch {
      if (typeof onError === 'function') {
        onError();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Voulez-vous uploader le document signé ?</DialogTitle>
      <DialogContent>
        {file && (
          <embed src={URL.createObjectURL(file)} type="application/pdf" style={{ width: '100%', height: '90vh' }} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<AiOutlineUpload size={24} />}
          onClick={handleUpload}
          disabled={loading}
        >
          Uploader
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SignContractDialog;
