import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
  TableBody,
  Grid,
  Box,
  Typography,
  CircularProgress,
  IconButton,
  Button,
  Tooltip,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { contracts } from '../../../Axios/Call/AuthApi';
import text from '../../../utils/text';
import AlertGeneral from '../../Commons/AlertGeneral/AlertGeneral';
import moment from 'moment';
import CreateContractDialog from './EditContractDialog';
import SiteContractViewDialog from './SiteContractViewDialog';
import EmailIcon from '@material-ui/icons/Email';
import { AiFillDelete, AiOutlineEye, AiOutlineUpload } from 'react-icons/ai';
import DeleteContractDialog from './DeleteContractDialog';
import SendContractDialog from './SendContractDialog';
import SignContractDialog from './SignContractDialog';

/**
 *
 * @param {string} template
 * @returns string
 */
export function formatTemplateName(template) {
  const mapping = {
    reception_travaux: 'proces verbal de réception des travaux',
    overture_de_chantier: 'Décision d’ouverture de chantier',
    contrat_travaux_sans_annexe: 'Contrat Travaux Sans Annexe',
    attest_prop: 'Attestation de propriété',
    tva_reduite: 'TVA Reduite',
    retraction: 'Retractation',
    reserves: 'Reserves',
    levee_reserves: 'Levee Reserves',
  };

  if (mapping[template]) {
    return mapping[template];
  }

  return template.replaceAll('_', ' ');
}

const StyledTableCell = withStyles((theme) => ({
  root: {
    backgroundColor: '#345369',
    fontSize: 14,
    fontFamily: ['roboto', '-apple-system'].join(','),
    color: 'white',
    wordWrap: 'break-word',
    border: 'none',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function getContractStatus(contract) {
  if (!contract.sent_on) {
    return 'Brouillon';
  }

  if (!contract.signed_on) {
    return 'Envoyé au client le ' + moment(contract.sent_on).format('DD/MM/YYYY');
  }
  return `Signé le ${moment(contract.signed_on).format('DD/MM/YYYY')}`;
}

/**
 *
 * @param {Object} props
 * @param {number} props.siteId
 * @returns {ReactComponent}
 */
function SiteContractList({ siteId }) {
  const [contractList, setContractList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [viewModal, setViewModal] = useState('');
  const [deleteModal, setDeleteModal] = useState(0);
  const [sendContract, setSendContract] = useState(0);
  const [pendingSignature, setPendingSignature] = useState(null);

  useEffect(() => {
    if (siteId) {
      setLoading(true);
      contracts
        .index(siteId)
        .then((res) => {
          setContractList(res.data.contracts);
          setTemplateList(res.data.templates);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => setLoading(false));
    }
  }, [siteId]);

  return (
    <>
      <Box marginBottom={3}>
       
      </Box>
      <SignContractDialog
        open={Boolean(pendingSignature)}
        onClose={() => setPendingSignature(null)}
        contractId={pendingSignature?.id}
        onError={() => setError(true)}
        onSigned={(updatedContract) =>
          setContractList((l) => l.map((c) => (c.id === updatedContract.id ? updatedContract : c)))
        }
        file={pendingSignature?.file}
      />
      <SendContractDialog
        open={Boolean(sendContract)}
        onClose={() => setSendContract(0)}
        contractId={sendContract}
        onError={() => setError(true)}
        onSent={(updatedContract) =>
          setContractList((l) => l.map((c) => (c.id === updatedContract.id ? updatedContract : c)))
        }
      />
      <DeleteContractDialog
        open={Boolean(deleteModal)}
        onClose={() => setDeleteModal(0)}
        contractId={deleteModal}
        onDeleted={(id) => setContractList((l) => l.filter((c) => c.id !== id))}
        onError={() => setError(true)}
      />
      <SiteContractViewDialog open={Boolean(viewModal)} onClose={() => setViewModal('')} link={viewModal} />
      <CreateContractDialog
        open={createOpen}
        onClose={() => setCreateOpen(false)}
        templateList={templateList}
        onCreated={(newContract) => setContractList((c) => [...c, newContract])}
        siteId={siteId}
        onError={() => setError(true)}
      />
      {error && <AlertGeneral color="danger" setTrigger={setError} description={text.General.alertError} />}
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell width={'65%'}>Motif</StyledTableCell>
            <StyledTableCell width={'25%'}>État</StyledTableCell>
            <StyledTableCell>Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contractList.map((contract) => (
            <StyledTableRow key={contract.id}>
              <TableCell>{formatTemplateName(contract.template.type)}</TableCell>
              <TableCell>{getContractStatus(contract)}</TableCell>
              <TableCell padding="checkbox">
                <Box display={'flex'} justifyContent={'flex-end'}>
                  {contract.status === 'sent' && (
                    <Tooltip title="Signature manuelle">
                      <IconButton color="primary" size="small" component="label">
                        <AiOutlineUpload size={24} />
                        <input
                          type="file"
                          hidden
                          accept="application/pdf"
                          onChange={(e) => setPendingSignature({ file: e.target.files[0], id: contract.id })}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                  {contract.status === 'draft' && (
                    <>
                      <Tooltip title="Envoyer par email">
                        <IconButton size="small" color="primary" onClick={() => setSendContract(contract.id)}>
                          <EmailIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  <Tooltip title="Voir">
                    <IconButton color="primary" size="small" onClick={() => setViewModal(contract.file_link)}>
                      <AiOutlineEye size={24} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Supprimer">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setDeleteModal(contract.id);
                      }}
                      color="secondary"
                    >
                      <AiFillDelete size={24} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </TableCell>
            </StyledTableRow>
          ))}
          {(contractList.length === 0 || loading) && (
            <TableRow>
              <TableCell colSpan={5}>
                <Box height={200} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                  {loading ? <CircularProgress size={40} /> : <Typography variant="h6">Pas de contrats</Typography>}
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Box marginTop={2}>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              type={'button'}
              onClick={() => {
                setCreateOpen(true);
              }}
              variant="contained"
              color="primary"
            >
              NOUVEAU DOCUMENT
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default SiteContractList;
