import * as actionsTypes from './actionTypes';
import * as authApi from '../../Axios/Call/AuthApi';
import {authApiAxios} from '../../Axios/AxiosInstance';

export const populateUserData = () => {
    authApiAxios.defaults.headers.common['Authorization'] = localStorage.getItem("token");
    return(dispatch,state) => {
        authApi.me().then(response => {
            const userData = response.data
            // console.log(userData);
            dispatch(saveUserData(userData));
        }).catch( error => {
            dispatch(errorApi());
        })
    }
}

const saveUserData = (userData) => {
    return{
        type: actionsTypes.POPULATE_USER_DATA,
        userData: userData
    }
}

export const cleanUserData = () => {
    localStorage.removeItem('token');
    return{
        type: actionsTypes.CLEAN_USER_DATA,
    }
}
//---------------------------------- General function -------------------------------------------
const errorApi = () => {
    return{
        type: actionsTypes.ERROR_ON_API,
    }
}
