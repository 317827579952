import { useState, useEffect } from "react";
import SideMenu from "../../../Components/Commons/SideMenu";
import {
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import text from "../../../utils/text";
import { Dashboard } from "../../../utils/icons";
import {
  getAllSitesNoType,
  getBusinesses,
  showDashboard,
  getAllInvoices,
  getAllQuoteAllSites,
} from "../../../Axios/Call/AuthApi";
import FinancialComponent from "../../../Components/DashboardComponents/FinancialComponent";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";


const DashboardPage = () => {
  const [loadingApi, setLoadingApi] = useState(false);

  /// OBJECTS WE NEED FOR DASHBOARD
  const [sites, setSites] = useState([]);
  const [commerciaux, setCommerciaux] = useState([]);
  const [sitesList, setSitesList] = useState([]);
  const [conversion, setConversion] = useState(0);

  const [invoices, setInvoices] = useState([]);
  const [quotes, setQuotes] = useState([]);

  // GET SITES LIST AND COMMERCIALS LIST
  useEffect(() => {
    setLoadingApi(true);
    getBusinesses().then((res) => {
    setLoadingApi(false);
    setCommerciaux(res.data.filter((p) => p.role.name === "Commercial"))
  }
  );
    getAllSitesNoType().then((res) => {
      setLoadingApi(false);
      setSitesList(
        res?.data
          .filter((c) => c.status !== "not-signed")
          .sort((a, b) => a.name.localeCompare(b.name)) 
      );
    });
  }, []);

  // GET ALL DATA FROM API
  useEffect(() => {
    showDashboard().then((res) => {
      setLoadingApi(false);
      setSites(res.data);
    })

    getAllInvoices("all").then((res) => {
      setInvoices(res.data?.invoices.filter((invoice) => invoice.status != "draft"));
    });

    getAllQuoteAllSites("all").then((res) => {
      setQuotes(res.data.quotes.filter((quote) => quote.status != "draft"));
    });



  }, [sitesList, commerciaux]);


  return (
    <SideMenu>
      {loadingApi ? (
        <>
          <LoadingButton />
          <Typography variant="h6">Chargement du tableau de bord</Typography>
        </>
      ) : (
        <Container style={{ maxWidth: "none", backgroundColor: "#F8F9FA" }}>
          <Grid
            container
            spacing={2}
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            <div style={{ marginTop: "auto", marginBottom: "auto" }}>
              <Dashboard fill={"#345369"} size={28} />
            </div>
            <Typography variant="h5" style={{ paddingLeft: 10 }}>
              {text.SiteDetail.dashboard}
            </Typography>
          </Grid>

          {/* Inputs */}

         {sites && <FinancialComponent sites={sites} commerciaux={commerciaux} sitesList={sitesList} conversion={conversion} invoices={invoices} quotes={quotes}/>}

        </Container>
      )}
    </SideMenu>
  );
};

export default DashboardPage;
