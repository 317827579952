import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField,
  Box,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import { useState } from 'react';
import EuroIcon from '@material-ui/icons/Euro';
import { postArtisanOrderQuote } from '../../../Axios/Call/AuthApi';
import { toBase64 } from '../../../utils/utils';

function UploadArtisanQuoteDialog({ order, onClose, onUploaded = async () => {} }) {
  const [title, setTitle] = useState('');
  const [amount, setAmount] = useState(0);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const handleClose = () => {
    onClose();
    setAmount(0);
    setTitle('');
    setFile(null);
    setUploading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file || !amount || !title) {
      return;
    }

    setUploading(true);
    const base64file = await toBase64(file);
    await postArtisanOrderQuote(order.id, { title, amount, file: base64file });
    await onUploaded();
    handleClose();
  };

  return (
    <Dialog open={Boolean(order)} onClose={handleClose} maxWidth="sm" fullWidth  >
      <DialogTitle>Importer le devis de l'artisan</DialogTitle>
      <DialogContent>
        <DialogContentText style={{marginTop:"-10px"}}>Importer ici le devis de l'artisan correspondant au contrat de sous-traitance</DialogContentText>
        <form id="upload-artisan-quote-form" onSubmit={handleSubmit} noValidate>
          <TextField
            variant="outlined"
            label="Nom du document"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            margin="normal"
            fullWidth
          />
          <TextField
            type="number"
            variant="outlined"
            label="Montant (à insérer dans le contrat)"
            value={amount.toString()}
            onChange={(e) => setAmount(Number(e.target.value))}
            margin="normal"
          
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EuroIcon />
                </InputAdornment>
              ),
            }}
          />

          <Box marginTop={2} display="flex" justifyContent="space-between" alignItems="center">
            <Typography>{file ? file.name : 'Aucun document selectionné'}</Typography>
            <input
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
              accept="application/pdf"
              hidden
              id="file-input"
            />
            <Button variant="contained" color="primary" component="label" htmlFor="file-input">
              Sélectionner un document
            </Button>
          </Box>

          <Box marginTop={4} minHeight={1}>
            {uploading && <LinearProgress />}
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
        <Button
          startIcon={<CloudUpload />}
          variant="contained"
          color="primary"
          type="submit"
          form="upload-artisan-quote-form"
          disabled={!file || !amount || !title}
        >
          Envoyer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UploadArtisanQuoteDialog;
