import React, { useEffect, useState, useRef } from 'react';
import { Grid, Typography, Modal, Switch, TextField, Tooltip } from '@material-ui/core';
import text from '../../../utils/text';
import { AddPicture, Artisan, Cross } from '../../../utils/icons';
import classes from './ArtisanList.module.css';
import TextFieldCustom from '../../../Components/FormComponents/TextFieldCustom';
import { useForm, Controller } from 'react-hook-form';
import CommonButton from '../../../Components/FormComponents/CommonButton';
import {
  formatErrorEmail,
  formatErrorLetters,
  formatErrorPhoneNumber,
  formatErrorSiret,
} from '../../../utils/formValidation';
import CommonSelect from '../../../Components/FormComponents/CommonSelect';
import moment from 'moment';
import CommonDateTimePicker from '../../../Components/FormComponents/CommonDateTimePicker';
import { AiOutlineEye } from 'react-icons/ai';
import { getArtisanFile } from '../../../Axios/Call/AuthApi';

const ModalAddArtisan = ({
  setOpenModalAdd,
  openModalAdd,
  onCreateNewArtisan,
  loadingApiAdd,
  editArtisan,
  setEditArtisan,
  crafts,
  errorEmail,
}) => {
  const typeRef = useRef(null);
  const [documentations, setDocumentations] = useState([]);
  const [file, setFile] = useState(null);
  const [errorApi, setErrorApi] = useState(null);
  const inputFileRef = useRef(null);
  const downloadRef = useRef(null);
  //{name: '', file_path: '', base64: '', date: '', isEdit: true}
  const { register, handleSubmit, errors, reset, control, setError } = useForm({
    mode: 'onBlur',
  }); // initialise the hook

  const createNewArtisan = (data) => {
    onCreateNewArtisan({
      ...data,
      documentations: documentations.map((doc) => {
        return { ...doc, date: moment(doc.date).format('yyyy-MM-DD') };
      }),
    });
  };

  useEffect(() => {
    if (Object.keys(editArtisan).length) {
      reset({ ...editArtisan, ...editArtisan?.artisan_profile });
      setDocumentations(editArtisan.artisan_files || []);
    } else {
      reset({});
    }
  }, [editArtisan]);

  const addADocumentToList = () => {
    setDocumentations(
      documentations.concat({
        name: '',
        date: moment(),
        isEdit: true,
        base64: '',
      })
    );
  };

  const onSaveDocumentToBeAdded = (index) => {
    setDocumentations(
      documentations.map((documentMap, indexMap) =>
        indexMap === index
          ? {
              ...documentMap,
              isEdit: documentMap.name && (documentMap.base64 || documentMap.file_path) ? !documentMap.isEdit : true,
            }
          : { ...documentMap }
      )
    );
  };

  function fileToBase64(file) {
    return new Promise((res, rej) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onloadend = () => {
        res(fileReader.result);
      };
      fileReader.onerror = () => rej();
    });
  }

  const getArtisanFileHandler = (documentId, base64) => {
    if (base64) {
      setFile(base64);
    } else {
      getArtisanFile(documentId)
        .then((response) => {
          console.log(response.data);
          setFile(response.data);
        })
        .catch((error) => {
          setErrorApi(true);
        });
    }
  };

  useEffect(() => {
    if (file) {
      downloadRef?.current?.click();
      setFile();
    }
  }, [file]);

  useEffect(() => {
    if (errorEmail) {
      setError('email', {
        message: text.General.emailTooken,
      });
      if (typeRef.current) {
        typeRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    }
  }, [errorEmail]);

  return (
    <Modal open={openModalAdd} size={'xl'}>
      <Grid container className={'modalWrapperWeb'}>
        <div
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            cursor: 'pointer',
          }}
          onClick={() => {
            setOpenModalAdd(false);
            setEditArtisan({});
            setDocumentations([]);
          }}
        >
          <Cross />
        </div>
        <Grid item align="left" style={{ paddingTop: 5 }}>
          <Artisan size={38} fill="#345369" />
        </Grid>
        <Grid item align="left" style={{ paddingLeft: 10 }}>
          <Typography variant="h2">{text.ArtisanList.modalTitle}</Typography>
        </Grid>
        <Grid item xs={12} align="left" style={{ marginTop: 10 }}>
          <form onSubmit={handleSubmit(createNewArtisan)} style={{ marginTop: 20 }} noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12} align="left" style={{ marginTop: 10 }}>
                <Typography variant="h3">{text.ArtisanList.sectionOne}</Typography>
              </Grid>
              <Grid item xs={12} md={4} align="left" style={{ marginTop: -15 }}>
                <Controller
                  render={(field) => (
                    <CommonSelect name="craft_id" options={crafts} field={field} labelCustom={'Métier'} />
                  )}
                  control={control}
                  defaultValue={''}
                  rules={{ required: text.General.required }}
                  name="craft_id"
                />
              </Grid>
              <Grid item xs={12} md={2} align="left" style={{ marginTop: 5 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={'Effectifs'}
                  type="number"
                  ref={register({ required: text.General.required })}
                  defaultValue=""
                  name={'number_employee'}
                  error={errors.number_employee}
                />
              </Grid>
              <Grid item xs={12} md={4} align="left" style={{ marginTop: 5 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={'Chantiers simultanés'}
                  type="number"
                  ref={register({ required: text.General.required })}
                  defaultValue=""
                  name={'number_simulaneus_site'}
                  error={errors.number_simulaneus_site}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ fontWeight: 'bolder' }}>Blacklisté</Typography>
                <Controller
                  render={(field) => <Switch checked={field.value} onClick={() => field.onChange(!field.value)} />}
                  name="is_blacklisted"
                  defaultValue={false}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} md={4} align="left" style={{ marginTop: 5 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={text.ArtisanList.entreprise}
                  type="input"
                  ref={register({ required: text.General.required })}
                  defaultValue=""
                  name={'company'}
                  error={errors.company}
                />
              </Grid>
              <Grid item xs={12} md={4} align="left" style={{ marginTop: 5 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={text.ArtisanList.siret}
                  type="input"
                  ref={register({
                    //required: text.General.required,
                    //validate: { formatErrorSiret: formatErrorSiret },
                  })}
                  defaultValue=""
                  name={'siret'}
                  error={errors.siret}
                />
              </Grid>
              <Grid item xs={12} md={4} align="left" style={{ marginTop: 5 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={text.ArtisanList.tva}
                  type="input"
                  ref={register({
                    //required: text.General.required
                  })}
                  defaultValue=""
                  name={'tva'}
                  error={errors.tva}
                />
              </Grid>
              <Grid item xs={12} align="left" style={{ marginTop: 10 }}>
                <Typography variant="h3">{text.ArtisanList.sectionTwo}</Typography>
              </Grid>
              <Grid item xs={12} md={4} align="left" style={{ marginTop: 5 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={text.ArtisanList.name}
                  type="input"
                  ref={register({
                    required: text.General.required,
                    validate: { formatErrorLetters: formatErrorLetters },
                  })}
                  defaultValue=""
                  name={'name'}
                  error={errors.name}
                />
              </Grid>
              <Grid item xs={12} md={4} align="left" style={{ marginTop: 5 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={text.ArtisanList.surname}
                  type="input"
                  ref={register({
                    required: text.General.required,
                    validate: { formatErrorLetters: formatErrorLetters },
                  })}
                  defaultValue=""
                  name={'surname'}
                  error={errors.surname}
                />
              </Grid>
              <Grid item xs={12} md={4} align="left" style={{ marginTop: 5 }} ref={typeRef}>
                <TextFieldCustom
                  variant="outlined"
                  label={text.ArtisanList.email}
                  type="input"
                  ref={register({
                    required: text.General.required,
                    validate: { formatErrorEmail: formatErrorEmail },
                  })}
                  defaultValue=""
                  name={'email'}
                  error={errors.email}
                />
              </Grid>
              <Grid item xs={12} md={6} align="left" style={{ marginTop: 5 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={'Numéro et rue'}
                  type="input"
                  ref={register({ required: text.General.required })}
                  defaultValue=""
                  name={'address'}
                  error={errors.address}
                />
              </Grid>
              <Grid item xs={6} md={2} align="left" style={{ marginTop: 5 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={'Code postal'}
                  type="input"
                  ref={register({ required: text.General.required })}
                  defaultValue=""
                  name={'postal_code'}
                  error={errors.postal_code}
                />
              </Grid>
              <Grid item xs={6} md={4} align="left" style={{ marginTop: 5 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={'Ville'}
                  type="input"
                  ref={register({ required: text.General.required })}
                  defaultValue=""
                  name={'city'}
                  error={errors.city}
                />
              </Grid>

              <Grid item xs={12} md={6} align="left" style={{ marginTop: 5 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={text.ArtisanList.phone}
                  type="input"
                  ref={register({
                    //required: text.General.required,
                    //validate: {
                    //  formatErrorPhoneNumber: formatErrorPhoneNumber,
                    //},
                  })}
                  defaultValue=""
                  name={'phone'}
                  error={errors.phone}
                />
              </Grid>
              <Grid item xs={12} md={6} align="left" style={{ marginTop: 5 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={text.ArtisanList.mobile}
                  type="input"
                  ref={register({
                    //required: text.General.required,
                    //validate: {
                    //  formatErrorPhoneNumber: formatErrorPhoneNumber,
                    //},
                  })}
                  defaultValue=""
                  name={'mobile'}
                  error={errors.mobile}
                />
              </Grid>

              <Grid item xs={12} md={12} align="left" style={{ marginTop: 5 }}>
                <TextFieldCustom
                  variant="outlined"
                  label={text.ArtisanList.note}
                  type="input"
                  ref={register({})}
                  defaultValue=""
                  name={'note'}
                  error={errors.note}
                />
              </Grid>
              <Grid item align="left" style={{ marginTop: 20, paddingLeft: 20 }} xs={6}>
                <Typography variant="h3">{text.ArtisanList.sectionThree}</Typography>
              </Grid>
              <Grid item xs={6} align="right" style={{ marginTop: 20 }}>
                <CommonButton type="button" text={text.General.add} onClick={() => addADocumentToList()} />
              </Grid>
              {documentations?.map((document, index) => {
                return (
                  <>
                    <Grid item xs={6} md={3} style={{ marginTop: 15 }}>
                      {document.isEdit ? (
                        <>
                          <TextField
                            variant="outlined"
                            label={'Titre de document'}
                            name={'name'}
                            type={'input'}
                            value={document.name}
                            onChange={(event) => {
                              setDocumentations(
                                documentations.map((documentMap, indexMap) =>
                                  indexMap === index
                                    ? {
                                        ...documentMap,
                                        name: event.target.value,
                                      }
                                    : { ...documentMap }
                                )
                              );
                            }}
                            fullWidth
                            InputProps={{
                              className: `${classes.input} ${classes.label}`,
                            }}
                            size="small"
                          />
                        </>
                      ) : (
                        <Typography>{document.name}</Typography>
                      )}
                    </Grid>
                    <Grid item xs={6} md={3} style={{ marginTop: document.isEdit ? 0 : 15 }}>
                      {document.isEdit ? (
                        <CommonDateTimePicker
                          name={'date'}
                          labelCustom={'Date de validite'}
                          labelColor={'#345369'}
                          field={{
                            value: document.date,
                            onChange: (date) =>
                              setDocumentations(
                                documentations.map((documentMap, indexMap) =>
                                  indexMap === index ? { ...documentMap, date: date } : { ...documentMap }
                                )
                              ),
                          }}
                        />
                      ) : (
                        <Tooltip
                          title={moment(document.date).isBefore(moment()) ? 'Document expiré' : 'Document à jour'}
                        >
                          <Typography
                            style={{
                              color: moment(document.date).isBefore(moment()) ? 'red' : '',
                            }}
                          >
                            {moment(document.date).format('DD/MM/YYYY')}
                          </Typography>
                        </Tooltip>
                      )}
                    </Grid>
                    <Grid item xs={6} md={3}>
                      {(document.file_path || document.base64) && !document.isEdit ? (
                        <>
                          <AiOutlineEye
                            style={{ cursor: 'pointer', marginTop: 15 }}
                            onClick={() => getArtisanFileHandler(document.id, document.base64)}
                            size={20}
                          />
                          <a style={{ display: 'none' }} ref={downloadRef} href={file} download={'artisan_document'} />
                        </>
                      ) : (
                        <div onClick={() => inputFileRef?.current?.click()} style={{ cursor: 'pointer' }}>
                          <AddPicture fill="#345369" />
                        </div>
                      )}
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        ref={inputFileRef}
                        accept="image/png, image/jpeg, application/pdf"
                        onChange={async (e) => {
                          const b64 = await fileToBase64(e.target.files[0]);
                          setDocumentations(
                            documentations.map((documentMap, indexMap) =>
                              indexMap === index ? { ...documentMap, base64: b64 } : { ...documentMap }
                            )
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <CommonButton
                        type="button"
                        text={document.isEdit ? 'Enregister' : 'Modifier'}
                        onClick={() => onSaveDocumentToBeAdded(index)}
                      />
                    </Grid>
                  </>
                );
              })}
              <Grid item xs={12} md={12} style={{ marginTop: 10, marginBottom: 10 }}>
                <Grid container justifyContent="center" alignItems="center" style={{ marginTop: 20 }}>
                  <Grid item xs={12} md={4} align="center">
                    <CommonButton
                      loading={loadingApiAdd}
                      text={editArtisan ? text.ArtisanList.ctaEdit : text.ArtisanList.ctaCreate}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ModalAddArtisan;
