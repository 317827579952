import grey from '@material-ui/core/colors/grey';

export const scrollbarStyle = {
  '&::-webkit-scrollbar': {
    // General scrollbar
    width: 10,
    height: 10,
  },
  '&::-webkit-scrollbar-button': {
    // Side buttons
    width: 0,
    height: 0,
  },
  '&::-webkit-scrollbar-thumb': {
    // Scrollbar slider
    background: grey[300],
    borderRadius: 10,
  },
  '&::-webkit-scrollbar-thumb:hover': {
    // Slider hover
    background: grey[300],
  },
  '&::-webkit-scrollbar-thumb:active': {
    // Slider active
    background: grey[400],
  },
  '&::-webkit-scrollbar-track': {
    // Scrollbar track
    background: 'transparent',
    borderRadius: 10,
  },
  '&::-webkit-scrollbar-track:hover': {
    // Track hover
    background: grey[50],
  },
  '&::-webkit-scrollbar-track:active': {
    // Track active
    background: grey[50],
  },
  '&::-webkit-scrollbar-corner': {
    // Scrollbar corners where scrollbars meet
    background: 'transparent',
  },
};
