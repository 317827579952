const text = {
  General: {
    formatError: 'Format incorrect',
    emailNotMatching: 'Les emails ne correspondent pas',
    required: 'Champ obligatoire',
    continue: 'Continuer',
    emailTooken: 'Cet email est déjà utilisé',
    seeAll: 'Voir tout',
    forward: 'Suivant',
    alertGeneralError: 'Erreur de chargement des données',
    alertGeneralSuccess: 'Données chargées avec succès',
    finish: 'Terminer',
    yes: 'Oui',
    no: 'Non',
    add: 'Ajouter',
    modify: 'Modifier',
    anull: 'Annuler',
    errorDate: 'Date invalide',
    noRatingYet: "Pas encore d'évaluation",
    precedent: 'Précédent',
    register: 'Enregister',
    alertError: 'Connexion impossible',
    send: 'Envoyer par email',
    view: 'Voir',
    rowXPage: 'Nombre de lignes',
  },
  ErrorPage: {
    error403: "Vous n'avez pas les droits pour accéder à cette page",
    error404: "La page n'existe pas",
  },
  Login: {
    title: 'Espace admin',
    description: 'Connexion à votre espace Info Renov',
    passwordForgotten: 'Mot de passe oublié ?',
    passwordLabel: 'Mot de passe',
    emailLabel: 'E-mail',
    cta: 'Se connecter',
    alertError: 'Connexion impossible',
  },
  forgotPassword: {
    alertSuccess: 'Vous allez recevoir un email avec les instructions pour changer votre mot de passe.',
    title: 'Mot de passe oublié',
    description: 'Changer mon mot de passe',
    alreadyAccount: 'Vous avez déjà un compte ?',
    emailLabel: 'E-mail',
    cta: 'Mot de passe oublié',
  },
  changePassword: {
    alertSuccess: 'Votre mot de passe a été changé',
    title: 'Changer mon mot de passe',
    description: 'Créer un nouveau mot de passe',
    passwordLabel: 'Mot de passe',
    cta: 'Changer mon mot de passe',
  },
  Configuration: {
    title: 'Configuration',
  },
  ArtisanList: {
    title: 'Artisans',
    listArtisan: 'Artisan',
    listJob: 'Métier',
    listAddress: 'Adresse',
    listPhone: 'Téléphone',
    listPaye: 'Payé',
    ListWaiting: 'En attente',
    ListDocumentsToDate: 'Documents à jour',
    ctaCreate: 'CRÉER UN ARTISAN',
    ctaEdit: 'MODIFIER UN ARTISAN',
    modalTitle: 'Artisan',
    sectionOne: 'Entreprise',
    siret: 'SIRET',
    entreprise: 'Entreprise',
    tva: 'Numéro de TVA',
    sectionTwo: 'Coordonnées',
    sectionThree: 'Documents',
    name: 'Prénom du contact',
    surname: 'Nom du contact',
    job: 'Métier',
    email: 'E-mail',
    address: 'Adresse complète',
    phone: 'Téléphone fixe',
    mobile: 'Téléphone portable',
    note: 'Note',
    newDocs: 'Nouveau document',
    deleteTitle: 'Supprimer un artisan',
    deleteDescription: 'Êtes-vous sur de vouloir supprimer un artisan ? Cette opération est irreversible.',
  },
  SupplierList: {
    supplier: 'Fournisseurs',
    address: 'Adresse',
    phone: 'Téléphone',
    contact: 'Contact',
    email: 'E-mail',

    ctaCreate: 'Créer un fournisseur',
    ctaEdit: 'Sauvegarder',
    modalTitle: 'Fournisseur',
  },
  BusinessesList: {
    title: 'Utilisateurs',
    listUsers: 'Utilisateur',
    listRole: 'Rôle',
    listEmail: 'Email',
    listTelefone: 'Téléphone',
    listConexion: 'Connexions',
    ctaCreate: 'CRÉER UN UTILISATEUR',
    ctaEdit: 'MODIFIER UN UTILISATEUR',
    modalTitle: 'Nouvel utilisateur',
    modalTitleEdit: "Modifier l'utilisateur",
    role: 'Rôle',
    sectionOne: 'Contact',
    poste: 'Poste',
    email: 'E-mail',
    name: 'Prénom du contact',
    surname: 'Nom du contact',
    phone: 'Téléphone fixe',
    mobile: 'Téléphone portable',
    ctaModal: 'CRÉER UN UTILISATEUR',
    deleteTitle: "Suppression d'un utilisateur",
    deleteDescription: 'Êtes-vous certain de vouloir supprimer un utilisateur ? Cette opération est irreversible.',
  },
  ClientList: {
    title: 'Clients',
    listName: 'Client',
    listContact: 'Contact',
    listEmail: 'Mail',
    listPhone: 'Téléphone',
    listPay: 'Payé',
    listWaiting: 'En attente',
    listActions: 'Action',
    titleTwo: 'Prospects',
    ctaCreate: 'CRÉER UN CLIENT',
    ctaEdit: 'MODIFIER UN CLIENT',
    ctaCreateTwo: 'CRÉER UN PROSPECT',
    modalTitle: 'Client',
    sectionOne: 'Entreprise',
    sectionTwo: 'Contact',
    sectionThree: 'Notes',
    company: 'Entreprise',
    status: 'Type',
    siret: 'SIRET',
    tva: 'Numéro de TVA',
    address: 'Numéro et rue',
    houseNumber: 'Numéro',
    codePostal: 'Code postal',
    city: 'Ville',
    poste: 'Poste',
    name: 'Prénom du contact',
    surname: 'Nom du contact',
    email: 'E-mail',
    phone: 'Téléphone fixe',
    mobile: 'Téléphone portable',
    recontact_at: 'Recontacter le',
    notification: 'Notification',
    note: 'Note',
    ctaModal: 'CRÉER UN CLIENT',
    labelDate: 'Recontacter le',
    minDateMessage: 'Veuillez choisir une date',
    deleteTitle: 'Supprimer un client',
    deleteDescription: 'Êtes-vous certain de vouloir supprimer un client ? Cette opération est irreversible.',
    commercial: 'Commercial',
    conducteur: 'Conducteur',
    assistant: 'Assistant',
  },
  Profile: {
    myProfile: 'Mon profil',
    myInformation: 'Mes notifications',
    personalInfo: 'Information personnelles',
    password: 'Identifiant',
  },
  ArticleList: {
    title: 'Articles',
    ctaCreate: 'NOUVEL ARTICLE',
    name: 'Libellé',
    furnisces: 'Fournisseurs',
    categories: 'Catégorie',
    subCategories: 'Sous-Catégorie',
    description: 'Description',
    Coeff: 'Coeff',
    sellPrice: 'Prix de vente',
    buyingPrice: 'Prix d’achat',
    margin: 'Marge brute',
    action: 'Action',
    deleteTitle: 'Supprimer un article',
    deleteDescription: "Supprimer l'article",
    modalTitle: 'Article',
    ctaCreateModal: 'ENREGISTRER L’ARTICLE',
    ctaEditModal: 'Sauvegarder',
    sectionOne: 'Informations',
    sectionTwo: 'Catégorie',
  },
  SitesList: {
    title: 'Chantiers',
    ctaAdd: 'NOUVEAU CHANTIER',
    section1: 'En cours',
    section2: 'Prochainement',
    section3: 'Terminés',
    section4: 'À l’étude',
    site: 'Chantier',
    client: 'Client',
    address: 'Adresse',
    starting_date: 'Démarrage',
    ending_date: 'Livraison',
    commercial: 'Commercial',
    conducteur: 'Conducteur',
    assistant: 'Assitant',
    price: 'Marché',
    advancement: 'Avancement',
    action: 'Action',
  },
  SiteDetail: {
    titleNew: 'Début du chantier',
    dashboard: 'Tableau de bord',
    planning: 'Planning',
    information: 'Informations',
    textEditor: 'Découverte',
    contracts: 'Contrats',
    invoiceAndQuote: 'Devis & factures',
    plan: 'Plan',
    suppliers: 'Fournisseurs',
    artisans: 'Artisans',
    incidents: 'Suivi de chantier',
    sectionInformation: {
      site: 'Nom du chantier',
      sitePlaceholder: 'Nom du chantier',
      clientLabel: 'Client / Maître d’ouvrage',
      reference: 'Référence devis',
      referencePlaceholder: 'Ex : 2334',
      price: 'Montant estimatif HT',
      address: 'Adresse du chantier',
      descriptionProject: 'Description du projet',
      descriptionProjectPlaceholder: 'Construction d’une maison en pierres',
      status: 'État du chantier',
      starting: 'Démarrage',
      ending: 'Livraison',
      description: 'Description détaillée',
      descriptionPlaceholder: 'Description du chantier',
      alertSuccess: 'Les données ont bien été enregistrées',
      alertSuccessEdit: 'Les données ont bien été enregistrées',
      createCta: 'Enregistrer',
      editCta: 'Enregistrer',
    },
    sectionInvoiceAndQuote: {
      invoice: 'Factures',
      quote: 'Devis',
    },
  },
  createEditQuote: {
    name: 'Numéro du devis',
    mainSection: 'Corps d’état',
  },
  planSection: {
    title: 'Plans du chantier',
    createPlan: 'Télécharger le plan',
  },
  quoteList: {
    date: 'Date',
    client: 'Client',
    status: 'État',
    number: 'N° de devis',
    price: 'Montant HT',
    action: 'Action',
  },
  invoiceList: {
    date: 'Date',
    number: 'N°',
    status: 'état',
    client: 'Client',
    site: 'Chantier',
    price: 'Montant HT',
    action: 'Action',
  },
  artisanBusyDialog: {
    title: "L'artisan est déjà présent sur un chantier à cette période",
    content: 'Souhaitez-vous le positionner malgré tout ?',
    cancel: 'Annuler',
    confirm: 'Oui',
  },
};

export default text;
