import React, { useState } from "react";
import { Modal, Grid, Button, Typography } from "@material-ui/core";
import classes from "./ModalGeneral.module.css";
import { useForm } from "react-hook-form";
import text from "../../../utils/text";
import TextFieldCustom from "../../../Components/FormComponents/TextFieldCustom";
import { checkPassword } from "../../../Axios/Call/AuthApi";
import LoadingButton from "../LoadingButton/LoadingButton";

const ModalGeneral = ({
  isOpenModal,
  title,
  description,
  button1Text,
  button2Text,
  onClick1,
  onClick2,
  isPassword,
}) => {
  const [loadingApi, setLoadingApi] = useState(false);
  const { register, handleSubmit, errors, setValue, setError } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  const checkPasswordHandler = (data) => {
    setLoadingApi(true);
    checkPassword({ ...data })
      .then((response) => {
        setLoadingApi(false);
        onClick1();
      })
      .catch(() => {
        setLoadingApi(false);
        setError("password", { message: "Le mot de passe est incorrect" });
      });
  };

  return (
    <Modal open={isOpenModal} size={"xs"} onClose={() => onClick2()}>
      <Grid container className={classes.modalWrapperWeb} justify="center">
        <Grid item xs={12} align="center">
          <Typography container="h1" variant="h5" style={{ color: "#fd4953" }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20 }} align="center">
          <Typography container="span" variant="span">
            {description}
          </Typography>
        </Grid>
        {isPassword ? (
          <>
            <form
              onSubmit={handleSubmit(checkPasswordHandler)}
              style={{ marginTop: 20 }}
              noValidate
            >
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <TextFieldCustom
                    variant="outlined"
                    label="Mot de passe"
                    type="password"
                    ref={register({ required: text.General.required })}
                    defaultValue=""
                    name={"password"}
                    error={errors.password}
                  />
                </Grid>
                {loadingApi ? (
                  <>
                    <LoadingButton />
                    <Typography variant="h6">
                      Chargement des données...
                    </Typography>
                  </>
                ) : (
                  <>
                    <Grid item xs={6} style={{ marginTop: 30 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={"buttonGeneralStyle"}
                        type="submit"
                      >
                        {button1Text}
                      </Button>
                    </Grid>
                    <Grid item xs={6} style={{ marginTop: 30 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={"buttonGeneralStyleEmpty"}
                        onClick={() => onClick2()}
                      >
                        {button2Text}
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          </>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={6} style={{ marginTop: 30, paddingRight: 10 }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={"buttonGeneralStyle"}
                onClick={() => onClick1()}
              >
                {button1Text}
              </Button>
            </Grid>
            <Grid item xs={6} style={{ marginTop: 30 }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={"buttonGeneralStyleEmpty"}
                onClick={() => onClick2()}
                fullWidth
              >
                {button2Text}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Modal>
  );
};

export default ModalGeneral;
