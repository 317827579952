import React from "react";
//--------------------------- Lottie ---------------------------
import Lottie from 'react-lottie';
import loader from "../../../Assets/Animation/loader.json";
import error from "../../../Assets/Animation/error.json";

interface Props {
  animationData?: string;
}

const LoadingButton: React.FC<Props> = ({ animationData }) => {
  let animation: any = loader;
  if (animationData === "error") {
    animation = error;
  }

  const defualtOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    renderSettings: {
      preserveAspectRation: "xMidYmid slice",
    },
  };

  return (
    <Lottie
      options={defualtOptions}
      height={animationData === "error" ? 120 : 120}
      width={animationData === "error" ? 120 : 200}
    />
  );
};

export default LoadingButton;
