import { useEffect, useState } from "react";
import { Grid, TextField, MenuItem } from "@material-ui/core";
import moment from "moment";
import { SquareComponent } from "./SquareComponent";
import CustomBarChart from "./CustomBarChart";
import CommonDateTimePicker from "../FormComponents/CommonDateTimePicker";

const FinancialComponent = ({ sites, commerciaux, sitesList, invoices, quotes }) => {
  const [list, setList] = useState(sitesList);
  const [data, setData] = useState([]);
  /// STATES FOR DROPLIST
  const [chantier, setchantier] = useState("Tous");
  const [commercial, setCommercial] = useState("Tous");
  const [graphData, setGraphdata] = useState([]);
  /// SET UP START AND END DATES
  const [dateStart, setdateStart] = useState(
    moment().set("month", 0).set("date", 1).format("YYYY-MM-DD")
  );
  const [dateEnd, setdateEnd] = useState(
    moment().set("month", 11).set("date", 31).format("YYYY-MM-DD")
  );

  const computeConversion = (sites) => {
    /// CALCULTATE CONVERSION RATE (NUMBER OF CHANTIER THAT BECAME ACTIVE)
    let conversion_total = 0;
    let chantier_total = 0;
    let conversion_rate = 0;

    sites.map((chantier) => {
      if (chantier.become_operative_at !== null) conversion_total += 1;
      chantier_total += 1;
      if (chantier.deleted_at === null) chantier_total += 1;
    });
    conversion_rate = (conversion_total / chantier_total) * 100;

    return isNaN(conversion_rate) ? 0 : conversion_rate;
  };

  const computePrevisionnel = (data) => {
    let total = 0;
    data?.map((quote) => (total += quote.total_amount_no_tva));
    return total.toFixed(0);
  };

  const computeCA = (data) => {
    let total = 0;
    data?.map((invoice) => (total += invoice.total_amount_no_tva));
    return total.toFixed(0);
  };

  const createGraph = () => {
    const result = [];
 
    function monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    }
    const diff = monthDiff(new Date(dateStart), new Date(dateEnd));

    for (let i = 0; i < diff + 1; i++) {
      let tmpCAprev = 0; // quotes
      let tmpCA = 0; // invoices

      let start =
        i === 0
          ? new Date(dateStart)
          : new Date(moment(dateStart).startOf("month").add(i, "month"));
      let end =
        i === diff
          ? new Date(dateEnd)
          : new Date(moment(dateStart).endOf("month").add(i, "month"));

      let resCAprev = quotes.filter((quote) => {
        return (
          new Date(quote.created_at) >= new Date(start) &&
          new Date(quote.created_at) <= new Date(end)
        );
      });

      tmpCAprev = computePrevisionnel(resCAprev);

      let resCA = invoices.filter((invoice) => {
        return (
          new Date(invoice.created_at) >= new Date(start) &&
          new Date(invoice.created_at) <= new Date(end)
        );
      });

      tmpCA = computeCA(resCA);

      result.push({
        date: moment(start).format("MMMM"),
        CA: tmpCA,
        CAprev: tmpCAprev,
      });
    }
    setGraphdata(result);
  };

  useEffect(() => {
    let margin = 0;
    let CA = 0;
    let CAprev = 0;
    let debt = 0;
    let waiting = 0;
    let unpaid = 0;
    let paid = 0;
    let factured = 0;
    let filteredSite = [];
    let incidentNb = 0;
    let invoices = [];
    let quotes = [];

    if (chantier !== "Tous") {
      filteredSite = sites.filter((s) => s.id === chantier);
      setList(sitesList);
    }
    if (commercial !== "Tous") {
      filteredSite = sites.filter((s) =>
        s.client.client_assignation?.some((p) => p?.business_id === commercial)
      );
      setList(filteredSite);
    } else if (chantier === "Tous" && commercial === "Tous") {
      filteredSite = sites;
      setList(sitesList);
    
    }

    filteredSite = filteredSite.filter((s) =>s.invoices.filter((i) => i.created_at >= dateStart && i.created_at <= dateEnd) );
    filteredSite = filteredSite.filter((s) =>s.quotes.filter((i) => i.created_at >= dateStart && i.created_at <= dateEnd) );

    filteredSite.map((site) => {

      margin += site.avg_margin/filteredSite.length;
     
      site.quotes
        .filter((i) => i.status !== "draft").filter((i) => i.created_at >= dateStart && i.created_at <= dateEnd)
        .map((i) => (CAprev += i.total_amount_no_tva));
      site.invoices
        .filter((i) => i.status !== "draft").filter((i) => i.created_at >= dateStart && i.created_at <= dateEnd).map((i) => (CA += i.total_amount_no_tva));
      site.invoices
        .filter((i) => i.status === "paid").filter((i) => i.created_at >= dateStart && i.created_at <= dateEnd)
        .map((i) => (factured += i.total_amount_no_tva));
      site.invoices
        .filter((i) => i.status === "paid").filter((i) => i.created_at >= dateStart && i.created_at <= dateEnd)
        .map((i) => (paid += i.total_amount_no_tva));
      site.invoices
        .filter((i) => i.status === "not-paid").filter((i) => i.created_at >= dateStart && i.created_at <= dateEnd)
        .map((i) => (waiting += i.total_amount_no_tva));
      site.invoices
        .filter((i) => i.status === "in-retard").filter((i) => i.created_at >= dateStart && i.created_at <= dateEnd)
        .map((i) => (unpaid += i.total_amount_no_tva));
      site.artisansOrders
        .filter((a) => a.status === "sent").filter((i) => i.created_at >= dateStart && i.created_at <= dateEnd)
        .map((d) => (debt += d.amount));
      incidentNb += site.incidents;
    });
    

    setData({
      margin: margin,
      CA: CA,
      CAprev: CAprev,
      factured: factured,
      waiting: waiting,
      paid: paid,
      unpaid: unpaid,
      debt: debt,
      conversionRate: computeConversion(sites),
      incidentNb: incidentNb,
    });

    createGraph();


  }, [sites, chantier, commercial, dateStart, dateEnd, invoices, quotes]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item>
          <CommonDateTimePicker
            id="dateStart"
            name={"dateStart"}
      
            label="Début"
            field={{
              value: dateStart,
              onChange: (date) => setdateStart(moment(date).format("yyyy-MM-DD")),
            }}
            
        
            
        
          />
        </Grid>

        <Grid item>
          <CommonDateTimePicker
            id="dateEnd"
            label="Fin"
            name={"dateEnd"}
           
            
            field={{
              value: dateEnd,
              onChange: (date) => setdateEnd(moment(date).format("yyyy-MM-DD")),
            }}
     
          />
        </Grid>

        <Grid item>
          <TextField
            id="commercial"
            select
            variant="outlined"
            label="Commercial"
            value={commercial}
            onChange={(e) => setCommercial(e.target.value)}
            size="medium"
            style={{ textAlign: "left", minWidth: 150 }}
          >
            <MenuItem key={"NullOpt"} value={"Tous"}>
              <p style={{ margin: 0, color: "rgb(152, 155, 159)" }}>Tous</p>
            </MenuItem>

            {commerciaux.map((c, index) => (
              <MenuItem key={index} value={c.id}>
                <p style={{ margin: 0 }}>{c.name}</p>
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item>
          <TextField
            id="chantier"
            select
            variant="outlined"
            label="Chantier"
            value={chantier}
            onChange={(e) => setchantier(e.target.value)}
            size="medium"
            style={{ textAlign: "left", minWidth: 200 }}
          >
            <MenuItem key={"NullOpt"} value={"Tous"}>
              <p style={{ margin: 0, color: "rgb(152, 155, 159)" }}>Tous</p>
            </MenuItem>

            {list.map((site) => (
              <MenuItem key={site.id} value={site.id}>
                <p style={{ margin: 0 }}>{site.name}</p>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        style={{ marginTop: "10px", marginBottom: 10 }}
      >
        <Grid item md={4} xs={12}>
          <SquareComponent
            title="Chiffre d’affaires (HT)"
            value={data.CA}
            color="#33A795"
            unit="€"
          />
          <SquareComponent
            title="Chiffre d’affaires prévisionnel (HT)"
            value={data.CAprev}
            color="#345369"
            unit="€"
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <SquareComponent
                title="Marge"
                value={data.margin}
                color={data.margin > 30 ? "rgba(75, 142, 74, 0.95)" : "#C04646"}
                unit="%"
              />

              {chantier === "Tous" && commercial === "Tous" && (
                <SquareComponent
                  title="Conversion (Chantiers signés)"
                  value={data.conversionRate}
                  color={
                    data.conversionRate > 20
                      ? "rgba(75, 142, 74, 0.95)"
                      : "#C04646"
                  }
                  unit="%"
                />
              )}

              {(chantier !== "Tous" || commercial !== "Tous") && (
                <SquareComponent
                  title="Incidents"
                  value={data.incidentNb}
                  color={
                    data.incidentNb < 1 ? "rgba(75, 142, 74, 0.95)" : "#C04646"
                  }
                />
              )}
            </Grid>

            <Grid item xs={4}>
              <SquareComponent
                title="Reglé (HT)"
                value={data.factured}
                color="#345369"
                unit="€"
              />
              <SquareComponent
                title="Approuvées"
                value={data.waiting}
                color="#989B9F"
                unit="€"
              />
            </Grid>
            <Grid item xs={4}>
              <SquareComponent
                title="Impayés"
                value={data.unpaid}
                color="#DB3838"
                unit="€"
              />
              <SquareComponent
                title="Dette artisan"
                value={data.debt}
                color="#C04646"
                unit="€"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CustomBarChart
        title="Évolution du chiffre d'affaires"
        data={graphData}
      />
    </>
  );
};

export default FinancialComponent;
