import React, { useState, useEffect } from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import text from "../../../utils/text";
import TextFieldCustom from "../../../Components/FormComponents/TextFieldCustom";
import { useForm } from "react-hook-form";
import { formatErrorEmail } from "../../../utils/formValidation";
import CommonButton from "../../../Components/FormComponents/CommonButton";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import Img from "../../../Assets/Images/login-illu.png";
import LogoWhite from "../../../Assets/Images/logo-white.png";
import {
  ResetPassword,
  ResetPasswordEmail,
} from "../../../Axios/Call/ClientCredential";
import classes from "./ChangePassword.module.css";
import { useHistory, useParams } from "react-router-dom";

const ChangePassword = () => {
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
  }); // initialise the hook
  const { tokenId } = useParams();

  const sendFormLogin = (data) => {
    setLoadingApi(true);
    ResetPassword({ ...data, reset_token: tokenId })
      .then((response) => {
        setEmailSuccess(true);
        setLoadingApi(false);
        const time = setTimeout(() => history.push("/"), 1500);
        return () => {
          clearTimeout(time);
        };
      })
      .catch((error) => {
        setErrorApi(true);
        setLoadingApi(false);
      });
  };

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertGeneralError}
        />
      )}
      {emailSuccess && (
        <AlertGeneral
          color="success"
          setTrigger={setEmailSuccess}
          description={text.changePassword.alertSuccess}
        />
      )}
      <div style={{ backgroundColor: "white" }}>
        <Container style={{ maxWidth: "none" }}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} md={8} style={{ backgroundColor: "white" }}>
              <form
                onSubmit={handleSubmit(sendFormLogin)}
                style={{ marginTop: 20 }}
                noValidate
              >
                <Grid container justifyContent="center">
                  <Grid item xs={12} md={5}>
                    <Grid container>
                      <Grid item xs={12} align="left">
                        <Typography variant="h1" style={{ fontSize: 40 }}>
                          {text.changePassword.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} align="left">
                        <Typography style={{ fontSize: 18 }}>
                          {text.changePassword.description}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 30 }}>
                        <TextFieldCustom
                          variant="outlined"
                          label={text.changePassword.passwordLabel}
                          type="password"
                          ref={register({ required: text.General.required })}
                          defaultValue=""
                          name={"password"}
                          error={errors.password}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{ marginTop: 10, marginBottom: 10 }}
                      >
                        <CommonButton
                          loading={loadingApi}
                          text={text.changePassword.cta}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
              <div style={{ position: "absolute", bottom: 10, left: 10 }}>
                <Typography style={{ color: "#AEB4BE" }}>
                  © 2021 Vigee
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4} className={classes.rightColumn}>
              <img
                style={{ width: "100%", marginTop: "30vh" }}
                src={Img}
                alt="person-writing"
              />
              <div style={{ position: "absolute", top: 10, right: 10 }}>
                <img style={{ width: 120 }} src={LogoWhite} alt="logo-white" />
              </div>
              <div style={{ position: "absolute", bottom: 10, right: 10 }}>
                <Typography style={{ color: "#AEB4BE" }}>
                  www.vigee.fr
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default ChangePassword;
