import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useState } from 'react';
import { deleteClient } from '../../../Axios/Call/AuthApi';
import { AiFillDelete } from 'react-icons/ai';

function DeleteClientDialog({ open, onClose, onError, onSuccess, clientId }) {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await deleteClient(clientId);
      if (typeof onSuccess === 'function') {
        onSuccess(clientId);
      }
      onClose();
    } catch (e) {
      if (typeof onError === 'function') {
        onError(e);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Suppression</DialogTitle>
      <DialogContent>Êtes vous certain de vouloir supprimer définitivement un client ? </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button
          startIcon={<AiFillDelete size={24} />}
          disabled={loading}
          color="secondary"
          variant="contained"
          onClick={handleDelete}
        >
          Supprimer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteClientDialog;
