import { useEffect, useState } from "react";
import {Fragment} from "react";
import { useHistory } from "react-router-dom";
import { useWindowWidth } from "@react-hook/window-size";
import { Grid, Typography, Select, MenuItem } from "@material-ui/core";

const HeaderSections = ({
  section,
  setSection,
  sections,
  fontSize,
  isBlocked,
  style,
}) => {
  const history = useHistory();
  const onlyWidth = useWindowWidth();
  const [mobile, setMobile] = useState(false);
  const [item, setItem] = useState(section);



  useEffect(() => {
    if (onlyWidth < 1000) {
      setMobile(true);
    } else if (onlyWidth > 1000) {
      setMobile(false);
    }
  }, [onlyWidth]);

  useEffect(() => {
    
    sections.filter(s => s.action === section).map(s => setItem(s.action));

  }, [section, sections]);


  const handleChange = (sectionItem) => {

    sections.filter(s => s.action === sectionItem.target.value).map(s => {
      if (!isBlocked) {
        history.push(s.redirect);
      setSection(s.action);
    }
      setItem(s.action)
    }
      );

   

  };

  return (
    <>
  {mobile ? (
<Grid item xs={12} md={12} style={{ padding: 40, paddingLeft:'2%', paddingRight:'2%',paddingTop:15}}>

          <Select
            style={{ width: "100%", minWidth:'300px' }}
            id="item"
            value={item}
            label=" "
            onChange={handleChange}
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
          >
            {sections.map((sectionItem, index) => (
              <MenuItem key={index} value={sectionItem.action}>
                <Typography variant="h5">{sectionItem.name}</Typography>
              </MenuItem>
             ))}
          </Select>
         
          </Grid>
        ) : (
           
             sections.map((sectionItem, index) => {

              return (
              
            <Grid item key={index} xs={12} md={'auto'} style={{ paddingLeft: index ? 0 : 0, paddingBottom:10, textAlign:"left" }}>
              <Typography
                style={{
                  ...style,
                  fontSize: fontSize ? fontSize : 18,
                  marginRight: 20,
                  cursor: !isBlocked && "pointer",
                  borderBottom:
                    section === sectionItem.action
                      ? "2px solid #345369"
                      : "none",
                  color: section === sectionItem.action ? "#345369" : "#AEB4BE",
                  fontWeight: section === sectionItem.action ? "600" : "400",
                }}
                onClick={() => {
                  if (!isBlocked) {
                    if (sectionItem.redirect) {
                      history.push(sectionItem.redirect);
                    }
                    setSection(sectionItem.action);
                  
                  }
                }}
              >
                {sectionItem.name}
              </Typography>
            </Grid>  )
            })
        )}
    </>
  );
};

export default HeaderSections;
