import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
  TableBody,
  Box,
  Typography,
  CircularProgress,
  IconButton,
  Button,
  Tooltip,
} from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import {
  getSitePlanningDocuments,
  postSitePlanningDocument,
  deleteSitePlanningDocument,
  sendSitePlanningDocument,
} from '../../../Axios/Call/AuthApi';
import text from '../../../utils/text';
import AlertGeneral from '../../Commons/AlertGeneral/AlertGeneral';
import moment from 'moment';
import { AiFillDelete, AiOutlineEye } from 'react-icons/ai';
import UploadDocumentDialog from './UploadDocumentDialog';
import ViewSiteDocumentDialog from './ViewSiteDocumentDialog';
import DeleteSiteDocumentDialog from './DeleteSiteDocumentDialog';
import EmailIcon from '@material-ui/icons/Email';
import SendEmailDialog from './SendEmailDialog';

const StyledTableCell = withStyles((theme) => ({
  root: {
    backgroundColor: '#345369',
    fontSize: 14,
    fontFamily: ['roboto', '-apple-system'].join(','),
    color: 'white',
    wordWrap: 'break-word',
    border: 'none',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function SitePlanningDocuments({ siteId }) {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [viewDocument, setViewDocument] = useState(null);
  const [deleteDocument, setDeleteDocument] = useState(null);
  const [sendDoc, setSendDoc] = useState(null);

  const fetchDocuments = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getSitePlanningDocuments(siteId);
      setDocuments(response.data.data);
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [siteId]);

  const uploadDocument = useCallback(
    async (payload) => {
      await postSitePlanningDocument(siteId, payload);
      await fetchDocuments();
    },
    [siteId, fetchDocuments]
  );

  const handleDeleteDocument = async () => {
    if (!deleteDocument) {
      return;
    }

    setLoading(true);
    await deleteSitePlanningDocument(siteId, deleteDocument.id);
    await fetchDocuments();
    setLoading(false);
    setDeleteDocument(null);
  };

  useEffect(() => {
    if (typeof siteId === 'number' && siteId) {
      fetchDocuments();
    }
  }, [fetchDocuments, siteId]);

  const handleSendEmail = async (email) => {
    setLoading(true);
    await sendSitePlanningDocument(siteId, sendDoc.id, { email });
    setLoading(false);
  };

  return (
    <>
      {error && <AlertGeneral color="danger" setTrigger={setError} description={text.General.alertError} />}
      <UploadDocumentDialog open={uploadOpen} onClose={() => setUploadOpen(false)} uploadDocument={uploadDocument} />
      <ViewSiteDocumentDialog document={viewDocument} onClose={() => setViewDocument(null)} />
      <SendEmailDialog open={Boolean(sendDoc)} onClose={() => setSendDoc(null)} sendEmail={handleSendEmail} />
      <DeleteSiteDocumentDialog
        document={deleteDocument}
        onClose={() => setDeleteDocument(null)}
        onDelete={handleDeleteDocument}
        deleting={loading}
      />
      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell width={'70%'}>Titre</StyledTableCell>
              <StyledTableCell width={'20%'}>Uploadé le</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(documents) &&
              documents.map((doc) => (
                <StyledTableRow key={doc.id}>
                  <TableCell>{doc.title}</TableCell>
                  <TableCell>{moment(doc.created_at).format('DD/MM/yyyy')}</TableCell>
                  <TableCell padding="checkbox">
                    <Box display={'flex'} justifyContent={'flex-end'}>
                      <Tooltip title="Email">
                        <IconButton size="small" color="primary" onClick={() => setSendDoc(doc)}>
                          <EmailIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Voir">
                        <IconButton color="primary" size="small" onClick={() => setViewDocument(doc)}>
                          <AiOutlineEye size={24} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Supprimer">
                        <IconButton size="small" onClick={() => setDeleteDocument(doc)} color="secondary">
                          <AiFillDelete size={24} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </StyledTableRow>
              ))}
            {((Array.isArray(documents) && documents.length === 0) || loading) && (
              <StyledTableRow>
                <TableCell colSpan={3}>
                  <Box height={200} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    {loading ? <CircularProgress size={40} /> : <Typography variant="h6">Pas de plans</Typography>}
                  </Box>
                </TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>

        <Box marginTop={2} display="flex" justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={() => setUploadOpen(true)}>
          Choisir le fichier
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default SitePlanningDocuments;
