import { useState, useRef, useEffect } from "react";
import { Grid, Typography, Modal } from "@material-ui/core";
import { Cross } from "../../../utils/icons";
import CommonButton from "../../FormComponents/CommonButton";
import TextBoxCustom from "../../FormComponents/TextBoxCustom";
import { useForm } from "react-hook-form";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import {
  addIncidentToItem,
  deleteIncident,
  showIncidentsOfItems,
} from "../../../Axios/Call/AuthApi";
import AlertGeneral from "../../Commons/AlertGeneral/AlertGeneral";
import text from "../../../utils/text";
import moment from "moment";
import { AiFillDelete } from "react-icons/ai";
import LoadingButton from "../../Commons/LoadingButton/LoadingButton";

const ModalAddIncident = ({ openModalAdd, setOpenModalAdd, noAdd }) => {
  const [files, setFiles] = useState([]);
  const [currentPicture, setCurrentPicture] = useState(0);
  const [incidents, setIncidents] = useState([]);
  const [category, setCategory] = useState("");
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const inputFileRef = useRef(null);
  const downloadRef = useRef(null);
  const { register, watch } = useForm({
    mode: "onBlur",
  }); // initialise the hook

  useEffect(() => {
    if (openModalAdd) {
      setLoadingApi(true);
      showIncidentsOfItems(openModalAdd?.item?.id)
        .then((response) => {
          setIncidents(response.data.incidents);
          setCategory(response.data.categoryName);
          setLoadingApi(false);
        })
        .catch((error) => {
          setErrorApi(true);
          setLoadingApi(false);
        });
    }
  }, [openModalAdd]);

  function fileToBase64(file) {
    return new Promise((res, rej) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onloadend = () => {
        res(fileReader.result);
      };
      fileReader.onerror = () => rej();
    });
  }

  const onForwardPictureShow = (
    incidentId,
    incidentPicture,
    incidentPictureLength
  ) => {
    if (incidentId) {
      if (incidentPicture + 1 === incidentPictureLength) {
        setIncidents(
          incidents.map((incident) =>
            incident.id === incidentId
              ? { ...incident, current_picture: 0 }
              : { ...incident }
          )
        );
      } else {
        setIncidents(
          incidents.map((incident) =>
            incident.id === incidentId
              ? {
                  ...incident,
                  current_picture: incident.current_picture + 1,
                }
              : { ...incident }
          )
        );
      }
    } else {
      if (currentPicture + 1 === files.length) {
        setCurrentPicture(0);
      } else {
        setCurrentPicture(currentPicture + 1);
      }
    }
  };

  const onBackPictureShow = (
    incidentId,
    incidentPicture,
    incidentPictureLength
  ) => {
    if (incidentId) {
      if (incidentPicture - 1 === -1) {
        setIncidents(
          incidents.map((incident) =>
            incident.id === incidentId
              ? { ...incident, current_picture: incidentPictureLength - 1 }
              : { ...incident }
          )
        );
      } else {
        setIncidents(
          incidents.map((incident) =>
            incident.id === incidentId
              ? { ...incident, current_picture: incidentPicture - 1 }
              : { ...incident }
          )
        );
      }
    } else {
      if (currentPicture - 1 === -1) {
        setCurrentPicture(files.length - 1);
      } else {
        setCurrentPicture(currentPicture - 1);
      }
    }
  };

  const onRegisterIncidentHandler = () => {
    addIncidentToItem(openModalAdd?.item?.id, {
      files: files,
      messages: watch("messages"),
    })
      .then((response) => {
        setOpenModalAdd(false);
        setCurrentPicture(0);
        setFiles([]);
      })
      .catch((error) => {});
  };

  const onDeleteIncidentHandler = (incidentId) => {
    deleteIncident(incidentId)
      .then((response) => {
        setIncidents(
          incidents.filter((incident) => incident.id !== incidentId)
        );
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height: 5
        }}
    />
);

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertError}
        />
      )}
      <Modal open={openModalAdd} size={"lg"}>
        <Grid container className={"modalWrapperWeb"}>
          <div
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              cursor: "pointer",
            }}
            onClick={() => {
              setOpenModalAdd(false);
              setCurrentPicture(0);
              setFiles([]);
            }}
          >
            <Cross />
          </div>


          <Grid item xs={12} align="left">
            <Typography variant="h2" style={{ marginBottom: 20 }}>{category}</Typography>
          </Grid>


 {/*History*/}
 {loadingApi ? (
            <LoadingButton />
          ) : (
            incidents.map((incident) => {
              return (
                <>
                  <Grid item xs={4} md={2} className="my-auto">
                    <Typography>
                      {moment(incident.created_at).format("DD/MM/yyyy")}
                      <Typography style={{ marginTop: 5 }}>
                        {incident?.business?.name} {incident?.business?.surname}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={4} className="my-auto">
                    <Typography>{incident.messages}</Typography>
                  </Grid>
                  <Grid item xs={6} md={5} className="my-auto">
                    <Grid container>
                      <Grid
                        item
                        xs={1}
                        align="center"
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                      >
                        {incident?.decoded_picture?.length > 1 && (
                          <div
                            className="my-auto"
                            onClick={() =>
                              onBackPictureShow(
                                incident.id,
                                incident?.current_picture,
                                incident?.decoded_picture?.length
                              )
                            }
                          >
                            <ChevronLeft />
                          </div>
                        )}
                      </Grid>
                      {incident?.decoded_picture?.length > 0 && (
                        <Grid item xs={10} align="center" className="my-auto">
                          <img
                            alt="addIncident-1"
                            src={
                              incident?.decoded_picture?.length
                                ? incident?.decoded_picture[
                                    incident?.current_picture
                                  ]
                                : ""
                            }
                            style={{
                              width: "80%",
                              border: "1px solid #444",
                              cursor: "pointer",
                            }}
                            className="my-auto"
                            onClick={() => downloadRef?.current?.click()}
                          />
                          <a
                            style={{ display: "none" }}
                            ref={downloadRef}
                            href={
                              incident?.decoded_picture?.length
                                ? incident?.decoded_picture[
                                    incident?.current_picture
                                  ]
                                : ""
                            }
                            download="incident_picture"
                          />
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={1}
                        style={{ marginTop: "auto", marginBottom: "auto", paddingTop:"60px"}}
                      >
                        {incident?.decoded_picture?.length > 1 && (
                          <div
                            onClick={() =>
                              onForwardPictureShow(
                                incident.id,
                                incident?.current_picture,
                                incident?.decoded_picture?.length
                              )
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <ChevronRight />
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} md={1} className="my-auto">
                    <AiFillDelete
                      color="#FF5555"
                      size={28}
                      style={{ cursor: "pointer" }}
                      onClick={() => onDeleteIncidentHandler(incident.id)}
                    />
                  </Grid>
                </>
              );
            })
          )}



          {!noAdd && (
            <>


            <Grid container spacing={2}
             style={{ borderRadius: "10px", width:"100%"}}
            >
            <Grid
                item
                xs={9}
                align="center"
                style={{ marginTop: 30, marginBottom: 0}}
              >


                <TextBoxCustom
                  variant="outlined"
                  label={"Écrire votre commentaire"}
                  defaultValue=""
                  name={"messages"}
                  ref={register({})}
                  color="#EEE"
                />
              </Grid>

              <Grid
                item
                xs={3}
                align="center"
                style={{ marginTop: 30, marginBottom: 20, height:"100%" }}
              >

                <CommonButton
                  text={"Photos"}
                  style={{ backgroundColor: "#222", border: "2px #FF1111", bordeStyle: "dashed solid"}}
                  onClick={() => inputFileRef?.current?.click()}
                />
                </Grid>

                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={inputFileRef}
                  accept="image/png, image/jpeg"
                  onChange={async (e) => {
                    const b64 = await fileToBase64(e.target.files[0]);
                    setFiles(files.concat(b64));
                  }}
                />
              </Grid>
              <Grid
                item
                xs={1}
                align="center"
                style={{ marginTop: "auto", marginBottom: "auto" }}
              >
                {files.length > 1 && (
                  <div onClick={() => onBackPictureShow()}>
                    <ChevronLeft />
                  </div>
                )}
              </Grid>
              {files.length > 0 && (
                <Grid
                  item
                  xs={10}
                  align="center"
                  style={{ marginTop: 50 }}
                  className="my-auto"
                >
                  <a
                    style={{ display: "none" }}
                    ref={downloadRef}
                    href={files[currentPicture]}
                    download={"incident_picture"}
                  />
                  <img
                    alt="addIncident-1"
                    src={files.length ? files[currentPicture] : ""}
                    style={{
                      width: "40%",
                      border: "1px solid black",
                      cursor: "pointer",
                    }}
                    onClick={() => downloadRef?.current?.click()}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={1}
                align="center"
                style={{ marginTop: "auto", marginBottom: "auto" }}
              >
                {files.length > 1 && (
                  <div
                    onClick={() => onForwardPictureShow()}
                    style={{ cursor: "pointer" }}
                  >
                    <ChevronRight />
                  </div>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                align="center"
                style={{ marginTop: 10, marginBottom: 20 }}
              >
                <CommonButton
                  text={"ENVOYER"}
                  onClick={() => onRegisterIncidentHandler()}
                />
              </Grid>
            </>
          )}



         
        </Grid>
      </Modal>
    </>
  );
};

export default ModalAddIncident;
