import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import text from "../../../../utils/text";
import HeaderSections from "../../../../Components/Commons/HeaderSections/HeaderSections";
import CommonButton from "../../../../Components/FormComponents/CommonButton";
import { useParams, useHistory } from "react-router-dom";
import QuoteList from "../../../../Components/BusinessComponents/InvoiceAndQuote/QuoteList";
import InvoiceList from "../../../../Components/BusinessComponents/InvoiceAndQuote/InvoiceList";

const InvoiceAndQuote = () => {
  const [section, setSection] = useState("quote");
  const history = useHistory();
  const { siteId, redirectSection } = useParams();
  const sections = [

    {
      action: "quote",
      name: text.SiteDetail.sectionInvoiceAndQuote.quote,
    },
    {
      action: "invoice",
      name: text.SiteDetail.sectionInvoiceAndQuote.invoice,
    },
  
  ];

  useEffect(() => {
    if (redirectSection) {
      setSection(redirectSection);
    }
  }, [redirectSection]);

  return (
    <>
      <Grid container style={{ marginTop: 10}} spacing={2} alignItems='center'>
        <>
          <HeaderSections
            sections={sections}
            section={section}
            setSection={setSection}
            fontSize={26}
            style={{marginLeft:10}}
          />
          {section === 'quote' && <Grid align="right" >
            <CommonButton
              text={"NOUVEAU DEVIS"}
              onClick={() =>
                history.push(`/site-detail/${siteId}/create-quote/`)
              }
            />
          </Grid>}
        </>
        <Grid item xs={12} >
          {section === "invoice" && <InvoiceList />}
        </Grid>
        <Grid item xs={12} >
          {section === "quote" && <QuoteList />}
        </Grid>
      </Grid>
    </>
  );
};

export default InvoiceAndQuote;
