import React from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Grid,
  Typography,
} from "@material-ui/core";

const RadioGroupCustom = ({
  field,
  options,
  name,
  labelName,
  error,
  ref,
  isInt,
}) => {
  return (
    <FormControl component="fieldset">
      <RadioGroup
        name={name}
        value={isInt ? parseInt(field.value, 10) : field.value}
        onChange={field.onChange}
      >
        <Grid container spacing={3}>
          <Grid style={{ paddingTop: 5 }} ref={ref}>
            <Typography variant="h3">{labelName}</Typography>
          </Grid>
          {options.map((option) => {
            return (
              <Grid key={option.id} style={{ paddingLeft: 10 }}>
                <FormControlLabel
                  value={option.id}
                  control={<Radio />}
                  label={option.name}
                />
              </Grid>
            );
          })}
          {error?.message && (
            <Grid item xs={12}>
              <Typography style={{ fontSize: 12, color: "red" }}>
                {error?.message}
              </Typography>
            </Grid>
          )}
        </Grid>
      </RadioGroup>
    </FormControl>
  );
};

export default RadioGroupCustom;
