
import { Grid, Typography } from '@material-ui/core';
import { height } from '@mui/system';


export const SquareComponent = ({ title, value, unit, color, height }) => {

    const numberWithSpaces = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };


    const backgroundColor = `'${color}'`;

    return (

        <Grid container alignContent='center' 
            style={{
                backgroundColor: color,
                borderRadius: "5px",
                padding: "0.7rem 0.8rem",
                marginTop: 5,
                height: height,
               
            }}
        >
            <Typography style={{ color: "white", fontSize: "26px", width: "100%", textAlign: "left" }} variant='h1'>
                {value===0 ?  '--' : numberWithSpaces(Math.round(value))} {unit}
            </Typography>
            <Typography
                style={{
                    color: "#EEE",
                    fontSize: "14px",
                    textAlign: "left"
                }}
            >
                {title}
            </Typography>
        </Grid>

    )
}
