import React, { useEffect, useState } from 'react';
import { Grid, Typography, Card, Switch, TextField, InputAdornment,  CircularProgress,Tooltip } from '@material-ui/core';
import AlertGeneral from '../../../../Components/Commons/AlertGeneral/AlertGeneral';
import text from '../../../../utils/text';
import { useParams } from 'react-router-dom';
import {
  changeIsUrgentStatus,
  changePercentageDone,
  changePercentageDoneCategory,
  getAllQuotesWithCategory,
} from '../../../../Axios/Call/AuthApi';
import moment from 'moment';
import { Chat, ChatFull, chatFull } from '../../../../utils/icons';
import ModalAddIncident from '../../../../Components/BusinessComponents/IncidentPage/ModalAddIncident';
import CommonButton from '../../../../Components/FormComponents/CommonButton';
import ModalSendIncident from '../../../../Components/BusinessComponents/IncidentPage/ModalSendIncident';

const IncidentPage = () => {
  const [errorApi, setErrorApi] = useState(false);
  const [errorApiDoneValue, setErrorApiDoneValue] = useState(false);
  const [choosenQuote, setChoosenQuote] = useState([]);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalEmail, setOpenModalEmail] = useState(false);
  const { siteId } = useParams();
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setLoading(true);
    getAllQuotesWithCategory(siteId)
      .then((response) => {
        setChoosenQuote(response.data.siteCategories);
      })
      .catch((error) => {
        setErrorApi(true);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModalAdd]);

  const onChangeIsUrgentHandler = ({ categoryId, itemId }) => {
    setChoosenQuote(
      choosenQuote.map((category) =>
        category.id === categoryId
          ? {
              ...category,
              site_items: category.site_items.map((item) =>
                item.id === itemId ? { ...item, is_urgent: item.is_urgent ? false : true } : item
              ),
            }
          : category
      )
    );

    changeIsUrgentStatus(itemId)
      .then((response) => {})
      .catch((error) => {
        setErrorApi(true);
      });
  };

  const truncate = (str) => {
    return str.length > 10 ? str.substring(0, 60) + "..." : str;
}

  const changePercentageDoneHandler = ({ categoryId, itemId, value }) => {
    setChoosenQuote(
      choosenQuote.map((category) =>
        category.id === categoryId
          ? {
              ...category,
              site_items: category.site_items.map((item) =>
                item.id === itemId ? { ...item, percentage_done: value } : item
              ),
            }
          : category
      )
    );
  };

  const onBlurPercentageDone = ({ categoryId, itemId }) => {
    const foundItem = choosenQuote
      .find((category) => category.id === categoryId)
      .site_items.find((item) => item.id === itemId);
    const category = choosenQuote.find((category) => category.id === categoryId);

    changePercentageDone(itemId, {
      percentage_done: foundItem.percentage_done,
      quote_id: category.quote_id,
    })
      .then((response) => {
        setChoosenQuote(
          choosenQuote.map((category) =>
            category.id === categoryId
              ? {
                  ...category,
                  percentage_done: response?.data?.site_category_percentage_done,
                }
              : { ...category }
          )
        );
      })
      .catch((error) => {
        if (error?.response?.data?.percentage_done) {
          setChoosenQuote(
            choosenQuote.map((category) =>
              category.id === categoryId
                ? {
                    ...category,
                    site_items: category.site_items.map((item) =>
                      item.id === itemId
                        ? {
                            ...item,
                            percentage_done: error?.response?.data?.percentage_done,
                          }
                        : { ...item }
                    ),
                  }
                : { ...category }
            )
          );
        }
        setErrorApiDoneValue(true);
      });
  };

  const changePercentageDoneCategoryHandler = ({ categoryId, value }) => {
    setChoosenQuote(
      choosenQuote.map((category) =>
        category.id === categoryId
          ? {
              ...category,
              percentage_done: value,
            }
          : category
      )
    );
  };

  const onBlurPercentageDoneCategoryHandler = ({ categoryId }) => {
    const foundItem = choosenQuote.find((category) => category.id === categoryId);

    changePercentageDoneCategory(categoryId, {
      percentage_done: foundItem.percentage_done,
    })
      .then((response) => {
        setChoosenQuote(
          choosenQuote.map((category) =>
            category.id === categoryId
              ? {
                  ...category,
                  site_items: category.site_items.map((item) => {
                    return {
                      ...item,
                      percentage_done: foundItem.percentage_done,
                    };
                  }),
                }
              : category
          )
        );
      })
      .catch((error) => {
        if (error?.response?.data?.percentage_done) {
          setChoosenQuote(
            choosenQuote.map((category) =>
              category.id === categoryId
                ? {
                    ...category,
                    percentage_done: error?.response?.data?.percentage_done,
                  }
                : category
            )
          );
        }
        setErrorApiDoneValue(true);
      });
  };

  return (
    <>
      {errorApi && <AlertGeneral color="danger" setTrigger={setErrorApi} description={text.General.alertError} />}
      {errorApiDoneValue && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApiDoneValue}
          description={"Il n'est pas possible de revenir en arrière sur l'avancement"}
        />
      )}
      <ModalAddIncident openModalAdd={openModalAdd} setOpenModalAdd={setOpenModalAdd} />
      <ModalSendIncident openModalAdd={openModalEmail} setOpenModalAdd={setOpenModalEmail} />

      <Grid container style={{ marginTop: 30 }}>
        <Grid item xs={12} md={6} align="left">
          <CommonButton text={'Générer un rapport'} onClick={() => setOpenModalEmail(true)} style={{ width: 200 }} />
        </Grid>

       

        <Grid item xs={12} style={{ marginBottom: 20, marginTop: 10 }}>

        {loading && <>
         <CircularProgress size={40} />  
        <Typography variant="h6" style={{ color:'#345369', fontSize:"18px" }}>Chargement du suivi</Typography>
         </>
        }

          {choosenQuote?.map((quoteCategory) => {
            return (
              <>
                <Grid container key={quoteCategory.id} style={{ marginTop: 1 }} justifyContent="space-between">
                  <Grid align="left" className="my-auto" >
                    <Typography variant="h6" style={{ color: quoteCategory.color ?? '#345369', fontSize:"18px" }}>
                      {quoteCategory.level} {quoteCategory.name}
                    </Typography>
                  </Grid>
                  <Grid className="my-auto">
                    <div style={{ display: 'flex' }}>
                      <div className="my-auto">
                        <Typography
                          style={{
                            paddingLeft: 20,
                            fontSize: 14,
                            color: '#345369',
                            paddingTop: 10,
                            fontWeight: 500,
                          }}
                        >
                          Commentaire
                        </Typography>
                      </div>
                      <div className="my-auto">
                        <Typography
                          style={{
                            paddingLeft: 20,
                            fontSize: 14,
                            color: '#345369',
                            paddingTop: 10,
                            fontWeight: 500,
                          }}
                        >
                          Urgent
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          style={{
                            paddingLeft: 25,
                            paddingRight: 25,
                            fontSize: 11,
                            color: '#345369',
                            paddingTop: 10,
                            fontWeight: 500,
                          }}
                        >
                          Avancement
                        </Typography>
                        <TextField
                          variant="outlined"
                          type={'number'}
                          size="small"
                          style={{ width: 70 }}
                          onChange={(event) =>
                            changePercentageDoneCategoryHandler({
                              categoryId: quoteCategory.id,
                              value: event.target.value,
                            })
                          }
                          onBlur={() => {
                            onBlurPercentageDoneCategoryHandler({
                              categoryId: quoteCategory.id,
                            });
                          }}
                          value={quoteCategory.percentage_done}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Typography>%</Typography>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Card
                  style={{
                    padding: 20,
                    border: '1px solid #AEB4BE40',
                    borderRadius: 11,
                    marginTop: 10,
                    boxShadow: "rgba(100, 100, 111, 0.1) 0px 7px 29px 0px"
                  }}
                  elevation={0}
                >
                  {quoteCategory.site_items.map((item, index) => {
                    return (
                      <Grid container justifyContent="space-between" style={{ marginTop: 10 }}>
                        <Grid className="my-auto">
                         
                         <Typography
                            style={{
                              color: quoteCategory.color ?? '#345369',
                            }}
                          >
                            {truncate(item.artisan_work_name)}
                            {'  '}
                            {item.starting_date &&
                              item.ending_date &&
                              `( ${moment(item.starting_date).format('DD/MM/yyyy')} - ${moment(item.ending_date).format(
                                'DD/MM/yyyy'
                              )} )`}
                          </Typography>
                        </Grid>
                        <Grid>
                          <div style={{ display: 'flex' }}>
                            <div
                              style={{ paddingLeft: 10, cursor: 'pointer', marginRight: 20 }}
                              className="my-auto"
                              onClick={() =>
                                setOpenModalAdd({
                                  item: item,
                                  category: quoteCategory,
                                })
                              }
                            >
                              {item?.incidents?.length ? (
                                <ChatFull height={26} width={26} />
                              ) : (
                                <Chat fill={'#AEB4BE'} height={26} width={26} />
                              )}
                            </div>
                            <div style={{ paddingLeft: 20, marginRight: 18 }} className="my-auto">
                              <Switch
                                checked={item.is_urgent}
                                onClick={() =>
                                  onChangeIsUrgentHandler({
                                    categoryId: quoteCategory.id,
                                    itemId: item.id,
                                  })
                                }
                              />
                            </div>
                            <div>
                              <TextField
                                variant="outlined"
                                type={'number'}
                                size="small"
                                value={item.percentage_done}
                                onChange={(event) =>
                                  changePercentageDoneHandler({
                                    categoryId: quoteCategory.id,
                                    itemId: item.id,
                                    value: event.target.value,
                                  })
                                }
                                onBlur={() => {
                                  onBlurPercentageDone({
                                    categoryId: quoteCategory.id,
                                    itemId: item.id,
                                  });
                                }}
                                style={{ width: 70 }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Typography>%</Typography>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Card>
              </>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default IncidentPage;
