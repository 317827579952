import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@material-ui/core';
import { useState } from 'react';
import moment from 'moment';
import { changeDatePlanning } from '../../../Axios/Call/AuthApi';

function PostproneDialog({ open, onClose, onError, onSuccess, items }) {
  const [days, setDays] = useState('0');
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    onClose();
    setDays('0');
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (Array.isArray(items) && items.length > 0) {
        const daysToAdd = parseInt(days);
        const promises = items.map((i) =>
          changeDatePlanning(i.id, {
            starting_date: moment(i.starting_date).add(daysToAdd, 'days').format('yyyy-MM-DD'),
            ending_date: moment(i.ending_date).add(daysToAdd, 'days').format('yyyy-MM-DD'),
          })
        );

        await Promise.all(promises);
        onSuccess(days);
      }
    } catch {
      onError();
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Décaler les dates</DialogTitle>
      <DialogContent>
        <DialogContentText>Selectionnez le nombre de jours de décalage :</DialogContentText>
        <TextField
          fullWidth
          margin="normal"
          type="number"
          step="1"
          value={days}
          onChange={(e) => setDays(e.target.value)}
          variant="outlined"
          min="1"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Fermer</Button>
        <Button disabled={parseInt(days) < 1 || loading} variant="contained" color="primary" onClick={handleSubmit}>
          Décaler
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PostproneDialog;
