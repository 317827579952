import React, { useEffect, useState } from "react";
import SideMenu from "../../../Components/Commons/SideMenu";
import {
  Container,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
} from "@material-ui/core";
import text from "../../../utils/text";
import { Artisan } from "../../../utils/icons";
import CommonButton from "../../../Components/FormComponents/CommonButton";
import ModalAddArtisan from "./ModalAddArtisan";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import {
  createArtisan,
  deleteArtisan,
  editArtisanApi,
  getArtisan,
  getCrafts,
} from "../../../Axios/Call/AuthApi";
import { withStyles } from "@material-ui/core/styles";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import ModalGeneral from "../../../Components/Commons/ModalGeneral/ModalGeneral";
import FilterTextList from "../../../Components/Commons/FilterTextList/FilterTextList";
import { useHistory } from "react-router-dom";

const ArtisanList = () => {
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [editArtisan, setEditArtisan] = useState({});
  const [artisans, setArtisans] = useState([]);
  const [filteredArtisans, setFilteredArtisans] = useState([]);
  const [crafts, setCrafts] = useState([]);
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [loadingApiAdd, setLoadingApiAdd] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("company");
  const [errorEmail, setErrorEmail] = useState("");
  const history = useHistory();

  useEffect(() => {
    populateTable();
    getCrafts()
      .then((response) => {
        setCrafts(response.data);
      })
      .catch((error) => {
        setErrorApi(true);
      });
  }, []);

  const populateTable = () => {
    setLoadingApi(true);
    getArtisan()
      .then((response) => {
        setArtisans(response.data);
        setFilteredArtisans(response.data);
        setLoadingApi(false);
      })
      .catch(() => {
        setErrorApi(true);
        setLoadingApi(false);
      });
  };

  const onCreateNewArtisan = (data) => {
    setLoadingApiAdd(true);
    if (Object.keys(editArtisan).length) {
      editArtisanApi(editArtisan.id, data)
        .then((response) => {
          setLoadingApiAdd(false);
          populateTable();
          setOpenModalAdd(false);
          setEditArtisan({});
        })
        .catch((error) => {
          setLoadingApiAdd(false);
          if (error.response && error.response.status === 400) {
            setErrorEmail(text.General.emailTooken);
          } else {
            setErrorApi(true);
          }
        });
    } else {
      createArtisan({
        ...data,
      })
        .then((response) => {
          setLoadingApiAdd(false);
          populateTable();
          setOpenModalAdd(false);
        })
        .catch((error) => {
          setLoadingApiAdd(false);
          if (error.response && error.response.status === 400) {
            setErrorEmail(text.General.emailTooken);
          } else {
            setErrorApi(true);
          }
        });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const StyledTableCell = withStyles((theme) => ({
    root: {
      backgroundColor: "#345369",
      fontSize: 14,
      fontFamily: ["roboto", "-apple-system"].join(","),
      color: "white",
      wordWrap: "break-word",
      border: "none",
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const StyledTableSortLabel = withStyles((theme) => ({
    root: {
      color: "white",
      "&:hover": {
        color: "white",
      },
      "&$active": {
        color: "white",
      },
    },
    active: {},
    icon: {
      color: "inherit !important",
    },
  }))(TableSortLabel);

  const StyledTablePagination = withStyles((theme) => ({
    root: {
      color: "#345369",
    },
    menuItem: {
      fontFamily: ["roboto", "-apple-system"].join(","),
    },
    caption: {
      fontFamily: ["roboto", "-apple-system"].join(","),
    },
    selectIcon: {
      color: "white",
    },
  }))(TablePagination);

  const onDeleteArtisan = () => {
    deleteArtisan(openModalDelete)
      .then((response) => {
        setOpenModalDelete(false);
        populateTable();
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertError}
        />
      )}
      <ModalGeneral
        isOpenModal={openModalDelete}
        title={text.ArtisanList.deleteTitle}
        description={text.ArtisanList.deleteDescription}
        button1Text={"Oui"}
        button2Text={"Annuler"}
        onClick1={() => onDeleteArtisan()}
        onClick2={() => setOpenModalDelete(false)}
        isPassword
      />
      <ModalAddArtisan
        setOpenModalAdd={setOpenModalAdd}
        openModalAdd={openModalAdd}
        onCreateNewArtisan={onCreateNewArtisan}
        loadingApiAdd={loadingApiAdd}
        editArtisan={editArtisan}
        setEditArtisan={setEditArtisan}
        crafts={crafts}
        errorEmail={errorEmail}
      />
      <SideMenu>
        <Container style={{ maxWidth: "none" }}>
          <Grid container style={{paddingTop:20}}>
            <Grid item align="left" style={{ paddingTop: 5 }}>
              <Artisan size={38} fill="#345369" />
            </Grid>
            <Grid item align="left" style={{ paddingLeft: 10 }}>
              <Typography
                variant="h2"
                style={{ borderBottom: "2px solid #345369" }}
              >
                {text.ArtisanList.title}
              </Typography>
            </Grid>
            <Grid item align="left" style={{ paddingLeft: 20 }}>
              <Typography
                variant="h2"
                style={{ cursor: "pointer", color: "#AEB4BE" }}
                onClick={() => history.push("artisans-planning")}
              >
                {"Plannings"}
              </Typography>
            </Grid>
            <Grid item xs={12} align="left">
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  align="left"
                  style={{ marginTop: 20 }}
                >
                  <FilterTextList
                    allItems={artisans}
                    setFiltered={setFilteredArtisans}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  align="left"
                  style={{ marginTop: 20 }}
                >
                  <CommonButton
                    type="button"
                    text={text.ArtisanList.ctaCreate}
                    onClick={() => setOpenModalAdd(true)}
                    style={{ width: 200 }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {loadingApi ? (
                <LoadingButton />
              ) : (
                <Grid
                  container
                  justifyContent="center"
                  style={{ marginTop: 20 }}
                >
                  <Grid item xs={12} md={12}>
                    <TableContainer>
                      <Table style={{ width: "100%" }}>
                        <TableHead className="tableBackground">
                          <TableRow>
                            <StyledTableCell align="left">
                              <StyledTableSortLabel
                                active={orderBy === "company"}
                                direction={
                                  orderBy === "company" ? order : "asc"
                                }
                                onClick={createSortHandler("company")}
                              >
                                {text.ArtisanList.listArtisan}
                              </StyledTableSortLabel>
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              {text.ArtisanList.listJob}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <TableSortLabel
                                active={orderBy === "address"}
                                direction={
                                  orderBy === "address" ? order : "asc"
                                }
                                onClick={createSortHandler("address")}
                              >
                                {text.ArtisanList.listAddress}
                              </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {text.ArtisanList.listPhone}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {text.ArtisanList.listPaye}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {text.ArtisanList.ListWaiting}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {text.ArtisanList.ListDocumentsToDate}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {text.ClientList.listActions}
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {stableSort(
                            filteredArtisans,
                            getComparator(order, orderBy)
                          )
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((artisan) => (
                              <StyledTableRow key={artisan.id}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align={"left"}
                                >
                                  <Typography
                                    component="span"
                                    style={{
                                      color: artisan?.artisan_profile
                                        ?.is_blacklisted
                                        ? "red"
                                        : "none",
                                    }}
                                  >
                                    {artisan?.artisan_profile?.company}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">{`${artisan?.artisan_profile?.craft?.name}`}</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {artisan?.artisan_profile?.address}
                                    {", "}
                                    {artisan?.artisan_profile?.postal_code}
                                    {", "}
                                    {artisan?.artisan_profile?.city}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {artisan?.phone}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {Number(artisan.total_paid).toFixed(2)} €
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  {Number(artisan.total_not_paid).toFixed(2)} €
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: artisan.outdatedFiles
                                      ? "red"
                                      : undefined,
                                  }}
                                  align="left"
                                >
                                  {artisan.outdatedFiles
                                    ? text.General.no
                                    : text.General.yes}
                                </TableCell>
                                <TableCell align="right">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <AiFillEdit
                                      size={24}
                                      color="#345369"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setEditArtisan(artisan);
                                        setOpenModalAdd(true);
                                      }}
                                    />
                                    {/*<AiFillDelete
                                      size={24}
                                      color="#900C3F"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        setOpenModalDelete(artisan.id)
                                      }
                                    />*/}
                                  </div>
                                </TableCell>
                              </StyledTableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <StyledTablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={artisans.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage={text.General.rowXPage}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Container>
      </SideMenu>
    </>
  );
};

export default ArtisanList;
