import text from './text';

export const constant = {};

export const mapTypeToText = {
  admin: 'Administrateur',
  client: 'Client',
  prospect: 'Prospect',
  artisan: 'Artisan',
  chef: 'Chef d’équipe',
  employee: 'Employé',
  enterprise: 'Entreprise extérieure',
};

export const artisanOrderStatus = [
  { id: 'not-sent', name: 'BROUILLON' },
  { id: 'sent', name: 'ENVOYÉ' },
  { id: 'paid', name: 'PAYÉE' },
];

export const supplierOrderStatus = [
  { id: 'not-sent', name: 'BROUILLON' },
  { id: 'sent', name: 'ENVOYÉ' },
  { id: 'paid', name: 'PAYÉE' },
  { id: 'received', name: 'REÇUE' },
];
