import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  FormControl,
  Select,
  InputLabel,
  Tooltip,
} from "@material-ui/core";
import text from "../../../../utils/text";
import {
  editQuote,
  getOneQuote,
  getTemplateCategories,
  getTemplateCategoriesItems,
  getTemplates,
  storeQuote,
} from "../../../../Axios/Call/AuthApi";
import { useParams } from "react-router-dom";
import AlertGeneral from "../../../../Components/Commons/AlertGeneral/AlertGeneral";
import CommonButton from "../../../../Components/FormComponents/CommonButton";
import LoadingButton from "../../../../Components/Commons/LoadingButton/LoadingButton";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import QuoteBody from "../../../../Components/BusinessComponents/CreateEditQuote/QuoteBody";
import Fab from "@mui/material/Fab";
import SaveIcon from "@mui/icons-material/Save";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";

const CreateEditQuote = () => {
  const [quoteObject, setQuoteObject] = useState({
    site_categories: [],
    total_amount: 0,
    total_amount_tva: 0,
    total_amount_no_tva: 0,
  });
  const [templates, setTemplates] = useState([]);
  const [template, setTemplate] = useState("");
  const [templateCategories, setTemplateCategories] = useState([]);
  const [templateCategory, setTemplateCategory] = useState("");
  //Loading and error helper
  const [errorApi, setErrorApi] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [loadingApiGet, setLoadingApiGet] = useState(false);
  const { siteId, quoteId } = useParams();
  const clientMode = useSelector((state) => state.dashboard.clientMode);
  const history = useHistory();
  const [saveAlert, setSaveAlert] = useState(false);
  const [templateVisible, setTemplateVisible] = useState(false);

  useEffect(() => {
    let mounted = true;

    if(mounted) {
    if (quoteId) {
    
      setLoadingApiGet(true);
      getOneQuote(siteId, quoteId)
        .then((response) => {
          setQuoteObject(response.data);
          setLoadingApiGet(false);
        })
        .catch((error) => {
          setErrorApi(true);
        });
      }
    
    getTemplates()
      .then((response) => {
        setTemplates(response.data);
     
      })
      .catch((error) => {});
    }
 

    return function cleanup()  {
      mounted=false;
    }
  }, [quoteId]);

  useEffect(() => {
    let mounted = true;

    if (template) {
      if(mounted) {
      getTemplateCategories(template)
        .then((response) => {
          setTemplateCategories([
            { id: "all", name: "Tous" },
            ...response.data,
          ]);
        })
        .catch((error) => {
          setErrorApi(true);
        });
      }
    }

    return function cleanup()  {
      mounted=false;
    }
  }, [template]);

  const calculatePrice = (objectQuote) => {
    let total_amount = 0;
    let total_amount_tva = 0;
    let total_amount_no_tva = 0;
    objectQuote?.site_categories.forEach((category) => {
      category.site_items.forEach((item) => {
        total_amount_no_tva += item.total_price;
        total_amount_tva += (item.total_price * item.tva) / 100;
      });
    });
    total_amount = total_amount_no_tva + total_amount_tva;

    return { total_amount, total_amount_tva, total_amount_no_tva };
  };

  const numberWithspaces = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  useEffect(() => {

    let mounted = true;
    if (mounted){
      setQuoteObject({
        ...quoteObject,
        ...calculatePrice(quoteObject),
      });
  
    }
  
    return function cleanup()  {
      mounted=false;
    }


  }, [quoteObject.site_categories]);

  // Submit the quote to server
  const createQuoteHandler = (newObject) => {
    if (clientMode) {
      history.push(`/site-detail/${siteId}/invoice-quote/quote`);
      return true;
    }

    let isNew = true;
    if (!newObject) {
      isNew = false;
      newObject = quoteObject;
    }
    /*if (!newObject.name) {
      setNameError(true);
      return;
    }*/

    let sanitizeQuoteObject = {};
    let sanitizeCategories = [];
    let saniteItems = [];
    newObject.site_categories.forEach((category) => {
      if (category.name) {
        saniteItems = [];
        category.site_items.forEach((item) => {
          if (
            (item.type === "artisan" &&
              item.artisan_work_name &&
              item.artisan_id &&
              item.unit_id &&
              item.quantity &&
              item.buy_price &&
              item.selling_price &&
              item.margin_percentage &&
              item.total_price) ||
            (item.type === "article" &&
              item.unit_id &&
              item.quantity &&
              item.buy_price &&
              item.selling_price &&
              item.margin_percentage &&
              item.total_price)
          ) {
            saniteItems.push(item);
          }
        });
        sanitizeCategories.push({ ...category, site_items: saniteItems });
      }
    });

    sanitizeQuoteObject = {
      ...newObject,
      site_categories: sanitizeCategories,
    };

    sanitizeQuoteObject = {
      ...newObject,
      ...calculatePrice(sanitizeQuoteObject),
      site_categories: sanitizeCategories,
    };

    setLoadingApi(true);
    if (newObject.id) {
      editQuote(siteId, newObject.id, {
        ...sanitizeQuoteObject,
        site_id: siteId,
      })
        .then((response) => {
          setLoadingApi(false);
          setQuoteObject(response.data);
        })
        .catch((error) => {
          setLoadingApi(false);
          setErrorApi(true);
        });
    } else {
      storeQuote(siteId, {
        ...sanitizeQuoteObject,
        site_id: siteId,
      })
        .then((response) => {
          setLoadingApi(false);
          setQuoteObject(response.data);
        })
        .catch((error) => {
          setLoadingApi(false);
          setErrorApi(true);
        });
    }

    if (!isNew) {
      // history.push(`/site-detail/${siteId}/invoice-quote/quote`);
      setSaveAlert(!saveAlert);
    }
  };

  const onAddTemplateHandler = () => {
    if (template && templateCategory) {
      getTemplateCategoriesItems(template, templateCategory)
        .then((response) => {
          let newCategories = quoteObject.site_categories;
          let editedTemplate = [];

          //rename template
          response.data.forEach((category, index) => {
            if (!category.parent) {
              editedTemplate.push({
                ...category,
                level: `${index + 999999}.`,
                id: undefined,
              });
            }
            response.data
              .filter((subCate) => subCate.parent === category.level)
              .forEach((subCatFound, subIndex) => {
                editedTemplate.push({
                  ...subCatFound,
                  level: `${index + 999999}.${subIndex + 999999}.`,
                  parent: `${index + 999999}.`,
                  id: undefined,
                });
                response.data
                  .filter((subCate2) => subCate2.parent === subCatFound.level)
                  .forEach((subCatFound2, subIndex2) => {
                    editedTemplate.push({
                      ...subCatFound2,
                      level: `${index + 999999}.${subIndex + 999999}.${
                        subIndex2 + 999999
                      }.`,
                      parent: `${subIndex + 999999}.`,
                      id: undefined,
                    });
                  });
              });
          });

          editedTemplate = editedTemplate.map((category, index) => {
            return {
              ...category,
              site_items: category.site_items.map((item) => {
                return { ...item, id: undefined };
              }),
            };
          });

          editedTemplate.forEach((category) => {
            newCategories.push(category);
          });

          let renameCategory = [];
          newCategories
            .filter((category) => !category.parent)
            .forEach((category, index) => {
              renameCategory.push({ ...category, level: `${index + 1}.` });
            });

          newCategories.forEach((category, index) => {
            newCategories
              .filter((subCate) => subCate.parent === category.level)
              .forEach((subCatFound, subIndex) => {
                renameCategory.push({
                  ...subCatFound,
                  level: `${index + 1}.${subIndex + 1}.`,
                  parent: `${index + 1}.`,
                });
                newCategories
                  .filter((subCate2) => subCate2.parent === subCatFound.level)
                  .forEach((subCatFound2, subIndex2) => {
                    renameCategory.push({
                      ...subCatFound2,
                      level: `${index + 1}.${subIndex + 1}.${subIndex2 + 1}.`,
                      parent: `${subIndex + 1}.`,
                    });
                  });
              });
          });

          setQuoteObject({ ...quoteObject, site_categories: renameCategory });
        })
        .catch((error) => {
          console.log(error);
          setErrorApi(true);
        });
    }
  };

  const calculateMerge = () => {
    let calculateBuyPrice = 0;
    let marginTotal = 0;
    let calculateSellingPrice = 0;

    quoteObject.site_categories.forEach((category) => {
      category?.site_items.forEach((item) => {
        calculateBuyPrice += parseFloat(item.buy_price);
        calculateSellingPrice += parseFloat(item.selling_price);
      });
    });

    if (calculateBuyPrice && calculateSellingPrice) {
      marginTotal = (
        ((calculateSellingPrice - calculateBuyPrice) * 100) /
        calculateSellingPrice
      ).toFixed(1);
    }

    return marginTotal;
  };

  const calculateMergePrice = () => {
    let calculateBuyPrice = 0;
    let calculateSellingPrice = 0;

    quoteObject.site_categories.forEach((category) => {
      category?.site_items.forEach((item) => {
        calculateBuyPrice += parseFloat(item.buy_price) * item.quantity;
        calculateSellingPrice += parseFloat(item.selling_price) * item.quantity;
      });
    });

    return (calculateSellingPrice - calculateBuyPrice).toFixed(0);
  };

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertError}
        />
      )}

      {saveAlert && (
        <AlertGeneral color="success" description={"Devis enregistré !"} />
      )}

      {loadingApiGet ? (
        <LoadingButton />
      ) : (
        <>
    
          <Grid container style={{ backgroundColor:'#FFF', padding:20,marginTop:20,boxShadow: "#34536950 0px 7px 29px 0px",borderRadius: 10,}} spacing={1} >
      
     <Grid item md={4} >
  
       {templates.length > 0 && (
         <FormControl size="small" variant="outlined" fullWidth>
           <InputLabel style={{backgroundColor:'#FFF'}}>Choisir un modèle</InputLabel>
           <Select
             native
             label={"Modèle"}
             value={template}
             onChange={(event) => {
               setTemplate(event.target.value);
             }}
             name={"template"}
           >
             <option value={" "}></option>
             {templates.map((option) => {
               return (
                 <option key={option.id} value={option.id}>
                   {option.name}
                 </option>
               );
             })}
           </Select>
         </FormControl>
       )}
     </Grid>
     <Grid item md={4} >
       {templateCategories.length > 0 && (
         <FormControl size="small" variant="outlined" fullWidth>
           <InputLabel>Corps</InputLabel>
           <Select
             native
             label={"Corps"}
             value={templateCategory}
             onChange={(event) => {
               setTemplateCategory(event.target.value);
             }}
             name={"template_category"}
           >
             <option value={" "}></option>
             {templateCategories.map((option) => {
               return (
                 <option key={option.id} value={option.id}>
                   {option.name}
                 </option>
               );
             })}
           </Select>
         </FormControl>
       )}
     </Grid>
     <Grid item md={4} >
     {templateCategory && <CommonButton
         text={"Ajouter au devis"}
         onClick={() => onAddTemplateHandler()}
  
         disabled={!template && !templateCategory}
       />}
     </Grid>
     </Grid>


     <Grid container style={{paddingTop:50}}>
            <Grid item xs={6} align="left">
              <Typography variant="h3" style={{ fontWeight: 600 }}>
                {quoteObject.name}
              </Typography>
             
            </Grid>
          </Grid>





          <QuoteBody
            createQuoteHandler={createQuoteHandler}
            setQuoteObject={setQuoteObject}
            quoteObject={quoteObject}
            isQuote
          />
        </>
      )}

      <Grid
        container
        style={{ marginTop: 20, marginBottom: 100 }}
        justifyContent="flex-end"
      >
        {!clientMode && (
          <>
            <Grid
              item
              xs={12}
              align="right"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <Typography
                variant="h6"
                style={{ fontWeight: 400, fontSize: "16px" }}
              >
                Marge : {numberWithspaces(calculateMergePrice())} € ({" "}
                {numberWithspaces(calculateMerge())} % )
              </Typography>
            </Grid>
          </>
        )}

        <Grid
          item
          md={2}
          xs={12}
          align="right"
          style={{ borderTop: "1px solid #34536930" }}
        />
        <Grid item xs={12} align="right" style={{ marginTop: 10 }}>
          <Typography variant="h6" style={{ fontSize: "25px" }}>
            Total HT :{" "}
            {numberWithspaces(quoteObject?.total_amount_no_tva?.toFixed(2))} €
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          align="right"
          style={{ fontWeight: 400, marginTop: 10 }}
        >
          <Typography
            variant="h6"
            style={{ fontWeight: 400, fontSize: "15px" }}
          >
            TVA : {numberWithspaces(quoteObject?.total_amount_tva?.toFixed(2))}{" "}
            €
          </Typography>
        </Grid>
        <Grid item xs={12} align="right">
          <Typography variant="h6" style={{ fontSize: "15px" }}>
            Total TTC :{" "}
            {numberWithspaces(quoteObject?.total_amount?.toFixed(2))} €
          </Typography>
        </Grid>

        <Grid
          container
          spacing={3}
          style={{
            width: 220,
            position: "fixed",
            bottom: 20,
            right: 30,
            zIndex: 1000,
            boxShadow: "#34536950 0px 7px 29px 0px",
            borderRadius: 10,
            backgroundColor: "#FFF",
            height: 80,
          }}
        >
          <Grid item xs={4}>
            <Tooltip title="Enregistrer">
              <Fab
                aria-label="save"
                style={{ position: "fixed", bottom: 20, right: 30, backgroundColor:'#345369', color:"#FFF" }}
              >
                <SaveIcon onClick={() => createQuoteHandler()} />
              </Fab>
            </Tooltip>

            <Tooltip title="Remonter">
              <Fab
                aria-label="up"
                style={{ position: "fixed", bottom: 20, right: 100, backgroundColor:'#345369', color:"#FFF" }}
              >
                <ArrowCircleUpIcon
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                />
              </Fab>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateEditQuote;
