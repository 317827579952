import React, { useEffect, useState } from "react";
import SideMenu from "../../../Components/Commons/SideMenu";
import {
  Container,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
} from "@material-ui/core";
import text from "../../../utils/text";
import { Business } from "../../../utils/icons";
import CommonButton from "../../../Components/FormComponents/CommonButton";
import ModalAddBusinesses from "./ModalAddBusinesses";
import {
  createBusiness,
  deleteBusiness,
  editBusinessApi,
  getBusinesses,
  getRoles,
} from "../../../Axios/Call/AuthApi";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";
import { withStyles } from "@material-ui/core/styles";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import ModalGeneral from "../../../Components/Commons/ModalGeneral/ModalGeneral";
import moment from "moment";
import FilterTextList from "../../../Components/Commons/FilterTextList/FilterTextList";

const BusinessesList = () => {
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [editBusiness, setEditBusiness] = useState({});
  const [roles, setRoles] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [filteredBusiness, setFilteredBusiness] = useState([]);
  const [loadingApi, setLoadingApi] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [loadingApiAdd, setLoadingApiAdd] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [errorEmail, setErrorEmail] = useState("");

  useEffect(() => {
    populateTable();
    getRoles()
      .then((response) => {
        setRoles(response.data);
      })
      .catch((error) => {
        setErrorApi(true);
      });
  }, []);

  const populateTable = () => {
    setLoadingApi(true);
    getBusinesses()
      .then((response) => {
        setBusinesses(response.data);
        setFilteredBusiness(response.data);
        setLoadingApi(false);
      })
      .catch(() => {
        setErrorApi(true);
        setLoadingApi(false);
      });
  };

  const onCreateNewBusiness = (data) => {
    setLoadingApiAdd(true);
    if (Object.keys(editBusiness).length) {
      editBusinessApi(editBusiness.id, {
        ...data,
        dob: moment(data.dob).format("yyyy-MM-DD"),
      })
        .then((response) => {
          setLoadingApiAdd(false);
          populateTable();
          setOpenModalAdd(false);
          setEditBusiness({});
        })
        .catch((error) => {
          setLoadingApiAdd(false);
          if (error.response && error.response.status === 400) {
            setErrorEmail(text.General.emailTooken);
          } else {
            setErrorApi(true);
          }
        });
    } else {
      createBusiness({
        ...data,
        dob: moment(data.dob).format("yyyy-MM-DD"),
      })
        .then((response) => {
          setLoadingApiAdd(false);
          populateTable();
          setOpenModalAdd(false);
        })
        .catch((error) => {
          setLoadingApiAdd(false);
          if (error.response && error.response.status === 400) {
            setErrorEmail(text.General.emailTooken);
          } else {
            setErrorApi(true);
          }
        });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const StyledTableCell = withStyles((theme) => ({
    root: {
      backgroundColor: "#345369",
      fontSize: 14,
      fontFamily: ["roboto", "-apple-system"].join(","),
      color: "white",
      wordWrap: "break-word",
      border: "none",
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const StyledTableSortLabel = withStyles((theme) => ({
    root: {
      color: "white",
      "&:hover": {
        color: "white",
      },
      "&$active": {
        color: "white",
      },
    },
    active: {},
    icon: {
      color: "inherit !important",
    },
  }))(TableSortLabel);

  const StyledTablePagination = withStyles((theme) => ({
    root: {
      color: "#345369",
    },
    menuItem: {
      fontFamily: ["roboto", "-apple-system"].join(","),
    },
    caption: {
      fontFamily: ["roboto", "-apple-system"].join(","),
    },
    selectIcon: {
      color: "white",
    },
  }))(TablePagination);

  const onDeleteBusiness = () => {
    deleteBusiness(openModalDelete)
      .then((response) => {
        setOpenModalDelete(false);
        populateTable();
      })
      .catch((error) => {
        setErrorApi(true);
      });
  };

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertError}
        />
      )}
      <ModalGeneral
        isOpenModal={openModalDelete}
        title={text.BusinessesList.deleteTitle}
        description={text.BusinessesList.deleteDescription}
        button1Text={"Supprimer l'utilisateur"}
        button2Text={"Annuler"}
        onClick1={() => onDeleteBusiness()}
        onClick2={() => setOpenModalDelete(false)}
        isPassword
      />
      <ModalAddBusinesses
        setOpenModalAdd={setOpenModalAdd}
        openModalAdd={openModalAdd}
        onCreateNewBusiness={onCreateNewBusiness}
        loadingApiAdd={loadingApiAdd}
        editBusiness={editBusiness}
        setEditBusiness={setEditBusiness}
        roles={roles}
        errorEmail={errorEmail}
      />
      <SideMenu>
        <Container style={{ maxWidth: "none", paddingTop:20}}>
          <Grid container>
            <Grid
              item
              align="left"
              style={{ paddingTop: 10 }}
              className="my-auto"
            >
              <Business fill="#345369" size={38} />
            </Grid>
            <Grid
              item
              align="left"
              style={{ paddingLeft: 10, marginTop: 10 }}
              className="my-auto"
            >
              <Typography variant="h2" >{text.BusinessesList.title}</Typography>
            </Grid>
            <Grid item xs={12} align="left">
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  align="left"
                  style={{ marginTop: 20 }}
                >
                  <FilterTextList
                    allItems={businesses}
                    setFiltered={setFilteredBusiness}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  align="left"
                  style={{ marginTop: 20 }}
                >
                  <CommonButton
                    type="button"
                    text={text.BusinessesList.ctaCreate}
                    onClick={() => setOpenModalAdd(true)}
                    style={{ width: 250 }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {loadingApi ? (
                <LoadingButton />
              ) : (
                <Grid
                  container
                  justifyContent="center"
                  style={{ marginTop: 20 }}
                >
                  <Grid item xs={12} md={12}>
                    <TableContainer>
                      <Table style={{ width: "100%" }}>
                        <TableHead className="tableBackground">
                          <TableRow>
                            <StyledTableCell align="left">
                              <StyledTableSortLabel
                                active={orderBy === "company"}
                                direction={
                                  orderBy === "company" ? order : "asc"
                                }
                                onClick={createSortHandler("company")}
                              >
                                {text.BusinessesList.listUsers}
                              </StyledTableSortLabel>
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              <TableSortLabel
                                active={orderBy === "surname"}
                                direction={
                                  orderBy === "surname" ? order : "asc"
                                }
                                onClick={createSortHandler("surname")}
                              >
                                {text.BusinessesList.listRole}
                              </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <TableSortLabel
                                active={orderBy === "email"}
                                direction={orderBy === "email" ? order : "asc"}
                                onClick={createSortHandler("email")}
                              >
                                {text.BusinessesList.listEmail}
                              </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <TableSortLabel
                                active={orderBy === "phone"}
                                direction={orderBy === "phone" ? order : "asc"}
                                onClick={createSortHandler("phone")}
                              >
                                {text.BusinessesList.listTelefone}
                              </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {text.BusinessesList.listConexion}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {text.ClientList.listActions}
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {stableSort(
                            filteredBusiness,
                            getComparator(order, orderBy)
                          )
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((business) => (
                              <StyledTableRow key={business.id}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align={"left"}
                                >
                                  <Typography component="span">
                                    {`${business.name} ${business.surname}`}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {
                                      roles?.find(
                                        (role) => role.id === business.role_id
                                      )?.name
                                    }
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {business.email}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {business?.mobile}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography component="span">
                                    {business?.connection_number}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <AiFillEdit
                                      size={24}
                                      color="#345369"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setEditBusiness(business);
                                        setOpenModalAdd(true);
                                      }}
                                    />
                                    <AiFillDelete
                                      size={24}
                                      color="#900C3F"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        setOpenModalDelete(business.id)
                                      }
                                    />
                                  </div>
                                </TableCell>
                              </StyledTableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <StyledTablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={businesses.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage={text.General.rowXPage}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Container>
      </SideMenu>
    </>
  );
};

export default BusinessesList;
