import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import fr from 'date-fns/locale/fr';
import { Typography, TextField, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import text from '../../utils/text';
import moment from 'moment';

const useStyles = makeStyles({
  label: {
    fontFamily: ['Roboto', '-apple-system'].join(','),
    fontWeight: 700,
    color: '#345369',
  },
  input: {
    fontFamily: ['Roboto', '-apple-system'].join(','),
    fontSize: 14,
  },
  datePicker: {
    '& .MuiToolbar-root': {
      backgroundColor: '#345369 !important',
    },
    '& .MuiPickersDay-daySelected': {
      backgroundColor: '#345369 !important',
    },
    '& .MuiButton-label': {
      color: '#345369 !important',
    },
  },
});

const CommonDateTimePicker = ({
  field,
  name,
  error,
  label,
  labelCustom,
  minDate,
  maxDate,
  minDateMessage,
  labelColor,
  fontSize,
}) => {
  const classes = useStyles();
  return (
    <>
      <Grid container>
        {labelCustom && (
          <Grid item xs={12} align="left">
            <Typography component="span" className={classes.label} style={{ color: labelColor }}>
              {labelCustom}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} align="left" style={{ marginTop: -10 }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr} variant="outlined">
            <KeyboardDatePicker
              margin="normal"
              name={name}
              DialogProps={{ className: classes.datePicker }}
              id="date-picker-dialog"
              label={label}
              format="dd/MM/yyyy"
              value={field.value}
              onChange={(date) => field.onChange(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              error={!!error}
              invalidDateMessage={
                <Typography component="span" style={{ color: 'red' }}>
                  {text.General.errorDate}
                </Typography>
              }
              minDate={minDate}
              maxDate={maxDate}
              minDateMessage={minDateMessage}
             
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>

      <Typography component="span" style={{ color: 'red' }}>
        {error?.message}
      </Typography>
    </>
  );
};

export default CommonDateTimePicker;
