import { Container, Grid, Typography } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import SideMenu from "../../../Components/Commons/SideMenu";
import text from "../../../utils/text";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import TextBoxCustom from "../../../Components/FormComponents/TextBoxCustom";
import TextFieldCustom from "../../../Components/FormComponents/TextFieldCustom";
import CommonButton from "../../../Components/FormComponents/CommonButton";
import {
  createTemplate,
  getOneTemplate,
  editTemplate,
} from "../../../Axios/Call/AuthApi";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";
import QuoteBody from "../../../Components/BusinessComponents/CreateEditQuote/QuoteBody";

const TemplateDetail = () => {
  const [template, setTemplate] = useState({
    site_categories: [],
    total_amount: 0,
    total_amount_tva: 0,
    total_amount_no_tva: 0,
  });
  //Loading and error helper
  const [loadingApi, setLoadingApi] = useState(false);
  const [loadingApiGet, setLoadingApiGet] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const history = useHistory();
  const { templateId } = useParams();
  const { register, handleSubmit, errors, setError, watch, control, reset } =
    useForm({
      mode: "onBlur",
    }); // initialise the hook

  useEffect(() => {
    if (templateId) {
      setLoadingApiGet(true);
      getOneTemplate(templateId)
        .then((response) => {
          setTemplate(response.data);
          reset(response.data);
          setLoadingApiGet(false);
        })
        .catch((error) => {
          setLoadingApiGet(false);
          setErrorApi(true);
        });
    }
  }, [templateId]);

  const calculatePrice = (objectQuote) => {
    let total_amount = 0;
    let total_amount_tva = 0;
    let total_amount_no_tva = 0;
    objectQuote?.site_categories.forEach((category) => {
      category.site_items.forEach((item) => {
        total_amount_no_tva += item.total_price;
        total_amount_tva += (item.total_price * item.tva) / 100;
      });
    });
    total_amount = total_amount_no_tva + total_amount_tva;

    return { total_amount, total_amount_tva, total_amount_no_tva };
  };

  const createTemplateHandler = (newObject) => {
    if (!watch("name")) {
      setError("name", {
        message: text.General.required,
      });
    }
    let isNew = true;
    if (!newObject) {
      isNew = false;
      newObject = template;
    }

    let sanitizeQuoteObject = {};
    let sanitizeCategories = [];
    let saniteItems = [];
    newObject.site_categories.forEach((category) => {
      if (category.name) {
        saniteItems = [];
        category.site_items.forEach((item) => {
          if (
            (item.type === "artisan" &&
              item.artisan_work_name &&
              item.artisan_id &&
              item.unit_id &&
              item.quantity &&
              item.buy_price &&
              item.selling_price &&
              item.margin_percentage &&
              item.total_price) ||
            (item.type === "article" &&
              item.unit_id &&
              item.quantity &&
              item.buy_price &&
              item.selling_price &&
              item.margin_percentage &&
              item.total_price)
          ) {
            saniteItems.push(item);
          }
        });
        sanitizeCategories.push({ ...category, site_items: saniteItems });
      }
    });

    sanitizeQuoteObject = {
      ...newObject,
      site_categories: sanitizeCategories,
    };

    sanitizeQuoteObject = {
      ...newObject,
      ...calculatePrice(sanitizeQuoteObject),
      site_categories: sanitizeCategories,
    };

    setLoadingApi(true);
    if (newObject.id) {
      editTemplate(template.id, {
        ...sanitizeQuoteObject,
        name: watch("name"),
        description: watch("description"),
      })
        .then((response) => {
          setLoadingApi(false);
          setTemplate(response.data);
        })
        .catch((error) => {
          setLoadingApi(false);
          setErrorApi(true);
        });
    } else {
      createTemplate({
        ...sanitizeQuoteObject,
        name: watch("name"),
        description: watch("description"),
      })
        .then((response) => {
          setLoadingApi(false);
          setTemplate(response.data);
        })
        .catch((error) => {
          setLoadingApi(false);
          setErrorApi(true);
        });
    }

    if (!isNew) {
      history.push(`/templates`);
    }
  };

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description={text.General.alertError}
        />
      )}
      <SideMenu>
        <Container style={{ maxWidth: "none" }}>
          {loadingApiGet ? (
            <LoadingButton />
          ) : (
            <>
              <Grid container style={{paddingTop:20}}>
                <Grid item align="left" style={{ paddingTop: 5 }}>
                  <Settings size={38} fill="#345369" />
                </Grid>
                <Grid item align="left" style={{ paddingLeft: 10 }}>
                  <Typography
                    variant="h2"
                    onClick={() => history.push("/configuration")}
                    style={{ cursor: "pointer", color: "#AEB4BE" }}
                  >
                    {text.Configuration.title}
                  </Typography>
                </Grid>
                <Grid item align="left" style={{ paddingLeft: 10 }}>
                  <Typography
                    variant="h2"
                    style={{ borderBottom: "2px solid #345369" }}
                  >
                    Modèles devis
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <form
                  key={1}
                  onSubmit={handleSubmit(createTemplateHandler)}
                  style={{ marginTop: 20 }}
                >
                  <Grid container style={{ marginTop: 40 }}>
                    <Grid item xs={12} md={6}>
                      <TextFieldCustom
                        label={"Nom du modèle"}
                        type="input"
                        ref={register({})}
                        defaultValue=""
                        name="name"
                        error={errors.name}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CommonButton
                        type="button"
                        onClick={() => createTemplateHandler()}
                        loadingApi={loadingApi}
                        text={"Enregistrer le modèle"}
                        style={{ width: 200 }}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                      <TextBoxCustom
                        label={"Description du modèle"}
                        type="input"
                        ref={register({
                          required: text.General.required,
                        })}
                        defaultValue=""
                        name="description"
                        error={errors.description}
                      />
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              <QuoteBody
                createQuoteHandler={createTemplateHandler}
                setQuoteObject={setTemplate}
                quoteObject={template}
              />
            </>
          )}
        </Container>
      </SideMenu>
    </>
  );
};

export default TemplateDetail;
