import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { useState } from 'react';
import { getOneQuote, storeQuote } from '../../../Axios/Call/AuthApi';

/**
 *
 * @param {Object} props
 * @param {Boolean} open
 * @param {Function} onClose
 * @param {Function} onError
 * @param {Function} onSuccess
 * @param {Number} quoteId
 * @param {Number} siteId
 * @returns {import("react").ReactElement}
 */
function DuplicateQuoteDialog({ open, onClose, onError, onSuccess, quoteId, siteId }) {
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleClone = async () => {
    setLoading(true);
    try {
      const quote = (await getOneQuote(siteId, quoteId)).data;
      const created = (await storeQuote(siteId, quote)).data;
      onSuccess(created);
    } catch {
      onError();
    } finally {
      setLoading();
      handleClose();
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>Êtes-vous certain de vouloir dupliquer ce devis ?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annuler</Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<FileCopyOutlinedIcon />}
          onClick={handleClone}
          disabled={loading}
        >
          Dupliquer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DuplicateQuoteDialog;
